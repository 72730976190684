import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { memo } from 'react'

const Modal = ({
  open,
  close,
  titulo,
  size,
  children,
  fullWidth,
  fullScreen,
  fullScreenOnlyMobile,
  forceOpen,
  ignoreStopPropagation,
  modalBorderColor
}) => {
  const theme = useTheme()
  const fullScreenResponsive = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Dialog
      open={open}
      onClick={event => {
        if (!ignoreStopPropagation) event.stopPropagation()
      }}
      onFocus={event => {
        if (!ignoreStopPropagation) event.stopPropagation()
      }}
      onClose={!forceOpen ? close : null}
      aria-labelledby="customized-dialog-title"
      maxWidth={size || 'md'}
      fullWidth={fullWidth}
      fullScreen={
        fullScreenOnlyMobile ? fullScreenResponsive : fullScreen || false
      }
      className={`${modalBorderColor === 'green' ? 'modal-border-green' : ''}`}
    >
      <DialogTitle
        id="form-dialog-title"
        className={`${fullScreen || fullScreenOnlyMobile ? 'color' : ''}`}
      >
        <Typography datatest-id={'titulo_test_id'}>{titulo}</Typography>
        {!forceOpen && (
          <IconButton aria-label="close" onClick={close} size="large">
            <CloseIcon
              datatest-id={'close_modal_test_id'}
              id={'close_modal_btn'}
            />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent datatest-id={'content_test_id'} dividers>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default memo(Modal)
