import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

import { useConfiguracao } from '@hook/useConfiguracao'
import { useProjeto } from '@hook/useProjeto'

import Utils from '@service/serviceUtils.js'

const ResumoDescritivoProjeto = () => {
  const { servicos } = useConfiguracao()
  const { projeto, otimizacao, cliente, lojaCongifOtmizacao } = useProjeto()
  const [qtdPecas] = React.useState(
    Utils.retornaQuantidadePecas(projeto.projetoAmbiente)
  )
  const materiasUtilizados = Utils.extraiChapasEmUso(
    otimizacao,
    projeto.projetoProduto
  )
  const fitasUtilizadas = Utils.extraiFitasEmUso(
    otimizacao,
    projeto.projetoProduto
  )

  const exibeListaServicos = () => {
    if (
      otimizacao.length &&
      projeto.projetoProduto.length &&
      lojaCongifOtmizacao.minimoChapaCorte >= 0
    ) {
      const materiaisComBordeamento = Utils.addServicoBordeamento(
        projeto.projetoProduto,
        fitasUtilizadas,
        servicos
      )

      const listaServicos = Utils.extrairServico(
        materiaisComBordeamento,
        servicos
      )

      if (listaServicos.length > 0) {
        return listaServicos.map((mat, indexMat) => {
          return (
            <div key={indexMat} className="lineResumoItem">
              <span>
                {indexMat + 1} - {mat.idErp}: {mat.descricao}
              </span>
              <span>
                {mat.quantidadeComAcrecimo ? mat.quantidadeComAcrecimo : '...'}
              </span>
            </div>
          )
        })
      }
    } else return null
  }
  const exibeListaFitas = () => {
    return (
      <>
        {fitasUtilizadas.map((mat, indexMat) => {
          return (
            <div key={indexMat} className="lineResumoItem">
              <span>
                {indexMat + 1} - {mat.codigo}: {mat.descricao}
              </span>
              <span>
                {mat.aplicacaoFita ? mat.aplicacaoFita : 0} m
              </span>
            </div>
          )
        })}
      </>
    )
  }
  const exibeListaChapas = () => {
    if (materiasUtilizados.length > 0) {
      return materiasUtilizados.map((mat, indexMat) => {
        return (
          <div key={indexMat} className="lineResumoItem">
            <span>
              {indexMat + 1} - {mat.codigo}: {mat.descricao}{' '}
              {Utils.descricaoCanaletado(mat)}
            </span>
            <span>{mat.qtdPlacasCortadas}</span>
          </div>
        )
      })
    }
    return null
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6 col-lg-5">
        <div className="box">
          <div>
            <span className="titulo">Projeto: </span>
            {projeto.id} - {projeto.nome}
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-7">
        <div className="box">
          <div>
            <span className="titulo">Cliente: </span>
            {cliente?.nome}
          </div>
        </div>
      </div>
      {projeto.tipoCorte !== 4 && !isMobile && (
        <div className="col-12">
          <div className="box">
            <div>
              <span className="titulo">Ambientes: </span>
            </div>
            {projeto?.projetoAmbiente?.map((amb, indexAmb) => {
              return (
                <div key={indexAmb}>
                  {indexAmb + 1} - {amb.descricao}
                </div>
              )
            })}
          </div>
        </div>
      )}
      <div className="col-12">
        <div className="box">
          <div className="lineResumoTitle">
            <span className="titulo">Total de Tipos de Chapas: </span>{' '}
            <span className="titulo">Quantidade: </span>
          </div>
          {exibeListaChapas()}
        </div>
      </div>
      {fitasUtilizadas.length > 0 && (
        <div className="col-12">
          <div className="box">
            <div className="lineResumoTitle">
              <span className="titulo">Total de Fitas: </span>
              <span className="titulo">Quantidade: </span>
            </div>
            {exibeListaFitas()}
          </div>
        </div>
      )}
      <div className="col-12">
        <div className="box">
          <div className="lineResumoTitle">
            <span className="titulo">Total de Serviços: </span>
            <span className="titulo">Quantidade: </span>
          </div>
          {exibeListaServicos()}
        </div>
      </div>
      <div className="col-sm-6 col-md-3 col-lg-3">
        <div className="box">
          <div>
            <span className="titulo">Total Qtd. de Peças: </span>
            {qtdPecas}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(ResumoDescritivoProjeto)
