import { Tooltip } from '@mui/material'
import { memo } from 'react'

const TooltipListaFitas = ({ fitasSelecionadas, children }) => {
  return (
    <Tooltip
      arrow
      title={
        <div className="p-1">
          {fitasSelecionadas.map(fita => {
            return (
              <div className="ItemFita my-2" key={fita.codigo}>
                <span style={{ backgroundColor: fita.color }}></span>
                <strong>{fita.codigo}</strong> - {fita.descricao}
              </div>
            )
          })}
        </div>
      }
    >
      {children}
    </Tooltip>
  )
}

export default memo(TooltipListaFitas)
