import InfoMsg from '@component/InfoMsg/InfoMsg'

const ResumoAlertInfo = ({ invalidMaterial, hasIgnorarVeio }) => {
  return (
    <>
      {invalidMaterial && (
        <div className="noPrint">
          <InfoMsg
            type="error"
            title="Material Inválido"
            msg="O projeto contem um material inválido, por favor verifique os ambientes na etapa anterior e re-otimize o plano."
          />
        </div>
      )}
      <div className="noPrint">
        <InfoMsg
          type="warning"
          title="Atenção! "
          msg="Confira as informações do seu plano, gere orçamentos e baixe seu projeto."
        />
      </div>
      {hasIgnorarVeio && (
        <div className="mb20">
          <InfoMsg
            type="warning"
            title="Atenção"
            msg="Esse projeto contem peças com veio marcadas para 'ignorar veio', isso pode gerar peças com cortes que não sigam o sentido do veio. Confira a relação de peças a baixo:"
          />
        </div>
      )}
    </>
  )
}

export default ResumoAlertInfo
