import ImgMdfDefault from '@asset/img/mdf-defalut.PNG'
import React, { memo } from 'react'

import { useProjeto } from '@hook/useProjeto.js'

import FitasMiniatura from '@component/FitasMiniatura'

import Utils from '@base/services/serviceUtils'

import sistemasFixacaoMenu from '@mock/sistemasFixacao.js'

import { FitasLista } from './helper/index.js'

import './ListagemPecas.scss'

export const getDescricaoServico = peca => {
  return Utils.mapNomeServico(peca?.projetoPecaServico[0]?.tipoServico)
}

export const getDescricaoFixacao = peca => {
  return sistemasFixacaoMenu.find(
    sistema => sistema.id === peca?.projetoPecaServico[0]?.sistemaFixacaoId
  )?.nome
}

export const getDescricaoConector = peca => {
  if (peca?.projetoPecaServico[0]?.fixacaoConector === 1) {
    return sistemasFixacaoMenu.find(
      sistema => sistema.id === peca?.projetoPecaServico[0]?.sistemaFixacaoId
    )?.conectorPrimario
  }

  return sistemasFixacaoMenu.find(
    sistema => sistema.id === peca?.projetoPecaServico[0]?.sistemaFixacaoId
  )?.conectorSecundario
}

const ListagemPecas = () => {
  const { projeto } = useProjeto()

  const getMaterialInfo = materialId => {
    return projeto.projetoProduto.find(material => material.id === materialId)
  }

  const getStyle = peca => {
    const primaria = peca?.projetoPecaServico[0]?.projetoPecaSecundariaId
      ? 'primariaResumo'
      : ''
    const secundaria = peca?.projetoPecaServico[0]?.projetoPecaPrimariaId
      ? 'secundariaResumo'
      : ''

    return 'pecaResumo ' + primaria + secundaria
  }

  return (
    <div className="px-4">
      {projeto.projetoAmbiente.map(ambiente => (
        <div key={ambiente.id}>
          <div className="ambiente">
            <h2 className="nome-ambiente">{ambiente.descricao}</h2>
          </div>

          {ambiente.projetoPeca.map(peca => {
            const materialInfo = getMaterialInfo(peca.materialId)
            const { codigo, descricao, canaletado, veio } = materialInfo
            const imageUrl = Utils.getImageUrl(materialInfo.codigo)

            return (
              <div
                key={peca.id}
                className={`${getStyle(peca)} break-inside-avoid`}
              >
                <div className="flex w-4/12">
                  <div className="tableProdThumb">
                    <img
                      className="w-10 h-10 mr-2"
                      src={imageUrl || ImgMdfDefault}
                      onError={event => {
                        event.target.src = ImgMdfDefault
                      }}
                      alt=""
                    />
                    {canaletado ? <div className="canaletaThumb" /> : ''}
                  </div>
                  <div className="w-8/12">
                    <b>{codigo}</b> - {descricao}
                    {canaletado ? (
                      <div className="text-danger">
                        Canaletado: {canaletado} polegadas
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                <div className="flex flex-col justify-center w-2/12">
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <b>Qtd:</b> {peca.quantidade}
                  </div>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <b>Comp:</b> {peca.largura}
                  </div>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <b>Larg:</b> {peca.altura}
                  </div>
                  <div>
                    <b>Veio: </b>
                    {veio ? 'sim' : 'não'}
                  </div>
                  {veio && (
                    <div>
                      <b>Ignorar veio:</b> {!peca.veio ? 'sim' : 'não'}
                    </div>
                  )}
                </div>
                <div className="w-1/12">
                  <FitasMiniatura
                    peca={peca}
                    materiais={projeto.projetoProduto}
                  />
                </div>

                <div className="w-3/12">
                  <FitasLista peca={peca} materiais={projeto.projetoProduto} />
                </div>

                {peca?.projetoPecaServico?.length ? (
                  <div>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <b>Serviços:</b> {getDescricaoServico(peca)}
                    </div>

                    <div style={{ whiteSpace: 'nowrap' }}>
                      <b>Fixação:</b> {getDescricaoFixacao(peca)}
                    </div>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <b>Conector:</b> {getDescricaoConector(peca)}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default memo(ListagemPecas)
