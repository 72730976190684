import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import { useProjeto } from '@hook/useProjeto.js'

import InfoMsg from '@component/InfoMsg/InfoMsg.js'

import DialogAddAmbiente from './DialogAddAmbiente.js'

const DialogAmbienteImport = props => {
  const {
    openModalAmbiente,
    handleCloseModalAmbiente,
    targetAmbiente,
    udtTargetAmbiente,
    aplicaDeParaAmbiente,
    criarAmbienteViaApi
  } = props
  const { projeto } = useProjeto()
  const [targetAmbienteLocalId, setTargetAmbienteLocalId] = React.useState('')
  const [listaAmbientes, setListaAmbientes] = React.useState([])

  const [newAmbienteName, setNewAmbienteName] = React.useState('')
  const [openModalNewAmbiente, setOpenModalNewAmbiente] = React.useState(false)
  const handleOpenModalNewAmbiente = () => {
    setOpenModalNewAmbiente(true)
  }
  const handleCloseModalNewAmbiente = () => {
    setOpenModalNewAmbiente(false)
  }

  const updateTarget = value => {
    const newTarget = targetAmbiente
    newTarget.idAmbienteFinal = value
    udtTargetAmbiente(newTarget)
    setTargetAmbienteLocalId(value)
  }

  useEffect(() => {
    if (projeto?.projetoAmbiente?.length) {
      setListaAmbientes(projeto.projetoAmbiente)
    }
  }, [projeto?.projetoAmbiente])

  useEffect(() => {
    if (listaAmbientes.length) {
      const newAmb = listaAmbientes.find(
        amb => amb.descricao === newAmbienteName
      )
      if (newAmb) {
        updateTarget(newAmb.id)
      }
    }
  }, [listaAmbientes])

  useEffect(() => {
    setTargetAmbienteLocalId(targetAmbiente.idAmbienteFinal)
  }, [targetAmbiente])

  return (
    <Dialog
      open={openModalAmbiente}
      onClose={handleCloseModalAmbiente}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Ambiente</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseModalAmbiente}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        Ambiente de importação: <br />
        <strong>{targetAmbiente.nomeAmbienteFinal}</strong>
        {listaAmbientes.length > 0 ? (
          <div className="mb10 mt10">
            {targetAmbiente.idAmbienteFinal ? (
              <InfoMsg
                type="info"
                title=""
                msg="Se quiser alterar o ambiente escolha uma da opções abaixo"
              />
            ) : (
              <InfoMsg
                type="info"
                title=""
                msg="Será criado um ambiente com o nome acima,
                                caso queira escolher outro ambiente já existente selecione abaixo"
              />
            )}
          </div>
        ) : (
          <div className="mb10 mt10">
            <InfoMsg
              type="info"
              title=""
              msg="Será criado um ambiente com o nome acima no projeto"
            />
          </div>
        )}
        <FormControl
          variant="filled"
          className="FormControl-lessMargin FormControl-withBtn"
          size="small"
        >
          <InputLabel>Ambientes do Projeto</InputLabel>
          <Select
            value={targetAmbienteLocalId}
            onChange={event => updateTarget(event.target.value)}
          >
            {listaAmbientes.map(amb => {
              return (
                <MenuItem value={amb.id} key={amb.id}>
                  {amb.descricao}
                </MenuItem>
              )
            })}
          </Select>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenModalNewAmbiente}
          >
            {' '}
            Add Ambiente{' '}
          </Button>
        </FormControl>
        <div className="text-center">
          <Button
            onClick={aplicaDeParaAmbiente}
            variant="contained"
            color="secondary"
            className={isMobile ? 'w-100 mt-4' : 'mr5 mt5'}
            disabled={!targetAmbienteLocalId}
          >
            Confirmar Alteração
          </Button>
          <Button
            onClick={handleCloseModalAmbiente}
            variant="contained"
            className={`blackButton ${isMobile ? 'w-100 mt-4' : 'mr5 mt5'}`}
          >
            Cancelar
          </Button>
        </div>
        <DialogAddAmbiente
          open={openModalNewAmbiente}
          close={handleCloseModalNewAmbiente}
          setNewAmbienteName={setNewAmbienteName}
          newAmbienteName={newAmbienteName}
          criarAmbienteViaApi={() => criarAmbienteViaApi(newAmbienteName)}
        />
      </DialogContent>
    </Dialog>
  )
}

export default DialogAmbienteImport
