import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

const PageTitle = props => {
  return (
    <div className="row flexLeo">
      {props.action ? (
        <>
          <div className="col-12" style={{ flex: 1, minWidth: '300px' }}>
            <div className="h2Title mt10 mb10">
              <span className="faixaL"></span>
              <h2>{props.title}</h2>
              <span className="faixaR"></span>
            </div>
          </div>
          <div
            className={
              isMobile ? 'flex px-4 w-100 justify-between pb-2' : 'px-4'
            }
          >
            {props.action}
          </div>
        </>
      ) : (
        <div className="col-sm-12">
          <div className="h2Title mt10 mb10">
            <span className="faixaL"></span>
            <h2>{props.title}</h2>
            <span className="faixaR"></span>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(PageTitle)
