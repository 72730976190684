import ImgMdfDefault from '@asset/img/mdf-defalut.PNG'
import { Close, Reply, Reorder } from '@mui/icons-material'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton
} from '@mui/material'
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction
} from '@store/actions/snackbarActions.js'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { useDispatch } from 'react-redux'
import { withStyles } from 'tss-react/mui'

import { useConfiguracao } from '@hook/useConfiguracao'
import { useProjeto } from '@hook/useProjeto.js'

import api from '@service/api'
import Utils from '@service/serviceUtils'

import DialogAlertUsinagem from '../Dialogs/DialogAlertUsinagem.js'
import DialogCanaisPorChapaCanaletada from '../Dialogs/DialogCanaisPorChapaCanaletada.js'
import DialogTamanhoCanaletado from '../Dialogs/DialogTamanhoCanaletado.js'

const StyledTableCell = withStyles(TableCell, () => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: '#000',
    padding: '6px 16px'
  },
  body: {
    fontSize: 12,
    borderBottom: '1px solid #F5F5F5',
    padding: '4px 16px'
  }
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FDFDFD'
    }
  }
}))

const GestaoMateriais = ({ handleSwapMaterials, handleDeleteMaterial }) => {
  const dispatch = useDispatch()
  const showMsgW = msg => dispatch(showMsgWAction(msg))
  const showMsgE = msg => dispatch(showMsgEAction(msg))
  const { projeto, changeProjeto } = useProjeto()
  const { habilitarCanaletado, configData } = useConfiguracao()
  const hasProjetoCorteTransporte = projeto?.tipoCorte === 5
  const [openModalCanaletado, setOpenModalCanaletado] = useState(false)
  const [openModalCanaisPorChapa, setOpenModalCanaisPorChapa] = useState(false)
  const [openModalAlertUsinagem, setOpenModalAlertUsinagem] = useState(false)
  const [activeChapa, setActiveChapa] = useState()
  const [targetCanaletadoModal, setTargetCanaletadoModal] = useState()

  const handleOpenModalCanaletado = material => {
    setActiveChapa(material)
    setOpenModalCanaletado(true)
  }

  const handleOpenModalCanaisPorChapa = tamanhoCanaletado => {
    setOpenModalCanaisPorChapa(true)
    setTargetCanaletadoModal(
      configData?.configuracoesCanaletagem?.find(
        item => item.canaletado === tamanhoCanaletado
      )
    )
  }

  const handleCloseModalCanaletado = () => {
    setOpenModalCanaletado(false)
    setActiveChapa(null)
  }

  const handleCloseModalCanaisPorChapa = () => {
    setOpenModalCanaisPorChapa(false)
  }

  const verificarUsinagemNoProjeto = material => {
    const possuiUsinagem = projeto.projetoAmbiente.some(ambiente =>
      ambiente.projetoPeca.some(peca => peca.projetoPecaServico.length > 0)
    )

    if (possuiUsinagem) {
      setActiveChapa(material)
      setOpenModalAlertUsinagem(true)
    } else {
      actionTrocarChapa(material)
    }
  }

  const actionTrocarChapa = material => {
    const targetMaterial = openModalAlertUsinagem ? activeChapa : material
    const targetType = hasProjetoCorteTransporte ? 'G' : 'C'

    setOpenModalAlertUsinagem(false)
    handleSwapMaterials(targetMaterial, targetType)
  }

  const removeFitasEServicos = materialId => {
    const listaAmbientesAtualizada = projeto.projetoAmbiente.map(ambiente => {
      const projetoPecaAtualizado = ambiente.projetoPeca.map(peca => {
        if (peca.materialId.toString() === materialId.toString()) {
          return {
            ...peca,
            direitaMaterialId: 0,
            esquerdaMaterialId: 0,
            inferiorMaterialId: 0,
            superiorMaterialId: 0,
            pecaProduto: [],
            projetoPecaServico: []
          }
        }
        return peca
      })

      return { ...ambiente, projetoPeca: projetoPecaAtualizado }
    })
    changeProjeto('projetoAmbiente', listaAmbientesAtualizada)
  }

  const canaletarChapa = async canaletado => {
    try {
      if (!canaletado) {
        showMsgW('Selecione um tamanho de canaletado')
        return
      }

      const materialNoProjeto = projeto.projetoProduto.find(material => {
        const codigoComparar = activeChapa.codigo.includes('-')
          ? activeChapa.codigo.split('-', 1) + '-' + canaletado
          : activeChapa.codigo + '-' + canaletado

        if (material.codigo === codigoComparar) {
          return material
        }
      })

      if (materialNoProjeto) {
        showMsgW('Material já incluso no projeto')
        return
      }

      const novoCodigo = activeChapa.codigo.includes('-')
        ? activeChapa.codigo.split('-', 1) + '-' + canaletado
        : activeChapa.codigo + '-' + canaletado

      const updateMaterial = {
        id: activeChapa.id,
        codigo: novoCodigo,
        canaletado: Number(canaletado)
      }

      const response = await trackPromise(
        api.leoplan.apiUpdateProjetoProduto(updateMaterial)
      )

      if (response.fail) throw new Error()

      const updateMateriais = projeto.projetoProduto.map(material => {
        if (material.id === activeChapa.id) {
          return {
            ...material,
            id: activeChapa.id,
            codigo: novoCodigo,
            canaletado: Number(canaletado)
          }
        }
        return material
      })

      changeProjeto('projetoProduto', updateMateriais)

      if (projeto.projetoAmbiente.length) removeFitasEServicos(activeChapa.id)
      handleCloseModalCanaletado()
    } catch {
      showMsgE('Ocorreu um erro ao canaletar essa chapa')
    }
  }

  const imgBrokenMdf = event => {
    event.target.src = ImgMdfDefault
  }

  const isEditable = projeto.origemProjeto !== 3

  return (
    <>
      <div className="row">
        <div className="col-md">
          {isMobile &&
          projeto.projetoProduto.filter(x => x.tipoProdutoId === 1).length ? (
            <div className="my-4">MDFs</div>
          ) : (
            ''
          )}
          <TableContainer>
            <Table aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>MDFs</StyledTableCell>
                  {!projeto.pedidoId && <StyledTableCell></StyledTableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {projeto.projetoProduto
                  .filter(x => x.tipoProdutoId === 1)
                  .map(material => (
                    <StyledTableRow key={material.id}>
                      <StyledTableCell
                        data="Descrição"
                        component="th"
                        scope="row"
                      >
                        <span className="align-left">
                          <div className="tableProdThumb">
                            <img
                              alt="mdf"
                              onError={imgBrokenMdf}
                              src={Utils.getImageUrl(material.codigo)}
                            />
                            {material.canaletado ? (
                              <div className="canaletaThumb" />
                            ) : (
                              ''
                            )}
                          </div>
                        </span>
                        <span>
                          {material.codigo} - {material.descricao} <br />
                          <strong>Comprimento:</strong> {material.largura * 10}
                          mm , <strong>Largura:</strong> {material.altura * 10}
                          mm , <strong>Espessura:</strong>{' '}
                          {material.espessura * 10}mm , <strong>Veio:</strong>{' '}
                          {material.veio ? 'Sim' : 'Não'}
                        </span>
                        {material.canaletado ? (
                          <div className="text-danger">
                            <strong>Canaletado:</strong>{' '}
                            {material.canaletado ? 'Sim' : 'Não'},{' '}
                            <strong>Distância entre canaletas: </strong>
                            {material.canaletado} polegadas
                          </div>
                        ) : (
                          ''
                        )}
                      </StyledTableCell>
                      {!projeto.pedidoId && isEditable && (
                        <StyledTableCell component="th" scope="row">
                          <div
                            className={
                              isMobile
                                ? 'flex flex-col gap-1 pecasAction'
                                : 'flex justify-end gap-1 pecasAction'
                            }
                          >
                            {material.permiteSerCanaletado &&
                              habilitarCanaletado && (
                                <Tooltip title="Canaletar Chapa">
                                  <IconButton
                                    variant="contained"
                                    size="small"
                                    className="botaoCanaletar"
                                    onClick={() =>
                                      handleOpenModalCanaletado(material)
                                    }
                                  >
                                    <Reorder />
                                  </IconButton>
                                </Tooltip>
                              )}
                            {!hasProjetoCorteTransporte && (
                              <Tooltip
                                title={
                                  !hasProjetoCorteTransporte
                                    ? 'Trocar chapa'
                                    : 'Editar chapa'
                                }
                              >
                                <IconButton
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={() =>
                                    verificarUsinagemNoProjeto(material)
                                  }
                                >
                                  <Reply />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip title="Excluir Chapa">
                              <IconButton
                                variant="contained"
                                color="primary"
                                size="small"
                                className="danger"
                                onClick={() =>
                                  handleDeleteMaterial(material.id, 'C')
                                }
                              >
                                <Close />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {projeto?.tipoCorte !== 5 && (
          <div className="col-md-6">
            {isMobile &&
            projeto.projetoProduto.filter(x => x.tipoProdutoId === 2).length ? (
              <div className="mt-6 mb-4">Fitas</div>
            ) : (
              ''
            )}
            <TableContainer>
              <Table aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Fitas</StyledTableCell>
                    {!projeto.pedidoId && <StyledTableCell></StyledTableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projeto.projetoProduto
                    .filter(x => x.tipoProdutoId === 2)
                    .map(material => (
                      <StyledTableRow key={material.id}>
                        <StyledTableCell
                          data="Descrição"
                          component="th"
                          scope="row"
                        >
                          <span className="align-left">
                            <img
                              alt="mdf"
                              onError={imgBrokenMdf}
                              className="tableProdThumb"
                              src={Utils.getImageUrl(material.codigo)}
                            />
                          </span>
                          <span>
                            {material.codigo} - {material.descricao} <br />
                            <strong>Espessura:</strong> {material.espessura} mm
                          </span>
                        </StyledTableCell>
                        {!projeto.pedidoId && isEditable && (
                          <StyledTableCell component="th" scope="row">
                            <div
                              className={
                                isMobile
                                  ? 'flex flex-col gap-1 pecasAction'
                                  : 'flex gap-1 pecasAction'
                              }
                            >
                              <Tooltip title="Trocar fita">
                                <IconButton
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={() =>
                                    handleSwapMaterials(material, 'F')
                                  }
                                >
                                  <Reply />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Excluir fita">
                                <IconButton
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  className="danger"
                                  onClick={() =>
                                    handleDeleteMaterial(material.id, 'F')
                                  }
                                >
                                  <Close />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>

      {openModalCanaletado && (
        <DialogTamanhoCanaletado
          open={openModalCanaletado}
          close={handleCloseModalCanaletado}
          action={canaletarChapa}
          canaletado={activeChapa.canaletado}
          handleOpenModalCanaisPorChapa={handleOpenModalCanaisPorChapa}
        />
      )}

      {openModalCanaisPorChapa && (
        <DialogCanaisPorChapaCanaletada
          open={openModalCanaletado}
          close={handleCloseModalCanaisPorChapa}
          canaletado={targetCanaletadoModal}
        />
      )}

      {openModalAlertUsinagem && (
        <DialogAlertUsinagem
          open={openModalAlertUsinagem}
          action={actionTrocarChapa}
        />
      )}
    </>
  )
}

export default GestaoMateriais
