import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

const DialogAtivarInativarPlano = props => {
  const { projetoNome, projetoId, ativo, open, close, action, loadAction } =
    props

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>{ativo ? 'Inativar' : 'Ativar'} Projeto</Typography>
        <IconButton aria-label="close" onClick={close} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="text-center" dividers>
        <div className="mb20">
          Você tem certeza que deseja {ativo ? 'Inativar' : 'Ativar'} o projeto{' '}
          <br />
          <strong>
            {projetoId} - {projetoNome}
          </strong>
        </div>
        <div>
          <Button
            onClick={close}
            variant="contained"
            className="mb10 mr5 blackButton"
          >
            Não
          </Button>
          {loadAction ? (
            <Button
              variant="contained"
              color="secondary"
              className="mb10"
              disabled
            >
              {ativo ? 'Inativando' : 'Ativando'}...
            </Button>
          ) : (
            <Button
              onClick={() => action(projetoId, !ativo)}
              variant="contained"
              color="secondary"
              className="mb10"
            >
              {ativo ? 'Inativar' : 'Ativar'}
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default memo(DialogAtivarInativarPlano)
