import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

const DialogCanaisPorChapaCanaletada = ({ open, close, canaletado }) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Atenção</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <p className="mb10">
            Você escolheu <b>{canaletado.canaletado} polegadas </b>entre as
            canaletas
          </p>

          <p className="mb20">
            A chapa terá <b>{canaletado?.quantidadeCanaletas} canaletas </b>com
            aproximadamente <b>{canaletado.canaletado * 2.54} centímetros </b>
            de distância.
          </p>
        </div>

        <div className="flex justify-center">
          <Button onClick={close} variant="contained" className="mb10  mr5">
            Estou ciente
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default memo(DialogCanaisPorChapaCanaletada)
