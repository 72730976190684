import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

const DialogLimparAmbiente = props => {
  const { open, close, action } = props

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Limpar Ambiente</Typography>
        <IconButton aria-label="close" onClick={close} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="text-center" dividers>
        <Typography className="mb20">
          Esta ação vai excluir todas as peças deste ambiente, tem certeza que
          deseja continuar?
        </Typography>
        <Button
          onClick={action}
          variant="contained"
          color="secondary"
          className="mb10 mr5"
        >
          Limpar Ambiente
        </Button>
        <Button
          onClick={close}
          variant="contained"
          className="mb10 blackButton"
        >
          Cancelar
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default memo(DialogLimparAmbiente)
