import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  showMsgE as showMsgEAction,
  showMsgW as showMsgWAction,
  showMsgS as showMsgSAction
} from '@store/actions/snackbarActions.js'
import React, { Fragment, useEffect } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { useSelector, useDispatch } from 'react-redux'

import { appConfig } from '@config/env-config.js'

import { useProjeto } from '@hook/useProjeto'

import ResumoProjeto from '@component/Email/ResumoProjeto.js'
import InfoMsg from '@component/InfoMsg/InfoMsg.js'

import api from '@service/api'
import Utils from '@service/serviceUtils.js'

import { useConfiguracao } from '@base/hooks/useConfiguracao'

const DialogEnviarEmail = ({ open, close }) => {
  const dispatch = useDispatch()
  const showMsgE = msg => dispatch(showMsgEAction(msg))
  const showMsgW = msg => dispatch(showMsgWAction(msg))
  const showMsgS = msg => dispatch(showMsgSAction(msg))

  const { cliente, otimizacao, projeto, lojaCongifOtmizacao } = useProjeto()
  const { servicos } = useConfiguracao()

  const { configCorteLoja } = useSelector(state => state.planoCorteState)
  const [emailDestinatario, setEmailDestinatario] = React.useState('')
  const [loadEmail, setLoadEmail] = React.useState(false)

  const getDestinatarios = () => {
    const objctDestinatarios = `{"${emailDestinatario}": "${projeto.clienteLEO.nome}", "${projeto.vendedor.email}":"${projeto.vendedor.nome}"}`
    return JSON.parse(objctDestinatarios)
  }

  const fitasUtilizadas = Utils.extraiFitasEmUso(
    otimizacao,
    projeto.projetoProduto
  )

  const exibeListaServicos = () => {
    if (
      otimizacao.length &&
      projeto.projetoProduto.length &&
      lojaCongifOtmizacao.minimoChapaCorte >= 0
    ) {
      const materiaisComBordeamento = Utils.addServicoBordeamento(
        projeto.projetoProduto,
        fitasUtilizadas,
        servicos
      )

      const listaServicos = Utils.extrairServico(
        materiaisComBordeamento,
        servicos
      )

      return listaServicos
    } else return []
  }

  const formatEmail = () => {
    const emailBody = `
    <html>
    <head>${ResumoProjeto.stilosCss()}</head>
    <body>
        ${ResumoProjeto.cabecario()}
        ${ResumoProjeto.titulo('Resumo do Projeto')}
        ${ResumoProjeto.projetoNome(projeto.nome, projeto.id)}
        ${ResumoProjeto.projetoCliente(projeto.clienteLEO.nome)}
        ${
          projeto.tipoCorte !== 4
            ? ResumoProjeto.projetoAmbientes(projeto.projetoAmbiente)
            : ''
        }
        ${ResumoProjeto.projetoChapas(otimizacao, projeto.projetoProduto)}
        ${ResumoProjeto.projetoFitas(
          otimizacao,
          projeto.projetoProduto,
          configCorteLoja
        )}
        ${ResumoProjeto.projetoServicos(exibeListaServicos())}
        ${ResumoProjeto.projetoTotalPecas(projeto.projetoAmbiente)}

        ${ResumoProjeto.projetoAmbientesDetalhe(
          projeto.projetoAmbiente,
          projeto.projetoProduto,
          projeto.tipoCorte
        )}

        ${ResumoProjeto.downloadProjeto()}
    </body>
    </html>
`
    const objEmailMsg = {
      idProjeto: projeto.id,
      assuntoEmail: `LeoPlan projeto: ${projeto.id} - ${projeto.nome}`,
      corpoEmail: emailBody,
      destinatarios: getDestinatarios()
    }
    const obtEnvioEmail = {
      exchangeName: appConfig.REACT_APP_EXCHANGE_NAME,
      exchangeType: 'topic',
      routeKey: 'gerar_doc_storage',
      message: JSON.stringify(objEmailMsg)
    }

    return obtEnvioEmail
  }

  const dispararEmail = async () => {
    if (!Utils.validarEmail(emailDestinatario)) {
      showMsgW('Informe um e-mail válido para o destinatário')
      return false
    }

    const emailData = formatEmail()

    try {
      setLoadEmail(true)
      const response = await trackPromise(
        api.leoplan.apiSendMsgQueue(emailData)
      )
      if (response.fail) throw new Error()
      showMsgS('E-mail enviado com sucesso')
      close()
    } catch (e) {
      showMsgE('Erro enviar e-mail, tente novamente mais tarde')
    } finally {
      setLoadEmail(false)
    }
  }

  useEffect(() => {
    if (cliente?.emails?.length > 0) {
      setEmailDestinatario(cliente.emails[0].endereco)
    }
  }, [cliente])

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Confirmar envio de e-mail</Typography>
        <IconButton aria-label="close" onClick={close} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <strong>Cliente Nome:</strong> {cliente?.nome}
          <br />
          {cliente && cliente?.emails?.length > 0 ? (
            <Fragment key={cliente.emails[0].id}>
              <strong>Cliente E-mail</strong> {cliente?.emails[0].endereco}
            </Fragment>
          ) : (
            <Fragment>
              <strong>Cliente E-mail</strong> -
            </Fragment>
          )}
        </div>
        <FormControl variant="filled">
          <TextField
            id="emaiDeEnvio"
            label="E-mail do cliente para envio"
            variant="filled"
            size="small"
            value={emailDestinatario}
            onChange={event => setEmailDestinatario(event.target.value)}
          />
        </FormControl>
        <InfoMsg
          type="info"
          title="Uma cópia do email será envia para o vendedor"
        ></InfoMsg>
        <div className="text-center mt20">
          <Button
            onClick={close}
            variant="contained"
            className="mb10 mr10 blackButton"
          >
            Cancelar
          </Button>
          {!loadEmail ? (
            <Button
              onClick={dispararEmail}
              variant="contained"
              color="secondary"
              className="mb10"
            >
              Confirmar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              className="mb10"
              disabled
            >
              Processando ...
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default DialogEnviarEmail
