import Add from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

import { useProjeto } from '@hook/useProjeto'

const RowNewPeca = props => {
  const { projeto } = useProjeto()

  return (
    <>
      {!props.pedidoId && (
        <div className="w-100 text-center py-2">
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={`align-center ${isMobile ? 'w-100' : 'maxW200'}`}
            disabled={projeto.origemProjeto === 3}
            onClick={() =>
              props.addNewPeca(props.idAmbiente, props.indexAmbiente)
            }
            tabIndex={props.tabIndex}
          >
            <Add /> Incluir Peça
          </Button>
        </div>
      )}
    </>
  )
}

export default memo(RowNewPeca)
