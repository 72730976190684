import loadImg from '@asset/img/load-100.gif'
import { Button, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { appConfig } from '@config/env-config.js'

import Modal from '@component/Modal/Modal.js'

import api from '@service/api'
import Mask from '@service/mask.js'

import { showMsgE as showMsgEAction } from '../../store/actions/snackbarActions.js'

const REGISTRATIONTURL = appConfig.REACT_APP_EcomRegistrationUrl

const Login = () => {
  const { id: paramsId } = useParams()
  const dispatch = useDispatch()
  const showMsgE = msg => dispatch(showMsgEAction(msg))
  const navigate = useNavigate()
  const [clientId, setCLientId] = useState('')
  const [notFound, setNotFound] = useState(false)
  const [loading, setLoading] = useState(true)
  const [disableAction, setDisableAction] = useState(false)

  const handleAuthenticateClient = async id => {
    if (!clientId && !id) {
      return showMsgE('Preencha o campo documento.')
    }
    try {
      setDisableAction(true)
      const response = await api.core.apiGetClientByDocument(
        Mask.number(id || clientId)
      )

      if (response.fail) throw new Error(response.errors)

      if (response?.data?.idExterno) {
        navigate(`/leoplan/cliente/disponiveis/${response.data.idExterno}`)
      } else {
        setNotFound(true)
        setLoading(false)
      }
    } catch (error) {
      showMsgE(error)
    } finally {
      setDisableAction(false)
    }
  }

  const handleRedirect = async () => {
    window.parent.location.href = REGISTRATIONTURL
  }

  useEffect(() => {
    if (paramsId) {
      handleAuthenticateClient(paramsId)
    } else setLoading(false)
  }, [])

  const handleModalClose = () => {
    setNotFound(false)
  }

  return (
    <>
      {loading ? (
        <div className="flex flex-col text-center h-screen justify-center">
          <h5>Carregando... </h5>

          <div className="m-1 mx-auto">
            <img src={loadImg} alt="load" />
          </div>
        </div>
      ) : (
        <div className="vh100 px-2">
          <div className="flex flex-col vh100 m-auto justify-center items-center maxW28">
            <h5>Entre na sua conta</h5>
            <Typography className="mb-4">
              Por favor, preencha o campo abaixo:
            </Typography>
            <TextField
              id="login-input-id"
              label="CPF/CNPJ"
              variant="filled"
              size="small"
              value={Mask.cpfCnpj(clientId)}
              onChange={e => setCLientId(e.target.value)}
            />
            <Button
              id="login-send-id"
              data-testid="login-send-id"
              onClick={() => handleAuthenticateClient()}
              variant="contained"
              color="secondary"
              className="mb-2 mt-4"
              disabled={disableAction}
            >
              Enviar
            </Button>
          </div>

          <Modal
            open={notFound}
            close={handleModalClose}
            forceOpen
            titulo={'Registro não encontrado'}
            className="p-0 m-0"
          >
            <div className="p-0 m-0 w-100">
              <div className="text-center mb-2">
                <Typography>
                  Você ainda não possui registro, clique no botão abaixo para
                  criar sua conta.
                </Typography>
              </div>

              <div className="flex justify-content-center">
                <Button
                  id="login-cancel-modal-register"
                  data-testid="login-cancel-modal-register"
                  onClick={() => setNotFound(false)}
                  variant="contained"
                  className="mb10 mr10"
                >
                  Cancelar
                </Button>

                <Button
                  id="login-redirect-modal-register"
                  data-testid="login-redirect-modal-register"
                  onClick={handleRedirect}
                  variant="contained"
                  color="secondary"
                  className="mb10"
                >
                  Crie sua conta
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  )
}

export default Login
