import CDFita from '@asset/img/CLFitas.png'
const FitasMiniatura = ({ peca, materiais, aplicacao }) => {
  const { fitaDireita, fitaEsquerda, fitaCima, fitaBaixo } = materiais.reduce(
    (add, material) => {
      const fitaDireita =
        peca.direitaMaterialId.toString() === material.id.toString() && material

      const fitaEsquerda =
        peca.esquerdaMaterialId.toString() === material.id.toString() &&
        material

      const fitaCima =
        peca.superiorMaterialId.toString() === material.id.toString() &&
        material

      const fitaBaixo =
        peca.inferiorMaterialId.toString() === material.id.toString() &&
        material

      return {
        ...add,
        fitaDireita: fitaDireita || add.fitaDireita,
        fitaEsquerda: fitaEsquerda || add.fitaEsquerda,
        fitaCima: fitaCima || add.fitaCima,
        fitaBaixo: fitaBaixo || add.fitaBaixo
      }
    },
    {}
  )
  const formatBorder = fita => {
    return fita ? 'solid 5px ' + fita.color : ''
  }
  return (
    <span
      className={`${aplicacao ? 'pecaMiniaturaAplicacao ' : 'pecaMiniatura '}
            ${parseInt(peca.largura) > parseInt(peca.altura) && 'retH'} 
            ${parseInt(peca.largura) < parseInt(peca.altura) && 'retV'}
            `}
      style={{
        borderRight: formatBorder(fitaDireita),
        borderLeft: formatBorder(fitaEsquerda),
        borderTop: formatBorder(fitaCima),
        borderBottom: formatBorder(fitaBaixo)
      }}
    >
      {aplicacao && <img src={CDFita} alt="" />}
    </span>
  )
}
export default FitasMiniatura
