import { showMsgW as showMsgWAction } from '@store/actions/snackbarActions.js'
import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { useDispatch } from 'react-redux'

import { useProjeto } from '@hook/useProjeto'
import { useUser } from '@hook/useUser.js'

import PageTitle from '@component/PageTitle/PageTitle'

import api from '@service/api'
import FormatObj from '@service/serviceFormat.js'
import Utils from '@service/serviceUtils.js'

import DialogAddChapa from '../Dialogs/DialogAddChapa.js'
import DialogAddFita from '../Dialogs/DialogAddFita.js'
import DialogAddMaterialGenerico from '../Dialogs/DialogAddMaterialGenerico.js'
import DialogDelMaterial from '../Dialogs/DialogDelMaterial.js'
import DialogImportacao from '../Dialogs/DialogImportacao.js'
import GestaoMateriais from './GestaoMateriais.js'
import HeaderMateriais from './HeaderMateriais.js'

const Materiais = () => {
  const { user, ambient } = useUser()
  const {
    projeto,
    deleteProductProject,
    swapProductProject,
    changeProjeto,
    fitasPeca,
    setFitasPenca,
    loja
  } = useProjeto()

  const [openModalExcluirMat, setOpenModalExcluirMat] = useState(false)
  const handleOpenModalExcluirMat = () => {
    setOpenModalExcluirMat(true)
  }
  const handleCloseModalExcluirMat = () => {
    setOpenModalExcluirMat(false)
  }

  const [openModalImportacao, setOpenModalImportacao] = useState(false)
  const handleOpenModalImportacao = () => {
    setOpenModalImportacao(true)
  }
  const handleCloseModalImportacao = () => {
    setOpenModalImportacao(false)
  }

  const [openModalAddFita, setOpenModalAddFita] = useState(false)
  const [openModalAddMaterialGenerico, setOpenModalAddMaterialGenerico] =
    useState(false)
  const [fitaDepara, setFitaDepara] = useState('')
  const handleOpenModalAddFita = depara => {
    if (depara) setFitaDepara(depara)
    setOpenModalAddFita(true)
  }
  const handleCloseModalAddFita = () => {
    setOpenModalAddFita(false)
    setMaterialSwapId('')
    setMaterialSwapType('')
    setFitaDepara('')
  }

  const handleOpenModalAddMaterialGenerico = () => {
    setOpenModalAddMaterialGenerico(true)
  }
  const handleCloseModalAddMaterialGenerico = () => {
    setOpenModalAddMaterialGenerico(false)
  }

  const [openModalAddChapa, setOpenModalAddChapa] = useState(false)
  const [chapaDepara, setChapaDepara] = useState('')
  const handleOpenModalAddChapa = depara => {
    if (depara) {
      setChapaDepara(depara)
    }
    setOpenModalAddChapa(true)
  }
  const handleCloseModalAddChapa = () => {
    setOpenModalAddChapa(false)
    setMaterialSwapId('')
    setMaterialSwapType('')
    setChapaDepara('')
  }

  const [materialExclusaoId, setMaterialExclusaoId] = useState('')
  const [materialExclusaoType, setMaterialExclusaoType] = useState('')

  const [materialSwapId, setMaterialSwapId] = useState('')
  const [materialSwapType, setMaterialSwapType] = useState('')

  const [materialAutoSelect, setMaterialAutoSelect] = useState(null)

  const dispatch = useDispatch()
  const showMsgW = msg => dispatch(showMsgWAction(msg))

  const checkIfMaterialIsUsed = materialId =>
    projeto.projetoAmbiente?.reduce((add, ambiente) => {
      return (
        add +
        ambiente.projetoPeca.some(peca => {
          return (
            peca.materialId.toString() === materialId.toString() ||
            peca.pecaProduto.some(
              fita => fita.projetoProdutoId.toString() === materialId.toString()
            )
          )
        })
      )
    }, false)
  const handleSwapMaterials = (materialId, tipo) => {
    setMaterialSwapId(materialId)
    setMaterialSwapType(tipo)
    if (tipo === 'F') handleOpenModalAddFita()
    else if (tipo === 'G') handleOpenModalAddMaterialGenerico(materialId)
    else handleOpenModalAddChapa()
  }
  const handleDeleteMaterial = async (materialId, tipo) => {
    try {
      const response = await trackPromise(
        api.leoplan.apiDeletarProjetoProduto(materialId)
      )

      if (response.fail) throw new Error()

      deleteProductProject(materialId, tipo)
      handleCloseModalExcluirMat()
    } catch {
      showMsgW('Não foi possível remover o material')
    }
  }
  const confirmRemoveMaterialPopup = (materialId, tipo) => {
    const isUsed = checkIfMaterialIsUsed(materialId)
    if (isUsed) {
      setMaterialExclusaoId(materialId)
      setMaterialExclusaoType(tipo)
      handleOpenModalExcluirMat()
      return
    }
    handleDeleteMaterial(materialId, tipo)
  }
  const addMaterialnoProjeto = async (material, tipo, isMaterialGenerico) => {
    try {
      const materialJaEstaNoProjeto = projeto.projetoProduto.find(
        mat => mat.codigo.toString() === material.IdERP.toString()
      )

      if (!materialSwapId && materialJaEstaNoProjeto) {
        showMsgW('Material já está no projeto')
        return
      }

      if (materialJaEstaNoProjeto) {
        return materialJaEstaNoProjeto
      }

      if (!isMaterialGenerico) {
        const response = await trackPromise(
          api.core.apiProdutoById(material.IdERP)
        )
        if (response.fail || !response?.Id) {
          throw new Error('Algum erro aconteceu ao tentar incluir a chapa')
        }

        const produto = FormatObj.setNewProduto(
          tipo,
          projeto.id,
          formatMaterialProject(response)
        )
        const responseMatProj = await trackPromise(
          api.leoplan.apiAddProjetoProduto(produto)
        )

        if (responseMatProj.fail || !responseMatProj?.data?.id) {
          throw new Error()
        }

        const updateMateriais = [
          ...projeto.projetoProduto,
          responseMatProj.data
        ]
        changeProjeto('projetoProduto', Utils.atribuiCorFitas(updateMateriais))
        if (chapaDepara || fitaDepara) {
          setMaterialAutoSelect(responseMatProj.data)
          if (fitaDepara) handleCloseModalAddFita()
          else handleCloseModalAddChapa()
        }

        if (isMobile) {
          if (tipo === 'C') handleCloseModalAddChapa()
          else handleCloseModalAddFita()
        }

        return responseMatProj.data
      } else {
        const responseMatProj = await trackPromise(
          api.leoplan.apiAddProjetoProduto(material)
        )

        if (responseMatProj.fail || !responseMatProj?.data?.id) {
          throw new Error()
        }

        const updateMateriais = [
          ...projeto.projetoProduto,
          responseMatProj.data
        ]
        changeProjeto('projetoProduto', Utils.atribuiCorFitas(updateMateriais))

        handleCloseModalAddMaterialGenerico()
        return responseMatProj.data
      }
    } catch {
      showMsgW('Algum erro aconteceu ao tentar incluir o material')
    }
  }
  const swapMaterialnoProjeto = async newMaterial => {
    if (newMaterial.IdERP.toString() === materialSwapId.codigo.toString()) {
      showMsgW('Não é possivel trocar pelo mesmo material')
      return
    }
    try {
      const newMat = await addMaterialnoProjeto(newMaterial, materialSwapType)
      if (!newMat) return
      const response = await trackPromise(
        api.leoplan.apiDeletarProjetoProduto(materialSwapId.id)
      )
      if (response.fail) throw new Error()
      swapProductProject(newMat, materialSwapId.id, materialSwapType)
      if (materialSwapType === 'F') handleCloseModalAddFita()
      else handleCloseModalAddChapa()
    } catch {
      showMsgW('Algum erro aconteceu ao tentar trocar o material')
    }
  }
  const formatMaterialProject = (rawMaterial, tipo) => {
    if (
      tipo === 'F' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'M' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'R' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'RL'
    ) {
      throw new Error('Este tipo de material não pode ser incluido')
    } else if (
      tipo === 'C' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'CH' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'PC' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'UN'
    ) {
      throw new Error('Este tipo de material não pode ser incluido')
    }
    return {
      ...rawMaterial,
      canaletado: rawMaterial.canaletado || 0
    }

    // vendedor.centroSaida = user.empresaIdErp
    // vendedor.centroVenda = user.empresaIdErp
  }
  const handleIncludeMaterail = (
    material,
    tipo,
    isMaterialGenerico = false
  ) => {
    if (materialSwapId) swapMaterialnoProjeto(material)
    else addMaterialnoProjeto(material, tipo, isMaterialGenerico)
  }
  const buscaAllFitasRecomendadasSvl = () => {
    const mat = projeto.projetoProduto.filter(x => x.tipoProdutoId === 1) // fitra materias tipo chapa
    const fitaSugeridasRetorno = []
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index
    }

    mat.forEach(async (element, index) => {
      try {
        const response = await api.core.apiFitasSugeridasIntegracao(
          element.codigo,
          loja.idErp
        )

        if (response.fail) throw new Error()

        const fitasByCodigo = {
          CodigoChapa: element.codigo,
          DescricaoChapa: element.descricao,
          ListaFita: []
        }
        const sugestoesCategoriasHabilitadas = response.data.filter(
          x =>
            x.categoria.toUpperCase() === 'FITAS' ||
            x.categoria.toUpperCase() === 'FITA LEOPLAN' ||
            x.categoria.toUpperCase() === 'FITAS LEOPLAN'
        )
        if (sugestoesCategoriasHabilitadas.length > 0) {
          sugestoesCategoriasHabilitadas.forEach(categoriaSugestao => {
            if (categoriaSugestao.itens && categoriaSugestao.itens.length > 0) {
              fitasByCodigo.ListaFita = fitasByCodigo.ListaFita.concat(
                categoriaSugestao.itens.map(x => {
                  return {
                    IdERP: x.produtoSecundarioErpId
                  }
                })
              )
            }
          })
        }
        if (fitasByCodigo.CodigoChapa) {
          fitaSugeridasRetorno.push(fitasByCodigo)
        }
        if (mat.length === index + 1) {
          setTimeout(async function () {
            let idsextraidos = fitaSugeridasRetorno.map(x =>
              x.ListaFita.map(y => y.IdERP)
            )
            idsextraidos = [].concat.apply([], idsextraidos)
            idsextraidos = idsextraidos.filter(distinct)

            if (!idsextraidos.length) {
              setFitasPenca(fitaSugeridasRetorno)
              return
            }

            const lojaEstoque = ambient === 'SVL' ? user.lojaIdErp : loja.idErp

            const empresaEstoque =
              ambient === 'SVL' ? user.empresaIdErp : loja.empresa

            const response = await api.core.apiDetalhePrutosPencados(
              idsextraidos,
              lojaEstoque,
              empresaEstoque
            )

            if (response.fail) throw new Error()

            fitaSugeridasRetorno.forEach(fitaSugeridas => {
              fitaSugeridas.ListaFita.forEach(fitas => {
                const fitaComplemento = response.filter(
                  x => x.IdERP.toString() === fitas.IdERP.toString()
                )
                if (fitaComplemento.length) {
                  fitas.Centro = user.lojaIdErp
                  fitas.Descricao = fitaComplemento[0].DescricaoComercial
                    ? fitaComplemento[0].DescricaoComercial
                    : fitaComplemento[0].Descricao
                  fitas.TipoUnidadeMedida =
                    fitaComplemento[0].TipoUnidadeMedida.IdERP
                  fitas.IdERP = fitaComplemento[0].IdERP
                  fitas.EstoqueCD = Utils.getEstoqueQtd(
                    fitaComplemento[0].Estoques,
                    'CD',
                    user
                  )
                  fitas.EstoqueLoja = Utils.getEstoqueQtd(
                    fitaComplemento[0].Estoques,
                    'L',
                    user
                  )
                  fitas.EstoqueCentro = Utils.getEstoqueQtd(
                    fitaComplemento[0].Estoques,
                    'CS',
                    user
                  )
                }
              })
            })
            // filtra sugestões baseado no tipo de metragem que a loja trabalha 'rolo' ou 'metro'
            if (loja?.tipoArvoreMercadologicaFita) {
              if (
                loja.tipoArvoreMercadologicaFita === 'R' ||
                loja.tipoArvoreMercadologicaFita === 'RL'
              ) {
                fitaSugeridasRetorno.forEach(fitaSugeridas => {
                  fitaSugeridas.ListaFita = fitaSugeridas.ListaFita.filter(
                    x => {
                      if (
                        x.TipoUnidadeMedida === 'R' ||
                        x.TipoUnidadeMedida === 'RL'
                      ) {
                        return x
                      } else {
                        return null
                      }
                    }
                  )
                })
              } else {
                fitaSugeridasRetorno.forEach(fitaSugeridas => {
                  fitaSugeridas.ListaFita = fitaSugeridas.ListaFita.filter(
                    x => x.TipoUnidadeMedida === 'M'
                  )
                })
              }
            }
            setFitasPenca(fitaSugeridasRetorno)
          }, 1000)
        }
      } catch {
        // showMsgW('Algum erro aconteceu ao tentar incluir a chapa')
      }
    })
  }
  useEffect(() => {
    if (openModalAddFita && projeto.projetoProduto) {
      buscaAllFitasRecomendadasSvl()
    } else {
      setFitasPenca([])
    }
  }, [openModalAddFita])

  return (
    <div>
      <div className="pad20 padT10 padB0">
        {' '}
        <PageTitle title="Materiais" />{' '}
      </div>
      <HeaderMateriais
        handleOpenModalAddFita={handleOpenModalAddFita}
        handleOpenModalAddMaterialGenerico={handleOpenModalAddMaterialGenerico}
        handleOpenModalAddChapa={handleOpenModalAddChapa}
        goToImportacao={handleOpenModalImportacao}
      />

      <div className={isMobile ? '' : 'pad20 padT0'}>
        <GestaoMateriais
          handleSwapMaterials={handleSwapMaterials}
          handleDeleteMaterial={confirmRemoveMaterialPopup}
        />
      </div>

      <DialogImportacao
        openModalImportacao={openModalImportacao}
        handleCloseModalImportacao={handleCloseModalImportacao}
        handleOpenModalAddFita={handleOpenModalAddFita}
        handleOpenModalAddChapa={handleOpenModalAddChapa}
        materialAutoSelect={materialAutoSelect}
      />

      <DialogDelMaterial
        openModalExcluir={openModalExcluirMat}
        handleCloseModalExcluir={handleCloseModalExcluirMat}
        removeMaterial={() =>
          handleDeleteMaterial(materialExclusaoId, materialExclusaoType)
        }
      />

      {openModalAddFita && (
        <DialogAddFita
          showMsgW={showMsgW}
          openModalAddMaterial={openModalAddFita}
          changeMaterialRef={materialSwapId}
          handleCloseModalAddMaterial={handleCloseModalAddFita}
          handleIncludeMaterail={handleIncludeMaterail}
          fitasSugeridas={fitasPeca}
          materialDepara={fitaDepara}
        />
      )}

      {openModalAddMaterialGenerico && (
        <DialogAddMaterialGenerico
          showMsgW={showMsgW}
          openModalAddMaterial={openModalAddMaterialGenerico}
          handleCloseModalAddMaterial={handleCloseModalAddMaterialGenerico}
          handleIncludeMaterial={handleIncludeMaterail}
          espessuraChapa={18}
        />
      )}
      <DialogAddChapa
        showMsgW={showMsgW}
        openModalAddMaterial={openModalAddChapa}
        changeMaterialRef={materialSwapId}
        handleCloseModalAddMaterial={handleCloseModalAddChapa}
        handleIncludeMaterail={handleIncludeMaterail}
        materialDepara={chapaDepara}
      />
    </div>
  )
}

export default Materiais
