import ImgMdfDefault from '@asset/img/mdf-defalut.PNG'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { showMsgW } from '@store/actions/snackbarActions.js'
import React, { useEffect, Fragment } from 'react'
import { isMobile } from 'react-device-detect'
import { usePromiseTracker } from 'react-promise-tracker'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from 'tss-react/mui'

import { useUser } from '@hook/useUser'

import Utils from '@service/serviceUtils'

const StyledTableCell = withStyles(TableCell, () => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: '#000',
    padding: '6px 16px'
  },
  body: {
    fontSize: 12,
    borderBottom: '1px solid #F5F5F5',
    padding: '4px 16px'
  }
}))

const StyledTableCellMdf = withStyles(TableCell, () => ({
  head: {
    backgroundColor: '#2a7e5c',
    color: '#fff',
    padding: '6px 16px'
  },
  body: {
    fontSize: 12,
    backgroundColor: '#2a7e5c',
    color: '#fff',
    borderBottom: '1px solid #F5F5F5',
    padding: '4px 16px'
  }
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FDFDFD'
    }
  }
}))

const TabelaListaFitasSugeridas = props => {
  const { promiseInProgress } = usePromiseTracker()

  const [qtdColunas, setQtdColunas] = React.useState(4)
  const [fitasSugeridas, setFitasSugeridas] = React.useState([])
  const { user } = useUser()

  const imgBrokenMdf = event => {
    event.target.src = ImgMdfDefault
  }
  useEffect(() => {
    if (user.tipoUserLeoPlan !== 'cliente') {
      if (user.tipoVenda === 'ZCVS' || user.tipoVenda === 'ZMOB') {
        setQtdColunas(5)
      } else {
        setQtdColunas(4)
      }
    }
  }, [user, props.tipoMaterial])

  useEffect(() => {
    setFitasSugeridas(props.fitasSugeridas)
  }, [props.fitasSugeridas])

  return (
    <div>
      {isMobile && (
        <div className="w-100">
          <TableHead>
            <TableRow>
              <StyledTableCell className="w-100">
                Fitas Sugeridas com base nas chapas deste projeto
              </StyledTableCell>
              {user?.tipoUserLeoPlan !== 'cliente' && (
                <>
                  <StyledTableCell>Loja</StyledTableCell>
                  <StyledTableCell>CD</StyledTableCell>
                  {(user?.tipoVenda === 'ZCVS' ||
                    user?.tipoVenda === 'ZMOB') && (
                    <StyledTableCell>CS</StyledTableCell>
                  )}
                </>
              )}
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
        </div>
      )}

      <TableContainer className="mt10">
        <Table aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                Fitas Sugeridas com base nas chapas deste projeto
              </StyledTableCell>
              {user?.tipoUserLeoPlan !== 'cliente' && (
                <>
                  <StyledTableCell>Loja</StyledTableCell>
                  <StyledTableCell>CD</StyledTableCell>
                  {(user?.tipoVenda === 'ZCVS' ||
                    user?.tipoVenda === 'ZMOB') && (
                    <StyledTableCell>CS</StyledTableCell>
                  )}
                </>
              )}
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {fitasSugeridas.length === 0 && (
              <StyledTableRow>
                <StyledTableCell
                  data="Descrição"
                  component="th"
                  scope="row"
                  colSpan={qtdColunas}
                  align="center"
                >
                  Carregando...
                </StyledTableCell>
              </StyledTableRow>
            )}
            {fitasSugeridas.length > 0
              ? fitasSugeridas.map((material, indexMat) => (
                  <Fragment key={indexMat}>
                    <StyledTableRow>
                      <StyledTableCellMdf
                        data="Descrição"
                        colSpan={qtdColunas}
                        component="th"
                        scope="row"
                      >
                        <span>
                          MDF - {material.CodigoChapa} -{' '}
                          {material.DescricaoChapa}
                        </span>
                      </StyledTableCellMdf>
                    </StyledTableRow>
                    {material.ListaFita.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell
                          data="Descrição"
                          colSpan={qtdColunas}
                          component="th"
                          scope="row"
                        >
                          <span>Este Mdf não possui fitas sugeridas</span>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : null}
                    {material.ListaFita.map((fita, indexFita) => (
                      <StyledTableRow key={indexFita}>
                        <StyledTableCell
                          data="Descrição"
                          component="th"
                          scope="row"
                        >
                          <span className="align-left">
                            <img
                              alt="fita"
                              onError={imgBrokenMdf}
                              className="tableProdThumb"
                              src={Utils.getImageUrl(fita.IdERP)}
                            />
                          </span>
                          <span>
                            {fita.IdERP} - {fita.Descricao}
                          </span>
                        </StyledTableCell>
                        {user?.tipoUserLeoPlan !== 'cliente' && (
                          <>
                            <StyledTableCell
                              data="Loja"
                              component="th"
                              scope="row"
                            >
                              <span>{fita.EstoqueLoja}</span>
                            </StyledTableCell>
                            <StyledTableCell
                              data="CD"
                              component="th"
                              scope="row"
                            >
                              <span>{fita.EstoqueCD}</span>
                            </StyledTableCell>
                            {(user?.tipoVenda === 'ZCVS' ||
                              user?.tipoVenda === 'ZMOB') && (
                              <StyledTableCell
                                data="CS"
                                component="th"
                                scope="row"
                              >
                                <span>{fita.EstoqueCentro}</span>
                              </StyledTableCell>
                            )}
                          </>
                        )}
                        <StyledTableCell>
                          <div className="text-right-md">
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              disabled={promiseInProgress}
                              onClick={() =>
                                props.addMaterial.handleIncludeMaterail(
                                  fita,
                                  'F'
                                )
                              }
                            >
                              Incluir no projeto
                            </Button>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </Fragment>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMsgW
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(TabelaListaFitasSugeridas)
