import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import Close from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import { Store } from '@store/index.js'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { ConfiguracaoProvider } from '@hook/useConfiguracao'
import { ProjetoProvider } from '@hook/useProjeto'
import { StepperControlProvider } from '@hook/useStepperControl'
import { UserProvider } from '@hook/useUser'

import './styles/baseLeo.scss'

import AlertSnackbar from '@component/AlertMsg/AlertMsg.js'
import LoadingBar from '@component/LoadingBar/LoadingBar.js'
import Auth0 from '@view/LeoPlan/components/Auth0/Auth0'

import UserRoutes from './routes/index.js'
import * as serviceWorker from './serviceWorker.js'

import ParametricConfiguratorProvider from './services/parametric-configurator'

const notistackRef = React.createRef()
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key)
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <Auth0>
    <BrowserRouter>
      <Provider store={Store}>
        <UserProvider>
          <ConfiguracaoProvider>
            <ProjetoProvider>
              <ParametricConfiguratorProvider>
                <StepperControlProvider>
                  <SnackbarProvider
                    maxSnack={1}
                    ref={notistackRef}
                    action={key => (
                      <Button onClick={onClickDismiss(key)}>
                        <Close />
                      </Button>
                    )}
                  >
                    <AlertSnackbar />
                  </SnackbarProvider>

                  <LoadingBar />
                  <UserRoutes />
                </StepperControlProvider>
              </ParametricConfiguratorProvider>
            </ProjetoProvider>
          </ConfiguracaoProvider>
        </UserProvider>
      </Provider>
    </BrowserRouter>
  </Auth0>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
