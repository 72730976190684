import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { memo, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import Mask from '@service/mask.js'

const DialogDuplicaPlano = props => {
  const {
    projetoNome,
    projetoId,
    open,
    close,
    action,
    showMsgW,
    loadDuplicate
  } = props
  const [nomeNewProject, setNomeNewProject] = React.useState('')

  const changeNome = event => {
    event.persist()
    let descnome = ''
    if (event.target.value.length <= 25) {
      descnome = Mask.noEspecialCaracter(event.target.value)
      setNomeNewProject(descnome)
    }
  }

  const duplicar = () => {
    if (nomeNewProject?.length > 25) {
      showMsgW('O nome excede o limite de 25 caracteres')
      return
    }

    if (nomeNewProject) {
      if (nomeNewProject !== projetoNome) {
        action(projetoId, nomeNewProject)
      } else {
        showMsgW('Informe um nome diferente do projeto original')
      }
    } else {
      showMsgW('Informe um nome válido')
    }
  }

  useEffect(() => {
    if (projetoNome) {
      if (projetoNome.length <= 20) {
        setNomeNewProject(projetoNome + ' copy')
      } else {
        const copyName = projetoNome.slice(0, -5)
        setNomeNewProject(copyName + ' copy')
      }
    }
  }, [projetoNome])

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Duplicar Projeto</Typography>
        <IconButton aria-label="close" onClick={close} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="text-center" dividers>
        Você esta duplicando o projeto <strong>{projetoNome}</strong> de id{' '}
        <strong>{projetoId}</strong>
        <FormControl variant="filled">
          <TextField
            id="newProjectName"
            label="Nome do Projeto"
            variant="filled"
            size="small"
            value={nomeNewProject}
            onChange={changeNome}
            helperText={
              nomeNewProject?.length === 25
                ? 'O nome não pode exceder o limite de 25 caracteres'
                : ''
            }
          />
        </FormControl>
        {loadDuplicate ? (
          'Duplicando plano...'
        ) : (
          <Button
            onClick={duplicar}
            variant="contained"
            color="secondary"
            className="mb10"
          >
            Duplicar Projeto
          </Button>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default memo(DialogDuplicaPlano)
