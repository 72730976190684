import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'

const DialogAlertEstoque = ({ open, close, material }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography>Estoque</Typography>
          <IconButton aria-label="close" onClick={close} size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="text-center" dividers>
          <Typography>
            O material {material.descricao} não possui estoque suficiente, favor
            entre em contato com a loja.
          </Typography>

          <Button
            onClick={close}
            variant="contained"
            color="secondary"
            className="mb10 mt20"
          >
            Ok
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default memo(DialogAlertEstoque)
