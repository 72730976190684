import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

import Mask from '@service/mask.js'

const DialogAddAmbiente = props => {
  const {
    open,
    close,
    newAmbienteName,
    setNewAmbienteName,
    criarAmbienteViaApi
  } = props

  const validateName = event => {
    if (event.target.value.length <= 28) {
      setNewAmbienteName(Mask.noEspecialCaracter(event.target.value))
      // console.log(Mask.noEspecialCaracter(event.target.value))
    }
  }

  const criarAmbiente = () => {
    criarAmbienteViaApi()
    close()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="customized-dialog-title"
        fullScreen={isMobile}
      >
        <DialogTitle id="form-dialog-title">
          <Typography>Adicionar Ambiente</Typography>
          <IconButton aria-label="close" onClick={close} size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="text-center" dividers>
          <FormControl variant="filled">
            <TextField
              id="newAmbientName"
              label="Nome do Ambiente"
              variant="filled"
              size="small"
              value={newAmbienteName}
              onChange={validateName}
            />
          </FormControl>
          <Button
            onClick={criarAmbiente}
            variant="contained"
            color="secondary"
            className="mb10"
          >
            Criar Ambiente
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default memo(DialogAddAmbiente)
