import axios from 'axios'

import { appConfig } from '@config/env-config.js'

import { showMsgE } from '@base/store/actions/snackbarActions'

const APILEOPLAN = appConfig.REACT_APP_APILEOPLAN

const HTTP_STATUS = {
  NOT_AUTHORIZED: 401,
  INTERNAL_ERROR: 403
}
const MAX_ATTEMPTS = 3
let attempts = 0

const URL_SIGNALR = appConfig.REACT_APP_URL_SIGNALR
const URL_SIGNALR_CLIENT = appConfig.REACT_APP_URL_SIGNALR_CLIENT
const APIMSG = appConfig.REACT_APP_APIMSG

const CancelToken = axios.CancelToken
let cancel

axios.interceptors.request.use(
  async function (request) {
    if (
      request.url.startsWith(URL_SIGNALR) ||
      request.url.startsWith(URL_SIGNALR_CLIENT) ||
      request.url.startsWith(APIMSG)
    ) {
      return request
    }

    const auth0Token = sessionStorage.getItem('AUTH0_TOKEN')
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${auth0Token}`
    }

    return request
  },
  async function (error) {
    const {
      config,
      response: { status }
    } = error

    const originalRequest = config

    if (status === HTTP_STATUS.NOT_AUTHORIZED && attempts < MAX_ATTEMPTS) {
      window.parent.postMessage('getToken', '*')

      const auth0Token = sessionStorage.getItem('AUTH0_TOKEN')

      const retryOrigReq = new Promise(resolve => {
        originalRequest.headers.Authorization = `Bearer ${auth0Token}`
        resolve(axios(originalRequest))
      })

      attempts++
      return retryOrigReq
    }

    if (status === HTTP_STATUS.INTERNAL_ERROR || status === null) {
      return showMsgE('Erro Interno do Servidor.')
    }
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

export const sendWithAxiosBlob = async (url, method, payload, headers) => {
  try {
    const result = await axios({
      url: url,
      method: method,
      responseType: 'blob',
      data: payload,
      headers: headers
    })

    if (result.status >= 300) {
      return {
        data: false,
        fail: true,
        status: result.status,
        errors: result.data.notifications
      }
    }

    if (url.startsWith(APILEOPLAN)) {
      return {
        data: result.data,
        fail: null,
        status: result.status
      }
    }

    return {
      data: result.data.data,
      fail: null,
      status: result.status
    }
  } catch (error) {
    if (url.startsWith(APILEOPLAN)) {
      const errors = error?.response?.data?.mensagem
      return { data: false, fail: true, errors }
    }

    const errors = error?.response?.data?.notifications
    return { data: false, fail: true, errors }
  }
}

export const sendWithAxios = async (url, method, payload, headers) => {
  try {
    const result = await axios({
      url: url,
      method: method,
      data: payload,
      headers: headers
    })

    if (result.status >= 300) {
      return {
        data: false,
        fail: true,
        status: result.status,
        errors: result.data.notifications
      }
    }

    if (url.startsWith(APIMSG)) {
      return {
        data: result.data,
        fail: null,
        status: result.status
      }
    }

    if (url.startsWith(APILEOPLAN)) {
      return {
        data: result.data,
        fail: null,
        status: result.status
      }
    }

    return {
      data: result.data.data,
      fail: null,
      status: result.status
    }
  } catch (error) {
    if (url.startsWith(APILEOPLAN)) {
      const errors = error?.response?.data?.mensagem
      return { data: false, fail: true, errors }
    }

    const errors = error?.response?.data?.notifications
    return { data: false, fail: true, errors }
  }
}

export const sendWithAxiosCancelPrev = async (url, method, payload) => {
  if (cancel !== undefined) {
    cancel()
  }

  try {
    const result = await axios({
      url: url,
      method: method,
      data: payload,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c
      })
    })

    if (result.status >= 300) {
      return {
        data: false,
        fail: true,
        status: result.status,
        errors: result.data.notifications
      }
    }

    if (url.startsWith(APILEOPLAN)) {
      return {
        data: result.data,
        fail: null,
        status: result.status
      }
    }

    return {
      data: result.data.data,
      fail: null,
      status: result.status
    }
  } catch (error) {
    if (url.startsWith(APILEOPLAN)) {
      const errors = error?.response?.data?.mensagem
      return { data: false, fail: true, errors }
    }

    const errors = error?.response?.data?.notifications
    return { data: false, fail: true, errors }
  }
}
