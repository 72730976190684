import { Close } from '@mui/icons-material'
import {
  Dialog,
  Button,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  TextField,
  FormControl
} from '@mui/material'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'

export default function DialogSalvarAgrupamento({
  open,
  onClose,
  save,
  value
}) {
  const [name, setName] = useState(value || '')

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      <DialogTitle>
        <Typography>Adicionar novo grupo de materiais</Typography>
        <IconButton aria-label="close" onClick={onClose} size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className="text-center" dividers>
        <FormControl>
          <TextField
            label="Nome"
            variant="filled"
            className="mb10"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </FormControl>

        <Button
          onClick={() => save(name)}
          variant="contained"
          color="secondary"
          className="mb10 mr10"
        >
          Salvar
        </Button>
        <Button
          onClick={onClose}
          variant="contained"
          className="mb10 blackButton"
        >
          Cancelar
        </Button>
      </DialogContent>
    </Dialog>
  )
}
