import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControlLabel,
  Button,
  TextField,
  Typography,
  Switch
} from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'

import { useConfiguracao } from '@hook/useConfiguracao'
import { useProjeto } from '@hook/useProjeto'

import Mask from '@service/mask.js'

const DialogAddMaterialGenerico = ({
  openModalAddMaterial,
  handleCloseModalAddMaterial,
  handleIncludeMaterial,
  showMsgW,
  espessuraChapa
}) => {
  const [possuiVeio, setPossuiVeio] = useState(false)
  const [comprimento, setComprimento] = useState(0)
  const [largura, setLargura] = useState(0)
  const [descricao, setDescricao] = useState('')
  const { projeto } = useProjeto()
  const { configData } = useConfiguracao()
  const minChapaPermitidoLargura =
    configData?.minimoChapaCorte + configData?.descontoMaximoAltura
  const minChapaPermitidoComprimento =
    configData?.minimoChapaCorte + configData?.descontoMaximoLargura

  const count = `${moment().hours()}${moment().minutes()}${moment().millisecond()}`
  const material = {
    tipoProdutoId: 1,
    IdERP: `${count}`,
    codigo: `${count}`,
    descricao: descricao,
    descricaoResumida: descricao,
    largura: parseInt(comprimento) / 10,
    altura: parseInt(largura) / 10,
    espessura: parseInt(espessuraChapa) / 10,
    veio: possuiVeio,
    canaletado: 0,
    quantidade: 0,
    caminhoImagem: '',
    permiteSerCanaletado: false,
    projetoId: projeto.id
  }

  const onIncluirMaterialGenerico = () => {
    if (parseFloat(largura) < minChapaPermitidoLargura) {
      showMsgW(
        `Largura inválida. Mínimo permitido ${minChapaPermitidoLargura}mm`
      )
      return
    }

    if (parseFloat(comprimento) < minChapaPermitidoComprimento) {
      showMsgW(
        `Comprimento inválido. Mínimo permitido ${minChapaPermitidoComprimento}mm`
      )
      return
    }

    if (parseInt(espessuraChapa) <= 0) {
      showMsgW('Espessura inválida.')
      return
    }

    if (descricao === '') {
      showMsgW('Descrição inválida.')
      return
    }
    handleIncludeMaterial(material, 1, true)
  }

  return (
    <Dialog
      open={openModalAddMaterial}
      onClose={handleCloseModalAddMaterial}
      aria-labelledby="customized-dialog-title"
      fullWidth={true}
      maxWidth="sm"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Incluir material genérico</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseModalAddMaterial}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div className="d-flex">
          <TextField
            id="comprimento"
            label="Comprimento (mm)"
            variant="filled"
            className="py-2 pr-1"
            value={Number(comprimento)}
            inputProps={{ inputMode: 'numeric' }}
            onChange={e => setComprimento(Mask.number(e.target.value))}
          />
          <TextField
            id="largura"
            label="Largura (mm)"
            variant="filled"
            className="py-2 px-1"
            value={Number(largura)}
            inputProps={{ inputMode: 'numeric' }}
            onChange={e => setLargura(Mask.number(e.target.value))}
          />
          <TextField
            hidden
            id="espessura"
            label="Espessura"
            variant="filled"
            className="py-2 pl-1"
            value={`${espessuraChapa}mm`}
            disabled
          />
        </div>

        <TextField
          id="descricao"
          label="Descrição"
          variant="filled"
          className="py-2"
          value={descricao}
          onChange={e => setDescricao(e.target.value)}
        />

        <div className="">
          <FormControlLabel
            value="start"
            control={
              <Switch
                color="primary"
                className="tabela-pecas-ignorarveio"
                value={possuiVeio}
                onChange={(_, checked) => setPossuiVeio(checked)}
              />
            }
            label="Possui veio?"
            labelPlacement="start"
          />
        </div>
        <div className="d-flex">
          <div className="mr-auto">
            <Button
              variant="contained"
              className="m5 blackButton"
              color="primary"
              onClick={handleCloseModalAddMaterial}
            >
              {' '}
              Cancelar
            </Button>
          </div>

          <div className="ml-auto">
            <Button
              variant="contained"
              className="m5"
              color="secondary"
              onClick={() => onIncluirMaterialGenerico()}
            >
              {' '}
              Incluir
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DialogAddMaterialGenerico
