import { appConfig } from '@config/env-config.js'

import Utils from '@service/serviceUtils.js'

import {
  getDescricaoConector,
  getDescricaoFixacao,
  getDescricaoServico
} from '@base/views/LeoPlan/components/PlanoCorte/components/ListagemPecas'

const isSVL = localStorage.getItem('LEOPLAN_PATCH_ORIGEM') === 'SVL'

const API_CORE_IMAGES = isSVL
  ? appConfig.REACT_APP_API_CORE_IMAGES_SVL
  : appConfig.REACT_APP_API_CORE_IMAGES_GW

const baseUrl = appConfig.REACT_APP_BASEURL

function _exibeListaChapas(otimizacao, materiais) {
  let chapaEmUso = []
  chapaEmUso = Utils.extraiChapasEmUso(otimizacao, materiais)

  if (chapaEmUso.length > 0) {
    return chapaEmUso
      .map(
        (mat, indexMat) => `
          <tr>
            <td>${indexMat + 1} - ${mat.codigo}: ${
          mat.descricao
        } ${Utils.descricaoCanaletado(mat, true)}</td>
            <td style="text-align: right;">${mat.qtdPlacasCortadas}</td>
          </tr>
        `
      )
      .join('')
  }
  return ''
}

function _exibeListaFitas(otimizacoes, materiais) {
  let fitasEmUso = []
  fitasEmUso = Utils.extraiFitasEmUso(otimizacoes, materiais)
  if (fitasEmUso.length > 0) {
    return fitasEmUso
      .map(
        (mat, indexMat) => `
          <tr>
            <td>${indexMat + 1} - ${mat.codigo}: ${mat.descricao}</td>
              <td style="text-align: right;">${
                mat.aplicacaoFita ? mat.aplicacaoFita : 0
              } m
              </td>
          </tr>
        `
      )
      .join('')
  }
  return ''
}

function _exibeListaServicos(listaServicos) {
  if (listaServicos.length > 0) {
    return listaServicos
      .map(
        (mat, indexMat) => `
                    <tr>
                        <td>${indexMat + 1} - ${mat.idErp}: ${
          mat.descricao
        }</td>
                        <td style="text-align: right;">${
                          mat.quantidadeComAcrecimo
                            ? mat.quantidadeComAcrecimo
                            : '...'
                        }</td>
                    </tr>
                `
      )
      .join('')
  }
  return ''
}

function _getCodNomeMaterial(materiais, materialId) {
  let materialInfo = ''
  let materialFind = []
  let materialObj = {}
  if (materiais.length && materialId) {
    materialFind = materiais.filter(m => m.id === materialId)
  }
  if (materialFind.length > 0) {
    materialObj = materialFind[0]
    materialInfo = `${materialObj.codigo} - ${materialObj.descricao}`
  }
  return materialInfo
}

function _getImgMaterial(materiais, materialId) {
  const materialFind = materiais.find(m => m.id === materialId)
  return `${API_CORE_IMAGES}${materialFind.codigo}${isSVL ? '.jpg' : ''}`
}

function _getFitasAplicadas(materiais, peca) {
  const urlImagem = API_CORE_IMAGES

  const listaFitasAtivas = []
  if (peca) {
    peca.pecaProduto.forEach(produtoFita => {
      const f = materiais.filter(x => x.id === produtoFita.projetoProdutoId)
      if (f.length > 0) {
        listaFitasAtivas.push(f[0])
      }
    })
  }
  if (listaFitasAtivas.length === 0) {
    return ''
  }

  const fitas =
    listaFitasAtivas.length > 0
      ? listaFitasAtivas
          .map(
            fita => `
            <div className="prodThumbContainer" style="float: left">
                <span class="borderFitaGeral" style="float: left; border-color: ${
                  fita.color
                }; height: 40px; width: 40px; margin-right: 10px;" >
                    <img src="${urlImagem + fita.codigo}${
              isSVL ? '.jpg' : ''
            }" alt="fita" style="height: 38px; width: 38px;" width="38" height="38"  />
                </span>
                ${fita.codigo} - ${fita.descricao}
            </div>
        `
          )
          .join('')
      : ''

  return fitas
}

function _getMiniatura(materiais, peca) {
  const urlImagemFita = baseUrl + 'fita-bg.png'
  const urlImagemFitaH = baseUrl + 'fita-bgH.png'
  const urlImagemFitaV = baseUrl + 'fita-bgV.png'

  const fitaPosicoesAplicadas = { top: '', bottom: '', left: '', right: '' }

  // let bordaCorAplicacao = ''
  const listaFitasAtivas = []
  if (peca) {
    peca.pecaProduto.forEach(produtoFita => {
      const f = materiais.filter(x => x.id === produtoFita.projetoProdutoId)
      if (f.length > 0) {
        listaFitasAtivas.push(f[0])
      }
    })
  }

  if (
    peca.superiorMaterialId ||
    peca.inferiorMaterialId ||
    peca.esquerdaMaterialId ||
    peca.direitaMaterialId
  ) {
    if (listaFitasAtivas.length > 0) {
      listaFitasAtivas.forEach(x => {
        if (peca.superiorMaterialId === x.id) {
          // bordaCorAplicacao += `border-top: 6px solid ${x.color}; `;
          fitaPosicoesAplicadas.top = `background: ${x.color}; `
        }
        if (peca.inferiorMaterialId === x.id) {
          // bordaCorAplicacao += `border-bottom: 6px solid ${x.color}; `;
          fitaPosicoesAplicadas.bottom = `background: ${x.color}; `
        }
        if (peca.esquerdaMaterialId === x.id) {
          // bordaCorAplicacao += `border-left: 6px solid ${x.color}; `;
          fitaPosicoesAplicadas.left = `background: ${x.color}; `
        }
        if (peca.direitaMaterialId === x.id) {
          // bordaCorAplicacao += `border-right: 6px solid ${x.color}; `;
          fitaPosicoesAplicadas.right = `background: ${x.color}; `
        }
      })
    }
  }

  const miniatura = `
        <table border="0" cellpadding="0" cellspacing="0" class="noPaddingTd noBorder" style="height: 40px">
            <tr>
                <td style="padding:0px; border-bottom: none"></td>
                <td style='${
                  fitaPosicoesAplicadas.top
                } height: 6px; padding:0px; border-bottom: none'></td>
                <td style="padding:0px; border-bottom: none"></td>
            <tr>
            <tr>
                <td style='${
                  fitaPosicoesAplicadas.left
                } width: 6px; padding:0px; border-bottom: none'></td>
                <td style="padding:0px; border-bottom: none"><img src="${
                  parseInt(peca.largura) > parseInt(peca.altura)
                    ? urlImagemFitaH
                    : parseInt(peca.largura) < parseInt(peca.altura)
                    ? urlImagemFitaV
                    : urlImagemFita
                }" alt="" height: 40px; width: 40px; style="height: auto; width: auto; display:block"  /></td>
                <td style='${
                  fitaPosicoesAplicadas.right
                } width: 6px; padding:0px; border-bottom: none'></td>
            <tr>
            <tr>
                <td style="padding:0px;  border-bottom: none"></td>
                <td style='${
                  fitaPosicoesAplicadas.bottom
                } height: 6px; padding:0px; border-bottom: none'></td>
                <td style="padding:0px; border-bottom: none"></td>
            <tr>
        </table>
    `
  return miniatura
}

function _getVeioChapa(materiais, materialId) {
  let materialVeio = ''
  const material = materiais.find(material => material.id === materialId)
  if (material) {
    materialVeio = material.veio ? 'sim' : 'não'
  }
  return materialVeio
}
function _getCanaletaChapa(materiais, materialId) {
  let materialCanaletado = ''
  const material = materiais.find(material => material.id === materialId)
  if (material) {
    materialCanaletado = material.canaletado ? material.canaletado + '"' : 'não'
  }
  return materialCanaletado
}

function _exibeListaAmbPecas(planAmbientes, materiais, tipoCorte) {
  if (planAmbientes.length > 0) {
    return planAmbientes
      .map(
        amb => `
                <tr class="tableAmbientName">
                    <td colspan="${
                      tipoCorte.toString() === '1' ? '11' : '10'
                    }"><h4>Ambiente: ${amb.descricao}</h4></td>
                </tr>
                ${amb.projetoPeca
                  .map(
                    peca => `
                    <tr class="tableAmbientPecas">
                        <td style="border-bottom: 1px solid #F5F5F5">
                            <div class="tableImageCell" style="float: left; display: flex;">
                                <img src="${_getImgMaterial(
                                  materiais,
                                  peca.materialId
                                )}" width="40" height="40" style="height: 40px; width: 40px; margin-right: 10px; float: left"> 
                                ${peca.descricao} ${
                      peca.descricao ? '<br />' : ''
                    }
                                ${_getCodNomeMaterial(
                                  materiais,
                                  peca.materialId
                                )}
                            </div>
                        </td>
                        <td style="border-bottom: 1px solid #F5F5F5">${
                          peca.quantidade
                        }</td>
                        <td style="border-bottom: 1px solid #F5F5F5">${
                          peca.largura
                        }</td>
                        <td style="border-bottom: 1px solid #F5F5F5">${
                          peca.altura
                        }</td>
                        <td style="border-bottom: 1px solid #F5F5F5">${_getVeioChapa(
                          materiais,
                          peca.materialId
                        )}</td>
                        ${
                          tipoCorte.toString() === '1' &&
                          _getVeioChapa(materiais, peca.materialId) === 'sim'
                            ? `<td style="border-bottom: 1px solid #F5F5F5">${
                                peca.veio ? '' : 'ignorar'
                              }</td>`
                            : ''
                        }
                        ${
                          tipoCorte.toString() === '1' &&
                          _getVeioChapa(materiais, peca.materialId) !== 'sim'
                            ? '<td style="border-bottom: 1px solid #F5F5F5"></td>'
                            : ''
                        }
                        <td style="border-bottom: 1px solid #F5F5F5">
                            <div class="tableImageCell">
                                ${_getFitasAplicadas(materiais, peca)}
                            </div>
                        </td>
                        <td style="border-bottom: 1px solid #F5F5F5">
                            ${_getMiniatura(materiais, peca)}
                        </td>
                        <td style="border-bottom: 1px solid #F5F5F5;text-align:center;">
                            ${getDescricaoServico(peca) || '-'}
                        </td>
                        <td style="border-bottom: 1px solid #F5F5F5;text-align:center;">
                            ${getDescricaoFixacao(peca) || '-'}
                        </td>
                        <td style="border-bottom: 1px solid #F5F5F5;text-align:center;">
                            ${getDescricaoConector(peca) || '-'}
                        </td>
                    </tr>
                    `
                  )
                  .join('')}
                `
      )
      .join('')
  }
  return ''
}

function _exibeListaAmbPecasCanaletadas(planAmbientes, materiais) {
  if (planAmbientes.length > 0) {
    return planAmbientes
      .map(
        amb => `${amb.projetoPeca
          .map(
            peca => `
                    <tr class="tableAmbientPecas">
                        <td style="border-bottom: 1px solid #F5F5F5">
                            <div class="tableImageCell" style="float: left; display: flex;">
                                <img src="${_getImgMaterial(
                                  materiais,
                                  peca.materialId
                                )}" width="40" height="40"  style="height: 40px; width: 40px; margin-right: 10px; float: left"> 
                                ${peca.descricao} ${
              peca.descricao ? '<br />' : ''
            }
                                ${_getCodNomeMaterial(
                                  materiais,
                                  peca.materialId
                                )}
                            </div>
                        </td>
                        <td style="border-bottom: 1px solid #F5F5F5">${
                          peca.quantidade
                        }</td>
                        <td style="border-bottom: 1px solid #F5F5F5">${
                          peca.largura
                        }</td>
                        <td style="border-bottom: 1px solid #F5F5F5">${
                          peca.altura
                        }</td>
                        <td style="border-bottom: 1px solid #F5F5F5">${_getVeioChapa(
                          materiais,
                          peca.materialId
                        )}</td>
                        <td style="border-bottom: 1px solid #F5F5F5">${_getCanaletaChapa(
                          materiais,
                          peca.materialId
                        )}</td>
                    </tr>
                    `
          )
          .join('')}
                `
      )
      .join('')
  }
  return ''
}

const ResumoBody = {
  stilosCss: () => {
    return `
            <style>
                *{
                    margin: 0;
                    list-style: 0;
                    border: none;
                    background: none;
                    font-weight: 400;
                    text-decoration: none;
                    outline: none;
                    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    font-size: 1rem;
                    color: #212529;
                    padding-inline-start: 0;
                    box-sizing: border-box;
                    border-collapse: collapse;
                }
                table tr th {
                    font-weight: bold;
                }
                strong {
                    font-weight: bold;
                }
                h2 {
                    font-size: 1.3rem;
                    text-transform: uppercase;
                    font-weight: 700;
                    color: #1D5840 ;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    white-space: nowrap;
                    margin-bottom: .5rem;
                }
                h4{
                    color: #1D5840;
                    font-weight: bold;
                    display: inline-block;
                }
                ul {
                    list-style: none;
                    margin: 0px;
                    padding: 0px;
                }
                ul li {
                    padding: 0px;
                    margin: 0px;
                }
                ul li a {
                    border-bottom: 1px solid #ccc;
                    padding: 4px 8px;
                    margin-bottom: 2px;
                    display: block;
                    color: blue;
                }
                .borderFitaGeral {
                    border: 1px solid #FECD04;
                    width: 50px;
                    height: 50px;
                }
                .right {
                    text-align: right;
                }

                .pecaMiniatura {
                    display: block;
                    height: 40px;
                    width: 40px;
                    background-color: #fff;
                    border: 1px solid #ccc;
                    margin: auto;
                }
                .retH {
                    width: 48px !important;
                    height: 38px !important;
                }
                .retV {
                    width: 38px !important;
                    height: 48px !important;
                }
                .ret {
                    width: 42px !important;
                    height: 42px !important;
                }
                .padL10 {
                    padding-left: 10px;
                }
                .padR10 {
                    padding-right: 10px;
                }

                .tableDefaut {
                    width:700px; 
                    margin: auto; 
                    margin-bottom: 10px;
                }
                .pageHeader {
                    background-color: #114634; 
                    border: 0px;
                }
                .pageHeader tr {
                    background-color: #114634;
                    height:80px;
                }
                .pageHeader h1 {
                    color: white;
                    font-size: 2rem;
                    text-transform: uppercase;
                    margin-left: 20px;
                    display: inline;
                    vertical-align: top;
                    line-height: 4rem;
                }
                .pageHeader_img {
                    height: 60px; 
                    margin-right: 20px; 
                    float: right;
                }
                .titleMail td {
                    padding: .5rem;
                }
                .titleMail_lineBefore {
                    background-color: #FECD04; 
                    height: 4px;
                    color: transparent;
                    width: 30px;
                    margin: 0px;
                    display: block;
                }
                .titleMail_lineAfter {
                    background-color: #F5F5F5;
                    height: 1px; 
                    width: 300px;
                    color: transparent;
                    margin: 0px;
                    display: block;
                }
                .titleMail_text {
                    font-size: 1.3rem; 
                    text-transform: uppercase; 
                    font-weight: 700; 
                    color: #1D5840; 
                    display: flex; 
                    align-items: center; 
                    justify-content: space-between; 
                    white-space: nowrap; 
                    margin: 0px;
                }
                
                .sectionSumaryPieces {
                    width:700px; 
                    margin: auto; 
                    margin-bottom: 1rem;
                    margin-top: 2rem;
                    border-collapse: separate;
                }
                .sectionSumaryPieces th {
                    text-align: left;
                }
                
                .sectionSumaryPieces .border img{
                    border: .2rem solid #FECD04;
                    width: 50px;
                    height: 50px;
                }
                .sectionSumaryPieces thead{
                    background-color: #F5F5F5;
                }
                .sectionSumaryPieces thead tr th{
                    padding: .3rem .5rem;
                    line-height: 2rem;
                    font-weight: bold;
                    font-size: .7rem;
                    text-align: left;
                }
                .sectionSumaryPieces tr:not(.tableAmbientName){
                    text-align: center;
                }
                .sectionSumaryPieces tbody img{
                    margin-right: .4rem;
                    width: 60px;
                    height: 60px;
                }
                .sectionSumaryPieces tbody tr:nth-child(odd):not(.tableAmbientName) td{
                    background-color: #FDFDFD;
                }
                .sectionSumaryPieces tbody tr:not(.tableAmbientName) td{
                    border-bottom: 1px solid #F5F5F5;
                    font-size: .8rem;
                }

                .tableImageCell {
                    align-items: center;
                    text-align: left;
                    font-size: .8rem;
                }
                .tableImageCell div {
                    font-size: .8rem;
                }

                .tableAmbientName{
                    background-color: #2a7e5c;
                }
                .tableAmbientName td, .tableAmbientName h4{
                    color:white;
                    font-size: .7rem;
                }
                .tableAmbientName td{
                    padding: .2rem 1rem;
                }
                .tableAmbientName tbody tr:not(.tableAmbientName) {
                    font-size: .3rem;
                }
                .tableAmbientPecas td {
                    
                    font-size: .8rem;
                }

                table.borderGreen {
                    width:700px; 
                    color: black;
                    margin: auto; 
                    padding: .4rem 1rem;
                    border: 1px solid #1D5840;
                    border-radius: 6px;
                    margin-bottom: 10px;
                    border-collapse:separate;
                }

                .nthTable tr:nth-child(2n){
                    background-color: #F5F5F5;
                }
                table.borderGreen h4 {
                    margin: 0px;color: 
                    black;font-size: 14px; 
                    font-weight: bold;
                }
                .noPaddingTd td {
                    padding: 0px;
                    marging: 0px;
                }
                .noPaddingTd img {
                    display: block;
                    margin-right: 0px !important;
                }
                .noBorder td {
                    border-bottom: none !important;
                    padding: 0px;
                }
            </style>
        `
  },

  cabecario: () => {
    return `
            <table class="tableDefaut" border="0" cellpadding="0" cellspacing="0">
                <tbody class="pageHeader">
                    <tr>
                        <td>
                            <h1>Projeto de cortes</h1>
                        </td>
                        <td class="right padR10">
                                <img class='pageHeader_img' src='${baseUrl}/leoplan_logo.png' alt=''>
                        </td>
                    </tr>
                </tbody>
            </table>
        `
  },
  titulo: title => {
    return `
            <table class="tableDefaut">
                <tr class="titleMail">
                    <td>
                        <h3 class="titleMail_lineBefore">&nbsp</h3>
                    </td>
                    <td><h2 class="titleMail_text">${title}</h2></td>
                    <td>
                        <h3 class="titleMail_lineAfter">&nbsp</h3>
                    </td>
                </tr>
            </table>
        `
  },
  projetoNome: (nome, id) => {
    return `
            <table class="borderGreen">
                <tr>
                    <td>
                        <h4>Projeto: </h4>
                        <p style="margin:0px"> ${id} - ${nome}</p>
                    </td>
                </tr>
            </table>
        `
  },
  projetoCliente: nome => {
    return `
            <table class="borderGreen">
                <tr>
                    <td>
                        <h4>Cliente: </h4>
                        <p style="margin:0px"> ${nome}</p>
                    </td>
                </tr>
            </table>
        `
  },
  projetoAmbientes: ambientes => {
    return `
            <table class="borderGreen">
                <tr>
                    <td>
                        <h4>Ambientes:</h4>
                        ${ambientes
                          .map(
                            (ambiente, index) =>
                              `<p style="margin:0px">${index + 1} - ${
                                ambiente.descricao
                              }</p>`
                          )
                          .join('')}
                    </td>
                </tr>
            </table>
        `
  },
  projetoChapas: (otimizacao, materiais) => {
    return `
            <table class="borderGreen">
                <tr>
                    <td>
                        <table style='width:100%'>
                            <thead>
                                <tr>
                                    <th style="text-align: left;">Total de Tipos de Chapas:</th>
                                    <th style="text-align: right;">Quantidade:</th>
                                </tr>
                            </thead>
                            <tbody class='nthTable'>
                                ${_exibeListaChapas(otimizacao, materiais)}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </table>
        `
  },
  projetoFitas: (otimizacao, materiais, configCorteLoja) => {
    return `
            <table class="borderGreen">
                <tr>
                    <td>
                        <table style='width:100%'>
                            <thead>
                                <tr>
                                    <th style="text-align: left;">Total de Fitas:</th>
                                    <th style="text-align: right;">Quantidade:</th>
                                </tr>
                            </thead>
                            <tbody class='nthTable'>
                                ${_exibeListaFitas(
                                  otimizacao,
                                  materiais,
                                  configCorteLoja
                                )}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </table>
        `
  },
  projetoServicos: listaServicos => {
    return `
            <table class="borderGreen">
                <tr>
                    <td> 
                        <table style='width:100%'>
                            <thead>
                                <tr>
                                    <th style="text-align: left;">Total de Serviços:</th>
                                    <th style="text-align: right;">Quantidade:</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${_exibeListaServicos(listaServicos)}
                            </tbody>  
                        </table>
                    </td>
                </tr>
            </table>
        `
  },
  projetoTotalPecas: ambientes => {
    return `
            <table class="borderGreen">
                <tr>
                    <td>
                        <h4>Total Qtd. de Peças: </h4>
                        <span>${Utils.retornaQuantidadePecas(ambientes)}</span>
                    </td>
                </tr>
            </table>
        `
  },
  projetoAmbientesDetalhe: (planAmbientes, planMateriais, tipoCorte) => {
    if (tipoCorte.toString() === '1') {
      return `
                <table class="sectionSumaryPieces">
                    <thead style="font-size: .8rem;">
                        <tr>
                            <th>Desc.</th>
                            <th>Qtd.</th>
                            <th>Comp.</th>
                            <th>Larg.</th>
                            <th>Veio</th>
                            <th>Ignorar Veio</th>
                            <th>Fitas de borda</th>
                            <th>Miniatura</th>
                            <th>Serviços</th>
                            <th>Fixação</th>
                            <th>Conector</th>
                        </tr>
                    </thead>
                    <tbody style="font-size: .8rem;">
                        ${_exibeListaAmbPecas(
                          planAmbientes,
                          planMateriais,
                          tipoCorte
                        )}
                    </tbody>
                </table>
            `
    } else if (tipoCorte.toString() === '4') {
      return `
                <table class="sectionSumaryPieces">
                    <thead style="font-size: .8rem;">
                        <tr>
                            <th>Desc.</th>
                            <th>Qtd.</th>
                            <th>Comp.</th>
                            <th>Larg.</th>
                            <th>Veio</th>
                            <th>Canal.</th>
                        </tr>
                    </thead>
                    <tbody style="font-size: .8rem;">
                        ${_exibeListaAmbPecasCanaletadas(
                          planAmbientes,
                          planMateriais,
                          tipoCorte
                        )}
                    </tbody>
                </table>
            `
    } else {
      return `
                <table class="sectionSumaryPieces">
                    <thead style="font-size: .8rem;">
                        <tr>
                            <th>Desc.</th>
                            <th>Qtd.</th>
                            <th>Comp.</th>
                            <th>Larg.</th>
                            <th>Veio</th>
                            <th>Fitas de borda</th>
                            <th>Miniatura</th>
                            <th>Serviços</th>
                            <th>Fixação</th>
                            <th>Conector</th>
                        </tr>
                    </thead>
                    <tbody style="font-size: .8rem;">
                        ${_exibeListaAmbPecas(
                          planAmbientes,
                          planMateriais,
                          tipoCorte
                        )}
                    </tbody>
                </table>
            `
    }
  },
  downloadProjeto: () => {
    return `
            <table class="tableDefaut">
                <tr class="titleMail">
                    <td>
                        <h3 class="titleMail_lineBefore">&nbsp</h3>
                    </td>
                    <td><h2 class="titleMail_text">Planos de corte</h2></td>
                    <td>
                        <h3 class="titleMail_lineAfter">&nbsp</h3>
                    </td>
                </tr>
            </table>
            <table class="tableDefaut">
                <tbody>
                    <tr>
                        <td colspan="3">
                            [**PDFS_PROJETO_LEOPLAN_OTIMIZACAO**]
                        </td>
                    </tr>
                </tbody>
            </table>
        `
  }
}

export default ResumoBody
