import { Button } from '@mui/material'
import {
  showMsgE as showMsgEAction,
  showMsgW as showMsgWAction
} from '@store/actions/snackbarActions.js'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { useDispatch } from 'react-redux'

import { useConfiguracao } from '@hook/useConfiguracao.js'
import { useProjeto } from '@hook/useProjeto.js'

import PageTitle from '@component/PageTitle/PageTitle'
import Table from '@component/Table/Table'

import api from '@service/api'
import FormatObj from '@service/serviceFormat.js'
import Utils from '@service/serviceUtils.js'

import DialogAddChapa from '../Dialogs/DialogAddChapa.js'
import DialogCanaisPorChapaCanaletada from '../Dialogs/DialogCanaisPorChapaCanaletada.js'
import * as helper from './helper/index.js'

export default function AbaChapasCanaletadas() {
  const dispatch = useDispatch()
  const showMsgE = msg => dispatch(showMsgEAction(msg))
  const showMsgW = msg => dispatch(showMsgWAction(msg))

  const { projeto, changeProjeto } = useProjeto()
  const { configData } = useConfiguracao()

  const [openModalAddChapa, setOpenModalAddChapa] = useState(false)
  const [openModalCanaisPorChapa, setOpenModalCanaisPorChapa] = useState(false)
  const [targetCanaletadoModal, setTargetCanaletadoModal] = useState()

  const handleOpenModalCanaisPorChapa = (tamanhoCanaletado, peca) => {
    atualizaCanaletado(tamanhoCanaletado, peca)
    setTargetCanaletadoModal(
      configData?.configuracoesCanaletagem?.find(
        item => item.canaletado === tamanhoCanaletado
      )
    )
    setOpenModalCanaisPorChapa(true)
  }

  const handleCloseModalCanaisPorChapa = () => {
    setOpenModalCanaisPorChapa(false)
    setTargetCanaletadoModal()
  }

  const handleCloseModalAddChapa = () => {
    setOpenModalAddChapa(false)
  }

  const formatMaterialProject = (rawMaterial, tipo) => {
    if (
      tipo === 'C' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'CH' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'PC' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'UN'
    ) {
      throw new Error('Este tipo de material não pode ser incluido')
    }
    return {
      ...rawMaterial,
      canaletado: rawMaterial.canaletado || 0
    }
  }

  const addMaterialnoProjeto = async (material, tipo) => {
    try {
      const materialJaEstaNoProjeto = projeto.projetoProduto.find(
        mat => mat.codigo.toString() === material.IdERP.toString()
      )

      if (materialJaEstaNoProjeto) {
        showMsgW('O material já existe no projeto.')
        return
      }

      const response = await trackPromise(
        api.core.apiProdutoById(material.IdERP)
      )

      if (response.fail || !response?.Id) {
        throw new Error('Algum erro aconteceu ao tentar incluir a chapa')
      }

      const produto = FormatObj.setNewProduto(
        tipo,
        projeto.id,
        formatMaterialProject(response)
      )

      const responseMatProj = await trackPromise(
        api.leoplan.apiAddProjetoProduto(produto)
      )

      if (responseMatProj.fail || !responseMatProj?.data?.id) throw new Error()

      addChapaCanaletada(responseMatProj.data)
    } catch {
      showMsgW('Algum erro aconteceu ao tentar incluir o material')
    }
  }

  const addAmbienteCanaletado = async () => {
    const newAmbiente = [
      {
        descricao: 'Chapa Canaletada',
        projetoId: parseInt(projeto.id),
        pecas: []
      }
    ]

    const response = await trackPromise(
      api.leoplan.apiNovoAmbiente(newAmbiente)
    )

    if (response.fail || !response?.data[0]?.id) throw new Error()

    const ambienteCanaletado = {
      id: response.data[0].id,
      descricao: response.data[0].descricao,
      projetoId: response.data[0].projetoId,
      projetoPeca: []
    }

    return ambienteCanaletado
  }

  const addNewPecaCanaletada = async (ambId, material) => {
    const peca = FormatObj.setNewPeca(ambId, projeto.id)

    peca.altura = Utils.retornaLimitMaxAltura(
      material.altura,
      4,
      configData.descontoMaximoAltura
    ).toString()

    peca.largura = Utils.retornaLimitMaxLargura(
      material.largura,
      4,
      configData.descontoMaximoLargura
    ).toString()

    peca.veio = material?.veio
    peca.quantidade = 1
    peca.canaletado = 0
    peca.materialId = material.id

    return trackPromise(api.leoplan.apiAddPeca(peca))
  }

  const addChapaCanaletada = async material => {
    try {
      const ambienteCanaletado =
        projeto.projetoAmbiente.length === 0
          ? await addAmbienteCanaletado()
          : projeto.projetoAmbiente[0]

      const pecaCanaletada = await addNewPecaCanaletada(
        ambienteCanaletado.id,
        material
      )

      if (pecaCanaletada.fail) throw new Error()

      const ambienteCanaletadoAtualizado = {
        ...ambienteCanaletado,
        projetoPeca: ambienteCanaletado.projetoPeca.concat(pecaCanaletada.data)
      }

      const baseProjeto = {
        projetoId: ambienteCanaletadoAtualizado.projetoId,
        ambientes: [
          {
            id: ambienteCanaletadoAtualizado.id,
            descricao: ambienteCanaletadoAtualizado.descricao,
            projetoId: ambienteCanaletadoAtualizado.projetoId,
            pecas: ambienteCanaletadoAtualizado.projetoPeca
          }
        ]
      }

      const response = await trackPromise(
        api.leoplan.apiFinalizarProjeto(baseProjeto)
      )

      if (response.fail) throw new Error()

      const updateMateriais = [...projeto.projetoProduto, material]
      changeProjeto('projetoProduto', updateMateriais)
      changeProjeto('projetoAmbiente', [ambienteCanaletadoAtualizado])
    } catch {
      showMsgE('Não foi possível incluir chapa para canaletar')
    }
  }

  const salvaProjeto = async (ambienteSalvar, responsePeca) => {
    try {
      const ambienteCanaletadoAtualizado = {
        ...ambienteSalvar,
        projetoPeca: responsePeca
          ? ambienteSalvar.projetoPeca.concat(responsePeca)
          : ambienteSalvar.projetoPeca
      }

      const projetoAtualizado = {
        projetoId: ambienteCanaletadoAtualizado.projetoId,
        ambientes: [
          {
            id: ambienteCanaletadoAtualizado.id,
            descricao: ambienteCanaletadoAtualizado.descricao,
            projetoId: ambienteCanaletadoAtualizado.projetoId,
            pecas: ambienteCanaletadoAtualizado.projetoPeca
          }
        ]
      }

      const response = await trackPromise(
        api.leoplan.apiFinalizarProjeto(projetoAtualizado)
      )

      if (response.fail) throw new Error()

      changeProjeto('projetoAmbiente', [ambienteCanaletadoAtualizado])
    } catch {
      showMsgE('Ocorreu um erro ao salvar o projeto')
    }
  }

  const removeChapaCanaletada = async peca => {
    try {
      const ambienteCanaletado = projeto.projetoAmbiente[0]

      if (!ambienteCanaletado) return

      const response = await trackPromise(
        Promise.all([
          api.leoplan.apiDeletarPeca(peca.id, projeto.id),
          api.leoplan.apiDeletarProjetoProduto(peca.materialId)
        ])
      )

      if (response[1].fail) throw new Error()

      const ambienteCanaletadoAtualizado = {
        ...ambienteCanaletado,
        projetoPeca: ambienteCanaletado.projetoPeca.filter(
          x => x.id !== peca.id
        )
      }

      const novaListaMateriais = projeto.projetoProduto.filter(
        x => x.id.toString() !== peca.materialId.toString()
      )

      changeProjeto('projetoProduto', novaListaMateriais)
      changeProjeto('projetoAmbiente', [ambienteCanaletadoAtualizado])
    } catch {
      showMsgE('Não foi possível remover este MDF')
    }
  }

  const atualizaMaterial = async (canaletado, peca) => {
    const material = projeto.projetoProduto.find(
      material => peca.materialId === material.id
    )

    if (!material) throw new Error()

    const novoCodigo = material.codigo.includes('-')
      ? material.codigo.split('-', 1) + '-' + canaletado
      : material.codigo + '-' + canaletado

    const materialNoProjeto = projeto.projetoProduto.find(
      material => material.codigo === novoCodigo
    )

    if (materialNoProjeto) {
      throw new Error(
        `O ${material.descricao} com canaletas de ${canaletado} polegadas já está no projeto`
      )
    }

    const updateMaterial = {
      id: material.id,
      codigo: novoCodigo,
      canaletado: Number(canaletado)
    }

    const response = await trackPromise(
      api.leoplan.apiUpdateProjetoProduto(updateMaterial)
    )

    if (response.fail) throw new Error()

    const updateMateriais = projeto.projetoProduto.map(mdf => {
      if (mdf.id === material.id) {
        return {
          ...mdf,
          id: mdf.id,
          codigo: novoCodigo,
          canaletado: Number(canaletado)
        }
      }
      return mdf
    })

    changeProjeto('projetoProduto', updateMateriais)
  }

  const atualizaPeca = async (canaletado, peca) => {
    const ambienteCanaletado = projeto.projetoAmbiente[0]

    if (!ambienteCanaletado) throw new Error()

    const projetoPecaAtualizado = ambienteCanaletado.projetoPeca.map(findPeca =>
      findPeca.id === peca.id
        ? { ...findPeca, canaletado: Number(canaletado) }
        : findPeca
    )

    const ambienteAtualizado = {
      ...ambienteCanaletado,
      projetoPeca: projetoPecaAtualizado
    }

    await salvaProjeto(ambienteAtualizado)
  }

  const atualizaCanaletado = async (canaletado, peca) => {
    try {
      if (canaletado.toString() !== peca.canaletado.toString()) {
        await atualizaMaterial(canaletado, peca)
        await atualizaPeca(canaletado, peca)
      }
    } catch (error) {
      showMsgE(error || 'Não foi possível alterar o canaletado deste MDF')
      setOpenModalCanaisPorChapa(false)
    }
  }

  const atualizaQuantidade = (quantidade, peca) => {
    const ambienteCanaletado = projeto.projetoAmbiente[0]

    if (!ambienteCanaletado) throw new Error()

    const projetoPecaAtualizado = ambienteCanaletado.projetoPeca.map(findPeca =>
      findPeca.id === peca.id
        ? { ...findPeca, quantidade: Number(quantidade) }
        : findPeca
    )

    const ambienteAtualizado = {
      ...ambienteCanaletado,
      projetoPeca: projetoPecaAtualizado
    }

    changeProjeto('projetoAmbiente', [ambienteAtualizado])
  }

  return (
    <div className={isMobile ? 'w-100' : 'pad20 padT10'}>
      <PageTitle
        title="Chapas Canaletadas"
        action={
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModalAddChapa(true)}
            className={isMobile ? 'w-100' : ''}
          >
            Incluir Chapa
          </Button>
        }
      />

      <Table
        className="small"
        headers={helper.tableHeader}
        type="group"
        data={helper.formatDataTableChapas(
          projeto.projetoProduto,
          projeto.projetoAmbiente,
          removeChapaCanaletada,
          atualizaQuantidade,
          configData?.configuracoesCanaletagem,
          handleOpenModalCanaisPorChapa
        )}
      />

      <DialogAddChapa
        showMsgW={showMsgW}
        openModalAddMaterial={openModalAddChapa}
        handleCloseModalAddMaterial={handleCloseModalAddChapa}
        handleIncludeMaterail={addMaterialnoProjeto}
        espessuraChapa="18mm"
      />

      {openModalCanaisPorChapa && (
        <DialogCanaisPorChapaCanaletada
          open={openModalCanaisPorChapa}
          close={handleCloseModalCanaisPorChapa}
          canaletado={targetCanaletadoModal}
        />
      )}
    </div>
  )
}
