import { createContext, useContext, useEffect, useState } from 'react'

const UserContext = createContext()

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [ambient, setAmbient] = useState('')
  const [redirectUrl, setRedirectUrl] = useState('')

  const storeData = user => {
    sessionStorage.setItem('LEOPLAN_USER_INFOS', JSON.stringify(user))
    setUser(user)
  }

  const setupVendor = initialVendor => {
    localStorage.setItem('LEOPLAN_PATCH_ORIGEM', 'SVL')
    setAmbient('SVL')
    storeData(initialVendor)
  }

  const setupClient = initialClient => {
    localStorage.setItem('LEOPLAN_PATCH_ORIGEM', 'ECOM')
    setAmbient('ECOM')
    storeData(initialClient)
  }

  const clearUser = () => {
    sessionStorage.clear()
    localStorage.clear()
    setAmbient('')
    setUser({})
  }

  const hasUser = () => {
    return !!sessionStorage.getItem('LEOPLAN_USER_INFOS')
  }

  useEffect(() => {
    const user = sessionStorage.getItem('LEOPLAN_USER_INFOS')
    if (user && user !== 'undefined') {
      setUser(JSON.parse(user))
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        user,
        hasUser,
        ambient,
        clearUser,
        setupClient,
        setupVendor,
        redirectUrl,
        setRedirectUrl
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

function useUser() {
  const context = useContext(UserContext)
  return context
}

export { UserProvider, useUser }
