import imgProjCanaletado from '@asset/img/projCanaletado.png'
import imgProjCompleto from '@asset/img/projCompleto.png'
import imgProjCorteTransporte from '@asset/img/projCorteTransporte.png'
import imgProjTirasH from '@asset/img/projTiraH.png'
import imgProjTirasV from '@asset/img/projTiraV.png'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

import { useConfiguracao } from '@hook/useConfiguracao'
import { useStepperControl } from '@hook/useStepperControl'

import './DialogEscolhaProjType.scss'
import { useUser } from '@hook/useUser'

const DialogEscolhaProjType = props => {
  const ORIGEM = localStorage.getItem('LEOPLAN_PATCH_ORIGEM')
  const { setIsCanaletadoProject, handleRestartSteps } = useStepperControl()
  const { habilitarCanaletado } = useConfiguracao()
  const { user } = useUser()

  const { open, close, action } = props

  const handleSelectProjeto = type => {
    setIsCanaletadoProject(false)
    handleRestartSteps()
    action(type)
  }
  const handleSelectProjetoChapasCanaletadas = () => {
    setIsCanaletadoProject(true)
    handleRestartSteps()
    action('CC')
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
      maxWidth="lg"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Criar plano de corte</Typography>
        <IconButton aria-label="close" onClick={close} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="text-center" dividers>
        {isMobile && (
          <Button className="mb-5 w-100" variant="contained" onClick={close}>
            Ver histórico de planos
          </Button>
        )}
        <div className="typeProjectContainer">
          <div className="typeProjectContent">
            <img src={imgProjCompleto} alt="Projeto Completo" />
            <strong>Projeto Completo</strong>
            <p>Projeto com peças de tamanhos variados</p>
            <Button
              onClick={() => handleSelectProjeto('C')}
              variant="contained"
              color="secondary"
              className="btn-block"
            >
              Acessar
            </Button>
          </div>
          <div className="typeProjectContent">
            <img src={imgProjTirasH} alt="Tiras Horizontais" />
            <strong>Tiras Horizontais</strong>
            <p>Projeto com cortes apenas em tiras</p>
            <Button
              onClick={() => handleSelectProjeto('H')}
              variant="contained"
              color="secondary"
              className="btn-block"
            >
              Acessar
            </Button>
          </div>
          <div className="typeProjectContent">
            <img src={imgProjTirasV} alt="Tiras Verticais" />
            <strong>Tiras Verticais</strong>
            <p>Projeto com cortes apenas em tiras</p>
            <Button
              onClick={() => handleSelectProjeto('V')}
              variant="contained"
              color="secondary"
              className="btn-block"
            >
              Acessar
            </Button>
          </div>

          {ORIGEM !== 'ECOM' && (
            <div className="typeProjectContent">
              <img src={imgProjCorteTransporte} alt="Corte Loja" />
              <strong>Corte Transporte Loja</strong>
              <p>Medidas diversas</p>
              <Button
                onClick={() => handleSelectProjeto('T')}
                variant="contained"
                color="secondary"
                className="btn-block"
              >
                Acessar
              </Button>
            </div>
          )}

          {habilitarCanaletado && user.tipoVenda !== 'ZLPL' && (
            <div className="typeProjectContent">
              <img src={imgProjCanaletado} alt="Chapa Canaletada" />
              <strong>Chapa Canaletada</strong>
              <p>Projeto com chapas canaletadas</p>
              <Button
                onClick={handleSelectProjetoChapasCanaletadas}
                variant="contained"
                color="secondary"
                className="btn-block"
              >
                Acessar
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default memo(DialogEscolhaProjType)
