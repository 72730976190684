import { HubConnectionBuilder } from '@microsoft/signalr'
import SettingsIcon from '@mui/icons-material/Settings'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { showMsgS, showMsgW, showMsgE } from '@store/actions/snackbarActions.js'
import React, { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { appConfig } from '@config/env-config.js'

import { useConfiguracao } from '@hook/useConfiguracao'
import { useProjeto } from '@hook/useProjeto.js'
import { useUser } from '@hook/useUser.js'

import api from '@service/api'

import ListaProjetos from './components/ListaProjetos.js'

const URL_SIGNALR = appConfig.REACT_APP_URL_SIGNALR

const TabComponent = props => {
  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
    props.changeTipo(newValue)
  }
  return (
    <div className="row mt10">
      <div className="col-sm-12">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Disponíveis" id="0" />
          <Tab label="Historico" id="1" />
          <Tab label="Inativados" id="2" />
        </Tabs>
      </div>
    </div>
  )
}

function contains(target, pattern) {
  if (target) {
    var value = 0
    pattern.forEach(function (word) {
      value = value + target.includes(word)
    })
    return value === 1
  }
}
const LeoPlanLista = props => {
  const [connection, setConnection] = useState(null)
  const { showMsgS, showMsgW, showMsgE } = props
  const { user, ambient } = useUser()
  const navigate = useNavigate()
  const { tipo } = useParams()
  const { setProjeto } = useProjeto()
  const { configData, setConfigData, setConfigStatus, setUpdateData } =
    useConfiguracao()
  const [planos, setPlanos] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [enableConfig, setEnableConfig] = useState(false)
  const [tipoLista, setTipoLista] = useState(
    tipo === 'disponiveis' ? 0 : tipo === 'desativados' ? 2 : 1
  )

  const getProjetoPorCliente = async (tipo, cliente, empresa, lojaIdErp) => {
    if (tipo === 'disponiveis') {
      return await api.leoplan.apiObterProjetosPorCliente(
        cliente,
        empresa,
        lojaIdErp
      )
    } else if (tipo === 'historico') {
      return await api.leoplan.apiObterProjetosPorCliente(
        cliente,
        empresa,
        lojaIdErp,
        undefined,
        true
      )
    } else if (tipo === 'desativados') {
      return await api.leoplan.apiObterProjetosPorCliente(
        cliente,
        empresa,
        lojaIdErp,
        false
      )
    }
  }

  const getProjetos = async tipo => {
    if (user.tipoUserLeoPlan === 'cliente') {
      const result = await getProjetoPorCliente(tipo, user.idErp)
      const list = result.data
      setPlanos([...list])
      setLoaded(true)
    } else {
      if (user.clientePreSelecionado) {
        const result = await getProjetoPorCliente(
          tipo,
          user.clientePreSelecionado.idExterno,
          user.empresaIdErp,
          user.lojaIdErp
        )
        const list = result.data

        setPlanos([...list])
        setLoaded(true)
      } else {
        try {
          const response = await trackPromise(
            api.leoplan.apiGetProjetosVendedorPorCliente(tipo, user.email)
          )

          if (response.fail) throw new Error()

          setPlanos(
            response.data.map(item => ({ projeto: item, statusOtimizacao: {} }))
          )
        } catch (error) {
          showMsgE('Ocorreu um erro ao buscar os projetos')
        }
      }
    }
  }
  const changeTipo = tipo => {
    setLoaded(false)
    setPlanos([])

    if (tipo === 0) {
      setTipoLista(tipo)
      getProjetos('disponiveis')
    } else if (tipo === 1) {
      setTipoLista(tipo)
      getProjetos('historico')
    } else {
      setTipoLista(tipo)
      getProjetos('desativados')
    }
  }
  const flagPlanoCorte = id => {
    const newList = [...planos]
    const indexItem = newList
      .map(function (e) {
        return e.projetoId
      })
      .indexOf(id)

    if (indexItem >= 0) {
      newList[indexItem].select = !newList[indexItem].select
    }

    setPlanos([...newList])
  }
  const getStatusAmbienteSalvo = proj => {
    const dataAltProjeto = proj.projeto.dataUltimaAtualizacao
      .substring(0, 22)
      .replace(/[^\d]/g, '')
    const dataAltOtimizacao = proj.statusOtimizacao.dataUltimaAtualizacao
      .substring(0, 22)
      .replace(/[^\d]/g, '')
    if (dataAltProjeto < dataAltOtimizacao) {
      return false
    } else {
      return true
    }
  }
  const enviaPlanosSVL = () => {
    if (user.tipoUserLeoPlan.toUpperCase() !== 'VENDEDOR') {
      showMsgW('Seu perfil não tem permissão para executar esta operação')
      return false
    }

    const planosSelecionados = planos.filter(x => x.select)

    if (!planosSelecionados || !planosSelecionados.length) {
      showMsgW('Nenhum projeto selecionado')
      return false
    }

    for (let x = 0; x < planosSelecionados.length; x++) {
      if (getStatusAmbienteSalvo(planosSelecionados[x])) {
        showMsgW(`
          O projeto '${planosSelecionados[x].projetoId} - ${planosSelecionados[x].projeto.projetoNome}' 
          Foi alterado e precisa ser reotimizado antes de incluir no pedido
        `)
        return false
      }
      if (planosSelecionados[x].produtos.length > 0) {
        for (let y = 0; y < planosSelecionados[x].produtos.length; y++) {
          if (planosSelecionados[x].produtos[y].quantidade === 0) {
            showMsgW(`
              O projeto '${planosSelecionados[x].projetoId} - ${planosSelecionados[x].projetoNome}' 
              Possui materias adicionados que não estão sendo usados
            `)
            return false
          }
        }
      } else {
        showMsgW(`
          O projeto '${planosSelecionados[x].projetoId} - ${planosSelecionados[x].projetoNome}' 
          Não possui produtos
        `)
        return false
      }
      if (
        planosSelecionados[x].lojaIdErp.toString() !== user.lojaIdErp.toString()
      ) {
        showMsgW(`
          O projeto '${planosSelecionados[x].projetoId} - ${planosSelecionados[x].projetoNome}' 
          Esta configurado para outra loja, altere para sua loja e otimize novamente.
        `)
        return false
      }
    }
  }

  const handleSaveName = async (projectId, value) => {
    if (value.length > 25) {
      showMsgW('O nome excede o limite de 25 caracteres')
    } else if (value.length >= 3) {
      try {
        const response = await trackPromise(
          api.leoplan.apiSalvarNomeProjeto(projectId, value)
        )

        if (response.fail) throw new Error(response.errors)

        getProjetos('disponiveis')
      } catch (error) {
        showMsgE(error)
      }
    } else {
      showMsgW('O projeto precisa ter um nome de no mínimo 3 caracteres.')
    }
  }

  const handleOpenConfiguracoes = () => {
    navigate('/configuracao')
  }

  useEffect(() => {
    setProjeto({})
  }, [])

  useEffect(() => {
    if (user.id) {
      getProjetos(tipo)
    }
  }, [user])

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(URL_SIGNALR)
      .withAutomaticReconnect()
      .build()

    setConnection(newConnection)
  }, [])

  useEffect(() => {
    const idExterno =
      user.tipoUserLeoPlan === 'cliente'
        ? user.idErp
        : user?.clientePreSelecionado?.idExterno
    const messageReciever = async () => {
      try {
        await connection.start()
        connection.invoke('JoinGroup', idExterno)
        connection.on('otimizacao_status', (group, message) => {
          atualizaListaProjeto(JSON.parse(message))
        })
      } catch (e) {
        console.log('error conexao websocket: ', e)
      }
    }

    if (connection) messageReciever()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection])

  useEffect(() => {
    if (configData && user) {
      if (
        contains(user?.perfilOperacional, [
          'Diretor Operacional',
          'Diretor Geral',
          'Gerente Industrial'
        ])
      ) {
        setEnableConfig(true)
        return
      }
    }

    setEnableConfig(false)
  }, [configData, user])

  async function getConfigData() {
    try {
      const { data } = await api.leoplan.apiGetConfigdata(
        user.lojaIdErp,
        user.empresaIdErp
      )
      setConfigData(data)
      setUpdateData(data)
      setConfigStatus(true)
    } catch {
      showMsgE('Erro ao consultar as configurações da loja')
    }
  }
  useEffect(() => {
    if (
      user &&
      user?.lojaIdErp &&
      user?.empresaIdErp &&
      ambient &&
      ambient !== 'ECOM' &&
      !configData
    ) {
      getConfigData()
    }
  }, [user, ambient])

  const atualizaListaProjeto = atualizacao => {
    setPlanos(planosOld => {
      return planosOld.map(plano => {
        if (plano?.projeto?.projetoId === atualizacao.projetoId) {
          return {
            ...plano,
            statusOtimizacao: {
              ...plano?.projeto?.statusOtimizacao,
              status:
                atualizacao?.status ?? plano?.projeto?.statusOtimizacao?.status,
              dataUltimaAtualizacao:
                atualizacao?.dataAlteracao ??
                plano?.projeto?.statusOtimizacao?.dataUltimaAtualizacao
            }
          }
        }
        return plano
      })
    })
  }

  return (
    <>
      <div className="flexLeo f-start lineResumoTitle">
        <TabComponent active={tipoLista} changeTipo={changeTipo} />
        {enableConfig && (
          <SettingsIcon
            onClick={handleOpenConfiguracoes}
            className="clicable"
          />
        )}
      </div>
      <ListaProjetos
        enviaPlanosSVL={enviaPlanosSVL}
        handleSaveName={handleSaveName}
        flagPlanoCorte={flagPlanoCorte}
        planosCorte={planos}
        tipoLista={tipoLista}
        showMsgS={showMsgS}
        showMsgW={showMsgW}
        showMsgE={showMsgE}
        getProjetos={getProjetos}
        loaded={loaded}
        {...props}
      />
    </>
  )
}

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMsgS,
      showMsgW,
      showMsgE
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LeoPlanLista)
