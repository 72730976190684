import {
  EdgebandRepoItem,
  type BoardRepoItem,
  type ColorRef,
  type ImageTextureAssetRef,
  type RepoItemKind,
  type TextureInfo,
  type RepositoryData,
  type NumArray3,
  type Object3DExtraProps,
  type Object3DSource,
  Repository,
  getAsset
} from '@dotproductdev/parametric-configurator'
import {
  CylinderGeometry,
  MeshPhongMaterial,
  Mesh,
  MirroredRepeatWrapping,
  type Object3D
} from 'three'

import RepoData from './repo.json'

const mkColorRef = (color: string, opacity?: number): ColorRef => ({
  color,
  opacity,
  refType: 'color'
})

const mkImgTexRef = (
  name: string,
  texInfo: TextureInfo
): ImageTextureAssetRef => ({ name, texInfo, refType: 'image' })

const sharedProps = {
  wrapping: MirroredRepeatWrapping,
  metalness: 0.5,
  roughness: 1
}
const textureRefDatabase: Record<string, ImageTextureAssetRef> = {
  particleBoard: mkImgTexRef('aglomerado.jpg', {
    grain: null,
    width: 0.15,
    height: 0.03,
    ...sharedProps
  }),
  mdfBoard: mkImgTexRef('mdf.jpg', {
    grain: null,
    width: 1,
    height: 1,
    ...sharedProps
  }),
  walnut: mkImgTexRef('nogueira.jpg', {
    grain: 'Horizontal',
    width: 3,
    height: 3,
    ...sharedProps
  })
}

type BoardBase = Omit<BoardRepoItem, 'width' | 'height' | 'thickness' | 'sku'>

const coloredMdfBoard = (name: string, color: string): BoardBase => ({
  itemType: 'Board',
  name,
  grain: 'NoGrain',
  inside: textureRefDatabase.mdfBoard,
  bottomFace: mkColorRef(color),
  topFace: mkColorRef(color)
})

// A little helper function that simplifies the construction of the mock repo
// below by allowing '...' syntax.
const boardHelper = (
  sku: string,
  info: BoardBase,
  width: number,
  height: number,
  thickness: number
) => ({
  [sku]: {
    ...info,
    sku,
    width,
    height,
    thickness
  } as BoardRepoItem
})

const edgeHelper = (sku: string, info: Omit<EdgebandRepoItem, 'sku'>) => ({
  [sku]: { ...info, sku } as EdgebandRepoItem
})

export class MockRepository implements Repository {
  public readonly name = 'Repository'

  public readonly data: RepositoryData

  constructor() {
    this.data = RepoData as RepositoryData
  }

  getChoices(itemType: RepoItemKind) {
    return this.data[itemType] ?? {}
  }
}

const getRotationMultiplierByFace = (face: string) => {
  let rotMultiplier = 2
  if (face === 'West') {
    rotMultiplier = 4
  } else if (face === 'North') {
    rotMultiplier = 3
  } else if (face === 'South') {
    rotMultiplier = 1
  }
  return rotMultiplier
}

interface Object3DAssetWithOption {
  asset: () => Object3D | undefined
  extraprops: (options: string) => Object3DExtraProps
}

const mockAssets: Partial<
  Record<RepoItemKind, Record<string, Record<string, Object3DAssetWithOption>>>
> = {
  DoorHinge: {
    hinge: {
      door: {
        asset: () => getAsset('hinge_door'),
        extraprops: () => ({
          scale: 13,
          rotation: [0, Math.PI, -Math.PI / 2],
          position: [-0.62, 0, -0.18]
        })
      },
      side: {
        asset: () => getAsset('hinge_side'),
        extraprops: (options: string) => {
          let rotation: NumArray3 = [0, 0, 0]
          if (options === 'Left') {
            rotation = [0, Math.PI, 0]
          } else if (options === 'Right') {
            rotation = [Math.PI, 0, 0]
          } else if (options === 'Top') {
            rotation = [Math.PI, 0, Math.PI / 2]
          } else if (options === 'Bottom') {
            rotation = [Math.PI, 0, -Math.PI / 2]
          }
          return {
            scale: 13,
            rotation,
            position: [0, 0, 0]
          }
        }
      }
    }
  },
  PullHandle: {
    handle_famA_001: {
      pull: {
        asset: () => getAsset('handle_001'),
        extraprops: (options: string) => ({
          position:
            options === 'Top' ? [-0.19, 0.58, 0.268] : [-0.19, -0.58, -0.125],
          rotation:
            options === 'Top'
              ? [Math.PI / 2, Math.PI / 2, 0]
              : [-Math.PI / 2, Math.PI / 2, 0],
          scale: 0.132
        })
      },
      screw: {
        asset: () => getAsset('screw'),
        extraprops: (options: string) => ({
          position: options === 'Bottom' ? [0, -0.22, 0] : [0, -0.22, 0],
          rotation: options === 'Bottom' ? [0, 0, 0] : [0, 0, 0],
          scale: 0.05
        })
      }
    }
  },
  DrawerSlide: {
    standard_slide: {
      inner: {
        asset: () => getAsset('drawer_slide_innermount'),
        extraprops: (options: string) => ({
          position:
            options === 'Right'
              ? [1.355, -0.12, -0.225]
              : [-1.355, -0.12, 0.225],
          rotation:
            options === 'Right'
              ? [Math.PI / 2, Math.PI / 2, 0]
              : [-Math.PI / 2, -Math.PI / 2, 0],
          scale: 10
        })
      },
      outer: {
        asset: () => getAsset('drawer_slide_outermount'),
        extraprops: () => ({
          position: [0.23, 0.0, 3.6],
          rotation: [Math.PI, Math.PI, -Math.PI / 2],
          scale: 10
        })
      }
    }
  },
  Misc: {
    minifix: {
      cam: {
        asset: () => getAsset('minifix_cam'),
        extraprops: (options: string) => {
          return {
            scale: 0.0085,
            position: [0, -0.115, 0],
            // NOTE: we would need to get the side where the minifix is
            // applied to compute the right rotation angle so that
            // connector is locked in place.
            rotation: [
              0,
              (getRotationMultiplierByFace(options) * Math.PI) / 2,
              0
            ]
          }
        }
      },
      connector: {
        asset: () => getAsset('minifix_connector'),
        extraprops: () => ({
          scale: 0.009
        })
      }
    },
    dfix: {
      main: {
        asset: () => getAsset('dfix'),
        extraprops: (options: string) => {
          return {
            scale: 0.1,
            position: [0.002, 0.002, 0],
            rotation: [(3 * Math.PI) / 2, 0, options === 'East' ? 0 : Math.PI]
          }
        }
      }
    },
    vb35m16: {
      main: {
        asset: () => getAsset('vb35m'),
        extraprops: (options: string) => {
          return {
            position: [0.006, 0.014, 0],
            rotation: [Math.PI / 2, 0, options === 'West' ? 0 : Math.PI]
          }
        }
      }
    },
    vb35m19: {
      main: {
        asset: () => getAsset('vb35m'),
        // TODO: slightly scale the component so that we can reuse the
        // model.
        extraprops: (options: string) => {
          return {
            position: [0.006, 0.014, 0],
            rotation: [Math.PI / 2, 0, options === 'West' ? 0 : Math.PI]
          }
        }
      }
    },
    vb36m16: {
      main: {
        asset: () => getAsset('vb36m'),
        extraprops: (options: string) => {
          return {
            position: [0.006, 0.014, 0],
            rotation: [Math.PI / 2, 0, options === 'West' ? 0 : Math.PI]
          }
        }
      }
    },
    vb36m19: {
      main: {
        asset: () => getAsset('vb36m'),
        // TODO: slightly scale the component so that we can reuse the
        // model.
        extraprops: (options: string) => {
          return {
            position: [0.006, 0.014, 0],
            rotation: [Math.PI / 2, 0, options === 'West' ? 0 : Math.PI]
          }
        }
      }
    },
    dowel: {
      main: {
        asset: () => getAsset('dowel'),
        extraprops: () => ({
          scale: [0.0094, 0.0071, 0.0094]
        })
      }
    },
    shelf_support: {
      main: {
        asset: () => getAsset('shelf_support'),
        extraprops: (options: string) => ({
          scale: 0.01,
          position: [options === 'Left' ? -0.1 : 0.1, 0, 0],
          rotation:
            options === 'Left'
              ? [-Math.PI / 2, -Math.PI, -Math.PI / 2]
              : [Math.PI / 2, 0, -Math.PI / 2]
        })
      }
    },
    shock_absorber: {
      main: {
        asset: () => {
          // Create a cylinder and call it a day.
          const cyl = new CylinderGeometry(0.05, 0.05, 0.01)
          const mat = new MeshPhongMaterial({
            color: 'white',
            transparent: true,
            opacity: 0.75
          })
          return new Mesh(cyl, mat)
        },
        extraprops: () => ({})
      }
    }
  }
}

export class MockObject3DSource implements Object3DSource {
  readonly name = 'Object3DSource'

  get(
    itemType: RepoItemKind,
    sku: string,
    element: null | string,
    options: null | string
  ) {
    const entry = mockAssets[itemType]
    if (!entry) {
      return null
    }
    const item = entry[sku]
    if (!item) {
      return null
    }
    const asset = item[element ?? 'main']
    return asset
      ? { ...asset, extraprops: asset.extraprops(options ?? '') }
      : null
  }
}

let cached: [Object3DSource, Repository] | null = null

export const getMockServices = () => {
  if (cached) {
    return cached
  }
  cached = [new MockObject3DSource(), new MockRepository()]
  return cached
}
