import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Checkbox, Chip, TextField, Autocomplete } from '@mui/material'
import { createFilterOptions } from '@mui/material/useAutocomplete'
import { useState, useEffect } from 'react'
import './SelectCheckbox.scss'
import { isMobile } from 'react-device-detect'

const SelectCheckbox = ({
  items,
  label,
  limitTags = 2,
  required,
  onChangeSelectedValues,
  className,
  id,
  disableClear,
  error,
  selectedValue,
  size = 'small',
  disabled
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])

  const getOptionLabel = option => `${option.label}`
  const onToggleOption = selectedOptions => {
    onChangeSelectedValues(selectedOptions)
    setSelectedOptions(selectedOptions)
  }
  const onClearOptions = () => setSelectedOptions([])
  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedOptions(items)
      onChangeSelectedValues(items)
    } else {
      onClearOptions()
      onChangeSelectedValues([])
    }
  }
  const allSelected = items.length === selectedOptions.length

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected)
  }

  const handleChange = (_, selectedItems, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedItems.find(option => option.value === 'select-all')) {
        handleToggleSelectAll()
      } else {
        onToggleOption && onToggleOption(selectedItems)
      }
    } else if (reason === 'clear') {
      onClearOptions && onClearOptions()
    }
  }
  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option.value === 'select-all' ? { checked: allSelected } : {}

    const optionIsSelectAll = getOptionLabel(option) === 'Selecionar todos'

    if (option.value || option.value === 0) {
      return (
        <div className="selectCheckbox_item" key={option.label} {...props}>
          <Checkbox
            id={id + '-' + option.value + '-checkbox'}
            icon={<CheckBoxOutlineBlankIcon fontSize={'small'} />}
            checkedIcon={<CheckBoxIcon fontSize={'small'} />}
            style={{ marginRight: 8 }}
            className="selectCheckbox_checkbox"
            checked={selected}
            {...selectAllProps}
          />

          {optionIsSelectAll ? (
            <strong>Selecionar todos</strong>
          ) : (
            getOptionLabel(option)
          )}
        </div>
      )
    } else {
      return (
        <div className="selectCheckbox_item">
          <hr />
        </div>
      )
    }
  }

  const inputRenderer = params => (
    <TextField
      {...params}
      id={id + '-input-buscar'}
      required={required}
      error={error}
      variant="filled"
      placeholder="Pesquisar"
      label={label}
    />
  )
  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value

  const filter = createFilterOptions()

  useEffect(() => {
    if (selectedValue) setSelectedOptions(selectedValue)
  }, [selectedValue])

  const tagLimit = isMobile ? 1 : limitTags

  return (
    <div className={className}>
      <Autocomplete
        label={label}
        data-testid="autocomplete_SelectCheckbox"
        classes={{ listbox: 'selectCheckbox' }}
        multiple
        id={id + '-input-select'}
        size={size}
        className="selectCheckbox"
        options={items}
        disabled={disabled || false}
        disableClearable={disableClear || false}
        value={selectedOptions}
        disableCloseOnSelect
        isOptionEqualToValue={getOptionSelected}
        noOptionsText={'Nenhum item encontrado'}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          return [
            { label: 'Selecionar todos', value: 'select-all' },
            <hr key="hr" />,
            ...filtered
          ]
        }}
        onChange={handleChange}
        renderTags={(options, getTagProps) => {
          const numTags = options.length
          return (
            <div className="m0">
              {options.slice(0, tagLimit).map(option => (
                <Chip
                  className="m0 selectCheckbox_tag"
                  size="small"
                  key={option.value}
                  label={option.label}
                  {...getTagProps({})}
                />
              ))}

              {numTags > tagLimit && ` +${numTags - tagLimit}`}
            </div>
          )
        }}
        getOptionLabel={getOptionLabel}
        renderOption={optionRenderer}
        renderInput={inputRenderer}
      />
    </div>
  )
}

SelectCheckbox.defaultProps = {
  items: [],
  selectedOptions: [],
  onChangeSelectedValues: () => false
}

export default SelectCheckbox
