import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

const DialogVoltarListagem = props => {
  const [openModalVoltar, setOpenModalVoltar] = React.useState(false)
  const handleOpenModalVoltar = () => {
    setOpenModalVoltar(true)
  }
  const handleCloseModalVoltar = () => {
    setOpenModalVoltar(false)
  }
  return (
    <>
      <div style={{ float: 'left' }}>
        {props.pedidoId ? (
          <Link to="/plano-de-corte/projetos/disponiveis">
            <Button
              variant="contained"
              className={`blackButton ${isMobile ? 'm-1' : 'm5'}`}
            >
              {props.txt ? props.txt : 'Voltar para listagem de projetos'}
            </Button>
          </Link>
        ) : (
          <>
            {props.activeStep === 3 ? (
              <Link to="/plano-de-corte/projetos/disponiveis">
                <Button
                  variant="contained"
                  className={`blackButton ${isMobile ? 'm-1' : 'm5'}`}
                >
                  {props.txt ? props.txt : 'Voltar para listagem de projetos'}
                </Button>
              </Link>
            ) : (
              <Button
                onClick={handleOpenModalVoltar}
                variant="contained"
                className={`blackButton ${isMobile ? 'm-1' : 'm5'}`}
              >
                {props.txt ? props.txt : 'Voltar para listagem de projetos'}
              </Button>
            )}
          </>
        )}
      </div>
      <Dialog
        open={openModalVoltar}
        onClose={handleCloseModalVoltar}
        aria-labelledby="customized-dialog-title"
        fullScreen={isMobile}
      >
        <DialogTitle id="form-dialog-title">
          <Typography>Voltar para listagem de projetos</Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModalVoltar}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="text-center" dividers>
          <Typography className="mb20">
            Tem certeza que deseja voltar para a listagem? todas as informações
            não salvas serão perdidas
          </Typography>
          <Link to="/plano-de-corte/projetos/disponiveis">
            <Button variant="contained" color="secondary" className="mb10 mr5">
              Confirmar
            </Button>
          </Link>
          <Button
            onClick={handleCloseModalVoltar}
            variant="contained"
            className="mb10 blackButton"
          >
            Cancelar
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default memo(DialogVoltarListagem)
