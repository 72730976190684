import {
  SNACKBAR_SUCCESS,
  SNACKBAR_CLEAR,
  SNACKBAR_WARNING,
  SNACKBAR_ERROR
} from '@store/actions/actionTypes'

const initialState = {
  successSnackbarOpen: false,
  successSnackbarInfo: 'info',
  successSnackbarMessage: ''
}

const snackbarReducer = (state = initialState, action) => {
  let message
  switch (action.type) {
    case SNACKBAR_SUCCESS:
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarInfo: 'success',
        successSnackbarMessage: action.message
      }
    case SNACKBAR_WARNING:
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarInfo: 'warning',
        successSnackbarMessage: action.message
      }
    case SNACKBAR_ERROR:
      if (action.message?.message?.[0] === '[') {
        message = JSON.parse(action.message?.message)?.[0]?.message
      } else if (typeof action.message?.message === 'string') {
        message = action.message?.message
      } else if (typeof action.message === 'string') {
        message = action.message
      } else {
        message = 'Algum erro aconteceu, não foi possível realizar esta ação'
      }

      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarInfo: 'error',
        successSnackbarMessage: message
      }
    case SNACKBAR_CLEAR:
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false
      }
    default:
      return state
  }
}

export default snackbarReducer
