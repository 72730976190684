import React, { memo, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

const BoderColors = ['#FECD04', '#28a745', '#007bff', '#946bdf']
const useStyles = makeStyles()(() => ({
  fitasA: {
    '& div.ItemFita span': {
      height: '10px',
      width: '10px',
      display: 'block',
      borderRadius: '10px',
      float: 'left',
      marginRight: '4px',
      marginTop: '2px'
    },
    '& div.ItemFita:nth-child(4n+1) span': {
      backgroundColor: `${BoderColors[0]}`
    },
    '& div.ItemFita:nth-child(4n+2) span': {
      backgroundColor: `${BoderColors[1]}`
    },
    '& div.ItemFita:nth-child(4n+3) span': {
      backgroundColor: `${BoderColors[2]}`
    },
    '& div.ItemFita:nth-child(4n+4) span': {
      backgroundColor: `${BoderColors[3]}`
    }
  }
}))

const FitasListDotName = props => {
  const { classes } = useStyles()
  const [litaDeFitas, setLitaDeFitas] = React.useState([])

  // - rotina ao carregar componente
  useEffect(() => {
    // debugger
    const fitasValidas = []
    if (props.peca.fitaCima) {
      fitasValidas.push(props.peca.fitaCima)
    }
    if (props.peca.fitaBaixo) {
      if (!fitasValidas.includes(props.peca.fitaBaixo)) {
        fitasValidas.push(props.peca.fitaBaixo)
      }
    }
    if (props.peca.fitaEsquerda) {
      if (!fitasValidas.includes(props.peca.fitaEsquerda)) {
        fitasValidas.push(props.peca.fitaEsquerda)
      }
    }
    if (props.peca.fitaDireita) {
      if (!fitasValidas.includes(props.peca.fitaDireita)) {
        fitasValidas.push(props.peca.fitaDireita)
      }
    }
    setLitaDeFitas(fitasValidas)
  }, [props])

  return (
    <div className={classes.fitasA}>
      {litaDeFitas.map(fita => {
        return (
          <div className="ItemFita" key={fita}>
            <span></span>
            <strong>{fita}</strong>
          </div>
        )
      })}
    </div>
  )
}

export default memo(FitasListDotName)
