import React from 'react'
import { isMobile } from 'react-device-detect'

const Private = props => {
  return (
    <div id="wrapper">
      <div className={isMobile ? 'container-sm' : 'container-fluid'}>
        {props.children}
      </div>
    </div>
  )
}

export default Private
