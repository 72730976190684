import { createContext, useContext, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'

import api from '@service/api'

const ProjetoContext = createContext()

const ProjetoProvider = ({ children }) => {
  const [projeto, setProjeto] = useState({})
  const [fitasPeca, setFitasPenca] = useState([])
  const [cliente, setFormatedCliente] = useState({})
  const [vendedores, setListaVendedores] = useState([])
  const [vendedor, setVendedorProjeto] = useState({})
  const [loja, setLoja] = useState({})
  const [otimizacao, setOtimizacao] = useState([])
  const [lojaCongifOtmizacao, setConfigLojaState] = useState({})
  const [targetAmbienteImport, setTargetAmbienteImport] = useState()

  const setVendedores = vendedores => {
    const newVededores = vendedores.filter(
      x => x.ativo === true && x.leoPlanAtivo === true
    )
    setListaVendedores(newVededores)
  }
  const setVendedor = vendedor => {
    if (vendedor.email) {
      setVendedorProjeto({
        ...vendedor,
        lojaIdErp: vendedor.loja.idErp,
        empresaIdErp: vendedor.loja.empresa.idErp
      })
    } else {
      setVendedorProjeto(vendedor)
    }
  }

  const saveProjetoApi = async ambientesAtualizados => {
    try {
      const projetoHasPecas = ambientesAtualizados.some(
        ambiente => ambiente?.projetoPeca?.length
      )

      if (!projetoHasPecas) return

      const payload = {
        projetoId: projeto.id,
        ambientes: ambientesAtualizados.map(ambiente => ({
          id: ambiente.id,
          descricao: ambiente.descricao,
          projetoId: ambiente.projetoId,
          pecas: ambiente.projetoPeca
        }))
      }

      const response = await trackPromise(
        api.leoplan.apiFinalizarProjeto(payload)
      )

      if (response.fail) throw new Error()

      changeProjeto('projetoAmbiente', response.data.projetoAmbiente)
    } catch {
      throw new Error()
    }
  }

  const changeProjeto = (key, value) => {
    setOtimizacao([])
    setProjeto(projeto => {
      return {
        ...projeto,
        [key]: value
      }
    })
  }
  const setCliente = rawCliente => {
    const newCliente = { ...rawCliente }
    newCliente.enderecos = rawCliente.enderecos.filter(
      endereco => endereco.tipoEnderecoId === 1
    )
    if (newCliente.enderecos.length >= 1) {
      newCliente.enderecos = [newCliente.enderecos[0]]
    }

    if (!rawCliente.idErp) {
      newCliente.tipoUserLeoPlan = 'cliente'

      newCliente.idErp = rawCliente.idExterno
      newCliente.pedidoId = null
      newCliente.tipoVenda = 'ZCVS'
      newCliente.depositoId = '0001'
      return setFormatedCliente(newCliente)
    }
    setFormatedCliente(newCliente)
  }
  const deleteProductProject = (id, type) => {
    const newProjetoProduto = projeto.projetoProduto.filter(
      material => material.id.toString() !== id.toString()
    )
    changeProjeto('projetoProduto', newProjetoProduto)

    if (type === 'C') {
      // se estiver removendo uma chapa remove todas as peças com essa chapa vinculada
      const projetoAmbiente = projeto.projetoAmbiente
      projetoAmbiente.forEach(ambiente => {
        ambiente.projetoPeca = ambiente.projetoPeca.filter(
          x => x.materialId !== id
        )
      })
      changeProjeto('projetoAmbiente', projetoAmbiente)
    }
    if (type === 'F') {
      // se estiver removendo uma fita remove a fitagem
      const newProjetoAmbiente = projeto.projetoAmbiente
      newProjetoAmbiente.forEach(ambiente => {
        ambiente.projetoPeca.forEach(peca => {
          if (peca.direitaMaterialId === id) {
            peca.direitaMaterialId = 0
          }
          if (peca.esquerdaMaterialId === id) {
            peca.esquerdaMaterialId = 0
          }
          if (peca.inferiorMaterialId === id) {
            peca.inferiorMaterialId = 0
          }
          if (peca.superiorMaterialId === id) {
            peca.superiorMaterialId = 0
          }

          peca.pecaProduto = peca.pecaProduto.filter(
            x => x.projetoProdutoId !== id
          )
        })
      })
      changeProjeto('projetoAmbiente', newProjetoAmbiente)
    }
  }
  const swapProductProject = (newMaterial, idToSwap, type) => {
    const newProjetoProduto = projeto.projetoProduto
      .map(material => {
        if (material.id.toString() === idToSwap.toString()) return newMaterial
        else return material
      })
      .filter(
        (item, index, array) =>
          index === array.findIndex(x => x.id.toString() === item.id.toString())
      )
    changeProjeto('projetoProduto', newProjetoProduto)

    if (type === 'C') {
      const newProjetoAmbiente = projeto.projetoAmbiente
      newProjetoAmbiente.forEach(ambiente => {
        ambiente.projetoPeca = ambiente.projetoPeca.map(peca => {
          if (peca.materialId.toString() === idToSwap.toString()) {
            return { ...peca, veio: true, materialId: newMaterial.id }
          }
          return peca
        })
      })
      saveProjetoApi(newProjetoAmbiente)
    }
    if (type === 'F') {
      const newProjetoAmbiente = projeto.projetoAmbiente
      newProjetoAmbiente.forEach(ambiente => {
        ambiente.projetoPeca.forEach(peca => {
          if (peca.direitaMaterialId === idToSwap) {
            peca.direitaMaterialId = newMaterial.id
          }
          if (peca.esquerdaMaterialId === idToSwap) {
            peca.esquerdaMaterialId = newMaterial.id
          }
          if (peca.inferiorMaterialId === idToSwap) {
            peca.inferiorMaterialId = newMaterial.id
          }
          if (peca.superiorMaterialId === idToSwap) {
            peca.superiorMaterialId = newMaterial.id
          }

          peca.pecaProduto = peca.pecaProduto.map(x => {
            if (x.projetoProdutoId === idToSwap) {
              return { projetoProdutoId: newMaterial.id }
            } else {
              return x
            }
          })
        })
      })

      saveProjetoApi(newProjetoAmbiente)
    }
  }
  const planExcludeFita = (
    payload,
    indexAmbiente,
    indexPeca,
    campo,
    idFita,
    excluirAllFitas
  ) => {
    const newAmbiente = projeto.projetoAmbiente

    newAmbiente[indexAmbiente].projetoPeca[indexPeca].superiorMaterialId =
      payload.superiorMaterialId
    newAmbiente[indexAmbiente].projetoPeca[indexPeca].inferiorMaterialId =
      payload.inferiorMaterialId
    newAmbiente[indexAmbiente].projetoPeca[indexPeca].esquerdaMaterialId =
      payload.esquerdaMaterialId
    newAmbiente[indexAmbiente].projetoPeca[indexPeca].direitaMaterialId =
      payload.direitaMaterialId

    if (excluirAllFitas) {
      newAmbiente[indexAmbiente].projetoPeca[indexPeca].pecaProduto = []
    } else {
      const indexPecaProduto = newAmbiente[indexAmbiente].projetoPeca[
        indexPeca
      ].pecaProduto.findIndex(x => x.projetoProdutoId === idFita)
      newAmbiente[indexAmbiente].projetoPeca[indexPeca].pecaProduto.splice(
        indexPecaProduto,
        1
      )
    }
    changeProjeto('projetoAmbiente', newAmbiente)
  }
  const planEdtPecaBordaTotal = (payload, indexAmbiente, indexPeca) => {
    const newAmbiente = projeto.projetoAmbiente

    if (projeto.tipoCorte === 2 && payload > 0) {
      newAmbiente[indexAmbiente].projetoPeca[indexPeca].superiorMaterialId =
        payload
      newAmbiente[indexAmbiente].projetoPeca[indexPeca].inferiorMaterialId =
        payload
    } else if (projeto.tipoCorte === 3 && payload > 0) {
      newAmbiente[indexAmbiente].projetoPeca[indexPeca].esquerdaMaterialId =
        payload
      newAmbiente[indexAmbiente].projetoPeca[indexPeca].direitaMaterialId =
        payload
    } else {
      newAmbiente[indexAmbiente].projetoPeca[indexPeca].superiorMaterialId =
        payload
      newAmbiente[indexAmbiente].projetoPeca[indexPeca].inferiorMaterialId =
        payload
      newAmbiente[indexAmbiente].projetoPeca[indexPeca].esquerdaMaterialId =
        payload
      newAmbiente[indexAmbiente].projetoPeca[indexPeca].direitaMaterialId =
        payload
    }
    changeProjeto('projetoAmbiente', newAmbiente)
  }

  const planEdtFitasPeca = (fitasSelecionadas, indexAmbiente, indexPeca) => {
    const newAmbiente = projeto.projetoAmbiente
    const peca = projeto.projetoAmbiente[indexAmbiente].projetoPeca[indexPeca]

    const fitasIncluirNoProjetoProduto = fitasSelecionadas
      .map(fita => {
        if (
          !peca?.pecaProduto?.find(
            produto => produto.projetoProdutoId === fita.id
          )
        ) {
          return { projetoProdutoId: fita.id }
        }
      })
      .filter(item => item)

    const fitasRemoverDoProjetoProduto = peca?.pecaProduto
      ?.map(produto => {
        if (
          !fitasSelecionadas.find(fita => fita.id === produto.projetoProdutoId)
        ) {
          return { projetoProdutoId: produto.projetoProdutoId }
        }
      })
      .filter(item => item)

    const pecaProduto = peca?.pecaProduto
      ?.map(produto => {
        if (
          !fitasRemoverDoProjetoProduto.find(
            fita => fita.projetoProdutoId === produto.projetoProdutoId
          )
        ) {
          return produto
        }
      })
      .filter(item => item)
      .concat(fitasIncluirNoProjetoProduto)

    newAmbiente[indexAmbiente].projetoPeca[indexPeca].superiorMaterialId =
      pecaProduto.find(
        prod => prod.projetoProdutoId === peca.superiorMaterialId
      )
        ? peca.superiorMaterialId
        : 0

    newAmbiente[indexAmbiente].projetoPeca[indexPeca].inferiorMaterialId =
      pecaProduto.find(
        prod => prod.projetoProdutoId === peca.inferiorMaterialId
      )
        ? peca.inferiorMaterialId
        : 0

    newAmbiente[indexAmbiente].projetoPeca[indexPeca].esquerdaMaterialId =
      pecaProduto.find(
        prod => prod.projetoProdutoId === peca.esquerdaMaterialId
      )
        ? peca.esquerdaMaterialId
        : 0

    newAmbiente[indexAmbiente].projetoPeca[indexPeca].direitaMaterialId =
      pecaProduto.find(prod => prod.projetoProdutoId === peca.direitaMaterialId)
        ? peca.direitaMaterialId
        : 0

    newAmbiente[indexAmbiente].projetoPeca[indexPeca].pecaProduto = pecaProduto
    changeProjeto('projetoAmbiente', newAmbiente)
  }

  const planEdtPeca = (payload, indexAmbiente, indexPeca, campo) => {
    const newAmbiente = projeto.projetoAmbiente
    const newMateriais = projeto.projetoProduto

    if (campo === 'pecaProduto') {
      const found = newMateriais.filter(x => x.id === payload.id)
      if (found.length === 0) {
        newMateriais.push(payload)
      }
      changeProjeto('projetoProduto', newMateriais)

      const newfitainPeca = {
        id: null,
        projetoPecaId: newAmbiente[indexAmbiente].projetoPeca[indexPeca].id,
        projetoProdutoId: payload.id
      }
      newAmbiente[indexAmbiente].projetoPeca[indexPeca][campo].push(
        newfitainPeca
      )
      changeProjeto('projetoAmbiente', newAmbiente)
    } else if (campo === 'open') {
      newAmbiente.forEach(amb => {
        amb.projetoPeca.forEach(peca => {
          if (peca.open) {
            peca.open = false
          }
        })
      })

      newAmbiente[indexAmbiente].projetoPeca[indexPeca][campo] = payload

      changeProjeto('projetoAmbiente', newAmbiente)
    } else if (campo === 'inverterMedidas') {
      const pecaSelecionada = newAmbiente[indexAmbiente].projetoPeca[indexPeca]
      const pecaRotacionada = rotacionarPeca(pecaSelecionada)
      newAmbiente[indexAmbiente].projetoPeca[indexPeca] = pecaRotacionada
      changeProjeto('projetoAmbiente', newAmbiente)
    } else if (campo === 'inverterAllMedidas') {
      const pecasInvertidas = newAmbiente[indexAmbiente]?.projetoPeca.map(
        peca => rotacionarPeca(peca)
      )
      newAmbiente[indexAmbiente].projetoPeca = pecasInvertidas
      changeProjeto('projetoAmbiente', newAmbiente)
    } else if (campo === 'projetoPecaServico') {
      const pecaSelecionada = newAmbiente[indexAmbiente].projetoPeca[indexPeca]
      if (pecaSelecionada?.projetoPecaServico[0]?.projetoPecaSecundariaId) {
        const pecaSecundaria =
          newAmbiente[indexAmbiente].projetoPeca[indexPeca + 1]

        const pecaSecundariaAtualizada = {
          ...pecaSecundaria,
          projetoPecaServico: [
            {
              ...pecaSecundaria.projetoPecaServico[0],
              tipoServico: payload[0]?.tipoServico,
              sistemaFixacaoId: payload[0]?.sistemaFixacaoId
            }
          ]
        }

        newAmbiente[indexAmbiente].projetoPeca[indexPeca + 1] =
          pecaSecundariaAtualizada
      }
      newAmbiente[indexAmbiente].projetoPeca[indexPeca].projetoPecaServico =
        payload
      changeProjeto('projetoAmbiente', newAmbiente)
    } else {
      newAmbiente[indexAmbiente].projetoPeca[indexPeca][campo] = payload

      if (campo === 'materialId') {
        const newMaterial = projeto.projetoProduto.filter(x => x.id === payload)
        if (newMaterial.length > 0) {
          newAmbiente[indexAmbiente].projetoPeca[indexPeca].veio =
            newMaterial[0].veio
          if (projeto.tipoCorte === 2) {
            newAmbiente[indexAmbiente].projetoPeca[indexPeca].largura =
              newMaterial[0].largura * 10
          } else if (projeto.tipoCorte === 3) {
            newAmbiente[indexAmbiente].projetoPeca[indexPeca].altura =
              newMaterial[0].altura * 10
          }
        }
      }

      setProjeto(p => ({ ...p, projetoAmbiente: newAmbiente }))
    }
  }
  const rotacionarPeca = peca => {
    return {
      ...peca,
      altura: peca.largura,
      largura: peca.altura,
      superiorMaterialId: peca.direitaMaterialId,
      inferiorMaterialId: peca.esquerdaMaterialId,
      direitaMaterialId: peca.inferiorMaterialId,
      esquerdaMaterialId: peca.superiorMaterialId
    }
  }
  const setConfigLoja = dados => {
    setConfigLojaState(dados)
  }
  return (
    <ProjetoContext.Provider
      value={{
        lojaCongifOtmizacao,
        setConfigLoja,
        planExcludeFita,
        planEdtPecaBordaTotal,
        planEdtPeca,
        cliente,
        setCliente,
        setVendedores,
        deleteProductProject,
        vendedores,
        loja,
        setLoja,
        setVendedor,
        vendedor,
        setProjeto,
        projeto,
        swapProductProject,
        fitasPeca,
        setFitasPenca,
        changeProjeto,
        otimizacao,
        setOtimizacao,
        planEdtFitasPeca,
        targetAmbienteImport,
        setTargetAmbienteImport
      }}
    >
      {children}
    </ProjetoContext.Provider>
  )
}

function useProjeto() {
  const context = useContext(ProjetoContext)

  return context
}

export { ProjetoProvider, useProjeto }
