import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

const UploadButton = props => {
  const [fileName, setFileName] = React.useState('')

  const getFile = event => {
    setFileName(event.target.files[0].name)
    // console.log('f name', event.target.files[0])

    props.action(event.target.files[0])
    props.setFormat(
      event.target.files[0].name.substr(event.target.files[0].name.length - 4)
    )
  }
  return (
    <div className="mr5">
      <label
        htmlFor="myFileInput"
        className={`file mb0 ${isMobile ? 'w-100 text-center' : ''}`}
      >
        {props.children}
      </label>
      <input
        id="myFileInput"
        className="modal-uploadInput"
        type="file"
        accept=".txt, .csv, .xlsx "
        onChange={getFile}
      ></input>
      <span className="d-block">{fileName}</span>
    </div>
  )
}

export default memo(UploadButton)
