import { useSnackbar } from 'notistack'
import React from 'react'
import { useSelector } from 'react-redux'

export default function AlertSnackbar() {
  const { enqueueSnackbar } = useSnackbar()
  const { successSnackbarMessage, successSnackbarOpen, successSnackbarInfo } =
    useSelector(state => state.snackbarState)

  React.useEffect(() => {
    if (successSnackbarOpen) {
      enqueueSnackbar(successSnackbarMessage, {
        variant: successSnackbarInfo,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        persist: false
      })
    }
  })

  return null
}
