import loadImg from '@asset/img/load-100.gif'
import {
  planNewProjeto,
  planStatusProjeto,
  planUdtAmbiente,
  planAmbienteSalvo,
  planLoadAmbiente,
  planUdtMateriais,
  planLoadMateriais,
  planEdtPeca,
  planExcludeFita,
  planEdtPecaBordaTotal,
  planUdtBuscaFitas,
  planUdtFitasRecomendadas,
  planAddFitaRecomendada,
  planResetConfigLoja,
  planSetConfigLoja
} from '@store/actions/planoCorteActions.js'
import { showMsgS, showMsgW, showMsgE } from '@store/actions/snackbarActions.js'
import React, { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { useProjeto } from '@hook/useProjeto.js'
import { useUser } from '@hook/useUser.js'

import api from '@service/api'
import Utils from '@service/serviceUtils.js'

import LeoPlanContainer from './components/Container.js'

const LeoPlan = props => {
  const {
    showMsgS,
    showMsgW,
    showMsgE,
    planNewProjeto,
    planStatusProjeto,
    planUdtAmbiente,
    planAmbienteSalvo,
    planLoadAmbiente,
    planUdtMateriais,
    planEdtPeca,
    planExcludeFita,
    planEdtPecaBordaTotal,
    planUdtBuscaFitas,
    planUdtFitasRecomendadas,
    planAddFitaRecomendada,
    planResetConfigLoja,
    planSetConfigLoja
  } = props
  const { user } = useUser()
  const { id: projetoId } = useParams()
  const [lojas, setLojas] = useState([])
  const [load, setLoad] = useState('load')
  const [inicialStep, setInicialStep] = useState(0)
  const { setProjeto, setOtimizacao } = useProjeto()

  const getLojas = res => {
    let listaLojas = res.filter(x => x.idErp !== null)
    if (listaLojas.length > 0) {
      if (listaLojas[0].ativo === true || listaLojas[0].ativo === false) {
        listaLojas = res.filter(x => x.ativo === true)
      }
    }
    setLojas([...lojas, ...listaLojas])
  }
  const getOtimizacoes = res => {
    setOtimizacao(res)
  }
  const getStatusAmbienteSalvo = proj => {
    /// verificar datas / não deixar visualizar otimização de plano que
    /// foi alterado apos criação da otimização
    /// retona true se o plano foi alterado sem refazer a otimização
    if (proj.planoCorte.length > 0) {
      const dataAltProjeto = proj.dataUltimaAtualizacao.replace(/[^\d]/g, '')
      const dataAltOtimizacao = proj.planoCorte[0].dataCriacao.replace(
        /[^\d]/g,
        ''
      )
      if (dataAltProjeto < dataAltOtimizacao) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }
  const getProjeto = res => {
    const projeto = res.projeto
    if (res.statusOtimizacao) {
      if (res.statusOtimizacao.status === 1) {
        showMsgW('Aguarde a otimização ser finalizada para acessar seu projeto')
        props.navigate('/plano-de-corte/projetos/disponiveis')
        return false
      }
    }

    // atribui uma cor unica a cada fita do projeto
    projeto.projetoProduto = Utils.atribuiCorFitas(projeto.projetoProduto)

    // debugger
    // confere se todas as fitas marcadas estão na tabela produtoFita
    projeto.projetoAmbiente.forEach(ambiente => {
      ambiente.projetoPeca.forEach(peca => {
        if (peca.superiorMaterialId === null) {
          peca.superiorMaterialId = 0
        }
        if (peca.inferiorMaterialId === null) {
          peca.inferiorMaterialId = 0
        }
        if (peca.esquerdaMaterialId === null) {
          peca.esquerdaMaterialId = 0
        }
        if (peca.direitaMaterialId === null) {
          peca.direitaMaterialId = 0
        }

        if (
          !verificaSeFitaEstaNaListaInternaDaPeca(
            peca.superiorMaterialId,
            peca.pecaProduto
          )
        ) {
          const pecaInProducts = res.projeto.projetoProduto.filter(
            x => x.id === peca.superiorMaterialId
          )
          if (pecaInProducts.length > 0) {
            const objFitaProduto = {
              projetoPecaId: peca.id,
              projetoProdutoId: pecaInProducts[0].id
            }
            peca.pecaProduto.push(objFitaProduto)
          }
        }
        if (
          !verificaSeFitaEstaNaListaInternaDaPeca(
            peca.esquerdaMaterialId,
            peca.pecaProduto
          )
        ) {
          const pecaInProducts = res.projeto.projetoProduto.filter(
            x => x.id === peca.esquerdaMaterialId
          )
          if (pecaInProducts.length > 0) {
            const objFitaProduto = {
              projetoPecaId: peca.id,
              projetoProdutoId: pecaInProducts[0].id
            }
            peca.pecaProduto.push(objFitaProduto)
          }
        }
        if (
          !verificaSeFitaEstaNaListaInternaDaPeca(
            peca.inferiorMaterialId,
            peca.pecaProduto
          )
        ) {
          const pecaInProducts = res.projeto.projetoProduto.filter(
            x => x.id === peca.inferiorMaterialId
          )
          if (pecaInProducts.length > 0) {
            const objFitaProduto = {
              projetoPecaId: peca.id,
              projetoProdutoId: pecaInProducts[0].id
            }
            peca.pecaProduto.push(objFitaProduto)
          }
        }
        if (
          !verificaSeFitaEstaNaListaInternaDaPeca(
            peca.direitaMaterialId,
            peca.pecaProduto
          )
        ) {
          const pecaInProducts = res.projeto.projetoProduto.filter(
            x => x.id === peca.direitaMaterialId
          )
          if (pecaInProducts.length > 0) {
            const objFitaProduto = {
              projetoPecaId: peca.id,
              projetoProdutoId: pecaInProducts[0].id
            }
            peca.pecaProduto.push(objFitaProduto)
          }
        }
      })
    })
    function verificaSeFitaEstaNaListaInternaDaPeca(id, list) {
      let found = false
      if (!id || id === null) {
        return true
      }
      if (list.length > 0) {
        list.forEach(produtoFita => {
          if (produtoFita.projetoProdutoId === id) {
            found = true
          }
        })
      }
      if (id && found) {
        return true
      } else {
        return false
      }
    }
    projeto.nome = projeto.nome.substring(0, 28)
    setProjeto(projeto)
    props.planNewProjeto(projeto)
    props.planStatusProjeto(res.statusOtimizacao)
    props.planLoadMateriais(projeto.projetoProduto)

    const listAmb = projeto.projetoAmbiente

    listAmb.forEach(amb => {
      amb.projetoPeca.forEach(peca => {
        peca.open = false
      })
    })

    props.planLoadAmbiente(listAmb)
    props.planAmbienteSalvo(getStatusAmbienteSalvo(projeto))
  }

  useEffect(() => {
    async function init() {
      if (user.nome) {
        props.planNewProjeto({ nome: '', id: '' })
        props.planStatusProjeto({
          billingCenterId: '',
          empresaIdErp: null,
          optimizationIds: [],
          status: 0
        })
        props.planLoadMateriais([])
        props.planLoadAmbiente([])
        props.planUdtFitasRecomendadas([])

        let statusRet = true
        const statusComplete = () => {
          setLoad('complete')
        }
        const statusError = () => {
          statusRet = false
          setLoad('error')
        }
        const statusStepInical = (projeto, otimizacao) => {
          if (otimizacao.length > 0) {
            if (projeto.statusOtimizacao) {
              if (
                projeto.statusOtimizacao.status === 2 ||
                projeto.statusOtimizacao.status === 3
              ) {
                if (getStatusAmbienteSalvo(projeto.projeto)) {
                  setInicialStep(2)
                } else {
                  setInicialStep(3)
                }
              }
            } else {
              if (projeto.projeto.projetoProduto.length > 0) {
                setInicialStep(2)
              } else {
                setInicialStep(1)
              }
            }
          } else {
            if (projeto.projeto.projetoProduto.length > 0) {
              const produtoMDF = projeto.projeto.projetoProduto.filter(
                x => x.tipoProdutoId === 1
              )
              if (produtoMDF.length > 0 && projeto.projeto.tipoCorte !== 4) {
                setInicialStep(2)
              } else {
                setInicialStep(1)
              }
            } else {
              setInicialStep(1)
            }
          }
        }
        const loadConfigLoja = async () => {
          try {
            const response = await trackPromise(
              api.leoplan.apiGetConfigLoja(user.lojaIdErp, user.empresaIdErp)
            )

            if (response.fail) throw new Error()

            props.planSetConfigLoja(response.data)
          } catch {
            props.planResetConfigLoja()
          }
        }

        if (projetoId) {
          /// editando um projeto
          /// carrega as apis necessarias para exibir o projeto
          if (user.tipoUserLeoPlan === 'cliente') {
            try {
              const response = await trackPromise(
                Promise.all([
                  api.core.apiListarLojas(),
                  api.leoplan.apiGetProjetoDetAsync(projetoId),
                  api.leoplan.apiGetOtimizacao(`${projetoId}`)
                ])
              )

              if (response.find(x => x.fail)) throw new Error()

              getLojas(response[0].data)
              getProjeto(response[1].data)
              getOtimizacoes(response[2].data)

              if (
                response[0].data.length === 0 ||
                !response[1].data.projeto.id
              ) {
                statusError()
              } else {
                statusStepInical(response[1].data, response[2].data)
              }
            } catch {
              statusError()
            } finally {
              setTimeout(function () {
                if (statusRet) {
                  statusComplete()
                }
              }, 500)
            }
          } else {
            try {
              const response = await trackPromise(
                Promise.all([
                  api.leoplan.apiGetProjetoDetAsync(projetoId),
                  api.leoplan.apiGetOtimizacao(`${projetoId}`)
                ])
              )

              if (response[0].fail || response[1].fail) throw new Error()

              getProjeto(response[0].data)
              getOtimizacoes(response[1].data)
              if (!response[0].data.projeto.id) {
                statusError()
              } else {
                statusStepInical(response[0].data, response[1].data)
              }
            } catch {
              statusError()
            } finally {
              setTimeout(function () {
                if (statusRet) {
                  loadConfigLoja()
                  statusComplete()
                }
              }, 500)
            }
          }
        } else {
          // novo projeto
          // carrega apis necessaria para iniciar projeto
          if (user.tipoUserLeoPlan === 'cliente') {
            try {
              const response = await trackPromise(api.core.apiListarLojas())

              if (response.fail || response?.data?.length === 0) {
                throw new Error()
              }

              getLojas(response.data)
            } catch {
              statusError()
            } finally {
              setTimeout(function () {
                if (statusRet) {
                  statusComplete()
                }
              }, 500)
            }
          } else {
            loadConfigLoja()
            statusComplete()
          }
        }
      }
    }

    init()
  }, [user])

  return (
    <>
      {load === 'load' ? (
        <div className="flex flex-col text-center h-screen justify-center">
          <h5>Carregando... </h5>

          <div className="m-1 mx-auto">
            {load === 'load' ? <img src={loadImg} alt="load" /> : null}
          </div>
        </div>
      ) : load === 'error' ? (
        <div className="flex flex-col text-center h-screen justify-center">
          <h5>Problemas ao carregar, tente novamente mais tarde </h5>
        </div>
      ) : (
        <LeoPlanContainer
          userLogado={user}
          lojas={lojas}
          projetoId={projetoId}
          getOtimizacoes={getOtimizacoes}
          inicialStep={inicialStep}
          showMsgS={showMsgS}
          showMsgW={showMsgW}
          showMsgE={showMsgE}
          planNewProjeto={planNewProjeto}
          planStatusProjeto={planStatusProjeto}
          planUdtAmbiente={planUdtAmbiente}
          planAmbienteSalvo={planAmbienteSalvo}
          planLoadAmbiente={planLoadAmbiente}
          planUdtMateriais={planUdtMateriais}
          planEdtPeca={planEdtPeca}
          planExcludeFita={planExcludeFita}
          planEdtPecaBordaTotal={planEdtPecaBordaTotal}
          planUdtBuscaFitas={planUdtBuscaFitas}
          planUdtFitasRecomendadas={planUdtFitasRecomendadas}
          planAddFitaRecomendada={planAddFitaRecomendada}
          planResetConfigLoja={planResetConfigLoja}
          planSetConfigLoja={planSetConfigLoja}
        />
      )}
    </>
  )
}

const mapStateToProps = store => ({
  planoCorteState: store.planoCorteState
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMsgS,
      showMsgW,

      showMsgE,
      planNewProjeto,
      planStatusProjeto,
      planUdtAmbiente,

      planAmbienteSalvo,
      planLoadAmbiente,
      planUdtMateriais,
      planLoadMateriais,
      planEdtPeca,

      planExcludeFita,
      planEdtPecaBordaTotal,
      planUdtBuscaFitas,
      planUdtFitasRecomendadas,

      planAddFitaRecomendada,
      planResetConfigLoja,

      planSetConfigLoja
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LeoPlan)
