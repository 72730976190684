import Utils from '@service/serviceUtils.js'

import {
  PLAN_NEWPROJETO,
  PLAN_STATUSPROJETO,
  PLAN_UPTAMBIENTE,
  PLAN_UPTMATERIAIS,
  PLAN_EDTPECA,
  PLAN_EDTPECABORDATOTAL,
  PLAN_EXCLUDEFITA,
  PLAN_UPTBUSCAFITAS,
  PLAN_UPTFITAS_RECOMENDADAS,
  PLAN_ADDFITA_RECOMENDADA,
  PLAN_LOADMATERIAIS,
  PLAN_LOADAMBIENTE,
  PLAN_AMBIENTE_SALVO,
  PLAN_RESET_CONFIGLOJA,
  PLAN_SET_CONFIGLOJA
} from '../actions/actionTypes.js'

const initialState = {
  listaDeProjetos: [],
  listaDeProjetosType: '',
  planProjeto: { nome: '', id: '' },
  planStatusProjeto: {
    billingCenterId: '',
    empresaIdErp: null,
    optimizationIds: [],
    status: 0
  },
  planAmbientes: [],
  planMateriais: [],
  listaDeFitas: [],
  listaFitasRecomendadas: [],
  configCorteLoja: {},
  ambienteFoiAlterado: false
}

const planoCorteReducer = (state = initialState, action) => {
  let newAmbiente = []
  let newMateriais = []
  switch (action.type) {
    case PLAN_RESET_CONFIGLOJA:
      return {
        ...state,
        configCorteLoja: action.payload
      }
    case PLAN_SET_CONFIGLOJA:
      return {
        ...state,
        configCorteLoja: action.payload
      }

    case PLAN_NEWPROJETO:
      return {
        ...state,
        planProjeto: action.payload
      }
    case PLAN_STATUSPROJETO:
      return {
        ...state,
        planStatusProjeto: action.payload
      }
    case PLAN_LOADAMBIENTE:
      return {
        ...state,
        planAmbientes: action.payload
      }
    case PLAN_UPTAMBIENTE:
      return {
        ...state,
        planAmbientes: action.payload,
        ambienteFoiAlterado: true
      }
    case PLAN_AMBIENTE_SALVO:
      // console.log('pppp',action.payload)
      return {
        ...state,
        ambienteFoiAlterado: action.payload
      }
    case PLAN_LOADMATERIAIS:
      return {
        ...state,
        planMateriais: action.payload
      }
    case PLAN_UPTMATERIAIS:
      return {
        ...state,
        planMateriais: Utils.atribuiCorFitas(action.payload),
        ambienteFoiAlterado: true
      }
    case PLAN_EDTPECA:
      // debugger
      newAmbiente = state.planAmbientes
      if (action.campo === 'pecaProduto') {
        // atualiza lista de materiais cadastrados
        newMateriais = state.planMateriais
        // s� inclui na lista de materiais se o item ainda n�o estiver nela
        const found = newMateriais.filter(x => x.id === action.payload.id)
        if (found.length === 0) {
          newMateriais.push(action.payload)
        }

        // atualiza vinculo na pea
        const newfitainPeca = {
          id: null,
          projetoPecaId:
            newAmbiente[action.indexAmbiente].projetoPeca[action.indexPeca].id,
          projetoProdutoId: action.payload.id
        }
        newAmbiente[action.indexAmbiente].projetoPeca[action.indexPeca][
          action.campo
        ].push(newfitainPeca)

        return {
          ...state,
          planAmbientes: newAmbiente,
          planMateriais: newMateriais,
          ambienteFoiAlterado: true
        }
      } else if (action.campo === 'open') {
        newAmbiente.forEach(amb => {
          amb.projetoPeca.forEach(peca => {
            if (peca.open) {
              peca.open = false
            }
          })
        })

        newAmbiente[action.indexAmbiente].projetoPeca[action.indexPeca][
          action.campo
        ] = action.payload
        return {
          ...state,
          planAmbientes: newAmbiente
        }
      } else if (action.campo === 'inverterMedidas') {
        const pecaSelecionada =
          newAmbiente[action.indexAmbiente].projetoPeca[action.indexPeca]

        rotacionarPeca(pecaSelecionada)

        return {
          ...state,
          planAmbientes: newAmbiente,
          ambienteFoiAlterado: true
        }
      } else if (action.campo === 'inverterAllMedidas') {
        newAmbiente[action.indexAmbiente].projetoPeca.forEach(peca => {
          rotacionarPeca(peca)
        })
        return {
          ...state,
          planAmbientes: newAmbiente,
          ambienteFoiAlterado: true
        }
      } else {
        newAmbiente[action.indexAmbiente].projetoPeca[action.indexPeca][
          action.campo
        ] = action.payload
        if (action.campo === 'materialId') {
          const newMaterial = state.planMateriais.filter(
            x => x.id === action.payload
          )
          if (newMaterial.length > 0) {
            newAmbiente[action.indexAmbiente].projetoPeca[
              action.indexPeca
            ].veio = newMaterial[0].veio
            if (state.planProjeto.tipoCorte === 2) {
              // proj tiras horizontal
              newAmbiente[action.indexAmbiente].projetoPeca[
                action.indexPeca
              ].largura = newMaterial[0].largura * 10
            } else if (state.planProjeto.tipoCorte === 3) {
              // proj tiras vertical
              newAmbiente[action.indexAmbiente].projetoPeca[
                action.indexPeca
              ].altura = newMaterial[0].altura * 10
            }
          }
        }
        return {
          ...state,
          planAmbientes: newAmbiente,
          ambienteFoiAlterado: true
        }
      }
    case PLAN_EXCLUDEFITA:
      // debugger
      newAmbiente = state.planAmbientes
      newMateriais = state.planMateriais
      newAmbiente[action.indexAmbiente].projetoPeca[
        action.indexPeca
      ].superiorMaterialId = action.payload.superiorMaterialId
      newAmbiente[action.indexAmbiente].projetoPeca[
        action.indexPeca
      ].inferiorMaterialId = action.payload.inferiorMaterialId
      newAmbiente[action.indexAmbiente].projetoPeca[
        action.indexPeca
      ].esquerdaMaterialId = action.payload.esquerdaMaterialId
      newAmbiente[action.indexAmbiente].projetoPeca[
        action.indexPeca
      ].direitaMaterialId = action.payload.direitaMaterialId

      if (action.excluirAllFitas) {
        newAmbiente[action.indexAmbiente].projetoPeca[
          action.indexPeca
        ].pecaProduto = []
      } else {
        const indexPecaProduto = newAmbiente[action.indexAmbiente].projetoPeca[
          action.indexPeca
        ].pecaProduto.findIndex(x => x.projetoProdutoId === action.idFita)
        newAmbiente[action.indexAmbiente].projetoPeca[
          action.indexPeca
        ].pecaProduto.splice(indexPecaProduto, 1)
      }

      // if (action.excluirMaterial) {
      //     let indexPecaProduto = newAmbiente[action.indexAmbiente].projetoPeca[action.indexPeca]["pecaProduto"].findIndex(x => x.projetoProdutoId === action.idFita)
      //     newAmbiente[action.indexAmbiente].projetoPeca[action.indexPeca]["pecaProduto"].splice(indexPecaProduto, 1)
      //     let indexPecaMaterial = newMateriais.findIndex(x => x.id === action.idFita)
      //     // verifica se a fita esta vinculada a alguma outra pe�a
      //     let fitaVinculadaEmOutraPeca = false
      //     debugger
      //     //newAmbiente.forEach(a => a.chapas.forEach(c => c.pecas.forEach(p => p.pecaProduto.forEach(i => { if (i.projetoProdutoId === action.idFita) { fitaVinculadaEmOutraPeca = true } }))))
      //     newAmbiente.forEach(p => p.pecaProduto.forEach(i => { if (i.projetoProdutoId === action.idFita) { fitaVinculadaEmOutraPeca = true } }))
      //     // se n�o estiver vinculada a nenhuma pe�a remove da lista de materiais
      //     if (!fitaVinculadaEmOutraPeca) {
      //         newMateriais.splice(indexPecaMaterial, 1)
      //     }
      // }
      return {
        ...state,
        planAmbientes: newAmbiente,
        ambienteFoiAlterado: true
      }
    case PLAN_EDTPECABORDATOTAL:
      newAmbiente = state.planAmbientes

      if (state.planProjeto.tipoCorte === 2 && action.payload > 0) {
        // proj tiras horizontal
        newAmbiente[action.indexAmbiente].projetoPeca[
          action.indexPeca
        ].superiorMaterialId = action.payload
        newAmbiente[action.indexAmbiente].projetoPeca[
          action.indexPeca
        ].inferiorMaterialId = action.payload
      } else if (state.planProjeto.tipoCorte === 3 && action.payload > 0) {
        // proj tiras vertical
        newAmbiente[action.indexAmbiente].projetoPeca[
          action.indexPeca
        ].esquerdaMaterialId = action.payload
        newAmbiente[action.indexAmbiente].projetoPeca[
          action.indexPeca
        ].direitaMaterialId = action.payload
      } else {
        newAmbiente[action.indexAmbiente].projetoPeca[
          action.indexPeca
        ].superiorMaterialId = action.payload
        newAmbiente[action.indexAmbiente].projetoPeca[
          action.indexPeca
        ].inferiorMaterialId = action.payload
        newAmbiente[action.indexAmbiente].projetoPeca[
          action.indexPeca
        ].esquerdaMaterialId = action.payload
        newAmbiente[action.indexAmbiente].projetoPeca[
          action.indexPeca
        ].direitaMaterialId = action.payload
      }

      return {
        ...state,
        planAmbientes: newAmbiente,
        ambienteFoiAlterado: true
      }
    case PLAN_UPTBUSCAFITAS:
      return {
        ...state,
        listaDeFitas: action.payload
      }
    case PLAN_UPTFITAS_RECOMENDADAS:
      return {
        ...state,
        listaFitasRecomendadas: action.payload
      }
    case PLAN_ADDFITA_RECOMENDADA:
      return {
        ...state,
        listaFitasRecomendadas: [
          ...state.listaFitasRecomendadas,
          action.payload
        ]
      }
    default:
      return state
  }
}
function rotacionarPeca(peca) {
  const oldData = {
    altura: peca.altura,
    largura: peca.largura,
    direita: peca.direitaMaterialId,
    esquerda: peca.esquerdaMaterialId,
    inferior: peca.inferiorMaterialId,
    superior: peca.superiorMaterialId
  }

  peca.altura = oldData.largura
  peca.largura = oldData.altura
  peca.direitaMaterialId = oldData.inferior
  peca.esquerdaMaterialId = oldData.superior
  peca.inferiorMaterialId = oldData.esquerda
  peca.superiorMaterialId = oldData.direita
}

export default planoCorteReducer
