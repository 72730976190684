import { Step, StepButton, StepConnector, Stepper } from '@mui/material'
import React, { memo, useState } from 'react'
import { withStyles } from 'tss-react/mui'

import { useStepperControl } from '@hook/useStepperControl'

const CustonStepConnector = withStyles(
  StepConnector,
  (_theme, _params, classes) => ({
    alternativeLabel: {
      top: 4,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)'
    },
    active: {
      [`& .${classes.line}`]: {
        borderColor: '#1D5840'
      }
    },
    completed: {
      [`& .${classes.line}`]: {
        borderColor: '#1D5840'
      }
    },
    line: {
      borderColor: '#E6E6E6',
      borderTopWidth: 2,
      borderRadius: 1
    }
  })
)

const StepperControl = props => {
  const { navegaToStep } = props

  const { getSteps, activeStep, completed } = useStepperControl()

  const [skipped] = useState(new Set())
  const isStepSkipped = step => {
    return skipped.has(step)
  }

  function isStepComplete(step) {
    return completed.has(step)
  }

  return (
    <div className="fixedStepper">
      <Stepper
        className="steper"
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<CustonStepConnector />}
      >
        {getSteps().map((label, index) => {
          const stepProps = {}
          const buttonProps = {}
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps} completed={isStepComplete(index)}>
              <StepButton onClick={() => navegaToStep(index)} {...buttonProps}>
                {' '}
                {label}{' '}
              </StepButton>
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
}

export default memo(StepperControl)
