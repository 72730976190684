import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'
import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'

import { useProjeto } from '@hook/useProjeto'
import { useUser } from '@hook/useUser'

import InfoMsg from '@component/InfoMsg/InfoMsg'
import PageTitle from '@component/PageTitle/PageTitle'

import api from '@service/api/index.js'
import Mask from '@service/mask.js'

import ClienteInfos from './ClienteInfos.js'

const IniciarProjeto = ({ lojas, showMsgE }) => {
  const { user } = useUser()
  const {
    setConfigLoja,
    cliente,
    projeto,
    vendedores,
    setVendedores,
    setLoja,
    loja,
    vendedor,
    setVendedor,
    changeProjeto
  } = useProjeto()

  const [lojaLabel, setLojaLabel] = useState('')
  const [vendedorLabel, setVendedorLabel] = useState('')

  const handleChangeNomeProj = nome => {
    if (nome?.length <= 25) changeProjeto('nome', Mask.projectNameMask(nome))
  }

  const handlePasteNomeProj = nome => {
    if (nome?.length > 25) {
      showMsgE('O nome colado excede o limite de 25 caracteres')
      changeProjeto('nome', Mask.projectNameMask(nome.slice(0, 25)))
      return
    }

    changeProjeto('nome', Mask.projectNameMask(nome))
  }

  const buscaVendedorByEmail = async email => {
    try {
      const response = await trackPromise(api.core.apiGetVendedorInfos(email))
      if (response.fail) throw new Error()
      setVendedor(response.data)
      getConfigLojaData(response.data)
    } catch {
      showMsgE('Erro ao buscar informações do vendedor')
    }
  }
  const getConfigLojaData = async vendedor => {
    try {
      const { data } = await api.leoplan.apiGetConfigdata(
        vendedor.loja.idErp,
        vendedor.loja.empresa.idErp
      )
      setConfigLoja(data)
    } catch {
      showMsgE(
        'Erro ao coletar dados de configuração da loja, entre em contato com o suporte'
      )
    }
  }
  const buscaVendedores = async idErp => {
    try {
      const response = await trackPromise(
        api.core.apiGetVendedoresPorLoja(idErp)
      )

      if (response.fail) throw new Error()

      setVendedores(response.data)
    } catch {
      showMsgE('Erro ao carregar vendedores da loja')
    }
  }
  const handleSelectLoja = loja => {
    setLoja(loja)
    if (loja?.idErp) {
      buscaVendedores(loja.idErp)
      return
    }
    setVendedores([])
    setVendedorLabel('')
  }
  const handleSelectVendedor = vendedor => {
    if (vendedor) {
      if (vendedor.email) {
        buscaVendedorByEmail(vendedor.email)
      }
    } else {
      setVendedorLabel('')
    }
  }

  useEffect(() => {
    if (lojas.length && projeto.id) {
      if (!vendedores?.length) buscaVendedores(projeto.lojaIdErp)
    }
  }, [lojas])

  const isEditable = projeto.origemProjeto !== 3

  return (
    <div className={isMobile ? '' : 'pad20 padT10'}>
      <PageTitle title="Dados do Cliente"></PageTitle>

      <div className="row">
        {user.tipoUserLeoPlan !== 'cliente' ? (
          <div className="col-lg-8 col-sm-6 mb10">
            <Autocomplete
              options={[]}
              value={cliente.nome}
              id="combo-cliente"
              disabled={true}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Cliente"
                  variant="filled"
                  size="small"
                />
              )}
            />
          </div>
        ) : null}

        {user.tipoUserLeoPlan === 'cliente' ? (
          <>
            <div className="col-lg-4 col-sm-4 mb10">
              <Autocomplete
                value={loja}
                onChange={(_, newValue) => {
                  handleSelectLoja(newValue)
                }}
                inputValue={lojaLabel}
                onInputChange={(_, newInputValue) => {
                  setLojaLabel(newInputValue)
                }}
                id="combo-lojas"
                options={lojas}
                noOptionsText={'Sem registros'}
                disabled={!!projeto.pedidoId || !isEditable}
                getOptionLabel={option =>
                  typeof option === 'string'
                    ? option
                    : option.idErp
                    ? `${option.idErp} - ${option.nomeEmpresa}`
                    : ''
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Loja"
                    variant="filled"
                    size="small"
                  />
                )}
              />
            </div>

            <div className="col-lg-4 col-sm-4 mb10">
              <Autocomplete
                value={vendedor}
                onChange={(_, newValue) => {
                  handleSelectVendedor(newValue)
                }}
                inputValue={vendedorLabel}
                onInputChange={(_, newInputValue) => {
                  setVendedorLabel(newInputValue)
                }}
                id="combo-vendedor"
                options={vendedores}
                noOptionsText={'Sem registros'}
                disabled={!!projeto.pedidoId || !isEditable}
                getOptionLabel={option =>
                  typeof option === 'string'
                    ? option
                    : option.nome
                    ? `${option.nome}`
                    : ''
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Vendedor"
                    variant="filled"
                    size="small"
                  />
                )}
              />
            </div>
          </>
        ) : null}

        <div className="col-lg-4 col-sm-6 mb10">
          <TextField
            id="outlined-basic"
            label="Nome do projeto"
            variant="filled"
            size="small"
            value={projeto.nome}
            onChange={e => handleChangeNomeProj(e.target.value)}
            onPaste={e => handlePasteNomeProj(e.clipboardData.getData('Text'))}
            disabled={!!projeto.pedidoId || !isEditable}
            helperText={
              projeto?.nome?.length === 25
                ? 'O nome não pode exceder o limite de 25 caracteres'
                : ''
            }
          />
        </div>
      </div>

      {!projeto.id && !isMobile && (
        <div className="mb20">
          {user.tipoUserLeoPlan === 'cliente' ? (
            <InfoMsg
              type="info"
              title="Dados do projeto"
              msg="Informe a loja LEO Madeiras, o Vendedor e o nome do projeto"
            />
          ) : (
            <InfoMsg
              type="info"
              title="Dados do projeto"
              msg="Informe o nome do projeto"
            />
          )}
        </div>
      )}

      {!isMobile && <ClienteInfos />}
    </div>
  )
}

export default IniciarProjeto
