import CDFita from '@asset/img/CLFitas.png'
import Edit from '@mui/icons-material/Edit'
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction
} from '@store/actions/snackbarActions.js'
import React, { Fragment, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { useDispatch } from 'react-redux'
import { withStyles } from 'tss-react/mui'

import { useProjeto } from '@hook/useProjeto'
import { useStepperControl } from '@hook/useStepperControl.js'

import FitasListDotName from '@component/FitasList/FitasListDotName.js'
import InfoMsg from '@component/InfoMsg/InfoMsg.js'
import PecaMiniaturaImport from '@component/PecaMiniaturaImport/PecaMiniaturaImport.js'

import api from '@service/api'

import DialogAmbienteImport from '../Dialogs/DialogAmbienteImport.js'

const StyledTableCell = withStyles(TableCell, () => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: '#000',
    padding: '6px 16px'
  },
  body: {
    fontSize: 12,
    borderBottom: '1px solid #F5F5F5',
    padding: '4px 16px'
  }
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: '#FDFDFD'
    },
    '&.invalid:nth-of-type(odd)': {
      backgroundColor: '#ffe3e3'
    },
    '&.invalid': {
      backgroundColor: '#fde7e7'
    }
  }
}))

const StyledTableCellAmb = withStyles(TableCell, () => ({
  head: {
    backgroundColor: '#2a7e5c',
    color: '#fff',
    padding: '6px 16px'
  },
  body: {
    fontSize: 12,
    backgroundColor: '#2a7e5c',
    color: '#fff',
    borderBottom: '1px solid #F5F5F5',
    padding: '4px 16px'
  }
}))

const ImportacaoDepara = ({
  handleCloseModalImportacao,
  setPecasAgrupamentoMat,
  pecasAgrupamentoMat,
  setPecasAgrupamentoAmb,
  pecasAgrupamentoAmb,
  handleOpenModalAddFita,
  handleOpenModalAddChapa,
  materialAutoSelect,
  pecasImportadas
}) => {
  const { setActiveStep, handleComplete } = useStepperControl()
  const {
    projeto,
    changeProjeto,
    targetAmbienteImport,
    setTargetAmbienteImport
  } = useProjeto()
  const dispatch = useDispatch()
  const showMsgW = msg => dispatch(showMsgWAction(msg))
  const showMsgE = msg => dispatch(showMsgEAction(msg))
  const [formAmbValid, setFormAmbValid] = useState(true)
  const [formValid, setFormValid] = useState(true)
  const [finishingImport, setFinishingImport] = useState(false)
  const [indexMaterial, setIndexMaterial] = useState(null)
  const [indexMaterialFita, setIndexMaterialFita] = useState(null)
  const [targetAmbiente, setTargetAmbiente] = useState({})
  const [openModalAmbiente, setOpenModalAmbiente] = useState(false)
  const handleOpenModalAmbiente = (amb, index) => {
    const targetAmb = { ...amb }
    targetAmb.refIndex = index
    setTargetAmbiente(targetAmb)
    setOpenModalAmbiente(true)
  }
  const udtTargetAmbiente = ambiente => {
    setTargetAmbiente(ambiente)
  }

  const handleCloseModalAmbiente = () => {
    setOpenModalAmbiente(false)
  }
  const changeFita = (value, indexChapa, indexFita) => {
    const changeAgrupamento = [...pecasAgrupamentoMat]
    changeAgrupamento[indexChapa].fitas[indexFita].para = value
    setPecasAgrupamentoMat(changeAgrupamento)
    setIndexMaterial(null)
  }
  const changeChapa = (value, index) => {
    const changeAgrupamento = [...pecasAgrupamentoMat]
    changeAgrupamento[index].chapaEscolhida = value
    setPecasAgrupamentoMat(changeAgrupamento)
    setIndexMaterial(null)
  }
  const handleCheckboxChange = index => {
    const changeAgrupamento = [...pecasAgrupamentoMat]
    changeAgrupamento[index].ignorar = !changeAgrupamento[index].ignorar
    setPecasAgrupamentoMat(changeAgrupamento)
  }
  const concluirImportacao = async event => {
    event.preventDefault()
    let formIsValid = true
    let AmbienteSemNomeValid = true
    pecasAgrupamentoAmb.forEach(item => {
      if (
        item.idAmbienteFinal === '' &&
        item.nomeAmbienteFinal === 'sem nome' &&
        !item.ignorar
      ) {
        AmbienteSemNomeValid = false
        formIsValid = false
      }
    })

    setFormAmbValid(AmbienteSemNomeValid)
    if (!AmbienteSemNomeValid) {
      showMsgW(
        'Existe um ambiente sem nome, clique em editar para vincular a um ambiente já existente'
      )
      return false
    }
    const elements = event.target.elements

    for (const item of elements) {
      if (item.type === 'text') {
        if (!item.value) {
          formIsValid = false
        }
      }
    }

    setFormValid(formIsValid)
    if (!formIsValid) {
      showMsgW(
        'Informe todos os campos obrigatórios antes de concluir a importação'
      )
      return false
    }

    let ignorePecas = 0
    pecasAgrupamentoMat.forEach(peca => {
      if (peca.ignorar || peca.ocultar) {
        ignorePecas += 1
      }
    })
    if (ignorePecas >= pecasAgrupamentoMat.length) {
      showMsgW('Nenhuma peça está sendo importada')
      formIsValid = false
      return false
    }

    if (formIsValid) {
      setFinishingImport(true)
      const novosAmbientesNames = []
      const AgrupamentoAmbFechado = pecasAgrupamentoAmb
      AgrupamentoAmbFechado.forEach(amb => {
        if (!amb.idAmbienteFinal && !amb.ignorar) {
          novosAmbientesNames.push({
            descricao: amb.nomeAmbienteFinal,
            projetoId: parseInt(projeto.id),
            pecas: []
          })
        }
      })

      if (novosAmbientesNames.length > 0) {
        try {
          const response = await trackPromise(
            api.leoplan.apiNovoAmbiente(novosAmbientesNames)
          )

          if (response.fail) throw new Error()

          response.data.forEach(ambRet => {
            AgrupamentoAmbFechado.forEach(amb => {
              const ambienteSemNome =
                (amb.nomeAmbiente === '' || amb.nomeAmbiente === null) &&
                ambRet.descricao === 'sem nome'
              if (
                (amb.nomeAmbiente === ambRet.descricao || ambienteSemNome) &&
                !amb.ignorar &&
                !amb.idAmbienteFinal
              ) {
                amb.idAmbienteFinal = ambRet.id
              }
            })
          })
        } catch {
          showMsgE('Não foi possível criar os ambientes')
          setFinishingImport(false)
          return false
        }
      }
      const listaPecasFormatada = []
      pecasImportadas.forEach(pecaImportada => {
        const agroupCorrespondente = pecasAgrupamentoMat.filter(
          x => x.material === pecaImportada.material
        )

        if (!agroupCorrespondente.length > 0) return

        const agroupCorrespondenteAmb = targetAmbienteImport
          ? AgrupamentoAmbFechado.filter(
              x => x.idAmbienteFinal === targetAmbienteImport.id
            )
          : AgrupamentoAmbFechado.filter(
              x => x.nomeAmbiente === pecaImportada.nomeAmbiente
            )

        if (
          !agroupCorrespondente[0].ignorar &&
          !agroupCorrespondenteAmb[0].ignorar
        ) {
          listaPecasFormatada.push({
            ambienteId: agroupCorrespondenteAmb[0].idAmbienteFinal,
            descricao: pecaImportada.descricao,
            projetoId: projeto.id,
            quantidade: pecaImportada.quantidade,
            altura: pecaImportada.largura,
            largura: pecaImportada.comprimento,
            veio: agroupCorrespondente[0].chapaEscolhida.veio,
            materialId: agroupCorrespondente[0].chapaEscolhida.id,
            direitaMaterialId: getFitaMaterialId(
              'fitaDireita',
              pecaImportada.fitaDireita,
              agroupCorrespondente[0].fitas
            ),
            esquerdaMaterialId: getFitaMaterialId(
              'fitaEsquerda',
              pecaImportada.fitaEsquerda,
              agroupCorrespondente[0].fitas
            ),
            inferiorMaterialId: getFitaMaterialId(
              'fitaBaixo',
              pecaImportada.fitaBaixo,
              agroupCorrespondente[0].fitas
            ),
            superiorMaterialId: getFitaMaterialId(
              'fitaCima',
              pecaImportada.fitaCima,
              agroupCorrespondente[0].fitas
            ),
            pecaProduto: getPecaProduto(
              pecaImportada,
              agroupCorrespondente[0].fitas
            )
          })
        }
      })

      if (listaPecasFormatada.length > 0) {
        incluiPecasImportadas(listaPecasFormatada, AgrupamentoAmbFechado)
      } else {
        setFinishingImport(false)
        showMsgW('Nenhuma peça está sendo importada')
      }
    }

    function getPecaProduto(peca, dePara) {
      const valuesBordeados = []
      const valuesBordeadosFormat = []
      if (peca.fitaDireita && peca.fitaDireita !== '0.0') {
        if (!valuesBordeados.includes(peca.fitaDireita)) {
          valuesBordeados.push(peca.fitaDireita)
        }
      }
      if (peca.fitaCima && peca.fitaCima !== '0.0') {
        if (!valuesBordeados.includes(peca.fitaCima)) {
          valuesBordeados.push(peca.fitaCima)
        }
      }
      if (peca.fitaBaixo && peca.fitaBaixo !== '0.0') {
        if (!valuesBordeados.includes(peca.fitaBaixo)) {
          valuesBordeados.push(peca.fitaBaixo)
        }
      }
      if (peca.fitaEsquerda && peca.fitaEsquerda !== '0.0') {
        if (!valuesBordeados.includes(peca.fitaEsquerda)) {
          valuesBordeados.push(peca.fitaEsquerda)
        }
      }
      valuesBordeados.forEach(item => {
        const detalhefita = dePara.filter(x => x.de === item)
        if (detalhefita.length > 0) {
          const foundItem = valuesBordeadosFormat.filter(
            x => x.projetoProdutoId === detalhefita[0].para.id
          )
          if (foundItem.length === 0) {
            valuesBordeadosFormat.push({
              projetoProdutoId: detalhefita[0].para.id
            })
          }
        }
      })
      if (valuesBordeadosFormat.length > 0) {
        return valuesBordeadosFormat
      }
      return []
    }
    function getFitaMaterialId(parametro, fitaAplicada, dePara) {
      if (fitaAplicada && fitaAplicada !== '0.0') {
        const filterDePara = dePara.filter(x => x.de === fitaAplicada)
        if (filterDePara.length > 0) {
          return filterDePara[0].para.id
        } else {
          return 0
        }
      }
      return 0
    }
  }
  const incluiPecasImportadas = (pecas, novosAmbientes) => {
    const novaListaAmbientes = [...projeto.projetoAmbiente]
    const novosAmbientesImport = novosAmbientes.filter(x => !x.ignorar)

    novosAmbientesImport.forEach(amb => {
      let foundInAmb = false
      novaListaAmbientes.forEach(ambOriginal => {
        if (ambOriginal.id === amb.idAmbienteFinal) {
          foundInAmb = true
        }
      })

      if (!foundInAmb && amb.idAmbienteFinal) {
        novaListaAmbientes.push({
          descricao: amb.nomeAmbienteFinal,
          id: amb.idAmbienteFinal,
          projetoId: projeto.id,
          projetoPeca: []
        })
      }
    })

    novaListaAmbientes.forEach(amb => {
      pecas.forEach(peca => {
        if (peca.ambienteId === amb.id) {
          amb.projetoPeca.push(peca)
        }
      })
    })

    salvarGrupoPecas(novaListaAmbientes)
  }
  const formataObjAllPecas = novaListaAmbientes => {
    function pecaProduto(pProduto) {
      let pProdutoFormat = []
      if (pProduto.length > 0) {
        pProdutoFormat = pProduto
        pProdutoFormat.forEach(item => {
          if (item.id === null) {
            delete item.id
          }
        })
      }
      return pProduto
    }

    function getCanaletado(idMaterial) {
      return projeto.projetoProduto.find(m => m.id === idMaterial)?.canaletado
    }

    function getPeca(pecas) {
      const pecasFormat = []
      pecas.forEach(peca => {
        let veioPeca
        if (projeto.tipoCorte === 2 || projeto.tipoCorte === 3) {
          veioPeca = true
        } else {
          veioPeca = peca.veio
        }
        pecasFormat.push({
          id: peca.id,
          descricao: peca.descricao,
          largura: peca.largura,
          altura: peca.altura,
          quantidade: peca.quantidade,
          veio: veioPeca,
          materialId: peca.materialId,
          pecaProduto: pecaProduto(peca.pecaProduto),
          superiorMaterialId: peca.superiorMaterialId,
          inferiorMaterialId: peca.inferiorMaterialId,
          esquerdaMaterialId: peca.esquerdaMaterialId,
          direitaMaterialId: peca.direitaMaterialId,
          ambienteId: peca.ambienteId,
          canaletado: getCanaletado(peca.materialId)
        })
      })
      return pecasFormat
    }
    const baseProjeto = {
      projetoId: parseInt(projeto.id),
      ambientes: novaListaAmbientes
        ? novaListaAmbientes.map(a => {
            const ambiente = {
              id: a.id,
              descricao: a.descricao,
              projetoId: a.projetoId,
              pecas: getPeca(a.projetoPeca)
            }
            return ambiente
          })
        : projeto.projetoAmbiente.map(a => {
            const ambiente = {
              id: a.id,
              descricao: a.descricao,
              projetoId: a.projetoId,
              pecas: getPeca(a.projetoPeca)
            }
            return ambiente
          })
    }
    return baseProjeto
  }

  const salvarGrupoPecas = async novaListaAmbientes => {
    try {
      const baseProjeto = formataObjAllPecas(novaListaAmbientes)

      const response = await trackPromise(
        api.leoplan.apiFinalizarProjeto(baseProjeto)
      )

      if (response.fail) throw new Error()

      changeProjeto('projetoAmbiente', response.data.projetoAmbiente)
      handleCloseModalImportacao()
      handleComplete(1)
      setActiveStep(2)
      setTargetAmbienteImport(null)
    } catch {
      showMsgE('Algum erro aconteceu ao tentar salvar o projeto')
    }
  }
  const handleCheckboxChangeAmb = index => {
    const changeAgrupamento = [...pecasAgrupamentoAmb]
    changeAgrupamento[index].ignorar = !changeAgrupamento[index].ignorar
    setPecasAgrupamentoAmb(changeAgrupamento)

    const changeAgrupamentoMat = [...pecasAgrupamentoMat]
    changeAgrupamentoMat.forEach(element => {
      if (element.ambientesImportados.length === 1) {
        if (
          element.ambientesImportados[0] ===
          changeAgrupamento[index].nomeAmbiente
        ) {
          element.ocultar = changeAgrupamento[index].ignorar
        }
      } else {
        let ignorarAll = true
        element.ambientesImportados.forEach(groupMat => {
          changeAgrupamento.forEach(groupAmb => {
            if (
              groupAmb.nomeAmbiente === groupMat &&
              groupAmb.ignorar === false
            ) {
              ignorarAll = false
            }
          })
        })
        if (ignorarAll) {
          element.ocultar = true
        } else {
          element.ocultar = false
        }
      }
    })

    setPecasAgrupamentoMat(changeAgrupamentoMat)
  }
  const handleSelect = (material, index, type, indexFita) => {
    setIndexMaterial(index)
    if (type === 'F') {
      setIndexMaterialFita(indexFita)
      handleOpenModalAddFita(material)
    } else handleOpenModalAddChapa(material)
  }
  const aplicaDeParaAmbiente = () => {
    const newPecasAgrupamentoAmb = [...pecasAgrupamentoAmb]

    newPecasAgrupamentoAmb[targetAmbiente.refIndex].idAmbienteFinal =
      targetAmbiente.idAmbienteFinal
    newPecasAgrupamentoAmb[targetAmbiente.refIndex].nomeAmbienteFinal =
      getAmbienteNome()

    function getAmbienteNome() {
      for (let i = 0; i < projeto.projetoAmbiente.length; i++) {
        if (projeto.projetoAmbiente[i].id === targetAmbiente.idAmbienteFinal) {
          return projeto.projetoAmbiente[i].descricao
        }
      }
    }

    setPecasAgrupamentoAmb(newPecasAgrupamentoAmb)
    handleCloseModalAmbiente()
  }
  const criarAmbienteViaApi = async newAmbienteName => {
    try {
      const newAmbiente = [
        {
          descricao: newAmbienteName,
          projetoId: parseInt(projeto.id),
          pecas: []
        }
      ]

      if (!newAmbiente[0]?.descricao) {
        showMsgW('Informe um nome para o Ambiente')
        return
      }

      const response = await trackPromise(
        api.leoplan.apiNovoAmbiente(newAmbiente)
      )

      if (response.fail || !response?.data[0]?.id) throw new Error()

      const newAmbient = {
        id: response.data[0].id,
        descricao: response.data[0].descricao,
        projetoId: response.data[0].projetoId,
        projetoPeca: []
      }
      changeProjeto('projetoAmbiente', [...projeto.projetoAmbiente, newAmbient])
    } catch {
      showMsgE('Não foi possível criar o ambiente')
    }
  }

  useEffect(() => {
    if (materialAutoSelect?.descricao) {
      if (
        indexMaterialFita !== null &&
        materialAutoSelect?.tipoProdutoId === 2
      ) {
        changeFita(materialAutoSelect, indexMaterial, indexMaterialFita)
      } else changeChapa(materialAutoSelect, indexMaterial)
    }
  }, [materialAutoSelect])

  return (
    <div className="mb30">
      <DialogAmbienteImport
        openModalAmbiente={openModalAmbiente}
        handleCloseModalAmbiente={handleCloseModalAmbiente}
        targetAmbiente={targetAmbiente}
        udtTargetAmbiente={udtTargetAmbiente}
        aplicaDeParaAmbiente={aplicaDeParaAmbiente}
        criarAmbienteViaApi={criarAmbienteViaApi}
      />
      <div className="mb10">
        <InfoMsg type="info" title="Peças agrupadas por ambiente"></InfoMsg>
      </div>
      <TableContainer>
        <Table aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Qtd.</StyledTableCell>
              <StyledTableCell>Comp.</StyledTableCell>
              <StyledTableCell>Larg.</StyledTableCell>
              <StyledTableCell>Descrição</StyledTableCell>
              <StyledTableCell>Chapa</StyledTableCell>
              <StyledTableCell>Fitas</StyledTableCell>
              <StyledTableCell className="text-center">
                <img src={CDFita} alt="" />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pecasAgrupamentoAmb.map((amb, index) => {
              const isInvalidAmbient =
                amb.idAmbienteFinal === '' &&
                amb.nomeAmbienteFinal === 'sem nome' &&
                !amb.ignorar &&
                !formAmbValid
              return (
                <React.Fragment key={index}>
                  <StyledTableRow
                    id={
                      isInvalidAmbient ? 'invalid-ambient' : 'ambient' + index
                    }
                  >
                    <StyledTableCellAmb data="Ambiente: " colSpan="7">
                      <div className="row">
                        <div className="col-md-6 pecasAction2">
                          {!targetAmbienteImport ? (
                            <Tooltip title="Editar ambiente">
                              <IconButton
                                variant="contained"
                                color="primary"
                                size="small"
                                className="secondary align-left mt5 mr5"
                                onClick={() =>
                                  handleOpenModalAmbiente(amb, index)
                                }
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          ) : null}

                          <span
                            className={`mt5 ${
                              isMobile ? 'align-right' : 'align-left'
                            }`}
                          >
                            <strong className="d-none d-lg-block d-xl-block align-left">
                              Ambiente: &ensp;
                            </strong>
                            {amb.nomeAmbienteFinal}
                          </span>
                        </div>
                        <div className="col-md-6 text-right small">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={`ignorarAmb${index}`}
                                checked={amb.ignorar}
                                className="defaultColor"
                                onChange={() => handleCheckboxChangeAmb(index)}
                              />
                            }
                            label="Não importar peças deste ambiente"
                          />
                        </div>
                      </div>
                    </StyledTableCellAmb>
                  </StyledTableRow>
                  {isInvalidAmbient && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={7}>
                        <InfoMsg
                          className="fullwidth"
                          type="error"
                          msg="Clique no botão acima para vincular um ambiente"
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                  {!amb.ignorar && (
                    <>
                      {amb.pecas.map((peca, indexPeca) => {
                        return (
                          <StyledTableRow key={indexPeca}>
                            <StyledTableCell data="Qtd">
                              {peca.quantidade}
                            </StyledTableCell>
                            <StyledTableCell data="Comp.">
                              {peca.comprimento}
                            </StyledTableCell>
                            <StyledTableCell data="Larg.">
                              {peca.largura}
                            </StyledTableCell>
                            <StyledTableCell data="Descrição">
                              {peca.descricao}
                            </StyledTableCell>
                            <StyledTableCell data="Chapa">
                              {peca.material}
                            </StyledTableCell>
                            <StyledTableCell data="Fitas">
                              <FitasListDotName peca={peca} />
                            </StyledTableCell>
                            <StyledTableCell data="Aplicação Fita">
                              <PecaMiniaturaImport peca={peca} />
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      })}
                    </>
                  )}
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <form onSubmit={concluirImportacao} className="mt20 mb30" noValidate>
        <div className="mb10 padB60">
          <InfoMsg
            type="info"
            title="Informe o Material correspondente para cada item abaixo"
          ></InfoMsg>
          {pecasAgrupamentoMat.map((grupo, index) => {
            return (
              <div key={grupo.material}>
                {!grupo.ocultar && (
                  <>
                    <div className="headerMaterialImport">
                      <div className="row" style={{ alignItems: 'center' }}>
                        <div className="col-md-6">
                          <p>
                            <strong>Material:</strong> {grupo.material}
                          </p>
                        </div>
                        <div
                          className={`col-md-6 ${
                            isMobile ? 'text-left' : 'text-right'
                          }`}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={`ignorarFita${index}`}
                                checked={grupo.ignorar}
                                className="defaultColor"
                                onChange={() => handleCheckboxChange(index)}
                              />
                            }
                            label="Não importar peças com este material"
                          />
                        </div>
                      </div>
                    </div>

                    {!grupo.ignorar && (
                      <>
                        <div className="row" style={{ alignItems: 'center' }}>
                          <div
                            className={`col-md-2 ${
                              isMobile ? 'text-left' : 'text-right'
                            }`}
                          >
                            <p>
                              <strong>Chapa</strong>
                            </p>
                          </div>
                          <div className="col-md-10">
                            <FormControl
                              variant="filled"
                              className="FormControl-lessMargin FormControl-withBtn"
                              size="small"
                            >
                              <InputLabel
                                htmlFor={`chapa_${grupo.material}_${index}`}
                              >
                                Chapa
                              </InputLabel>
                              <Select
                                value={grupo.chapaEscolhida}
                                onChange={event =>
                                  changeChapa(event.target.value, index)
                                }
                                name={`chapa_${grupo.material}_${index}`}
                                error={!grupo.chapaEscolhida && !formValid}
                                required
                              >
                                {projeto.projetoProduto.map(chapa => {
                                  if (chapa.tipoProdutoId === 1) {
                                    return (
                                      <MenuItem value={chapa} key={chapa.id}>
                                        {chapa.codigo} - {chapa.descricao}
                                      </MenuItem>
                                    )
                                  }
                                })}
                              </Select>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleSelect(grupo.material, index, 'C')
                                }
                              >
                                Add Chapa
                              </Button>
                            </FormControl>
                            {!grupo.chapaEscolhida && !formValid && (
                              <FormHelperText className="Mui-error">
                                Campo obrigatório
                              </FormHelperText>
                            )}
                          </div>
                        </div>
                        {grupo.fitas.map((fita, indexF) => {
                          return (
                            <div
                              className="row"
                              style={{ alignItems: 'center' }}
                              key={indexF}
                            >
                              <div
                                className={`col-md-3 ${
                                  isMobile ? 'text-left' : 'text-right'
                                }`}
                              >
                                <p>
                                  <strong>Fita:</strong> {fita.de}
                                </p>
                              </div>
                              <div className="col-md-9">
                                <FormControl
                                  variant="filled"
                                  className="FormControl-lessMargin FormControl-withBtn"
                                  size="small"
                                >
                                  <InputLabel
                                    htmlFor={`fita_${grupo.material}_${index}_${fita.de}_${indexF}`}
                                  >
                                    Fita
                                  </InputLabel>
                                  <Select
                                    value={fita.para}
                                    onChange={event =>
                                      changeFita(
                                        event.target.value,
                                        index,
                                        indexF
                                      )
                                    }
                                    name={`fita_${grupo.material}_${index}_${fita.de}_${indexF}`}
                                    error={!fita.para && !formValid}
                                    required
                                  >
                                    {projeto.projetoProduto.map(fita => {
                                      if (fita.tipoProdutoId === 2) {
                                        return (
                                          <MenuItem value={fita} key={fita.id}>
                                            {fita.codigo} - {fita.descricao}
                                          </MenuItem>
                                        )
                                      }
                                    })}
                                  </Select>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      handleSelect(
                                        grupo.material,
                                        index,
                                        'F',
                                        indexF
                                      )
                                    }
                                  >
                                    {' '}
                                    Add Fita{' '}
                                  </Button>
                                </FormControl>
                                {!fita.para && !formValid && (
                                  <FormHelperText className="Mui-error">
                                    Campo obrigatório
                                  </FormHelperText>
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            )
          })}
        </div>

        <div className="groupFixedBtn">
          <div className="row">
            <div className="col-sm-6 text-left">
              <Button
                variant="contained"
                className={`blackButton ${isMobile ? 'w-100' : 'mr5'}`}
                onClick={handleCloseModalImportacao}
              >
                {' '}
                Voltar para o projeto{' '}
              </Button>
            </div>

            <div className="col-sm-6 text-right flexLeo f-end">
              <div className={`text-center ${isMobile ? 'w-100' : ''}`}>
                {!finishingImport ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    className={isMobile ? 'w-100 mt-2' : ''}
                  >
                    {' '}
                    Concluir Importação{' '}
                  </Button>
                ) : (
                  <> Processando... </>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ImportacaoDepara
