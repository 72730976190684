import { Clear } from '@mui/icons-material'
import { Button, IconButton, Tooltip } from '@mui/material'
import { isMobile } from 'react-device-detect'

export const titleTableMateriais = ['Código', 'Descrição']

export const titleTableParametros = ['Configuração', 'Parâmetro', 'Valor']

export const orderByBuscaMaterial = (a, b) => {
  if (a.EstoqueLoja < b.EstoqueLoja) {
    return 1
  }
  if (a.EstoqueLoja > b.EstoqueLoja) {
    return -1
  }
  return 0
}

export const formatDataTableMateriais = (data, handleDeleteMaterial) => {
  if (!data) return []
  return data.map(item => {
    return {
      _id: item.materialIdErp,
      codigo: { data: item.materialIdErp, type: 'number' },
      descricao: { data: item.descricao },
      actions: {
        data: (
          <>
            {isMobile ? (
              <Button
                id={`botao-remover-material-${item.materialIdErp}`}
                size="small"
                className="danger"
                onClick={() => handleDeleteMaterial(item.materialIdErp)}
              >
                Remover material
              </Button>
            ) : (
              <Tooltip title="Remover material">
                <IconButton
                  id={`botao-remover-material-${item.materialIdErp}`}
                  size="small"
                  className="danger"
                  onClick={() => handleDeleteMaterial(item.materialIdErp)}
                >
                  <Clear fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
        type: 'object'
      }
    }
  })
}

export const formatDataTableParametros = (
  data,
  handleOpenDialogSalvarParametro,
  handleDeleteParametro
) => {
  if (!data) return []
  return data.map(item => {
    return {
      _id: item.caminho,
      configuracao: { data: item.descricao },
      parametro: { data: item.descricaoConfig },
      valor: { data: item.valor, type: 'number' },
      actions: {
        data: (
          <>
            {isMobile ? (
              <Button
                id={`botao-remover-parametro-${item.id}`}
                size="small"
                className="danger"
                onClick={() => handleDeleteParametro(item.id)}
              >
                Remover parâmetro
              </Button>
            ) : (
              <Tooltip title="Remover parâmetro">
                <IconButton
                  id={`botao-remover-parametro-${item.id}`}
                  size="small"
                  className="danger"
                  onClick={() => handleDeleteParametro(item.id)}
                >
                  <Clear fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
        type: 'object'
      }
    }
  })
}
