import { showMsgW as showMsgWAction } from '@store/actions/snackbarActions.js'
import React, { useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { useDispatch } from 'react-redux'

import { useProjeto } from '@hook/useProjeto'

import api from '@service/api'
import FormatObj from '@service/serviceFormat.js'

import DialogAddChapa from '../Dialogs/DialogAddChapa.js'
import DialogAddFita from '../Dialogs/DialogAddFita.js'
import DialogImportacao from '../Dialogs/DialogImportacao.js'

const ImportacaoAmbiente = ({
  openModalImportacao,
  handleCloseModalImportacao
}) => {
  const dispatch = useDispatch()
  const showMsgW = msg => dispatch(showMsgWAction(msg))

  const { projeto, fitasPeca, changeProjeto } = useProjeto()
  const [materialAutoSelect, setMaterialAutoSelect] = useState(null)

  const [openModalAddFita, setOpenModalAddFita] = useState(false)
  const [fitaDepara, setFitaDepara] = useState('')
  const handleOpenModalAddFita = depara => {
    if (depara) setFitaDepara(depara)
    setOpenModalAddFita(true)
  }
  const handleCloseModalAddFita = () => {
    setOpenModalAddFita(false)
    setFitaDepara('')
  }

  const [openModalAddChapa, setOpenModalAddChapa] = useState(false)
  const [chapaDepara, setChapaDepara] = useState('')
  const handleOpenModalAddChapa = depara => {
    if (depara) {
      setChapaDepara(depara)
    }
    setOpenModalAddChapa(true)
  }
  const handleCloseModalAddChapa = () => {
    setOpenModalAddChapa(false)
    setChapaDepara('')
  }
  const formatMaterialProject = (rawMaterial, tipo) => {
    if (
      tipo === 'F' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'M' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'R' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'RL'
    ) {
      throw new Error('Este tipo de material não pode ser incluido')
    } else if (
      tipo === 'C' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'CH' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'PC' &&
      rawMaterial.TipoUnidadeMedida.IdERP !== 'UN'
    ) {
      throw new Error('Este tipo de material não pode ser incluido')
    }
    return {
      ...rawMaterial,
      canaletado: rawMaterial.canaletado || 0
    }

    // vendedor.centroSaida = user.empresaIdErp
    // vendedor.centroVenda = user.empresaIdErp
  }
  const addMaterialnoProjeto = async (material, tipo) => {
    try {
      const materialJaEstaNoProjeto = projeto.projetoProduto.find(
        mat => mat.codigo.toString() === material.IdERP.toString()
      )

      if (materialJaEstaNoProjeto) {
        showMsgW('O material já existe no projeto.')
        return
      }
      const response = await trackPromise(
        api.core.apiProdutoById(material.IdERP)
      )
      if (response.fail || !response?.Id) {
        throw new Error('Algum erro aconteceu ao tentar incluir a chapa')
      }
      const produto = FormatObj.setNewProduto(
        tipo,
        projeto.id,
        formatMaterialProject(response)
      )
      const responseMatProj = await trackPromise(
        api.leoplan.apiAddProjetoProduto(produto)
      )

      if (responseMatProj.fail || !responseMatProj?.data?.id) throw new Error()

      const updateMateriais = [...projeto.projetoProduto, responseMatProj.data]
      changeProjeto('projetoProduto', updateMateriais)
      if (chapaDepara || fitaDepara) {
        setMaterialAutoSelect(responseMatProj.data)
        if (fitaDepara) handleCloseModalAddFita()
        else handleCloseModalAddChapa()
      }

      return responseMatProj.data
    } catch {
      showMsgW('Algum erro aconteceu ao tentar incluir o material')
    }
  }
  return (
    <div>
      <DialogImportacao
        openModalImportacao={openModalImportacao}
        handleCloseModalImportacao={handleCloseModalImportacao}
        handleOpenModalAddFita={handleOpenModalAddFita}
        handleOpenModalAddChapa={handleOpenModalAddChapa}
        materialAutoSelect={materialAutoSelect}
      />

      <DialogAddFita
        showMsgW={showMsgW}
        openModalAddMaterial={openModalAddFita}
        handleCloseModalAddMaterial={handleCloseModalAddFita}
        handleIncludeMaterail={addMaterialnoProjeto}
        fitasSugeridas={fitasPeca}
        materialDepara={fitaDepara}
      />
      <DialogAddChapa
        showMsgW={showMsgW}
        openModalAddMaterial={openModalAddChapa}
        handleCloseModalAddMaterial={handleCloseModalAddChapa}
        handleIncludeMaterail={addMaterialnoProjeto}
        materialDepara={chapaDepara}
      />
    </div>
  )
}
export default ImportacaoAmbiente
