/* eslint-disable react/jsx-filename-extension */
import {
  ConfigModel,
  ConfigServiceContext,
  ConfigService
} from '@dotproductdev/parametric-configurator'
import CloseIcon from '@mui/icons-material/Close'
import LoopIcon from '@mui/icons-material/Loop'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction
} from '@store/actions/snackbarActions.js'
import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'

import Utils from '@base/services/serviceUtils'

import sistemasFixacaoMenu from '@mock/sistemasFixacao.js'

import { LeoVisualizer } from './LeoVisualizer'

interface ProjetoPecaServico {
  projetoPecaId: number
  descricao: string
  tipoServico: number
  payloadConfigurador: string
  ativo: boolean
  sistemaFixacaoId?: number
  fixacaoConector?: number
  projetoPecaPrimariaId?: number
  projetoPecaSecundariaId?: number
}

interface SistemaFixacaoProps {
  sistemaFixacaoId: number
  id: number
  nome: string
  conectorPrimario: string
  conectorSecundario: string
  permiteCavilhaExtra: boolean
}
interface PecaProps {
  sistemaFixacao: SistemaFixacaoProps
  id: number
  quantidade: number
  altura: number
  largura: number
  descricao: string
  projetoPecaServico: ProjetoPecaServico[]
}
interface ChangePropertyHandler {
  (property: string, data: ProjetoPecaServico[]): void
}
interface DialogIntegracaoFurcaoUsinagemProps {
  open: boolean
  close: () => void
  peca: PecaProps
  inverteMedidasPeca: () => void
  shouldShowInvertPiece: boolean
  dialogIntegracaoFurcaoUsinagemProps?: {
    configService: ConfigService
  }
  usinagem: string[]
  handleChangeProperty: ChangePropertyHandler
  salvarGrupoPecas: () => void
  sistemaFixacao: string
  sistemaFixacaoLado: string
  chapaSelecionada?: { espessura: number }
}

const getParams = (
  hingeSide: string,
  joinSystemType: string = 'None',
  sistemaFixacaoLado = 'side',
  tipoUsinagem: 'Dobradiça' | 'Rebaixo' | 'Canal' | '-'
) => {
  let params = {
    id: 1,
    componentType: '',
    name: tipoUsinagem,
    props: {}
  }

  if (tipoUsinagem === 'Dobradiça') {
    params = {
      ...params,
      componentType: 'door',
      props: {
        MaterialGrain: 'Vertical',
        LeftEdgeband: 'edge_textured',
        RightEdgeband: 'edge_textured',
        TopEdgeband: 'edge_textured',
        BottomEdgeband: 'edge_textured',
        HingeSide: hingeSide,
        HingeSku: 'hinge',
        HandleSku: '',
        JoinSystemType: joinSystemType
      }
    }
  }

  if (tipoUsinagem === 'Canal') {
    params = {
      ...params,
      componentType: sistemaFixacaoLado,
      props: {
        MaterialGrain: 'FromMaterial',
        LeftEdgeband: '',
        RightEdgeband: '',
        TopEdgeband: '',
        BottomEdgeband: '',
        DadoOffsetFromEnd: '1500',
        DadoThickness: '700',
        DadoDepth: '800',
        JoinSystemType: joinSystemType
      }
    }
  }

  if (tipoUsinagem === 'Rebaixo') {
    params = {
      ...params,
      componentType: sistemaFixacaoLado,
      props: {
        MaterialGrain: 'FromMaterial',
        LeftEdgeband: '',
        RightEdgeband: '',
        TopEdgeband: '',
        BottomEdgeband: '',
        DadoOffsetFromEnd: '0',
        DadoThickness: '700',
        DadoDepth: '1100',
        JoinSystemType: joinSystemType
      }
    }
  }
  return params
}

// Neste demo estamos configurando uma porta (componentType === 'door'). Na
// implementação real o tipo de componente deve ser passado como props. Para
// configurar canal/rebaixo, podem usar 'side' (lateral) como componente.

const initConfigurator = async (
  configService: ConfigService,
  peca: PecaProps,
  hingeSide: string,
  projetoPecaServico: ProjetoPecaServico[],
  joinSystemType: string,
  sistemaFixacaoLado: string,
  chapaSelecionada?: { espessura: number }
) => {
  // sistemaFixacaoLado = 'base'| 'side''
  // joinSystemType = "None" | "MinifixAndDowels" | "Minifix" | "Dowels" | "VBOne" | "VBTwo" | "DFix"

  const configParameters = getParams(
    hingeSide,
    joinSystemType,
    sistemaFixacaoLado,
    Utils.mapNomeServico(projetoPecaServico[0]?.tipoServico)
  )

  let cfg = await configService.initConfigModel(configParameters.componentType)
  // A configuração inicial é incompleta então vamos passar alguns parâmetros
  // para que a porta esteja completamente configurada.
  const { entries } = cfg.configuration

  const next: Record<string, string> = {}
  for (const e of entries) {
    next[e.prop] = e.val
  }

  for (const e of Object.entries(configParameters.props)) {
    next[e[0]] = e[1] as string
  }

  // Aqui vamos só substituir alguns campos. Para SKUs, os mesmos devem estar
  // cadastrados nos serviços de Repository e Object3DSource.

  next.Board = Utils.getBoard(chapaSelecionada) // sku da chapa

  if (configParameters.componentType === 'door') {
    next.Width = String(peca.altura * 100) // medida em centésimos de mm
    next.Height = String(peca.largura * 100)
  } else {
    next.Width = String(peca.largura * 100) // medida em centésimos de mm
    next.Height = String(peca.altura * 100)
  }

  cfg = await configService.updateConfigModel(
    configParameters.componentType,
    next
  )

  if (cfg.configuration.hasErrors) {
    alert('Erros! Verifique os diagnósticos associados a configuração')
  }

  return cfg
}

const DialogIntegracaoFurcaoUsinagem = ({
  open,
  close,
  peca,
  inverteMedidasPeca,
  shouldShowInvertPiece,
  handleChangeProperty,
  salvarGrupoPecas,
  sistemaFixacao,
  sistemaFixacaoLado,
  chapaSelecionada
}: DialogIntegracaoFurcaoUsinagemProps) => {
  const dispatch = useDispatch()

  const showMsgE = (msg: string) => dispatch(showMsgEAction(msg))
  const showMsgS = (msg: string) => dispatch(showMsgSAction(msg))
  const [config, setConfig] = useState<ConfigModel | null>(null)
  const [hingeSide, setHingeSide] = useState<string>('Right')
  const configService = useContext(ConfigServiceContext)

  const girarUsinagem = () => {
    setHingeSide(prev => (prev === 'Right' ? 'Top' : 'Right'))
  }

  useEffect(() => {
    if (!!peca?.projetoPecaServico[0]?.payloadConfigurador) {
      const payloadConfigurador = JSON.parse(
        peca.projetoPecaServico[0].payloadConfigurador
      )
      if (payloadConfigurador.componentType === 'door') {
        const hingeSidePiece = payloadConfigurador.entries.find(
          (entry: Record<string, string>) => {
            return entry.prop === 'HingeSide'
          }
        )
        setHingeSide(hingeSidePiece?.val)
      }
    }
  }, [peca.projetoPecaServico])

  useEffect(() => {
    if (configService) {
      const init = async () => {
        setConfig(
          await initConfigurator(
            configService,
            peca,
            hingeSide,
            peca?.projetoPecaServico,
            sistemaFixacao,
            sistemaFixacaoLado,
            chapaSelecionada
          )
        )
      }
      init()
    }
  }, [hingeSide, peca])

  const Configurator = () => {
    // OBS: para renderizar é necessário que a configuração seja considerada
    // válida, e para isso o tipo de material utilizado para produzir a peça
    // (chapa e fitas de bordo) deve estar devidamente ajustado. Neste exemplo,
    // estamos criando materiais fictícios em "Mocks.ts". Eventualmente isso
    // será trocado por uma base real de materiais [implementar os serviços
    // reais que Mocks.ts está retornando em getServices].

    useEffect(() => {
      if (config === null) {
        // Para facilitar, estamos já partindo de uma configuração pronta.
        const init = async () => {
          setConfig(
            await initConfigurator(
              configService,
              peca,
              hingeSide,
              peca?.projetoPecaServico,
              sistemaFixacao,
              sistemaFixacaoLado,
              chapaSelecionada
            )
          )
        }
        if (configService) {
          init()
        }
      }
    }, [config, configService])

    return (
      <div>
        {config?.materialization?.type === 'pc' && (
          <LeoVisualizer
            node={config.materialization}
            componentType={config.configuration.componentType}
          />
        )}
      </div>
    )
  }

  const getProp = (prop: string) => {
    const profundidade = config?.configuration?.entries?.find(
      entry => entry.prop === prop
    )?.val

    if (profundidade) return Number(profundidade) / 100

    return ''
  }

  const handleSaveService = async () => {
    const sistemaFixacaoId = sistemasFixacaoMenu.find(
      (item: { value: string }) => item.value === sistemaFixacao
    )?.id

    try {
      const data: ProjetoPecaServico = {
        projetoPecaId: peca.id,
        descricao: peca.descricao,
        tipoServico: peca?.projetoPecaServico?.[0]?.tipoServico,
        payloadConfigurador: JSON.stringify(config.configuration),
        ativo: true,
        sistemaFixacaoId: sistemaFixacaoId,
        fixacaoConector:
          sistemaFixacaoLado === 'side'
            ? 1
            : sistemaFixacaoLado === 'base'
            ? 2
            : null,
        projetoPecaPrimariaId: peca.projetoPecaServico[0].projetoPecaPrimariaId,
        projetoPecaSecundariaId:
          peca.projetoPecaServico[0].projetoPecaSecundariaId
      }

      handleChangeProperty('projetoPecaServico', [data])
      salvarGrupoPecas()
      showMsgS('Serviço incluído com sucesso')
      close()
    } catch {
      showMsgE('Erro ao incluir serviço de usinagem na peça')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleSaveService}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
      maxWidth="xl"
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Incluir serviço de usinagem</Typography>
        <IconButton aria-label="close" onClick={handleSaveService} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="text-center" dividers>
        <div className="w-100 flex">
          <div className="text-left p-14 w-96">
            {peca.descricao && <p>Peça: {peca.descricao} </p>}
            <p>
              Usinagem:{' '}
              {Utils.mapNomeServico(peca?.projetoPecaServico?.[0]?.tipoServico)}
            </p>
            <p>Quantidade: {peca.quantidade} </p>
            <p>Comprimento: {peca.largura} </p>
            <p>Largura: {peca.altura}</p>

            {config?.configuration &&
              (peca?.projetoPecaServico?.[0]?.tipoServico === 2 ||
                peca?.projetoPecaServico?.[0]?.tipoServico === 3) && (
                <div className="mt-5">
                  <p>Profundidade: {getProp('DadoDepth')}mm</p>
                  <p>Distância da borda: {getProp('DadoOffsetFromEnd')}mm</p>
                  <p>Espessura da usinagem: {getProp('DadoThickness')}mm</p>
                </div>
              )}

            <div className="mt-6">
              {peca?.projetoPecaServico?.[0]?.tipoServico === 1 && (
                <Button
                  onClick={girarUsinagem}
                  variant="contained"
                  color="secondary"
                  className="mb10 w-60"
                  endIcon={<LoopIcon />}
                >
                  Girar Usinagem
                </Button>
              )}
              {shouldShowInvertPiece && (
                <Button
                  onClick={inverteMedidasPeca}
                  variant="contained"
                  color="secondary"
                  className="mb10 w-60"
                  endIcon={<LoopIcon />}
                >
                  Inverter Medida
                </Button>
              )}
            </div>
          </div>
          <Configurator />
        </div>

        <Button
          onClick={handleSaveService}
          variant="contained"
          className="mr10 blackButton"
        >
          Fechar
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default DialogIntegracaoFurcaoUsinagem
