import Exemplo1 from '@asset/img/exemploParams1.jpg'
import Exemplo2 from '@asset/img/exemploParams2.jpg'
import Exemplo3 from '@asset/img/exemploParams3.jpg'
import Exemplo4 from '@asset/img/exemploParams4.jpg'
import { ExpandMore } from '@mui/icons-material'
import {
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import { useConfiguracao } from '@hook/useConfiguracao'

import SelectCheckbox from '@component/SelectCheckbox/SelectCheckbox'

import api from '@service/api'

export default function PlanoCorte({ value, index, userLogado }) {
  const { configData, configUpdateData, setConfigUpdateData } =
    useConfiguracao()

  const [usarioPodeAlterarCanaletado, setUsuarioPodeAlterarCanaletado] =
    useState(false)
  const [expanded, setExpanded] = useState(false)
  const [lojas, setLojas] = useState([])
  const [lojasPermitemCanaletar, setLojasPermitemCanaletar] = useState([])
  const [lojasPermitemFuracaoUsinagem, setLojasPermitemFuracaoUsinagem] =
    useState([])

  const optionsStore = [
    {
      title: 'Parametros da loja',
      obs: '',
      fields: [
        {
          title: 'Empresa',
          placeholder: 'EmpresaIdERP',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.empresaIdERP
        },
        {
          title: 'IDErp',
          placeholder: 'LojaIdERP',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.lojaIdERP
        },
        {
          title: 'Medida mínima de corte (mm)',
          placeholder: 'MinimoChapaCorte',
          disabled: true,
          onChange: '',
          obs1: 'Validação >= max(minStripHeight, minDimensionOfLastCut)/2',
          obs2: '',
          value: configData?.minimoChapaCorte
        },
        {
          title: 'Medida mínima de fitagem (mm)',
          placeholder: 'MinimoChapaPermiteFita',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.minimoChapaPermiteFita
        },
        {
          title: 'Acrescimo de fita por lado fitado',
          placeholder: 'AcrescimoBordeamento',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.acrescimoBordeamento
        },
        {
          title: 'Espessura mínima da chapa para bordear',
          placeholder: 'LimiteEspessuraBordeamento',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.limiteEspessuraBordeamento
        },
        {
          title: 'Mínimo fitagem lado oposto',
          placeholder: 'MinChapaPermiteFitaLadoOposto',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.minChapaPermiteFitaLadoOposto
        },
        {
          title: 'Estoque mínimo para mostrar materiais SF,FF,FL e SL',
          placeholder: 'minLimitFL',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.minLimitFL
        },
        {
          title: 'Corte adicional por chapa',
          placeholder: 'QtdCorteAdicionalRefilo',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              qtdCorteAdicionalRefilo: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.qtdCorteAdicionalRefilo
        },
        {
          title: 'Desconto na largura para corte útil da chapa  (mm)',
          placeholder: 'DescontoMaximoAltura',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              descontoMaximoAltura: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.descontoMaximoAltura
        },
        {
          title: 'Desconto no comprimento para corte útil da chapa (mm)',
          placeholder: 'DescontoMaximoLargura',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              descontoMaximoLargura: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.descontoMaximoLargura
        },
        {
          title: 'Lote múltiplo para cobrança de fita ',
          placeholder: 'MultiploFita',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              multiploFita: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.multiploFita
        }
      ]
    },
    {
      title: 'Serviço de Canaletagem',
      obs: '',

      fields: [
        {
          title: 'Serviço de canaletagem',
          placeholder: 'servicoCanaletagem',
          disabled: !usarioPodeAlterarCanaletado,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              servicoCanaletagem: e.target.checked
            }),
          obs1:
            configData?.servicoCanaletagem &&
            configData?.servicoCanaletagemOpcoes.length
              ? `Tamanhos de canaletagem habilitados para esta empresa: ${configData?.servicoCanaletagemOpcoes}`
              : '',
          obs2: '',
          value: configUpdateData?.servicoCanaletagem,
          type: 'switch'
        },
        {
          title: 'Lojas que permitem canaletar',
          placeholder: 'lojasCanaletar',
          disabled:
            !usarioPodeAlterarCanaletado || !configData?.servicoCanaletagem,
          selectedValue: lojasPermitemCanaletar,
          onChange: items => {
            setLojasPermitemCanaletar(items)
            setConfigUpdateData({
              ...configUpdateData,
              empresaLojasCanaletado: items.map(item => item.value)
            })
          },
          obs1: '',
          obs2: '',
          type: 'select'
        }
      ]
    },
    {
      title: 'Serviço de Furação e Usinagem',
      obs: '',

      fields: [
        {
          title: 'Habilitar furação e usinagem',
          placeholder: 'servicoFuracaoUsinagem',
          disabled: !usarioPodeAlterarCanaletado,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              servicoFuracaoUsinagem: e.target.checked
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.servicoFuracaoUsinagem,
          type: 'switch'
        },
        {
          title: 'Lojas que permitem furação e usinagem',
          placeholder: 'empresaLojasFuracaoUsinagem',
          disabled:
            !usarioPodeAlterarCanaletado || !configData?.servicoFuracaoUsinagem,
          selectedValue: lojasPermitemFuracaoUsinagem,
          onChange: items => {
            setLojasPermitemFuracaoUsinagem(items)
            setConfigUpdateData({
              ...configUpdateData,
              empresaLojasFuracaoUsinagem: items.map(item => item.value)
            })
          },
          obs1: '',
          obs2: '',
          type: 'select'
        },
        {
          title: 'Espessura minima para Chapas de furação e usinagem (mm)',
          placeholder: 'minEspessuraChapaFuracaoUsinagem',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              minEspessuraChapaFuracaoUsinagem: Number(e.target.value)
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.minEspessuraChapaFuracaoUsinagem
        },
        {
          title: 'Espessura máxima para Chapas de furação e usinagem (mm)',
          placeholder: 'maxEspessuraChapaFuracaoUsinagem',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              maxEspessuraChapaFuracaoUsinagem: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.maxEspessuraChapaFuracaoUsinagem
        },
        {
          title: 'Largura mínima para peças com dobradiça (mm)',
          placeholder: 'minAlturaPecaDobradiça',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              minAlturaPecaDobradica: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.minAlturaPecaDobradica
        },
        {
          title: 'Largura máxima para peças com dobradiça (mm)',
          placeholder: 'maxAlturaPecaDobradiça',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              maxAlturaPecaDobradica: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.maxAlturaPecaDobradica
        },
        {
          title: 'Comprimento mínimo para peças com dobradiça (mm)',
          placeholder: 'minLarguraPecaDobradiça',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              minLarguraPecaDobradica: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.minLarguraPecaDobradica
        },
        {
          title: 'Comprimento máximo para peças com dobradiça (mm)',
          placeholder: 'maxLarguraPecaDobradiça',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              maxLarguraPecaDobradica: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.maxLarguraPecaDobradica
        },
        {
          title: 'Largura mínima para peças com canal (mm)',
          placeholder: 'minAlturaPecaCanal',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              minAlturaPecaCanal: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.minAlturaPecaCanal
        },
        {
          title: 'Largura máxima para peças com canal (mm)',
          placeholder: 'maxAlturaPecaCanal',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              maxAlturaPecaCanal: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.maxAlturaPecaCanal
        },
        {
          title: 'Comprimento mínimo para peças com canal (mm)',
          placeholder: 'minLarguraPecaCanal',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              minLarguraPecaCanal: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.minLarguraPecaCanal
        },
        {
          title: 'Comprimento máximo para peças com canal (mm)',
          placeholder: 'maxLarguraPecaCanal',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              maxLarguraPecaCanal: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.maxLarguraPecaCanal
        },
        {
          title: 'Largura mínima para peças com rebaixo (mm)',
          placeholder: 'minAlturaPecaRebaixo',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              minAlturaPecaRebaixo: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.minAlturaPecaRebaixo
        },
        {
          title: 'Largura máxima para peças com rebaixo (mm)',
          placeholder: 'maxAlturaPecaRebaixo',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              maxAlturaPecaRebaixo: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.maxAlturaPecaRebaixo
        },
        {
          title: 'Comprimento mínimo para peças com rebaixo (mm)',
          placeholder: 'minLarguraPecaRebaixo',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              minLarguraPecaRebaixo: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.minLarguraPecaRebaixo
        },
        {
          title: 'Comprimento máximo para peças com rebaixo (mm)',
          placeholder: 'maxLarguraPecaRebaixo',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao
              },
              maxLarguraPecaRebaixo: e.target.value
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.maxLarguraPecaRebaixo
        }
      ]
    }
  ]
  const optionsMac = [
    {
      title: 'Opções da máquina',
      obs: '',
      fields: [
        {
          title: 'Espessura de Corte da serra (mm)',
          placeholder: 'bladeThickness',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao,
                bladeThickness: e.target.value
              }
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.configuracoesOtimizacao?.bladeThickness
        },
        {
          title: 'Refilo mínimo em cortes Z',
          placeholder: 'zCutTrimming',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao,
                zCutTrimming: e.target.value
              }
            }),
          obs1: 'Garante que sobra pelo menos essa quantidade de material para refilar um ou ambos os lados de uma faixa em corte Z. No exemplo: garante que para realizar os cortes C8 e C9 há no mímimo espaço para o refilo.',
          obs2: <img className="width100p" src={Exemplo1} alt="exemplo 1" />,
          value: configUpdateData?.configuracoesOtimizacao?.zCutTrimming
        },
        {
          title:
            'Altura máxima de corte útil da máquina (empilhamento de chapas em mm)',
          placeholder: 'maxStackHeight',
          disabled: false,
          onChange: e =>
            setConfigUpdateData({
              ...configUpdateData,
              configuracoesOtimizacao: {
                ...configUpdateData?.configuracoesOtimizacao,
                maxStackHeight: e.target.value
              }
            }),
          obs1: '',
          obs2: '',
          value: configUpdateData?.configuracoesOtimizacao?.maxStackHeight
        },
        {
          title:
            'Dimensão mínima de material que pode ser cortado enquanto pinçado.',
          placeholder: 'minDimensionOfLastCut',
          disabled: true,
          onChange: '',
          obs1: 'As pinças não podem invadir a linha de corte.',
          obs2: '',
          value: configData?.configuracoesOtimizacao.minDimensionOfLastCut
        },
        {
          title: 'Largura mínima pinçável.',
          placeholder: 'minStripHeight',
          disabled: true,
          onChange: '',
          obs1: 'Se uma faixa tiver dimensão menor que essa, a pinça não consegue prender a peça e mantê-la no esquadro para corte preciso.',
          obs2: '',
          value: configData?.configuracoesOtimizacao.minStripHeight
        }
      ]
    }
  ]
  const optionsOpt = [
    {
      title: 'Gestão de aproveitamento de sobras de material',
      obs: '',
      fields: [
        {
          title: 'Área mínima do material para ser considerado uma sobra (m²)',
          placeholder: 'dropParams.minArea',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.infoOtimizacao?.dropParams.minArea
        },
        {
          title:
            'Altura mínima do material para ser considerado uma sobra (mm)',
          placeholder: 'dropParams.minHeight',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.infoOtimizacao?.dropParams.minHeight
        },
        {
          title:
            'Largura mínima do material para ser considerado uma sobra (mm)',
          placeholder: 'dropParams.minWidth',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.infoOtimizacao?.dropParams.minWidth
        },
        {
          title: 'Penalidade aplicada por cada sobra gerada (m²)',
          placeholder: 'dropParams.sqMeterPenaltyPerIndividualDrop',
          disabled: true,
          onChange: '',
          obs1: 'Serve para dar preferência a uma quantidade menor de sobras grandes comparado a vários retalhos pequenos.',
          obs2: 'Exemplo, com penalidade 0.4, duas sobras de 1m² cada uma equivalem a uma única sobra com 1.6m². Três sobras de 1m² equivalem a uma única sobra de 2.2 m².',
          value:
            configData?.infoOtimizacao?.dropParams
              .sqMeterPenaltyPerIndividualDrop
        }
      ]
    },
    {
      title:
        'Previne o tipo de corte Z em bloco que causou problemas na Central.',
      obs: 'As máquinas conseguem cortar esse tipo de corte apenas quando muito bem alinhadas mecanicamente. Estamos estudando uma solução via software, mas depende da geração de arquivo máquina para cada fabricante.',
      fields: [
        {
          title: 'Ativar todos',
          placeholder: 'customParameters.lowVolumeOptions.EnableAllZCutStrips',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value:
            configData?.infoOtimizacao?.customParameters.lowVolumeOptions
              .EnableAllZCutStrips
        },
        {
          title: 'Ignorar restrições',
          placeholder:
            'customParameters.lowVolumeOptions.CanIgnoreZCutRestrictions',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value:
            configData?.infoOtimizacao?.customParameters.lowVolumeOptions
              .CanIgnoreZCutRestrictions
        }
      ]
    },
    {
      title: 'Base de corte: Vertical.',
      obs: '',
      fields: [
        {
          title: 'Número máximo de peças distintas numa mesma faixa',
          placeholder: 'basicGenerators.parameters.maxDistinctPartsPerStrip',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.infoOtimizacao?.basicGenerators.find(
            item => item.cutOrientation === 'Vertical'
          ).parameters.maxDistinctPartsPerStrip
        },
        {
          title: 'Número máximo de faixas distintas num mesmo esquema',
          placeholder: 'basicGenerators.parameters.maxStripGroups',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.infoOtimizacao?.basicGenerators.find(
            item => item.cutOrientation === 'Vertical'
          ).parameters.maxStripGroups
        },
        {
          title: 'Corte Z habilitado',
          placeholder: 'basicGenerators.parameters.zCutSettings.enabled',
          disabled: true,
          onChange: '',
          obs1: 'Prejudica a otimização caso desativado.',
          obs2: '',
          value: configData?.infoOtimizacao?.basicGenerators.find(
            item => item.cutOrientation === 'Vertical'
          ).parameters.zCutSettings.enabled
        },
        {
          title: 'Número de fases de Corte.',
          placeholder: 'basicGenerators.parameters.zCutSettings.maxDepth',
          disabled: true,
          onChange: '',
          obs1: 'Z1 equivale à 3, Z2 equivale à 4.',
          obs2: '',
          value: configData?.infoOtimizacao?.basicGenerators.find(
            item => item.cutOrientation === 'Vertical'
          ).parameters.zCutSettings.maxDepth
        },
        {
          title: 'Blocos distintos de corte Z.',
          placeholder:
            'basicGenerators.parameters.zCutSettings.maxZCutBlocksInStrip',
          disabled: true,
          onChange: '',
          obs1: 'No exemplo vemos 3 blocos distintos',
          obs2: <img className="width100p" src={Exemplo3} alt="exemplo 1" />,
          value: configData?.infoOtimizacao?.basicGenerators.find(
            item => item.cutOrientation === 'Vertical'
          ).parameters.zCutSettings.maxZCutBlocksInStrip
        },
        {
          title: 'Número de peças de encaixe exato para corte',
          placeholder:
            'basicGenerators.parameters.zCutSettings.minExactFitPieces',
          disabled: true,
          onChange: '',
          obs1: 'Toda faixa com corte Z deve ter altura definida por uma peça com encaixe exato. No exemplo vemos que a primeira peça azul define a altura da faixa.',
          obs2: <img className="width100p" src={Exemplo4} alt="exemplo 1" />,
          value: configData?.infoOtimizacao?.basicGenerators.find(
            item => item.cutOrientation === 'Vertical'
          ).parameters.zCutSettings.minExactFitPieces
        }
      ]
    },
    {
      title: 'Base de corte: Horizontal.',
      obs: '',
      fields: [
        {
          title: 'Número máximo de peças distintas numa mesma faixa',
          placeholder: 'basicGenerators.parameters.maxDistinctPartsPerStrip',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.infoOtimizacao?.basicGenerators.find(
            item => item.cutOrientation === 'Horizontal'
          ).parameters.maxDistinctPartsPerStrip
        },
        {
          title: 'Número máximo de faixas distintas num mesmo esquema',
          placeholder: 'basicGenerators.parameters.maxStripGroups',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value: configData?.infoOtimizacao?.basicGenerators.find(
            item => item.cutOrientation === 'Horizontal'
          ).parameters.maxStripGroups
        },
        {
          title: 'Corte Z habilitado',
          placeholder: 'basicGenerators.parameters.zCutSettings.enabled',
          disabled: true,
          onChange: '',
          obs1: 'Prejudica a otimização caso desativado.',
          obs2: '',
          value: configData?.infoOtimizacao?.basicGenerators.find(
            item => item.cutOrientation === 'Horizontal'
          ).parameters.zCutSettings.enabled
        },
        {
          title: 'Número de fases de Corte.',
          placeholder: 'basicGenerators.parameters.zCutSettings.maxDepth',
          disabled: true,
          onChange: '',
          obs1: 'Z1 equivale à 3, Z2 equivale à 4.',
          obs2: '',
          value: configData?.infoOtimizacao?.basicGenerators.find(
            item => item.cutOrientation === 'Horizontal'
          ).parameters.zCutSettings.maxDepth
        },
        {
          title: 'Blocos distintos de corte Z.',
          placeholder:
            'basicGenerators.parameters.zCutSettings.maxZCutBlocksInStrip',
          disabled: true,
          onChange: '',
          obs1: 'No exemplo vemos 3 blocos distintos',
          obs2: <img className="width100p" src={Exemplo3} alt="exemplo 1" />,
          value: configData?.infoOtimizacao?.basicGenerators.find(
            item => item.cutOrientation === 'Horizontal'
          ).parameters.zCutSettings.maxZCutBlocksInStrip
        },
        {
          title: 'Número de peças de encaixe exato para corte',
          placeholder:
            'basicGenerators.parameters.zCutSettings.minExactFitPieces',
          disabled: true,
          onChange: '',
          obs1: 'Toda faixa com corte Z deve ter altura definida por uma peça com encaixe exato. No exemplo vemos que a primeira peça azul define a altura da faixa.',
          obs2: <img className="width100p" src={Exemplo4} alt="exemplo 1" />,
          value: configData?.infoOtimizacao?.basicGenerators.find(
            item => item.cutOrientation === 'Horizontal'
          ).parameters.zCutSettings.minExactFitPieces
        }
      ]
    },
    {
      title: 'Pré-corte.',
      obs: <img className="width100p" src={Exemplo2} alt="exemplo 2" />,
      fields: [
        {
          title: 'Orientação do corte',
          placeholder: 'headCutGenerators.cutOrientation',
          disabled: true,
          onChange: '',
          obs1: 'Há poucos banefícios em fazer pré-cortes verticais, a maior vantagem é para máquinas com mesa rotante',
          obs2: '',
          value: configData?.infoOtimizacao?.headCutGenerators[0].cutOrientation
        },
        {
          title:
            'Número máximo de peças distintas numa mesma faixa da parte - Cabeça',
          placeholder:
            'headCutGenerators.headParameters.maxDistinctPartsPerStrip',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value:
            configData?.infoOtimizacao?.headCutGenerators[0].headParameters
              .maxDistinctPartsPerStrip
        },
        {
          title:
            'Número máximo de faixas distintas num mesmo esquema da parte - Cabeça',
          placeholder: 'headCutGenerators.headParameters.maxStripGroups',
          disabled: true,
          onChange: '',
          obs1: 'Prejudica a otimização caso desativado.',
          obs2: '',
          value:
            configData?.infoOtimizacao?.headCutGenerators[0].headParameters
              .maxStripGroups
        },
        {
          title: 'Habilita corte Z habilitado - Cabeça',
          placeholder: 'headCutGenerators.headParameters.zCutSettings.enabled',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value:
            configData?.infoOtimizacao?.headCutGenerators[0].headParameters
              .zCutSettings.enabled
        },
        {
          title: 'Tipo de corte - Cabeça',
          placeholder: 'headCutGenerators.headParameters.zCutSettings.maxDepth',
          disabled: true,
          onChange: '',
          obs1: '2 equivale à desabilitado, 3 equivale à Z1, não recomendamos corte Z2',
          obs2: '',
          value:
            configData?.infoOtimizacao?.headCutGenerators[0].headParameters
              .zCutSettings.maxDepth
        },
        {
          title:
            'Número máximo de peças distintas numa mesma faixa da parte - Principal',
          placeholder:
            'headCutGenerators.tailParameters.maxDistinctPartsPerStrip',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value:
            configData?.infoOtimizacao?.headCutGenerators[0].tailParameters
              ?.maxDistinctPartsPerStrip
        },
        {
          title:
            'Número máximo de faixas distintas num mesmo esquema da parte - Principal',
          placeholder: 'headCutGenerators.tailParameters.maxStripGroups',
          disabled: true,
          onChange: '',
          obs1: 'Prejudica a otimização caso desativado.',
          obs2: '',
          value:
            configData?.infoOtimizacao?.headCutGenerators[0].tailParameters
              ?.maxStripGroups
        },
        {
          title: 'Habilita corte Z habilitado - Principal',
          placeholder: 'headCutGenerators.tailParameters.zCutSettings.enabled',
          disabled: true,
          onChange: '',
          obs1: '',
          obs2: '',
          value:
            configData?.infoOtimizacao?.headCutGenerators[0].tailParameters
              ?.zCutSettings.enabled
        },
        {
          title: 'Tipo de corte - Principal',
          placeholder: 'headCutGenerators.tailParameters.zCutSettings.maxDepth',
          disabled: true,
          onChange: '',
          obs1: '2 equivale à desabilitado, 3 equivale à Z1, não recomendamos corte Z2',
          obs2: '',
          value:
            configData?.infoOtimizacao?.headCutGenerators[0].tailParameters
              ?.zCutSettings.enabled
        }
      ]
    }
  ]

  const handleChangeAccordions = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    if (userLogado.perfilOperacional.includes('Diretor Geral')) {
      setUsuarioPodeAlterarCanaletado(true)
    } else {
      setUsuarioPodeAlterarCanaletado(false)
    }
  }, [userLogado])

  useEffect(() => {
    async function buscaLojas() {
      const response = await api.core.apiListarLojas()

      if (response.fail) throw new Error()

      const todasLojas = response.data
        .filter(loja => loja.empresa === userLogado?.loja?.empresa?.idErp)
        .map(loja => ({
          label: `${loja.idErp} - ${loja.nomeEmpresa}`,
          value: loja.idErp
        }))

      const lojasCanaletar = todasLojas.filter(loja =>
        configData?.empresaLojasCanaletado?.includes(loja.value)
      )

      const lojasFuracaoUsinagem = todasLojas.filter(loja =>
        configData?.empresaLojasFuracaoUsinagem?.includes(loja.value)
      )

      setLojas(todasLojas)
      setLojasPermitemCanaletar(lojasCanaletar)
      setLojasPermitemFuracaoUsinagem(lojasFuracaoUsinagem)
    }

    buscaLojas()
  }, [])

  return (
    <>
      {value === index && (
        <div className="py-4">
          {[...optionsStore, ...optionsMac, ...optionsOpt].map(form => {
            return (
              <Accordion
                expanded={expanded === form.title}
                onChange={handleChangeAccordions(form.title)}
                key={form.title}
                className={
                  isMobile ? 'leoExpansionPanelMobile' : 'leoExpansionPanel'
                }
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>{form.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={isMobile ? 'row' : 'row py-4 px-8'}>
                    {form.fields.map(option => {
                      return (
                        <div key={option.title} className="col-12 col-md-4 p-3">
                          {option.type === 'switch' ? (
                            <FormControlLabel
                              control={
                                <Switch
                                  disabled={option.disabled}
                                  color="primary"
                                  className="tabela-pecas-ignorarveio"
                                  checked={option.value}
                                  onChange={
                                    option.onChange
                                      ? option.onChange
                                      : () => null
                                  }
                                />
                              }
                              label={option.title}
                              className="w-100"
                            />
                          ) : option.type === 'select' ? (
                            <SelectCheckbox
                              label={option.title}
                              variant="filled"
                              disabled={option.disabled}
                              items={lojas}
                              selectedValue={option.selectedValue}
                              onChangeSelectedValues={option.onChange}
                            />
                          ) : (
                            <TextField
                              label={option.title}
                              variant="filled"
                              pattern="^\d+(?:\.\d{1.2})?$"
                              type={option.onChange ? 'number' : 'text'}
                              step=".01"
                              value={option.value}
                              inputProps={{
                                inputMode: option.onChange ? 'numeric' : 'text'
                              }}
                              error={option.onChange ? option.value < 0 : false}
                              disabled={option.disabled}
                              onChange={
                                option.onChange ? option.onChange : () => null
                              }
                            />
                          )}

                          <p>{option.obs1 ? '* ' + option.obs1 : ''}</p>
                          <p>{option.obs2 ? '* ' + option.obs2 : ''}</p>
                        </div>
                      )
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      )}
    </>
  )
}
