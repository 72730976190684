import loadImg from '@asset/img/load-100.gif'
import { useEffect, useState } from 'react'

function Auth0({ children }) {
  const [hasToken, setHasToken] = useState()

  const token = sessionStorage.getItem('AUTH0_TOKEN')

  useEffect(() => {
    if (window.location.origin.includes('localhost')) {
      const tokenLocal = ''
      sessionStorage.setItem('AUTH0_TOKEN', tokenLocal)
      setHasToken(true)
    } else {
      window.addEventListener('message', function (event) {
        if (event?.data?.startsWith('ey')) {
          sessionStorage.setItem('AUTH0_TOKEN', event.data)
          setHasToken(true)
        }
      })

      const timer = setInterval(() => {
        window.parent.postMessage('getToken', '*')

        if (hasToken) {
          clearInterval(timer)
        }
      }, 1000)
    }
  }, [])

  if (hasToken) return children

  if (token?.startsWith('ey') && !hasToken) setHasToken(true)

  return (
    <div className="flex flex-col text-center h-screen justify-center">
      <h5>Autenticando... </h5>

      <div className="m-1 mx-auto">
        <img src={loadImg} alt="load" />
      </div>
    </div>
  )
}

export default Auth0
