import { Accordion, AccordionDetails } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction
} from '@store/actions/snackbarActions.js'
import React, { useState, Fragment, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { useDispatch } from 'react-redux'
import { makeStyles } from 'tss-react/mui'

import { useProjeto } from '@hook/useProjeto'
import { useUser } from '@hook/useUser.js'

import InfoMsg from '@component/InfoMsg/InfoMsg.js'
import PageTitle from '@component/PageTitle/PageTitle.js'

import api from '@service/api'
import FormatObj from '@service/serviceFormat.js'
import Utils from '@service/serviceUtils.js'

import DialogBordeamentoLote from '../Dialogs/DialogBordeamentoLote.js'
import DialogDelAmbiente from '../Dialogs/DialogDelAmbiente.js'
import DialogEditAmbiente from '../Dialogs/DialogEditAmbiente.js'
import DialogLimparAmbiente from '../Dialogs/DialogLimparAmbiente.js'
import HeaderAmbiente from './HeaderAmbiente.js'
import ImportacaoAmbiente from './ImportacaoAmbiente.js'
import MenuAmbientesOptions from './MenuAmbienteOptions.js'
import MenuCopyPeca from './MenuCopyPeca.js'
import TabelaAmbientes from './TabelaAmbientes.js'

const useStyles = makeStyles()(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const Ambientes = () => {
  const { classes } = useStyles()

  const dispatch = useDispatch()
  const { user, loja } = useUser()
  const {
    configLoja,
    projeto,
    changeProjeto,
    planEdtPecaBordaTotal,
    planEdtPeca,
    setTargetAmbienteImport
  } = useProjeto()
  const showMsgW = msg => dispatch(showMsgWAction(msg))
  const showMsgE = msg => dispatch(showMsgEAction(msg))
  const showMsgS = msg => dispatch(showMsgSAction(msg))
  const [openModalConfigBorda, setOpenModalConfigBorda] = useState(false)
  const handleOpenModalConfigBorda = ambIndex => {
    setAnchorElPeca(null)
    const fitas = projeto.projetoProduto.filter(x => x.tipoProdutoId === 2)
    if (fitas.length === 0) {
      showMsgW('Não ha fitas incluidas neste projeto')
      return false
    }
    const descricao = projeto?.projetoAmbiente[ambIndex]?.descricao
      ? projeto?.projetoAmbiente[ambIndex]?.descricao
      : projeto?.projetoAmbiente[0]?.descricao
    setOpenModalConfigBorda(true)
    setIndexAmbiente(ambIndex)
    setAmbienteName(descricao)
  }
  const handleCloseModalConfigBorda = () => {
    setOpenModalConfigBorda(false)
  }
  const [openModalLimparAmb, setOpenModalLimparAmb] = useState(false)
  const handleOpenModalLimparAmb = () => {
    setOpenModalLimparAmb(true)
  }
  const handleCloseModalLimparAmb = () => {
    setAnchorElAmb(null)
    setOpenModalLimparAmb(false)
  }
  const [openModalExcluirAmb, setOpenModalExcluirAmb] = useState(false)
  const handleOpenModalExcluirAmb = () => {
    setOpenModalExcluirAmb(true)
  }
  const handleCloseModalExcluirAmb = () => {
    setAnchorElAmb(null)
    setOpenModalExcluirAmb(false)
  }

  const [openModalNomeAmbiente, setOpenModalNomeAmbiente] = useState(false)
  const handleOpenModalNomeAmbiente = () => {
    setOpenModalNomeAmbiente(true)
  }
  const handleCloseModalNomeAmbiente = () => {
    setOpenModalNomeAmbiente(false)
  }

  const [openModalImportacao, setOpenModalImportacao] = useState(false)
  const handleOpenModalImportacao = () => {
    setTargetAmbienteImport(projeto.projetoAmbiente[indexAmbiente])
    setOpenModalImportacao(true)
  }
  const handleCloseModalImportacao = () => {
    setOpenModalImportacao(false)
    setTargetAmbienteImport(null)
  }

  const [openBkdrop, setOpenBkdrop] = useState(false)
  const closeBkdrop = () => {
    setOpenBkdrop(false)
  }
  const bkdropOpen = () => {
    setOpenBkdrop(true)
  }
  const [forceValidateAllPecas, setForceValidateAllPecas] = useState(0)
  const [copyPecaRef, setCopyPecaRef] = useState('')
  const [copyPecaInfo, setCopyPecaInfo] = useState({})

  const [indexAmbiente, setIndexAmbiente] = useState('')
  const [anchorElAmb, setAnchorElAmb] = useState(null)
  const [anchorElPeca, setAnchorElPeca] = useState(null)
  const [ambienteName, setAmbienteName] = useState('')

  const [expandedAmb, setExpandedAmb] = useState(0)

  const limiteMinimoAlturaLargura = configLoja?.minimoChapaCorte
  const limiteMinimoMaquina =
    configLoja?.configuracoesOtimizacao?.minStripHeight
  const limiteCorteBorda = configLoja?.minimoChapaPermiteFita
  const limiteCorteBordaOposto =
    configLoja?.minChapaPermiteFitaLadoOposto <=
    configLoja?.minimoChapaPermiteFita
      ? configLoja?.minChapaPermiteFitaLadoOposto
      : configLoja?.minimoChapaPermiteFita
  const limiteEspessura = configLoja?.limiteEspessuraBordeamento
  let limiteMaximoAltura = 0
  let limiteMaximoLargura = 0

  const alterarAmbientePopup = () => {
    setAmbienteName(projeto.projetoAmbiente[indexAmbiente].descricao)
    setIndexAmbiente(indexAmbiente)
    setAnchorElAmb(null)
    handleOpenModalNomeAmbiente()
  }

  const handleInverterPecasAmbiente = () => {
    const indexAmbienteExpanded = indexAmbiente || expandedAmb
    setAnchorElAmb(null)
    planEdtPeca(0, Number(indexAmbienteExpanded), 0, 'inverterAllMedidas')
    showMsgS(
      `A medida de todas a peças do ${projeto.projetoAmbiente[indexAmbienteExpanded].descricao} ambiente foram invertidas`
    )
    setForceValidateAllPecas(forceValidateAllPecas + 1)
  }

  const copyPeca = () => {
    setAnchorElPeca(null)
    let indexes
    if (!copyPecaRef) {
      showMsgW('Não foi possível copiar os dados da peça')
      return false
    } else {
      indexes = copyPecaRef.split('-')
      if (!indexes[1] || !indexes[2]) {
        showMsgW('Não foi possível copiar os dados da peça')
        return false
      }
    }
    setCopyPecaInfo(projeto.projetoAmbiente[indexes[1]].projetoPeca[indexes[2]])
  }
  const pastePeca = () => {
    setAnchorElPeca(null)
    let indexes

    if (copyPecaRef) {
      indexes = copyPecaRef.split('-')
      if (!indexes[1] || !indexes[2]) {
        showMsgW('Não foi possível copiar os dados da peça')
        return false
      }
    } else {
      showMsgW('Não foi possível copiar os dados da peça')
      return false
    }

    if (copyPecaInfo.id) {
      const pecaSecundariaId =
        copyPecaInfo?.projetoPecaServico[0]?.projetoPecaSecundariaId

      if (pecaSecundariaId) {
        copyPecaSecundaria(copyPecaInfo, pecaSecundariaId, indexes[1])
      } else {
        addNewPeca(projeto.projetoAmbiente[indexes[1]].id, [indexes[1]], {
          ...copyPecaInfo
        })
      }
    } else {
      showMsgW('Não ha nenhuma peça copiada')
      return false
    }
  }
  const duplicarAmbiente = async () => {
    try {
      const response = await trackPromise(
        api.leoplan.apiDuplicarAmbiente(
          projeto.projetoAmbiente[indexAmbiente].id
        )
      )

      if (
        response.fail ||
        response?.data?.length === 0 ||
        !response?.data[0]?.id
      ) {
        throw new Error()
      }

      const ambiente = {
        id: response.data[0].id,
        descricao: response.data[0].descricao,
        projetoId: response.data[0].projetoId,
        projetoPeca: response.data[0].projetoPeca
      }

      const novaListaAmbientes = projeto.projetoAmbiente
      novaListaAmbientes.splice(indexAmbiente + 1, 0, ambiente)
      changeProjeto('projetoAmbiente', novaListaAmbientes)
    } catch {
      showMsgE('Não foi possível duplicar o ambiente')
    } finally {
      setAnchorElAmb(null)
    }
  }

  const clickAmbExpand = panel => (event, isExpanded) => {
    setExpandedAmb(isExpanded ? panel : false)
  }

  const openAmbienteOption = event => {
    const indexAmbiente = event.currentTarget.id.split('-')
    setIndexAmbiente(indexAmbiente[indexAmbiente.length - 1])
    setAnchorElAmb(event.currentTarget)
  }
  const addNewPeca = async (ambId, ambIndex, copy) => {
    try {
      bkdropOpen()

      const mdfs = projeto.projetoProduto.filter(x => x.tipoProdutoId === 1)
      const fitas = projeto.projetoProduto.filter(x => x.tipoProdutoId === 2)
      let peca = {}
      const indexUltimaPeca =
        projeto.projetoAmbiente[ambIndex].projetoPeca.length - 1

      if (projeto.projetoAmbiente[ambIndex].projetoPeca.length > 0 && !copy) {
        const ultimaPeca =
          projeto.projetoAmbiente[ambIndex].projetoPeca[indexUltimaPeca]

        peca = FormatObj.setNewPecaCopy(ambId, projeto.id, ultimaPeca)
        if (projeto.tipoCorte === 2) {
          peca.altura = 0
        } else if (projeto.tipoCorte === 3) {
          peca.largura = 0
        } else {
          const veioMaterial = projeto.projetoProduto.find(
            material =>
              material.id.toString() === ultimaPeca.materialId.toString()
          )?.veio

          peca.altura = 0
          peca.largura = 0
          peca.veio = veioMaterial
        }
        peca.quantidade = 0
        peca.descricao = ''
        peca.direitaMaterialId = 0
        peca.esquerdaMaterialId = 0
        peca.inferiorMaterialId = 0
        peca.superiorMaterialId = 0
      } else {
        peca = FormatObj.setNewPeca(ambId, projeto.id)

        // Se projeto tiver apenas um MDF, já selecionar o mesmo na peça
        if (mdfs.length === 1) {
          peca.materialId = mdfs[0].id
          peca.veio = mdfs[0]?.veio

          if (projeto.tipoCorte === 2) {
            peca.largura = mdfs[0].largura * 10
          }

          if (projeto.tipoCorte === 3) {
            peca.altura = mdfs[0].altura * 10
          }

          // Se projeto tiver apenas uma fita, já selecionar a mesma na peça
          if (fitas.length === 1) {
            peca.pecaProduto = [{ projetoProdutoId: fitas[0].id }]
          }
        }
      }

      if (copy) {
        peca = {
          ...copy,
          pecaProduto: copy.pecaProduto.map(produto => {
            return {
              projetoProdutoId: produto.projetoProdutoId
            }
          })
        }
        delete peca.id
      }

      const response = await trackPromise(api.leoplan.apiAddPeca(peca))

      if (response.fail || !response?.data?.id) {
        throw new Error()
      }

      const novaPecaCriada = response.data

      if (indexUltimaPeca < 0 && fitas.length === 1 && mdfs.length === 1) {
        novaPecaCriada.pecaProduto = peca.pecaProduto
      } else if (indexUltimaPeca >= 0 && !copy) {
        novaPecaCriada.pecaProduto = peca.pecaProduto
      } else if (copy) {
        novaPecaCriada.pecaProduto = copy.pecaProduto.map(peca => {
          return {
            ...peca,
            projetoPecaId: response.data.id
          }
        })
      }

      const novaListaAmbientes = projeto.projetoAmbiente.map(
        (ambiente, index) => {
          if (index.toString() === ambIndex.toString()) {
            return {
              ...ambiente,
              projetoPeca: [
                ...ambiente.projetoPeca.map(peca => {
                  return { ...peca, open: false }
                }),
                { ...novaPecaCriada, open: true }
              ]
            }
          }

          return ambiente
        }
      )
      salvarGrupoPecasAoIncluirNovaPeca(false, novaListaAmbientes)
    } catch {
      showMsgE('Não foi possível adicionar a peça')
    } finally {
      closeBkdrop()
    }
  }

  const copyPecaSecundaria = async (
    pecaPrimaria,
    pecaSecundariaId,
    ambIndex
  ) => {
    try {
      bkdropOpen()

      const pecaSecundaria = Utils.buscarPecaPorId(
        projeto.projetoAmbiente,
        pecaSecundariaId
      )

      const payloadCopiaPecaPrimaria = {
        ...pecaPrimaria,
        id: 0,
        projetoPecaServico: [
          {
            ...pecaPrimaria.projetoPecaServico[0],
            id: 0,
            projetoPecaId: 0,
            projetoPecaSecundariaId: null,
            projetoPecaServicosHasTipoServicos: []
          }
        ]
      }

      const responsePrimaria = await trackPromise(
        api.leoplan.apiAddPeca(payloadCopiaPecaPrimaria)
      )

      if (responsePrimaria.fail) throw new Error()

      const payloadCopiaPecaSecundaria = {
        ...pecaSecundaria,
        id: 0,
        projetoPecaServico: [
          {
            ...pecaSecundaria.projetoPecaServico[0],
            id: 0,
            projetoPecaId: 0,
            projetoPecaPrimariaId: responsePrimaria.data.id,
            projetoPecaServicosHasTipoServicos: []
          }
        ]
      }

      const responseSecundaria = await trackPromise(
        api.leoplan.apiAddPeca(payloadCopiaPecaSecundaria)
      )

      if (responseSecundaria.fail) throw new Error()

      const pecaPrimariaAtualizada = {
        ...responsePrimaria.data,
        pecaProduto: pecaPrimaria.pecaProduto,
        projetoPecaServico: [
          {
            ...responsePrimaria.data.projetoPecaServico[0],
            projetoPecaSecundariaId: responseSecundaria.data.id
          }
        ]
      }

      const pecaSecundariaAtualizada = {
        ...responseSecundaria.data,
        pecaProduto: pecaSecundaria.pecaProduto
      }

      const novaListaAmbientes = projeto.projetoAmbiente
      novaListaAmbientes[ambIndex].projetoPeca = novaListaAmbientes[
        ambIndex
      ].projetoPeca.concat(pecaPrimariaAtualizada, pecaSecundariaAtualizada)

      salvarGrupoPecasAoIncluirNovaPeca(false, novaListaAmbientes)
    } catch {
      showMsgE('Não foi possível copiar a peça')
    } finally {
      closeBkdrop()
    }
  }

  const addPecaSecundaria = async (ambIndex, indexPeca, pecaPrimaria) => {
    try {
      let pecaSecundaria = FormatObj.setNewPecaCopy(
        pecaPrimaria.ambienteId,
        projeto.id,
        pecaPrimaria
      )

      pecaSecundaria.quantidade = 0
      pecaSecundaria.largura = 0
      pecaSecundaria.pecaProduto[0]
      pecaSecundaria.projetoPecaServico = pecaPrimaria.projetoPecaServico.map(
        servico => ({
          ativo: true,
          tipoServico: servico.tipoServico,
          sistemaFixacaoId: servico.sistemaFixacaoId,
          fixacaoConector: 2,
          projetoPecaPrimariaId: pecaPrimaria.id,
          payloadConfigurador: servico.payloadConfigurador || ''
        })
      )

      const response = await trackPromise(
        api.leoplan.apiAddPeca(pecaSecundaria)
      )

      if (response.fail || !response?.data?.id) {
        throw new Error()
      }

      const novaPecaCriada = response.data

      let novaListaAmbientes = projeto.projetoAmbiente

      novaListaAmbientes[ambIndex].projetoPeca[
        indexPeca
      ].projetoPecaServico[0].projetoPecaSecundariaId = novaPecaCriada.id

      novaListaAmbientes[ambIndex].projetoPeca.splice(
        indexPeca + 1,
        0,
        novaPecaCriada
      )

      salvarGrupoPecasAoIncluirNovaPeca(false, novaListaAmbientes)
    } catch {
      showMsgE('Não foi possível adicionar a peça secundária')
    }
  }

  const salvarGrupoPecasAoIncluirNovaPeca = (
    validation = false,
    novaListaAmbientes
  ) => {
    const baseProjeto = formataObjAllPecas(novaListaAmbientes)

    if (validation) {
      if (
        validForm('possuiPecas', baseProjeto) &&
        validForm('pecasEmBranco', baseProjeto) &&
        validForm('pecasFormatoValido', projeto.projetoAmbiente) &&
        validForm('fitasInvalidas', projeto.projetoAmbiente) &&
        validForm('fitasNaoAplicadas', projeto.projetoAmbiente)
      ) {
        savePecas()
      } else {
        savePecas()
        setForceValidateAllPecas(forceValidateAllPecas + 1)
      }
    } else {
      savePecas()
    }
    async function savePecas() {
      try {
        if (validation) bkdropOpen()

        const response = await trackPromise(
          api.leoplan.apiFinalizarProjeto(baseProjeto)
        )

        if (response.fail) throw new Error()

        changeProjeto('projetoAmbiente', novaListaAmbientes)
      } catch {
        showMsgE('Algum erro aconteceu ao tentar salvar o projeto')
      } finally {
        if (validation) closeBkdrop()
      }
    }
  }
  const formataObjAllPecas = novaListaAmbientes => {
    function pecaProduto(pProduto) {
      let pProdutoFormat = []
      if (pProduto.length > 0) {
        pProdutoFormat = pProduto
        pProdutoFormat.forEach(item => {
          if (item.id === null) {
            delete item.id
          }
        })
      }
      return pProduto
    }

    function getCanaletado(idMaterial) {
      return projeto.projetoProduto.find(m => m.id === idMaterial)?.canaletado
    }

    function getPeca(pecas) {
      const pecasFormat = []
      pecas.forEach(peca => {
        let veioPeca
        if (projeto.tipoCorte === 2 || projeto.tipoCorte === 3) {
          veioPeca = true
        } else {
          veioPeca = peca.veio
        }
        pecasFormat.push({
          id: peca.id,
          descricao: peca.descricao,
          largura: peca.largura,
          altura: peca.altura,
          quantidade: peca.quantidade,
          veio: veioPeca,
          materialId: peca.materialId,
          pecaProduto: pecaProduto(peca.pecaProduto),
          superiorMaterialId: peca.superiorMaterialId,
          inferiorMaterialId: peca.inferiorMaterialId,
          esquerdaMaterialId: peca.esquerdaMaterialId,
          direitaMaterialId: peca.direitaMaterialId,
          ambienteId: peca.ambienteId,
          canaletado: getCanaletado(peca.materialId),
          projetoPecaServico: Utils.updatePayloadConfigurador(peca, projeto)
        })
      })
      return pecasFormat
    }
    const baseProjeto = {
      projetoId: parseInt(projeto.id),
      ambientes: novaListaAmbientes
        ? novaListaAmbientes.map(a => {
            const ambiente = {
              id: a.id,
              descricao: a.descricao,
              projetoId: a.projetoId,
              pecas: getPeca(a.projetoPeca)
            }
            return ambiente
          })
        : projeto.projetoAmbiente.map(a => {
            const ambiente = {
              id: a.id,
              descricao: a.descricao,
              projetoId: a.projetoId,
              pecas: getPeca(a.projetoPeca)
            }
            return ambiente
          })
    }
    return baseProjeto
  }
  const validForm = function (id, obj, exibeAlert = true) {
    const errorFields = []
    let msgType = 0
    let ret = true

    if (id === 'novoProjeto') {
      if (obj.lojaIdErp === '' || obj.lojaIdErp === undefined) {
        ret = false
        msgType = 0
        errorFields.push('Loja')
      }
      if (obj.nomeVendedor === '' || obj.nomeVendedor === undefined) {
        ret = false
        msgType = 0
        errorFields.push('Vendedor')
      }
      if (obj.clienteIdErp === '' || obj.clienteIdErp === undefined) {
        ret = false
        msgType = 0
        errorFields.push('Cliente')
      }
      if (obj.nomeProjeto === '' || obj.nomeProjeto === undefined) {
        ret = false
        msgType = 0
        errorFields.push('Nome do Projeto')
      }
    }

    if (id === 'editarProjeto') {
      if (obj.nomeProjeto === '' || obj.nomeProjeto === undefined) {
        ret = false
        msgType = 0
        errorFields.push('Nome do Projeto')
      }
      if (obj.projetoId === '' || obj.projetoId === undefined) {
        ret = false
        msgType = 0
        errorFields.push('Projeto Id não encontrado')
      }
    }

    if (id === 'possuiPecas') {
      obj.ambientes.forEach(ambiente => {
        if (ambiente.pecas.length === 0) {
          ret = false
          msgType = 1
          errorFields.push(`${ambiente.descricao}`)
        }
      })
    }

    if (id === 'pecasEmBranco') {
      obj.ambientes.forEach(ambiente => {
        ambiente.pecas.forEach(peca => {
          if (
            peca.altura === 0 ||
            peca.largura === 0 ||
            peca.quantidade === 0
          ) {
            ret = false
            msgType = 2
            errorFields.push(`${ambiente.descricao}`)
          }
        })
      })
    }

    if (id === 'fitasNaoAplicadas') {
      let pecaNaoAplicada = false
      obj.forEach(ambiente => {
        ambiente.projetoPeca.forEach(peca => {
          peca.pecaProduto.forEach(produto => {
            if (
              produto.projetoProdutoId !== peca.direitaMaterialId &&
              produto.projetoProdutoId !== peca.esquerdaMaterialId &&
              produto.projetoProdutoId !== peca.inferiorMaterialId &&
              produto.projetoProdutoId !== peca.superiorMaterialId
            ) {
              const itemProduto = projeto.projetoProduto.filter(
                x => x.id === produto.projetoProdutoId
              )
              if (itemProduto.length > 0) {
                pecaNaoAplicada = true
              }
            }
          })
          if (pecaNaoAplicada) {
            ret = false
            msgType = 6
            errorFields.push(`${ambiente.descricao}`)
          }
        })
      })
    }

    if (id === 'fitasInvalidas') {
      const fitasIncluidasNoProjeto = projeto.projetoProduto.filter(
        x => x.tipoProdutoId === 2
      )

      obj.forEach(ambiente => {
        ambiente.projetoPeca.forEach(peca => {
          if (
            peca.superiorMaterialId ||
            peca.inferiorMaterialId ||
            peca.esquerdaMaterialId ||
            peca.direitaMaterialId
          ) {
            let temInconssistencia = false

            let superiorVinculoValido = false
            let inferiorVinculoValido = false
            let esquerdaVinculoValido = false
            let direitaVinculoValido = false

            fitasIncluidasNoProjeto.forEach((x, index) => {
              if (peca.superiorMaterialId !== 0) {
                if (peca.superiorMaterialId === x.id) {
                  superiorVinculoValido = true
                }
              } else {
                superiorVinculoValido = true
              }

              if (peca.inferiorMaterialId !== 0) {
                if (peca.inferiorMaterialId === x.id) {
                  inferiorVinculoValido = true
                }
              } else {
                inferiorVinculoValido = true
              }

              if (peca.esquerdaMaterialId !== 0) {
                if (peca.esquerdaMaterialId === x.id) {
                  esquerdaVinculoValido = true
                }
              } else {
                esquerdaVinculoValido = true
              }

              if (peca.direitaMaterialId !== 0) {
                if (peca.direitaMaterialId === x.id) {
                  direitaVinculoValido = true
                }
              } else {
                direitaVinculoValido = true
              }

              if (fitasIncluidasNoProjeto.length === index + 1) {
                if (
                  !superiorVinculoValido ||
                  !inferiorVinculoValido ||
                  !esquerdaVinculoValido ||
                  !direitaVinculoValido
                ) {
                  temInconssistencia = true
                }
              }
            })

            if (temInconssistencia) {
              ret = false
              msgType = 4
              errorFields.push(`${ambiente.descricao}`)
            }
          }
        })
      })
    }

    if (id === 'pecasFormatoValido') {
      let erroConfigLoja = false
      if (!configLoja.id || configLoja.id === 0) {
        ret = false
        msgType = 5
        erroConfigLoja = true
      }
      if (!erroConfigLoja) {
        obj.forEach(ambiente => {
          ambiente.projetoPeca.forEach(peca => {
            const chapaAtivaNaPeca = projeto.projetoProduto.filter(
              x => x.id === peca.materialId
            )

            if (chapaAtivaNaPeca.length > 0) {
              limiteMaximoAltura = Utils.retornaLimitMaxAltura(
                chapaAtivaNaPeca[0].altura,
                projeto.tipoCorte,
                configLoja.descontoMaximoAltura
              )
              limiteMaximoLargura = Utils.retornaLimitMaxLargura(
                chapaAtivaNaPeca[0].largura,
                projeto.tipoCorte,
                configLoja.descontoMaximoLargura
              )
            }

            if (configLoja) {
              if (peca.pecaProduto.length > 0) {
                if (chapaAtivaNaPeca[0].espessura < limiteEspessura / 10) {
                  ret = false
                  msgType = 3
                  errorFields.push(`${ambiente.descricao}`)
                }

                let tipeError = 'OK'

                tipeError = Utils.validaMedidaPecaFitagem(
                  peca.largura,
                  peca.altura,
                  limiteCorteBorda,
                  limiteCorteBordaOposto,
                  peca.inferiorMaterialId,
                  peca.superiorMaterialId,
                  peca.esquerdaMaterialId,
                  peca.direitaMaterialId
                )
                if (tipeError !== 'OK') {
                  ret = false
                  msgType = 3
                  errorFields.push(`${ambiente.descricao}`)
                }
              }
              if (
                !Utils.validarTamanhoPeca(
                  peca.altura,
                  limiteMinimoAlturaLargura,
                  limiteMaximoAltura
                )
              ) {
                ret = false
                msgType = 3
                errorFields.push(`${ambiente.descricao}`)
              }
              if (
                !Utils.validarTamanhoPeca(
                  peca.largura,
                  limiteMinimoAlturaLargura,
                  limiteMaximoLargura
                )
              ) {
                ret = false
                msgType = 3
                errorFields.push(`${ambiente.descricao}`)
              }
              if (
                peca.largura < limiteMinimoMaquina &&
                peca.altura < limiteMinimoMaquina
              ) {
                ret = false
                msgType = 3
                errorFields.push(`${ambiente.descricao}`)
              }
            }
          })
        })
      }
    }

    if (exibeAlert) {
      if (errorFields.length === 1 && msgType === 0) {
        showMsgW('O campo ' + errorFields[0] + ' é obrigatório.')
      }

      if (errorFields.length === 2 && msgType === 0) {
        showMsgW(
          'Os campos ' +
            errorFields[0] +
            ' e ' +
            errorFields[1] +
            ' são obrigatórios.'
        )
      }

      if (errorFields.length > 2 && msgType === 0) {
        let concatString = ''
        for (let n = 0; n < errorFields.length - 1; n++) {
          concatString += errorFields[n] + ', '
        }
        concatString = concatString.slice(0, concatString.lastIndexOf(','))
        concatString += ' e ' + errorFields[errorFields.length - 1]
        showMsgW('Os campos ' + concatString + ' são obrigatórios.')
      }

      if (msgType === 1) {
        showMsgW("O Ambiente '" + errorFields[0] + "' não possui peças")
      }

      if (errorFields.length > 2 && msgType === 1) {
        let concatString = ''
        for (let n = 0; n < errorFields.length - 1; n++) {
          concatString += errorFields[n] + ', '
        }
        concatString = concatString.slice(0, concatString.lastIndexOf(','))
        concatString += ' e ' + errorFields[errorFields.length - 1]
        showMsgW('Os Ambientes ' + concatString + ' não possuem peças.')
      }

      if (msgType === 2) {
        showMsgW(
          "O Ambiente '" +
            errorFields[0] +
            "' possui peças faltando informações."
        )
      }

      if (msgType === 3) {
        showMsgW(
          "O Ambiente '" +
            errorFields[0] +
            "' possui peças com informações inválidas."
        )
      }

      if (msgType === 4) {
        showMsgW(
          "O Ambiente '" +
            errorFields[0] +
            "' possui peças onde Não foi possível identificar a fita de uma das posições. Remarque a fitagem indicada em vermelho para prosseguir."
        )
      }

      if (msgType === 5) {
        showMsgW(
          'Não foi possível identificar as configurações de corte da loja, tente novamente mais tarde.'
        )
      }

      if (msgType === 6) {
        showMsgW(
          "O Ambiente '" +
            errorFields[0] +
            "' possui fita selecionada que não esta sendo usada."
        )
      }

      if (errorFields.length > 2 && msgType === 2) {
        let concatString = ''
        for (let n = 0; n < errorFields.length - 1; n++) {
          concatString += errorFields[n] + ', '
        }
        concatString = concatString.slice(0, concatString.lastIndexOf(','))
        concatString += ' e ' + errorFields[errorFields.length - 1]
        showMsgW(
          'Os Ambientes ' +
            concatString +
            ' possuem peças faltando informações.'
        )
      }
    }

    return ret
  }

  const removePeca = async (
    ambienteIndex,
    pecaId,
    pecaIndex,
    projetoPecaSecundariaId
  ) => {
    try {
      bkdropOpen()

      const response = await trackPromise(
        api.leoplan.apiDeletarPeca(pecaId, projeto.id)
      )

      if (response.fail) throw new Error()

      if (projetoPecaSecundariaId) {
        const responseSecundaria = await trackPromise(
          api.leoplan.apiDeletarPeca(projetoPecaSecundariaId, projeto.id)
        )

        if (responseSecundaria.fail) throw new Error()
      }

      const novaListaAmbientes = projeto.projetoAmbiente

      if (projetoPecaSecundariaId) {
        novaListaAmbientes[ambienteIndex].projetoPeca.splice(pecaIndex + 1, 1)
      }
      novaListaAmbientes[ambienteIndex].projetoPeca.splice(pecaIndex, 1)
      changeProjeto('projetoAmbiente', novaListaAmbientes)
    } catch {
      showMsgE('Não foi possível remover esta peça')
    } finally {
      closeBkdrop()
    }
  }

  const removePecaSecundaria = async (
    projetoPecaPrimariaId,
    projetoPecaSecundariaId
  ) => {
    try {
      bkdropOpen()

      const response = await trackPromise(
        api.leoplan.apiDeletarPeca(projetoPecaSecundariaId, projeto.id)
      )

      if (response.fail) throw new Error()

      const novaListaAmbientes = projeto.projetoAmbiente
        .map(ambiente => {
          return {
            ...ambiente,
            projetoPeca: ambiente.projetoPeca.filter(
              peca => peca.id.toString() !== projetoPecaSecundariaId.toString()
            )
          }
        })
        .map(ambiente => {
          return {
            ...ambiente,
            projetoPeca: ambiente.projetoPeca.map(peca => {
              if (peca.id.toString() === projetoPecaPrimariaId.toString()) {
                return {
                  ...peca,
                  projetoPecaServico: peca.projetoPecaServico?.length
                    ? [
                        {
                          ...peca.projetoPecaServico[0],
                          projetoPecaSecundariaId: null
                        }
                      ]
                    : []
                }
              }
              return peca
            })
          }
        })

      changeProjeto('projetoAmbiente', novaListaAmbientes)
    } catch {
      showMsgE('Não foi possível remover a peça secundária')
    } finally {
      closeBkdrop()
    }
  }

  const openPecaOption = event => {
    setCopyPecaRef(event.currentTarget.id)
    setAnchorElPeca(event.currentTarget)
  }
  const aplicarFitagemTodoAmbiente = (ambienteIndex, configFitagem) => {
    const novaListaAmbientes = projeto.projetoAmbiente
    novaListaAmbientes[ambienteIndex].projetoPeca.forEach(peca => {
      peca.direitaMaterialId = configFitagem.direitaMaterialId
      peca.esquerdaMaterialId = configFitagem.esquerdaMaterialId
      peca.inferiorMaterialId = configFitagem.inferiorMaterialId
      peca.superiorMaterialId = configFitagem.superiorMaterialId
      peca.pecaProduto = []

      if (peca.direitaMaterialId) {
        if (peca.pecaProduto.length > 0) {
          let found = false
          peca.pecaProduto.forEach(fita => {
            if (fita.projetoProdutoId === peca.direitaMaterialId) {
              found = true
            }
          })
          if (!found) {
            peca.pecaProduto.push({
              projetoPecaId: peca.id,
              projetoProdutoId: peca.direitaMaterialId
            })
          }
        } else {
          peca.pecaProduto.push({
            projetoPecaId: peca.id,
            projetoProdutoId: peca.direitaMaterialId
          })
        }
      }

      if (peca.esquerdaMaterialId) {
        if (peca.pecaProduto.length > 0) {
          let found = false
          peca.pecaProduto.forEach(fita => {
            if (fita.projetoProdutoId === peca.esquerdaMaterialId) {
              found = true
            }
          })
          if (!found) {
            peca.pecaProduto.push({
              projetoPecaId: peca.id,
              projetoProdutoId: peca.esquerdaMaterialId
            })
          }
        } else {
          peca.pecaProduto.push({
            projetoPecaId: peca.id,
            projetoProdutoId: peca.esquerdaMaterialId
          })
        }
      }

      if (peca.inferiorMaterialId) {
        if (peca.pecaProduto.length > 0) {
          let found = false
          peca.pecaProduto.forEach(fita => {
            if (fita.projetoProdutoId === peca.inferiorMaterialId) {
              found = true
            }
          })
          if (!found) {
            peca.pecaProduto.push({
              projetoPecaId: peca.id,
              projetoProdutoId: peca.inferiorMaterialId
            })
          }
        } else {
          peca.pecaProduto.push({
            projetoPecaId: peca.id,
            projetoProdutoId: peca.inferiorMaterialId
          })
        }
      }

      if (peca.superiorMaterialId) {
        if (peca.pecaProduto.length > 0) {
          let found = false
          peca.pecaProduto.forEach(fita => {
            if (fita.projetoProdutoId === peca.superiorMaterialId) {
              found = true
            }
          })
          if (!found) {
            peca.pecaProduto.push({
              projetoPecaId: peca.id,
              projetoProdutoId: peca.superiorMaterialId
            })
          }
        } else {
          peca.pecaProduto.push({
            projetoPecaId: peca.id,
            projetoProdutoId: peca.superiorMaterialId
          })
        }
      }
    })

    bkdropOpen()

    changeProjeto('projetoAmbiente', novaListaAmbientes)
    handleCloseModalConfigBorda()

    setTimeout(async function () {
      await salvarGrupoPecas()
    }, 1000)
  }
  const salvarGrupoPecas = (validation = false) => {
    bkdropOpen()
    const baseProjeto = formataObjAllPecas()
    checkIgnorarVeio()

    if (validation) {
      if (
        validForm('possuiPecas', baseProjeto, false) &&
        validForm('pecasEmBranco', baseProjeto, false) &&
        validForm('pecasFormatoValido', projeto.projetoAmbiente, false) &&
        validForm('fitasInvalidas', projeto.projetoAmbiente, false) &&
        validForm('fitasNaoAplicadas', projeto.projetoAmbiente, false)
      ) {
        savePecas()
      } else {
        savePecas()
        setForceValidateAllPecas(forceValidateAllPecas + 1)
      }
    } else {
      savePecas()
    }
    closeBkdrop()

    async function savePecas() {
      try {
        if (validation) bkdropOpen()

        const response = await trackPromise(
          api.leoplan.apiFinalizarProjeto(baseProjeto)
        )

        if (response.fail) throw new Error()

        changeProjeto('projetoAmbiente', response.data.projetoAmbiente)
        showMsgS('Projeto salvos com sucesso')
      } catch {
        showMsgE('Algum erro aconteceu ao tentar salvar o projeto')
      } finally {
        if (validation) closeBkdrop()
      }
    }
  }
  const checkIgnorarVeio = () => {
    const projectMaterials = projeto.projetoProduto.map(({ id, veio }) => {
      return { id, veio }
    })
    const hasIgnorarVeio =
      projeto.projetoAmbiente.reduce((acumulador, valorAtual) => {
        const veioIgnorado = valorAtual.projetoPeca.filter(peca => {
          const product = projectMaterials.find(
            material => material.id === peca.materialId
          )
          if (product) {
            if (product.veio !== peca.veio && peca.veio === true) {
              console.info('Veio Inconsistente', peca)
              peca.veio = false
            }
            return product.veio !== peca.veio
          } else return false
        })
        if (veioIgnorado.length) return acumulador + 1
        else return acumulador
      }, 0) > 0

    return hasIgnorarVeio
  }
  const alterarNomeAmbiente = async () => {
    try {
      if (!ambienteName) {
        showMsgW('Digite um nome para o ambiente')
        return
      }

      const response = await trackPromise(
        api.leoplan.apiEditarNomeAmbiente(
          projeto.projetoAmbiente[indexAmbiente].id,
          ambienteName
        )
      )

      if (response.fail) throw new Error()

      const novaListaAmbientes = projeto.projetoAmbiente
      novaListaAmbientes[indexAmbiente].descricao = ambienteName
      changeProjeto('projetoAmbiente', novaListaAmbientes)
      handleCloseModalNomeAmbiente()
    } catch {
      showMsgE('Não foi possível altera o ambiente')
    }
  }
  const limparAmbiente = async () => {
    try {
      handleCloseModalLimparAmb()
      const ambId = projeto.projetoAmbiente[indexAmbiente].id

      if (projeto.projetoAmbiente[indexAmbiente].projetoPeca.length === 0) {
        showMsgW('Este ambiente já está vazio')
        return false
      }

      bkdropOpen()

      const response = await trackPromise(
        api.leoplan.apiLimparAmbiente(projeto.id, ambId)
      )

      if (response.fail) throw new Error()

      const novaListaAmbientes = projeto.projetoAmbiente
      novaListaAmbientes[indexAmbiente].projetoPeca = []
      changeProjeto('projetoAmbiente', novaListaAmbientes)
      setAnchorElAmb(null)
    } catch {
      showMsgE('Não foi possível limpar este ambiente')
    } finally {
      closeBkdrop()
    }
  }

  const removeAmbiente = async () => {
    try {
      handleCloseModalExcluirAmb()

      if (projeto.projetoAmbiente.length <= 1) {
        setAnchorElAmb(null)
        showMsgW(
          'Você não pode remover todos os ambientes, deve existir pelo menos um'
        )
        return
      }

      const ambId = projeto.projetoAmbiente[indexAmbiente].id
      const response = await trackPromise(api.leoplan.apiDeletarAmbiente(ambId))

      if (response.fail) throw new Error()

      const novaListaAmbientes = projeto.projetoAmbiente
      novaListaAmbientes.splice(indexAmbiente, 1)
      changeProjeto('projetoAmbiente', novaListaAmbientes)
      setAnchorElAmb(null)
    } catch {
      showMsgE('Não foi possível remover este ambiente')
    }
  }

  const addNewLine = () => {
    addNewPeca(projeto.projetoAmbiente[expandedAmb].id, expandedAmb)
  }

  const editAmbientName = nome => {
    if (nome.length > 28) return
    setAmbienteName(nome)
  }

  return (
    <div>
      <DialogDelAmbiente
        openModalExcluirAmb={openModalExcluirAmb}
        handleCloseModalExcluirAmb={handleCloseModalExcluirAmb}
        removeAmbiente={removeAmbiente}
      />
      <DialogBordeamentoLote
        open={openModalConfigBorda}
        close={handleCloseModalConfigBorda}
        ambienteName={ambienteName}
        ambienteIndex={indexAmbiente}
        tipoCorte={projeto.tipoCorte}
        aplicarFitagemTodoAmbiente={aplicarFitagemTodoAmbiente}
      />
      <DialogEditAmbiente
        openModalNomeAmbiente={openModalNomeAmbiente}
        handleCloseModalNomeAmbiente={handleCloseModalNomeAmbiente}
        ambienteName={ambienteName}
        alterarNomeAmbiente={alterarNomeAmbiente}
        editAmbientName={editAmbientName}
      />
      <DialogLimparAmbiente
        open={openModalLimparAmb}
        close={handleCloseModalLimparAmb}
        action={limparAmbiente}
      />
      <ImportacaoAmbiente
        openModalImportacao={openModalImportacao}
        handleCloseModalImportacao={handleCloseModalImportacao}
      />
      <div className="md:pad20 md:padT10 md:padB0">
        <PageTitle title="Ambientes" />
      </div>

      {projeto.tipoCorte === 3 ? (
        <div className="pad20 padT10 padB0">
          <InfoMsg type="info" title="Plano em tiras Vertical" msg={''} />
        </div>
      ) : (
        projeto.tipoCorte === 2 && (
          <div className="pad20 padT10 padB0">
            <InfoMsg type="info" title="Plano em tiras Horizontal" />
          </div>
        )
      )}
      <div className="pad20 padT10 padB0"></div>
      <div>
        <div className="row">
          <div className="col-sm-12 px-8">
            <MenuAmbientesOptions
              tipoCorte={projeto.tipoCorte}
              anchorElAmb={anchorElAmb}
              closeAmbienteOption={() => setAnchorElAmb(null)}
              alterarAmbientePopup={alterarAmbientePopup}
              duplicarAmbiente={duplicarAmbiente}
              openModalExcluirAmb={handleOpenModalExcluirAmb}
              openModalLimparAmb={handleOpenModalLimparAmb}
              openModalImportPecas={handleOpenModalImportacao}
              openModalConfigBorda={handleOpenModalConfigBorda}
              inverterPecasAmbiente={handleInverterPecasAmbiente}
              indexAmbiente={indexAmbiente}
              planEdtPeca={planEdtPeca}
              showMsgS={showMsgS}
              forceValidateAllPecas={forceValidateAllPecas}
              setForceValidateAllPecas={setForceValidateAllPecas}
            />

            <MenuCopyPeca
              anchorElPeca={anchorElPeca}
              closePecaOption={() => setAnchorElPeca(null)}
              copyPeca={copyPeca}
              pastePeca={pastePeca}
            />
            {projeto.projetoAmbiente.length > 0 ? (
              <Fragment>
                {projeto.projetoAmbiente.map((ambiente, index) => {
                  return (
                    <Accordion
                      className={
                        isMobile
                          ? 'leoExpansionPanelMobile'
                          : 'leoExpansionPanel'
                      }
                      key={ambiente.id}
                      expanded={expandedAmb === index}
                      onChange={clickAmbExpand(index)}
                    >
                      {projeto?.tipoCorte !== 5 && (
                        <HeaderAmbiente
                          ambienteIndex={index}
                          ambienteDescricao={ambiente.descricao}
                          openAmbienteOption={openAmbienteOption}
                        />
                      )}

                      <AccordionDetails>
                        <div className="width100p">
                          {expandedAmb === index && (
                            <TabelaAmbientes
                              openBkdrop={openBkdrop}
                              indexAmbiente={index}
                              idAmbiente={ambiente.id}
                              vendedor={user}
                              loja={loja}
                              addNewPeca={addNewPeca}
                              addPecaSecundaria={addPecaSecundaria}
                              removePecaSecundaria={removePecaSecundaria}
                              pedidoId={projeto.pedidoId}
                              tipoCorte={projeto.tipoCorte}
                              pecas={ambiente.projetoPeca}
                              setForceValidateAllPecas={
                                setForceValidateAllPecas
                              }
                              forceValidateAllPecas={forceValidateAllPecas}
                              planEdtPeca={planEdtPeca}
                              planEdtPecaBordaTotal={planEdtPecaBordaTotal}
                              showMsgS={showMsgS}
                              showMsgW={showMsgW}
                              showMsgE={showMsgE}
                              removePeca={removePeca}
                              openPecaOption={openPecaOption}
                              modalBordaGeral={handleOpenModalConfigBorda}
                              inverterPecasAmbiente={
                                handleInverterPecasAmbiente
                              }
                              salvarGrupoPecas={salvarGrupoPecas}
                              addNewLine={addNewLine}
                            />
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </Fragment>
            ) : (
              <div className="pad20">Nenhum ambiente adicionado</div>
            )}
          </div>
        </div>
      </div>

      <Backdrop className={classes.backdrop} open={openBkdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
export default Ambientes
