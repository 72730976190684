import { Edit, MoreVert } from '@mui/icons-material'
import { FormControlLabel, IconButton, Menu, MenuItem } from '@mui/material'
import React, { useState, memo } from 'react'

export default memo(function OptionMenu({ actionEditar, key }) {
  const [anchorEl, setAnchorEl] = useState(HTMLElement > null)

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEditar = () => {
    handleClose()
    actionEditar()
  }

  return (
    <>
      <FormControlLabel
        className="btnTituloExpansivePanel"
        aria-label="Acknowledge"
        onClick={event => event.stopPropagation()}
        onFocus={event => event.stopPropagation()}
        control={
          <>
            <IconButton onClick={handleOpen} size="large">
              <MoreVert
                fontSize="small"
                className="m-0"
                onClick={handleOpen}
                onFocus={event => event.stopPropagation()}
              />
            </IconButton>
          </>
        }
      />
      <Menu
        key={key}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleEditar()}>
          <Edit className="mr-2" />
          Editar
        </MenuItem>
      </Menu>
    </>
  )
})
