import Add from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { useProjeto } from '@hook/useProjeto'

import PecaItemLine from './PecaItemLine.js'
import RowNewPeca from './RowNewPeca.js'

const TabelaAmbientes = props => {
  const [fitaFocus, setFitaFocus] = useState(false)

  const { projeto, planEdtPeca } = useProjeto()
  const tabNewPeca = pecaIndex => {
    if (props.pecas.length === pecaIndex + 1) {
      props.addNewPeca(props.idAmbiente, props.indexAmbiente)
    }
  }
  const telaAmbienteRef = useRef(null)
  const TAB_INDEX_LINE_FIELDS = 5
  const tabIndexInclude = props.pecas.length * TAB_INDEX_LINE_FIELDS + 1

  useEffect(() => {
    const handleTab = event => {
      if (event.key === 'Tab') {
        const allClosed = !props.pecas.find(peca => peca.open)
        if (allClosed) return planEdtPeca(true, 0, 0, 'open')

        if (fitaFocus) {
          const openPecaIndex = props.pecas.findIndex(peca => peca.open)

          setFitaFocus(false)

          if (props.pecas.length > openPecaIndex + 1) {
            planEdtPeca(true, 0, openPecaIndex + 1, 'open')
          }
        }
      }
    }
    document.addEventListener('keydown', handleTab)

    return () => {
      document.removeEventListener('keydown', handleTab)
    }
  })

  return (
    <div ref={telaAmbienteRef}>
      {props.pecas.length > 0 ? (
        <div className="mb-10">
          <div>
            <div aria-label="a dense table" className="tabela-pecas-ambiente">
              <div>
                {props.pecas.map((peca, indexPeca) => {
                  return (
                    <PecaItemLine
                      key={indexPeca}
                      peca={peca}
                      planEdtPeca={props.planEdtPeca}
                      indexAmbiente={props.indexAmbiente}
                      indexPeca={indexPeca}
                      openPecaOption={props.openPecaOption}
                      addPecaSecundaria={props.addPecaSecundaria}
                      removePecaSecundaria={props.removePecaSecundaria}
                      removePeca={props.removePeca}
                      tabNewPeca={tabNewPeca}
                      salvarGrupoPecas={props.salvarGrupoPecas}
                      tabIndex={indexPeca}
                      tabIndexLineFields={TAB_INDEX_LINE_FIELDS}
                      setFitaFocus={setFitaFocus}
                      fitaFocus={fitaFocus}
                      addNewLine={props.addNewLine}
                    />
                  )
                })}
                <RowNewPeca
                  pedidoId={props.pedidoId}
                  addNewPeca={props.addNewPeca}
                  idAmbiente={props.idAmbiente}
                  indexAmbiente={props.indexAmbiente}
                  tabIndex={tabIndexInclude}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div
              className={
                isMobile ? 'w-100' : 'col-sm-12 alert_ambientes_desktop'
              }
            >
              {!props.pedidoId && (
                <Alert severity="info">
                  <p className={isMobile ? '' : 'whitespace-nowrap'}>
                    Clique no botão a seguir para começar a incluir suas peças
                    de corte
                  </p>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={isMobile ? 'w-100 mt-2' : 'w-100 ml-2'}
                    disabled={projeto.origemProjeto === 3}
                    onClick={() =>
                      props.addNewPeca(props.idAmbiente, props.indexAmbiente)
                    }
                    tabIndex={tabIndexInclude}
                  >
                    <Add /> Incluir Peça
                  </Button>
                </Alert>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TabelaAmbientes
