import ImgMdfDefault from '@asset/img/mdf-defalut.PNG'
import { Clear } from '@mui/icons-material'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  TextField
} from '@mui/material'
import { isMobile } from 'react-device-detect'

import Utils from '@service/serviceUtils'

const imgBrokenMdf = event => {
  event.target.src = ImgMdfDefault
}

export const tableHeader = [
  null,
  'Qtd.',
  'Comp.',
  'Larg.',
  'Chapa',
  'Veio',
  'Distância entre canaletas (polegadas)',
  null
]

export const formatDataTableChapas = (
  materiais,
  ambientes,
  removeChapaCanaletada,
  atualizaQuantidade,
  tamanhosCanaletado,
  handleOpenModalCanaisPorChapa
) => {
  const getMaterialGroup = () => {
    return materiais
      .map(material => ({
        ...material,
        codigo: material.codigo.split('-', 1)[0]
      }))
      .reduce((list, sub) => list.concat(sub), [])
      .filter(
        (item, index, array) =>
          index === array.findIndex(i => i.codigo === item.codigo)
      )
  }

  const getPecasGroup = (projetoPeca, materialVerificar) => {
    return projetoPeca
      .map(peca => {
        const materialPeca = materiais.find(
          material => Number(material.id) === Number(peca.materialId)
        )

        if (!materialPeca) return

        if (
          materialVerificar.codigo.split('-', 1)[0] ===
          materialPeca.codigo.split('-', 1)[0]
        ) {
          return peca
        }
      })
      .filter(x => x)
  }

  const getGroupData = (
    pecas,
    material,
    tamanhosCanaletado,
    handleOpenModalCanaisPorChapa
  ) => {
    return pecas
      .map(peca => ({
        _id: peca.id,
        img: {
          data: (
            <span className="align-left">
              <img
                alt="mdf"
                onError={imgBrokenMdf}
                className="tableProdThumb"
                src={Utils.getImageUrl(material.codigo)}
              />
            </span>
          ),
          type: 'object'
        },
        qdt: {
          data: (
            <div className={isMobile ? 'w-3/4' : ''}>
              <FormControl>
                <TextField
                  size="small"
                  value={Number(peca.quantidade)}
                  error={Number(peca.quantidade) <= 0}
                  helperText={
                    peca.quantidade <= 0 && 'Digite um valor maior que zero'
                  }
                  InputProps={{ inputProps: { min: 1 } }}
                  inputProps={{ inputMode: 'numeric' }}
                  onChange={e => atualizaQuantidade(e.target.value, peca)}
                />
              </FormControl>
            </div>
          ),
          type: 'object'
        },
        comp: { data: peca.largura, type: 'number' },
        larg: { data: peca.altura, type: 'number' },
        chapa: {
          data: (
            <div className={isMobile ? 'w-3/4' : ''}>
              {`${material?.codigo}
              ${peca.canaletado ? `-${peca.canaletado}` : ''} - 
              ${material.descricao}`}
            </div>
          ),
          type: 'object'
        },
        veio: { data: peca.veio ? 'Sim' : 'Não' },
        distancia: {
          data: (
            <div className={isMobile ? 'w-3/4' : 'w-100'}>
              <FormControl variant="filled">
                <InputLabel id="parametros_label" htmlFor="filled-fileformat">
                  Distância entre canaletas (polegadas)
                </InputLabel>
                <Select
                  labelId="configuracoes_label"
                  variant="filled"
                  size="small"
                  error={peca.canaletado === 0}
                  value={peca.canaletado || ''}
                  onChange={e =>
                    handleOpenModalCanaisPorChapa(e.target.value, peca)
                  }
                >
                  {tamanhosCanaletado?.map(size => {
                    return (
                      <MenuItem key={size.canaletado} value={size.canaletado}>
                        {size.canaletado}
                      </MenuItem>
                    )
                  })}
                </Select>
                {peca.canaletado === 0 ? (
                  <FormHelperText>
                    Selecione a distância entre as canaletas
                  </FormHelperText>
                ) : (
                  ''
                )}
              </FormControl>
            </div>
          ),
          type: 'object'
        },
        botoes: {
          data: (
            <Tooltip title="Excluir Chapa" className="d-flex mb5">
              <IconButton
                className="danger"
                size="small"
                onClick={() => removeChapaCanaletada(peca)}
              >
                <Clear fontSize="small" />
              </IconButton>
            </Tooltip>
          ),
          type: 'object'
        }
      }))
      .filter(x => x)
  }

  if (!materiais?.length) return []
  if (!ambientes?.length) return []
  if (!ambientes[0]?.projetoPeca?.length) return []

  const materialGroup = getMaterialGroup()

  return materialGroup
    .map(material => {
      const pecas = getPecasGroup(ambientes[0].projetoPeca, material)

      if (!pecas?.length) return

      return {
        groupName: `${material.codigo} - ${material.descricao}`,
        groupData: getGroupData(
          pecas,
          material,
          tamanhosCanaletado,
          handleOpenModalCanaisPorChapa
        )
      }
    })
    .filter(x => x)
}
