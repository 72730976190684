import logoLeo from '@asset/img/logo_negativo.png'
import { HubConnectionBuilder } from '@microsoft/signalr'
import Button from '@mui/material/Button'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { Link } from 'react-router-dom'

import { appConfig } from '@config/env-config.js'

import { useUser } from '@hook/useUser.js'

import api from '@service/api'
const URL_SIGNALR = appConfig.REACT_APP_URL_SIGNALR

const initialLoadState = [
  {
    text: 'Carregando...',
    show: true,
    complete: false
  },
  {
    text: 'Otimização em fila',
    show: false,
    complete: false
  },
  {
    text: 'Processando plano de corte',
    show: false,
    complete: false
  },
  {
    text: 'Seu plano de corte esta sendo otimizado',
    show: false,
    complete: false
  },
  {
    text: 'Criando otimização do plano de corte',
    show: false,
    complete: false
  },
  {
    text: 'Verificando melhor otimização para seu projeto',
    show: false,
    complete: false
  },
  {
    text: 'Validando otimização',
    show: false,
    complete: false
  },
  {
    text: 'Otimização Concluida',
    show: false,
    complete: false
  }
]

const ItemLoadStatus = props => {
  return (
    <>
      {props.showStatus || props.complete ? (
        <li className="spinnerMsg">
          {props.complete ? (
            <span className="iconOtmizacaoSpinner complete"></span>
          ) : (
            <span className="iconOtmizacaoSpinner"></span>
          )}
          {props.children}
        </li>
      ) : null}
    </>
  )
}

const LoadOtimizacao = props => {
  const { load, buscarStatus } = props.loadOtimization
  const { errorOtimizacao, projetoId, getDetOtimizacao } = props

  const [loadStatus, setLoadStatus] = React.useState(initialLoadState)
  const timeOutAlert = false

  const [activeIndex, setActiveIndex] = React.useState(0)
  const [connection, setConnection] = React.useState(null)
  const { user } = useUser()
  const [loopMockStatus, setloopMockStatus] = React.useState(0)
  const [permitirPolling, setpermitirPolling] = React.useState(false)

  useEffect(() => {
    try {
      const newConnection = new HubConnectionBuilder()
        .withUrl(URL_SIGNALR)
        .withAutomaticReconnect()
        .build()

      setConnection(newConnection)
    } catch (e) {
      console.error('erro no signal', e)
      obterStatusPorApi()
    }
  }, [])

  useEffect(() => {
    alterarLoadStatus('load')
    const idExterno =
      user.tipoUserLeoPlan === 'cliente'
        ? user.idErp
        : user.clientePreSelecionado.idExterno
    const messageReciever = async () => {
      try {
        await connection.start()
        connection.invoke('JoinGroup', idExterno)
        connection.on('otimizacao_status', (group, message) => {
          const messageSerializada = JSON.parse(message)
          if (Number(messageSerializada.projetoId) === Number(projetoId)) {
            validarStatusOtimizacao(messageSerializada.status)
          }
        })
      } catch (e) {
        console.error('error conexao websocket: ', e)
        obterStatusPorApi()
      }
    }

    if (connection) messageReciever()
  }, [connection])

  const obterStatusPorApi = () => {
    setpermitirPolling(true)
  }

  useEffect(() => {
    async function buscaStatus() {
      const response = await trackPromise(
        api.leoplan.apiGetOtimizarcaoStatusGroup([projetoId])
      )

      if (response.fail) throw new Error()

      validarStatusOtimizacao(response?.data[0]?.status)
    }
    if (buscarStatus) buscaStatus()
  }, [buscarStatus])

  useEffect(() => {
    let intervalCall
    if (load && permitirPolling && buscarStatus) {
      intervalCall = setInterval(async () => {
        const response = await trackPromise(
          api.leoplan.apiGetOtimizarcaoStatusGroup([projetoId])
        )

        if (response.fail) throw new Error()

        const status = response?.data[0]?.status
        validarStatusOtimizacao(status)
      }, 4000)
    }
    return () => clearInterval(intervalCall)
  }, [permitirPolling, load, buscarStatus])

  function validarStatusOtimizacao(status) {
    if (status === 3) {
      alterarLoadStatus('concluido')
      getDetOtimizacao()
      setTimeout(function () {
        limparValores()
      }, 5000)
    } else if (status === 4) {
      limparValores()
      errorOtimizacao('Ocorreu um erro durante o processo de otimização.')
    } else {
      setActiveIndex(activeIndex + 1)
      alterarLoadStatus('processando')
    }
  }

  const limparValores = () => {
    alterarLoadStatus('reset')
    setloopMockStatus(0)
    setpermitirPolling(false)
  }

  function completarLoadStatus(etapa) {
    const newloadStatus = Object.assign([], loadStatus)
    for (var i = 0; i <= etapa; i++) {
      newloadStatus[i].complete = true
      newloadStatus[i].show = true
    }
    if (etapa < 6) newloadStatus[etapa + 1].show = true
    setLoadStatus(newloadStatus)
  }

  function resetarContador() {
    const newloadStatus = Object.assign([], loadStatus)
    newloadStatus[0].complete = false
    newloadStatus[1].complete = false
    newloadStatus[2].complete = false
    newloadStatus[3].complete = false
    newloadStatus[4].complete = false
    newloadStatus[5].complete = false
    newloadStatus[6].complete = false
    newloadStatus[7].complete = false

    newloadStatus[0].show = true
    newloadStatus[1].show = false
    newloadStatus[2].show = false
    newloadStatus[3].show = false
    newloadStatus[4].show = false
    newloadStatus[5].show = false
    newloadStatus[6].show = false
    newloadStatus[7].show = false
    setLoadStatus(newloadStatus)
  }

  function alterarLoadStatus(acao) {
    switch (acao) {
      case 'load':
        setLoadStatus(initialLoadState)
        break
      case 'fila':
        completarLoadStatus(0)
        break
      case 'processando':
        handleProcessingStatus()
        break
      case 'concluido':
        completarLoadStatus(7)
        break
      case 'reset':
        resetarContador()
        break
      default:
    }
  }

  const handleProcessingStatus = () => {
    setloopMockStatus(old => old + 1)
  }

  useEffect(() => {
    switch (loopMockStatus) {
      case 1:
        completarLoadStatus(1)
        break
      case 2:
        completarLoadStatus(2)
        break
      case 3:
        completarLoadStatus(3)
        break
      case 4:
        completarLoadStatus(4)
        break
      case 5:
        completarLoadStatus(5)
        break
      default:
    }
  }, [loopMockStatus])

  return (
    <>
      {load ? (
        <div className="spinnerBackGround" id="spinnerOtimizacao">
          <div className="spinnerLMOtimizacao">
            <div className="content">
              {!isMobile ? (
                <div>
                  <img
                    src={logoLeo}
                    style={{ maxHeight: '150px', maxWidth: '150px' }}
                    alt="Leo Madeiras"
                  />
                </div>
              ) : null}

              <div className="spinnerMsgContainer">
                <h2>Processamento de Otimização</h2>
                <ul>
                  <ItemLoadStatus
                    showStatus={loadStatus[0].show}
                    complete={loadStatus[0].complete}
                  >
                    {loadStatus[0].text}
                  </ItemLoadStatus>
                  <ItemLoadStatus
                    showStatus={loadStatus[1].show}
                    complete={loadStatus[1].complete}
                  >
                    {loadStatus[1].text}
                  </ItemLoadStatus>
                  <ItemLoadStatus
                    showStatus={loadStatus[2].show}
                    complete={loadStatus[2].complete}
                  >
                    {loadStatus[2].text}
                  </ItemLoadStatus>
                  <ItemLoadStatus
                    showStatus={loadStatus[3].show}
                    complete={loadStatus[3].complete}
                  >
                    {loadStatus[3].text}
                  </ItemLoadStatus>
                  <ItemLoadStatus
                    showStatus={loadStatus[4].show}
                    complete={loadStatus[4].complete}
                  >
                    {loadStatus[4].text}
                  </ItemLoadStatus>
                  <ItemLoadStatus
                    showStatus={loadStatus[5].show}
                    complete={loadStatus[5].complete}
                  >
                    {loadStatus[5].text}
                  </ItemLoadStatus>
                  <ItemLoadStatus
                    showStatus={loadStatus[6].show}
                    complete={loadStatus[6].complete}
                  >
                    {loadStatus[6].text}
                  </ItemLoadStatus>
                  <ItemLoadStatus
                    showStatus={loadStatus[7].show}
                    complete={loadStatus[7].complete}
                  >
                    {loadStatus[7].text}
                  </ItemLoadStatus>
                </ul>
                {timeOutAlert ? (
                  <p className="msgDelay">
                    A otimização esta demorando mais que o normal, mas fique
                    tranquilo estamos trabalhando nisso para você. Se não quiser
                    aguardar tente voltar daqui alguns minutos
                  </p>
                ) : (
                  <p className="mt20">
                    Este processo pode levar alguns minutos, se preferir você
                    pode voltar daqui a pouco que continuaremos trabalhando na
                    otimização para você.
                  </p>
                )}
                <Link to="/plano-de-corte/projetos/disponiveis">
                  <Button variant="contained" color="primary" className="mt10 ">
                    Voltar para lista de planos
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default LoadOtimizacao
