const Mask = {
  // mascara cpf
  cpf: v => {
    if (v) {
      return v
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }
    return v
  },

  // mascara cnpj
  cnpj: v => {
    if (v) {
      return v
        .replace(/\D/g, '') // Remove tudo o que não é dígito
        .replace(/^(\d{2})(\d)/, '$1.$2') // Coloca ponto entre o segundo e o terceiro dígitos
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Coloca ponto entre o quinto e o sexto dígitos
        .replace(/\.(\d{3})(\d)/, '.$1/$2') // Coloca uma barra entre o oitavo e o nono dígitos
        .replace(/(\d{4})(\d)/, '$1-$2') // Coloca um hífen depois do bloco de quatro dígitos
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }
    return v
  },

  cpfCnpj: v => {
    // Remove tudo o que não é dígito
    if (v) {
      v = v.toString()
      v = v.replace(/\D/g, '')

      if (v.slice(0, 1) === '0') v = v.slice(1)

      if (v.length <= 11) {
        // CPF
        v = v.padStart(11, 0)
        // Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, '$1.$2')

        // Coloca um ponto entre o terceiro e o quarto dígitos
        // de novo (para o segundo bloco de números)
        v = v.replace(/(\d{3})(\d)/, '$1.$2')

        // Coloca um hífen entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
      } else {
        // CNPJ
        v = v.padStart(14, 0)
        // Coloca ponto entre o segundo e o terceiro dígitos
        v = v.replace(/^(\d{2})(\d)/, '$1.$2')

        // Coloca ponto entre o quinto e o sexto dígitos
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')

        // Coloca uma barra entre o oitavo e o nono dígitos
        v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')

        // Coloca um hífen depois do bloco de quatro dígitos
        v = v
          .replace(/(\d{4})(\d)/, '$1-$2')

          // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
          .replace(/(-\d{2})\d+?$/, '$1')
      }
    }

    return v
  },

  // mascara cep
  cep: v => {
    if (v) {
      return v
        .replace(/\D/g, '') // Remove tudo o que não é dígito
        .replace(/^(\d{5})(\d)/, '$1-$2') // Esse é tão fácil que não merece explicações
        .replace(/(-\d{3})\d+?$/, '$1') // captura 3 numeros seguidos de um traço e não deixa ser digitado mais nada
    }
    return v
  },

  // marcara celular
  cel: v => {
    if (v) {
      return v
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, '($1) $2') // coloca () entre os dois primeiro caracteres
        .replace(/(\d{4})(\d)/, '$1-$2') // coloca - apos o 4 digito
        .replace(/(-\d{5})\d+?$/, '$1') // captura 5 numeros seguidos de um traço e não deixa ser digitado mais nada
    }
    return v
  },

  // permite apenas numeros
  number: v => {
    if (v) {
      return v
        .replace(/[^\d]/g, '') // permite apenas numeros
        .replace(/^(?:0+(?=[1-9])|0+(?=0$))/gm, '') // remove zero a esquerda
    }
    return v
  },

  // remove carcteres especiais
  noEspecialCaracter: v => {
    if (v) {
      // return v.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''); // remove totos os caracteres especiais
      return v
        .normalize('NFD')
        .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s._])/g, '') // remove todos os caracteres especiais menos o _
      // .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
      // .replace(/\-\-+/g, '-')// Substitui multiplos hífens por um único hífen
      // .replace(/(^-+|-+$)/, ''); // Remove hífens extras do final ou do inicio da string
    }
    return v
  },

  // mascara valor
  valor: v => {
    if (v) {
      v = v.replace(/\D/g, '') // Remove tudo o que não é dígito
      v = v.replace(/(\d)(\d{8})$/, '$1.$2') // coloca o ponto dos milhões
      v = v.replace(/(\d)(\d{5})$/, '$1.$2') // coloca o ponto dos milhares
      v = v.replace(/(\d)(\d{2})$/, '$1,$2') // coloca a virgula antes dos 2 últimos dígitos
    }

    return v
  },

  currency: v => {
    if (!v) v = 0
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(v)
  },

  unMask: v => {
    if (v) {
      return (
        v
          .replace(/\./g, '') // remove todos os .
          // .replace(/\-/g, "") // remove todos os -
          .replace(/\(/g, '') // remove todos os (
          .replace(/\)/g, '') // remove todos os )
          .replace(/\//g, '') // remove todos os /
          .replace(/\s/g, '')
      ) // remove todos os " "
    }
    return v
  },

  projectNameMask: v => {
    return v
      .normalize('NFD')
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s._])/g, '')
      .replace(/\s/g, '_')
      .replace(/\./g, '')
  }
}

export default Mask
