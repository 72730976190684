import { appConfig } from '@config/env-config.js'

import * as apiConfig from '@service/api/apiConfig.js'
import Utils from '@service/serviceUtils'
import Storage from '@service/storage'

const APILEOPLAN = appConfig.REACT_APP_APILEOPLAN
const APIMSG = appConfig.REACT_APP_APIMSG
const USERNAME = appConfig.REACT_APP_USERNAME
const USERPASS = appConfig.REACT_APP_USERPASS
const USERNAMESITE = appConfig.REACT_APP_USERNAMESITE
const USERPASSSITE = appConfig.REACT_APP_USERPASSSITE
const USERNAMEMSG = appConfig.REACT_APP_USERNAMEMSG
const USERPASSMSG = appConfig.REACT_APP_USERPASSMSG

const _getDateTime = async () => {
  try {
    const response = await apiConfig.sendWithAxios(
      `${APILEOPLAN}Leoplan/Login/ObterDataUTC`
    )

    if (response.status !== 200 && response.status !== 201) {
      throw new Error()
    }

    return response.data
  } catch (error) {
    console.error(error)
  }
}

// calcula o tempo de expiração do token e retorna uma data com esta experação baseada na data local do usuario
const _timeDiference = async (dataEx, dateN) => {
  const dataExformat = Utils.convertDateIsoToDate(dataEx)
  const dateNformat = Utils.convertDateIsoToDate(dateN)

  const diferenceTime = dataExformat.getTime() - dateNformat.getTime() + 3000

  const tzoffset = new Date().getTimezoneOffset() * 60000 // offset in milliseconds
  const localISOTime = new Date(Date.now() - tzoffset + diferenceTime)
    .toISOString()
    .slice(0, -1)

  return localISOTime
}

const _loginMsgQueue = async () => {
  const response = await apiConfig.sendWithAxios(
    `${APIMSG}validateUser`,
    'POST',
    { login: USERNAMEMSG, password: USERPASSMSG }
  )

  if (response.status > 300) throw new Error()

  return response.data
}

export const logar = async () => {
  const PATCH = Storage.get('LEOPLAN_PATCH_ORIGEM')
  let acessParams = {}

  if (PATCH === 'ECOM') {
    acessParams = {
      username: USERNAMESITE,
      password: USERPASSSITE
    }
  } else {
    acessParams = {
      username: USERNAME,
      password: USERPASS
    }
  }

  const result = await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Login`,
    'POST',
    JSON.stringify(acessParams),
    { 'Content-type': 'application/json' }
  )

  if (result.data.authenticated) {
    const dateNowAPI = await _getDateTime()
    const timeExpirate = await _timeDiference(
      result.data.expirationDate,
      dateNowAPI
    )

    result.data.expirationDateLocal = timeExpirate
    await Storage.set('LEOPLAN_USER_TOKEN', result.data)
    return result.data
  }
}

export const apiGetAgrupamento = async agrupamentoId => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/configuracao-material/agrupamento?agrupamentoId=${agrupamentoId}`,
    'GET'
  )
}

export const apiGetAgrupamentosPorLoja = async lojaId => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/configuracao-material/agrupamentos-por-loja?lojaId=${lojaId}`,
    'GET'
  )
}

export const apiCadastrarAgrupamento = async data => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/configuracao-material/cadastrar-agrupamento`,
    'POST',
    data
  )
}

export const apiAtualizarAgrupamento = async data => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/configuracao-material/atualizar-agrupamento`,
    'PUT',
    data
  )
}

export const apiCadastrarMaterialAgrupamento = async data => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/configuracao-material/cadastrar-material-agrupamento`,
    'POST',
    data
  )
}

export const apiDeletarMaterialAgrupamento = async (
  materialId,
  agrupamentoId
) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/configuracao-material/deletar-material-agrupamento?materialId=${materialId}&agrupamentoId=${agrupamentoId}`,
    'DELETE'
  )
}

export const apiGetConfiguracoesParametros = async () => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/configuracao-material/configuracoes-parametros-agrupamento`,
    'GET'
  )
}

export const apiCadastrarParametroPorAgrupamento = async data => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/configuracao-material/cadastrar-parametro-agrupamento`,
    'POST',
    data
  )
}

export const apiAtualizarParametroPorAgrupamento = async data => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/configuracao-material/atualizar-parametro-agrupamento`,
    'PUT',
    data
  )
}

export const apiDeletarParametroPorAgrupamento = async (
  parametroId,
  agrupamentoId
) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/configuracao-material/deletar-parametro-agrupamento?parametroId=${parametroId}&agrupamentoId=${agrupamentoId}`,
    'DELETE'
  )
}

export const apiGetConfigdata = async (lojaIdErp, empresaIdErp) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/ConfiguracoesPlanoCorte/obter-configuracao-loja?lojaIdErp=${lojaIdErp}&empresaIdErp=${empresaIdErp}`,
    'GET'
  )
}

export const apiPostConfigdata = async data => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/ConfiguracoesPlanoCorte/atualizar-configuracao-loja`,
    'PUT',
    data
  )
}

export const apiObterProjetosPorCliente = async (
  clienteleo,
  empresaIdErp,
  lojaIdErp,
  ativo,
  historico
) => {
  let queryAdicionais = ''
  if (empresaIdErp !== undefined) {
    queryAdicionais += `&empresaIdErp=${empresaIdErp}`
  }
  if (lojaIdErp !== undefined) {
    queryAdicionais += `&lojaIdErp=${lojaIdErp}`
  }
  if (ativo !== undefined) {
    queryAdicionais += `&ativo=${ativo}`
  }
  if (historico !== undefined) {
    queryAdicionais += `&historico=${historico}`
  }
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Projeto/obter-por-cliente?clienteLeo=${clienteleo}${queryAdicionais}`,
    'GET'
  )
}

export const apiClonarPlano = async (projectId, nome) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Projeto/Clonar/${projectId}/${nome}`,
    'GET'
  )
}

export const apiProjetoAtivarDesativar = async (projetoId, ativo) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}LeoPlan/Projeto/AtivarDesativar/${projetoId}/${ativo}`,
    'PUT'
  )
}

export const apiSalvarNomeProjeto = async (projectId, nome) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/projeto/nomeProjeto/${projectId}/${nome}`,
    'PUT'
  )
}

export const apiGetOtimizacao = async projectId => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Otimizador/projeto/${projectId}`,
    'GET'
  )
}

export const apiOtimizarGetFiles = async (
  tipo = 'Sumario',
  otimizaId = undefined,
  otimizaIndex = undefined,
  fileType = undefined,
  idProjeto = undefined,
  fileName = 'file'
) => {
  if (otimizaId && otimizaIndex && idProjeto) {
    let files = ['xml']
    let rotaUrl = `Leoplan/Otimizador/arquivo/${otimizaId}/${otimizaIndex}/${fileName}?bNomeSAP=false`
    if (fileType) {
      if (fileType === 'acad') {
        files = ['ac', 'ad']
      } else {
        files = [fileType]
      }
    }
    if (fileType === 'pdf') {
      if (tipo === 'Sumario') {
        rotaUrl = `Leoplan/Otimizador/arquivoPDF/${tipo}/${otimizaId}/${otimizaIndex}/${fileName}/${idProjeto}`
      } else {
        rotaUrl = `Leoplan/Otimizador/arquivoPDF/${tipo}/${otimizaId}/${otimizaIndex}/${fileName}`
      }
    }
    return await apiConfig.sendWithAxiosBlob(
      `${APILEOPLAN}${rotaUrl}`,
      'POST',
      files
    )
  }
}

export const apiCriarProjeto = async data => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Projeto`,
    'POST',
    data
  )
}

export const apiEditarProjeto = async data => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Projeto`,
    'PUT',
    data
  )
}

export const apiDeletarProjetoProduto = async materialId => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/ProjetoProduto/DeletarPorProjetoProduto/${materialId}`,
    'DELETE'
  )
}

export const apiDeletarAmbiente = async ambId => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Ambiente/${ambId}`,
    'DELETE'
  )
}

export const apiDeletarPeca = async (pecaId, projetoId) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Peca/${pecaId}/${projetoId}`,
    'DELETE'
  )
}

export const apiGetProjetoDetAsync = async projetoId => {
  const response = await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Projeto/ObterCompletoPorId/${projetoId}`,
    'GET'
  )

  if (!response.fail) {
    const { data } = response

    return {
      ...response,
      data: {
        ...data,
        projeto: {
          ...data.projeto,
          projetoAmbiente: Utils.reordenarPecasPrimariasSecundarias(
            data.projeto.projetoAmbiente
          )
        }
      }
    }
  }
}

export const apiLimparAmbiente = async (projetoId, ambId) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Ambiente/ApagarPecas/${projetoId}/${ambId}`,
    'POST'
  )
}

export const apiGetConfigLoja = async (lojaIdErp, empresaIdErp) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/ConfiguracoesPlanoCorte/ObterConfiguracoesPorLoja/${lojaIdErp}/${empresaIdErp}`,
    'GET'
  )
}

export const apiGetProjetosVendedorPorCliente = async (tipo, userEmail) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Projeto/ObterLista${tipo}VendedorPorClienteLeo/${userEmail}`,
    'GET'
  )
}

export const apiFinalizarProjeto = async data => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Projeto/Finalizar`,
    'POST',
    data
  )
}

export const apiOtimizar = async (id, lojaId, empresaId) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/PlanoCorte/Assincrono/IniciarOtimizacao/${id}/${lojaId}/${empresaId}`,
    'POST'
  )
}

export const apiUploadFile = async data => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Arquivo/UploadPecas`,
    'POST',
    data,
    { Accept: 'application/json, text/plain, */*' }
  )
}

export const apiEditarNomeAmbiente = async (ambId, nome) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Ambiente/${ambId}/${nome}`,
    'PUT'
  )
}

export const apiEditarLojaVendedorProjeto = async (
  projId,
  lojaIdErp,
  userEmail
) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Projeto/${projId}/${lojaIdErp}/${userEmail}`,
    'PUT'
  )
}

export const apiVincularPedido = async (projId, pedidoId) => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Projeto/${projId}?pedidoId=${pedidoId}`,
    'PUT'
  )
}

export const apiNovoAmbiente = async newAmbiente => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Ambiente`,
    'POST',
    newAmbiente
  )
}

export const apiAddProjetoProduto = async produto => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/ProjetoProduto`,
    'POST',
    produto
  )
}

export const apiUpdateProjetoProduto = async peca => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/ProjetoProduto`,
    'PATCH',
    peca
  )
}

export const apiDuplicarAmbiente = async ambId => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Ambiente/Duplicar/${ambId}`,
    'POST'
  )
}

export const apiAddPeca = async peca => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/Peca`,
    'POST',
    peca
  )
}

export const apiGetOtimizarcaoStatusGroup = async projetos => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/OtimizacaoStatus/ObterListaPorProjetos`,
    'POST',
    projetos
  )
}

export const apiSendMsgQueue = async obj => {
  const responseLogin = await _loginMsgQueue()

  return await apiConfig.sendWithAxios(`${APIMSG}Publish`, 'POST', obj, {
    Authorization: `Bearer ${responseLogin.token}`
  })
}

export const apiIncluirServicoPeca = async data => {
  return await apiConfig.sendWithAxios(
    `${APILEOPLAN}Leoplan/ProjetoPecaServicos/Incluir`,
    'PUT',
    data
  )
}
