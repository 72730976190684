const FormatObj = {
  setProjeto: obj => {
    const projeto = obj
    return projeto
  },

  setNewProduto: (tipoProduto, projetoId, material) => {
    const produto = {
      tipoProdutoId: tipoProduto === 'F' ? 2 : 1,
      codigo: material.Id.toString(),
      descricao: material.Descricao,
      descricaoResumida: material.DescricaoResumida,
      largura: material.Comprimento,
      altura: material.Largura,
      espessura: material.Altura,
      veio: material.Veio,
      canaletado: material.canaletado,
      projetoId: parseInt(projetoId),
      quantidade: 0,
      caminhoImagem: material.UrlImagem,
      centroSaida: material.centroSaida,
      centroVenda: material.centroVenda,
      permiteSerCanaletado: material.permiteSerCanaletado
    }
    return produto
  },

  setNewPeca: (ambienteId, projetoId) => {
    const peca = {
      descricao: '',
      largura: 0,
      quantidade: 0,
      veio: false,
      canaletado: 0,
      altura: 0,
      materialId: 0,
      superiorMaterialId: 0,
      inferiorMaterialId: 0,
      esquerdaMaterialId: 0,
      direitaMaterialId: 0,
      ambienteId: ambienteId,
      projetoId: parseInt(projetoId)
    }
    return peca
  },

  setNewPecaCopy: (ambienteId, projetoId, copy) => {
    const peca = {
      descricao: copy.descricao,
      largura: copy.largura,
      quantidade: copy.quantidade,
      veio: copy.veio,
      canaletado: 0,
      altura: copy.altura,
      materialId: copy.materialId,
      superiorMaterialId: 0,
      inferiorMaterialId: 0,
      esquerdaMaterialId: 0,
      direitaMaterialId: 0,
      ambienteId: ambienteId,
      projetoId: parseInt(projetoId),
      pecaProduto: copy.pecaProduto.map(x => {
        return { projetoProdutoId: x.projetoProdutoId }
      })
    }

    return peca
  }
}

export default FormatObj
