import Edit from '@mui/icons-material/Edit'
import Save from '@mui/icons-material/Save'
import { Tooltip } from '@mui/material'
import React, { useState } from 'react'

import Mask from '@service/mask'

import './style.scss'

export default function EditableCell({ cellContent, handleSave }) {
  const [editStatus, setEditStatus] = useState(false)
  const [fieldValue, setFieldValue] = useState(cellContent)

  function changeEditStatus() {
    setEditStatus(!editStatus)
  }

  function handleBlur(target) {
    if (target?.id !== 'editableCell_salve') {
      changeEditStatus()
      setFieldValue(cellContent)
    }
  }

  async function fireHandleSave() {
    await handleSave(fieldValue)
    changeEditStatus()
  }

  return (
    <div className="editableCell_container">
      {editStatus ? (
        <>
          <input
            type="text"
            autoFocus
            onBlur={e => handleBlur(e.relatedTarget)}
            onChange={e => setFieldValue(Mask.projectNameMask(e.target.value))}
            value={fieldValue}
            maxLength="28"
          />
          <Tooltip title="Salvar">
            <div className="editableCell_inputIcons">
              <button onClick={fireHandleSave} id="editableCell_salve">
                <Save />
              </button>
            </div>
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Editar nome">
          <>
            <button aria-label="edit name" onClick={changeEditStatus}>
              {cellContent}
              <Edit />
            </button>
          </>
        </Tooltip>
      )}
    </div>
  )
}
