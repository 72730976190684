import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { useNavigate } from 'react-router-dom'

import { useUser } from '@hook/useUser'

import api from '@service/api'

const DialogEnvioOrcamento = props => {
  const navigate = useNavigate()
  const {
    loja,
    projetoId,
    vendedor,
    cliente,
    projetoNome,
    showMsgE,
    showMsgS,
    materiais
  } = props

  const { user } = useUser()
  const [openModalOrcamento, setOpenModalOrcamento] = React.useState(false)
  const [load, setLoad] = React.useState(false)
  const handleOpenModalOrcamento = () => {
    setOpenModalOrcamento(true)
  }
  const handleCloseModalOrcamento = () => {
    setOpenModalOrcamento(false)
  }
  const goToListagemProjetos = () => {
    navigate('/plano-de-corte/projetos/disponiveis')
  }

  const validaOrcamentoValido = () => {
    let valid = true
    if (!user) {
      showMsgE('Informado um vendedor na aba Dados do projeto')
      valid = false
    }
    if (!loja) {
      showMsgE('Informado uma loja na aba Dados do projeto')
      valid = false
    }
    if (!vendedor) {
      showMsgE('Não foi possível carregar informações do vendedor')
      valid = false
    }
    if (!cliente) {
      showMsgE('Não foi possível carregar informações do cliente')
      valid = false
    }
    if (valid) {
      handleOpenModalOrcamento()
    }
  }

  const [descricao, setDescricao] = React.useState('')
  const handleDescricaoChange = event => {
    if (event.target.value.length <= 200) {
      setDescricao(event.target.value)
    }
  }

  function enviarParaVendedor() {
    setLoad(true)
    getMaterialPrices(materiais)
  }

  function getMaterialPrices(materiaisLista) {
    const materiasCodigos = []
    materiaisLista.forEach(mat => {
      materiasCodigos.push(mat.codigo)
    })

    const objsMaterial = []

    materiasCodigos.forEach(function (item) {
      objsMaterial.push({ codigo: item, preco: 0 })
    })
    formatPecas(materiais, objsMaterial)
  }

  async function formatPecas(materiaisLista, materiaisPriceLista) {
    // debugger
    const listaProdutosComPreco = []

    materiaisLista.forEach(material => {
      listaProdutosComPreco.push({
        ProdutoId: material.codigo,
        Quantidade: 1,
        ValorUnitario: materiaisPriceLista.filter(
          x => x.codigo === material.codigo
        )[0]?.preco
      })
    })

    // console.log('obj materiaisLista', materiaisLista)
    // console.log('obj materiaisPriceLista', materiaisPriceLista)
    // console.log('obj listaProdutosComPreco', listaProdutosComPreco)
    // console.log('obj orçamento nome', projetoNome)
    // console.log('obj orçamento loja', loja.IdERP)
    // console.log('obj orçamento projeto', projetoId)
    // console.log('obj orçamento cliente', cliente.documento)
    // console.log('obj orçamento vendedor', vendedor.id)

    handleCloseModalOrcamento()
    // api não desenvolvida para fl4
    // showMsgS('O leoplan não tem suporte para orçamentos externos na loja selecionada')
    // goToListagemProjetos()

    try {
      const response = await trackPromise(
        api.core.apiOrcamento(
          `${projetoId} ${projetoNome} ${descricao}`,
          loja.idErp,
          projetoId,
          cliente.documento,
          vendedor.id,
          listaProdutosComPreco,
          projetoNome
        )
      )

      if (response.fail) throw new Error()

      showMsgS('Plano de corte enviado com sucesso para o vendedor')
      goToListagemProjetos()
    } catch {
      showMsgE('Algum erro aconteceu, tente novamente mais tarde')
    } finally {
      setLoad(false)
    }
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        className={isMobile ? 'w-100' : 'm5'}
        onClick={validaOrcamentoValido}
      >
        Gerar Orçamento
      </Button>
      <Dialog
        open={openModalOrcamento}
        onClose={handleCloseModalOrcamento}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        fullScreen={isMobile}
      >
        <DialogTitle id="form-dialog-title">
          <Typography>Orçamento</Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModalOrcamento}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="row">
            <div className="col-sm-4">
              <strong>Loja:</strong> <br />
              {user?.tipoUserLeoPlan === 'cliente' ? (
                <div>
                  {loja?.idErp} - {loja?.nomeEmpresa}
                </div>
              ) : (
                <div> {user?.loja.nomeLoja} </div>
              )}
            </div>
            <div className="col-sm-8">
              <strong>Vendedor:</strong> <br />
              {user?.tipoUserLeoPlan === 'cliente' ? (
                <div>{vendedor?.nome}</div>
              ) : (
                <div>{user?.nome}</div>
              )}
            </div>

            <div className="col-sm-12 mt20">
              <TextField
                id="Descricao"
                label="Descrição"
                placeholder="Digite a descrição"
                multiline
                variant="filled"
                rows={4}
                value={descricao}
                onChange={handleDescricaoChange}
              />
            </div>
          </div>

          <div className="text-center mt20">
            {!load ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={enviarParaVendedor}
              >
                {' '}
                Enviar para vendedor
              </Button>
            ) : (
              <Button variant="contained" color="secondary" disabled>
                {' '}
                Processando...{' '}
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default memo(DialogEnvioOrcamento)
