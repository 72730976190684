import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { useProjeto } from '@hook/useProjeto.js'

import FitasMiniatura from '@component/FitasMiniatura'

const useStyles = makeStyles()(() => ({
  chip: {
    '& span': { border: '#ccc 1px solid' },
    '& span.active': { borderWidth: '5px' }
  }
}))

const ConfigFita = ({
  fitasSelecionadas,
  peca,
  setFitaSide,
  indexPeca,
  id,
  tabNewPeca
}) => {
  const { projeto } = useProjeto()
  const { classes } = useStyles()
  const [bordaFitasActive, setbordaFitasActive] = React.useState(0)

  const getColor = idFita => {
    const fitaIndex = fitasSelecionadas.find(fitas => fitas.id === idFita)
    return fitaIndex?.color
  }
  const getCheckFita = idFita => {
    if (idFita === 'all') {
      return (
        peca.superiorMaterialId > 0 &&
        peca.inferiorMaterialId > 0 &&
        peca.esquerdaMaterialId > 0 &&
        peca.direitaMaterialId > 0
      )
    }
    const hasFita = fitasSelecionadas.find(fitas => fitas.id === idFita)
    if (!hasFita) return false
    return idFita > 0
  }
  const keyHit = (side, fita, event) => {
    if (event.key === 'Enter') {
      setFitaSide(side, fita, event.target.checked)
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="featureContainer">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 text-center">
              <div className="fitasTab">
                <div className={classes.chip}>
                  {fitasSelecionadas.map((fita, index) => {
                    return (
                      <span
                        key={fita.id}
                        className={bordaFitasActive === index ? 'active' : ''}
                        style={{ borderColor: fita.color }}
                        onClick={() => setbordaFitasActive(index)}
                        id={`fita-${fita.id}-${id}`}
                      >
                        {fita.codigo} - {fita.descricao}
                      </span>
                    )
                  })}
                </div>
              </div>
              {projeto?.tipoCorte !== 2 && projeto?.tipoCorte !== 3 && (
                <div className="">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedF"
                        id={`fita-all-${id}`}
                        checked={getCheckFita('all')}
                        className="defaultColor"
                        disabled={projeto.origemProjeto === 3}
                        onChange={({ target }) =>
                          setFitaSide('all', bordaFitasActive, target.checked)
                        }
                        style={{
                          color: getColor(bordaFitasActive)
                        }}
                        onKeyDown={event =>
                          keyHit('all', bordaFitasActive, event)
                        }
                      />
                    }
                    label="Aplicar em todos os lados"
                  />
                </div>
              )}
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6">
              <div>
                <div className="flex justify-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="bordaA"
                        id={`fita-top-${id}`}
                        checked={getCheckFita(peca.superiorMaterialId)}
                        style={{
                          color: getColor(peca.superiorMaterialId)
                        }}
                        onChange={({ target }) =>
                          setFitaSide('top', bordaFitasActive, target.checked)
                        }
                        onKeyDown={event =>
                          keyHit('top', bordaFitasActive, event)
                        }
                        disabled={
                          projeto?.tipoCorte === 3 ||
                          projeto.origemProjeto === 3
                        }
                      />
                    }
                    className="m-0"
                    label="C 1"
                    labelPlacement="top"
                  />
                </div>

                <div className="col-12 flexLeo">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="bordaA"
                        id={`fita-left-${id}`}
                        checked={getCheckFita(peca.esquerdaMaterialId)}
                        style={{
                          color: getColor(peca.esquerdaMaterialId)
                        }}
                        onChange={({ target }) =>
                          setFitaSide('left', bordaFitasActive, target.checked)
                        }
                        onKeyDown={event =>
                          keyHit('left', bordaFitasActive, event)
                        }
                        disabled={
                          projeto?.tipoCorte === 2 ||
                          projeto.origemProjeto === 3
                        }
                      />
                    }
                    label="L 1"
                    labelPlacement="start"
                  />
                  <FitasMiniatura
                    peca={peca}
                    materiais={fitasSelecionadas}
                    aplicacao
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="bordaA"
                        id={`fita-right-${id}`}
                        checked={getCheckFita(peca.direitaMaterialId)}
                        style={{
                          color: getColor(peca.direitaMaterialId)
                        }}
                        onChange={({ target }) =>
                          setFitaSide('right', bordaFitasActive, target.checked)
                        }
                        onKeyDown={event =>
                          keyHit('right', bordaFitasActive, event)
                        }
                        disabled={
                          projeto?.tipoCorte === 2 ||
                          projeto.origemProjeto === 3
                        }
                      />
                    }
                    label="L 2"
                    labelPlacement="end"
                  />
                </div>

                <div className="col-12 text-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="bordaA"
                        id={`fita-bottom-${id}`}
                        checked={getCheckFita(peca.inferiorMaterialId)}
                        style={{
                          color: getColor(peca.inferiorMaterialId)
                        }}
                        onChange={({ target }) =>
                          setFitaSide(
                            'bottom',
                            bordaFitasActive,
                            target.checked
                          )
                        }
                        onKeyDown={event =>
                          keyHit('bottom', bordaFitasActive, event)
                        }
                        disabled={
                          projeto?.tipoCorte === 3 ||
                          projeto.origemProjeto === 3
                        }
                      />
                    }
                    className="m-0"
                    label="C 2"
                    labelPlacement="bottom"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfigFita
