import {
  PLAN_NEWPROJETO,
  PLAN_STATUSPROJETO,
  PLAN_UPTAMBIENTE,
  PLAN_UPTMATERIAIS,
  PLAN_EDTPECA,
  PLAN_EDTPECABORDATOTAL,
  PLAN_EXCLUDEFITA,
  PLAN_UPTBUSCAFITAS,
  PLAN_UPTFITAS_RECOMENDADAS,
  PLAN_ADDFITA_RECOMENDADA,
  PLAN_LOADMATERIAIS,
  PLAN_LOADAMBIENTE,
  PLAN_AMBIENTE_SALVO,
  PLAN_RESET_CONFIGLOJA,
  PLAN_SET_CONFIGLOJA
} from './actionTypes.js'

export const planResetConfigLoja = () => ({
  type: PLAN_RESET_CONFIGLOJA,
  payload: {}
})

export const planSetConfigLoja = config => ({
  type: PLAN_SET_CONFIGLOJA,
  payload: config
})

export const planNewProjeto = projeto => ({
  type: PLAN_NEWPROJETO,
  payload: projeto
})
export const planStatusProjeto = projetoStatus => ({
  type: PLAN_STATUSPROJETO,
  payload: projetoStatus
})

export const planLoadAmbiente = ambientes => ({
  type: PLAN_LOADAMBIENTE,
  payload: ambientes
})

export const planUdtAmbiente = ambientes => ({
  type: PLAN_UPTAMBIENTE,
  payload: ambientes
})

export const planAmbienteSalvo = status => ({
  type: PLAN_AMBIENTE_SALVO,
  payload: status
})

export const planUdtMateriais = materiais => ({
  type: PLAN_UPTMATERIAIS,
  payload: materiais
})

export const planLoadMateriais = materiais => ({
  type: PLAN_LOADMATERIAIS,
  payload: materiais
})

export const planUdtFitasRecomendadas = recomendacoes => ({
  type: PLAN_UPTFITAS_RECOMENDADAS,
  payload: recomendacoes
})

export const planAddFitaRecomendada = recomendacao => ({
  type: PLAN_ADDFITA_RECOMENDADA,
  payload: recomendacao
})

export const planEdtPeca = (peca, indAmbiente, indPeca, campo) => ({
  type: PLAN_EDTPECA,
  indexAmbiente: indAmbiente,
  indexPeca: indPeca,
  campo: campo,
  payload: peca
})

export const planExcludeFita = (
  peca,
  indAmbiente,
  indPeca,
  campo,
  idFita,
  excluirAllFitas
) => ({
  type: PLAN_EXCLUDEFITA,
  indexAmbiente: indAmbiente,
  indexPeca: indPeca,
  idFita: idFita,
  excluirAllFitas: excluirAllFitas,
  campo: campo,
  payload: peca
})

export const planEdtPecaBordaTotal = (fitaId, indAmbiente, indPeca) => ({
  type: PLAN_EDTPECABORDATOTAL,
  indexAmbiente: indAmbiente,
  indexPeca: indPeca,
  payload: fitaId
})

export const planUdtBuscaFitas = fitas => ({
  type: PLAN_UPTBUSCAFITAS,
  payload: fitas
})
