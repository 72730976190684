import ImgFitaDefault from '@asset/img/fita-defalut.PNG'

import { appConfig } from '@config/env-config.js'

import sistemasFixacaoMenu from '@mock/sistemasFixacao.js'

const isSVL = localStorage.getItem('LEOPLAN_PATCH_ORIGEM') === 'SVL'

const API_CORE_IMAGES = isSVL
  ? appConfig.REACT_APP_API_CORE_IMAGES_SVL
  : appConfig.REACT_APP_API_CORE_IMAGES_GW

function difference(a1, a2) {
  const a2Set = new Set(a2)
  return a1.filter(function (x) {
    return !a2Set.has(x)
  })
}

const BoderColors = [
  '#FECD04',
  '#21CF49',
  '#007BFF',
  '#7300CA',
  '#FF8181',
  '#7FF6BF',
  '#FDC6FF',
  '#FF962A',
  '#987575',
  '#0E09A8',
  '#00CEC7',
  '#AE8D33',
  '#5E5E5E',
  '#5BFF0A',
  '#DFFF00',
  '#FF00D5',
  '#3A8831',
  '#848484',
  '#000000',
  '#00EDFF',
  '#FF4F00',
  '#AE8D33',
  '#A3C1B4',
  '#FFF787',
  '#9070FF'
]

function _arredondamentoMultiplo(utilizadoProduto, configCorteLoja) {
  if (configCorteLoja && configCorteLoja.id) {
    if (configCorteLoja.multiploFita === 0) {
      configCorteLoja.multiploFita = 1
    }
    const qtdMultiplo = utilizadoProduto / 1000 / configCorteLoja.multiploFita
    const qtdMultiploArredondada = Math.floor(qtdMultiplo)
    if (qtdMultiplo % 1 === 0) {
      return qtdMultiploArredondada * configCorteLoja.multiploFita
    } else {
      return (
        qtdMultiploArredondada * configCorteLoja.multiploFita +
        configCorteLoja.multiploFita
      )
    }
  }
  return 0
}

const Utils = {
  buscarPecaPorId: (projetoAmbiente, id) => {
    for (const ambiente of projetoAmbiente) {
      for (const peca of ambiente.projetoPeca) {
        if (peca.id === id) {
          return peca
        }
      }
    }
    return null
  },

  updatePayloadConfigurador: (peca, projeto) => {
    if (!peca.projetoPecaServico.length) return []

    const chapa = projeto?.projetoProduto?.find(x => x.id === peca.materialId)
    if (!peca?.projetoPecaServico[0]?.payloadConfigurador?.length) {
      return [
        { ...peca.projetoPecaServico[0], payloadConfigurador: '', ativo: true }
      ]
    }

    const parsePayload = JSON.parse(
      peca.projetoPecaServico[0].payloadConfigurador
    )

    let componentType

    if (peca.projetoPecaServico[0]?.sistemaFixacaoId > 0) {
      if (peca.projetoPecaServico[0]?.fixacaoConector === 1) {
        componentType = 'side'
      } else if (peca.projetoPecaServico[0]?.fixacaoConector === 2) {
        componentType = 'base'
      }
    } else {
      componentType = parsePayload.componentType
    }

    const updatePayload = {
      ...parsePayload,
      componentType: componentType,
      entries: parsePayload.entries.map(entry => {
        switch (entry.prop) {
          case 'Width':
            const width =
              parsePayload.componentType === 'door'
                ? (peca.altura * 100).toString()
                : (peca.largura * 100).toString()

            return { ...entry, val: width }
          case 'Height':
            const height =
              parsePayload.componentType === 'door'
                ? (peca.largura * 100).toString()
                : (peca.altura * 100).toString()

            return { ...entry, val: height }
          case 'Board':
            return { ...entry, val: Utils.getBoard(chapa) }
          case 'JoinSystemType':
            return {
              ...entry,
              val: Utils.getJoinSystemType(
                peca?.projetoPecaServico[0]?.sistemaFixacaoId
              )
            }
          default:
            return entry
        }
      })
    }

    const updatedPecaServico = [
      {
        ...peca.projetoPecaServico[0],
        payloadConfigurador: JSON.stringify(updatePayload),
        ativo: true
      }
    ]

    return updatedPecaServico
  },

  getJoinSystemType: sistemaFixacaoId => {
    return (
      sistemasFixacaoMenu.find(item => item.id === sistemaFixacaoId)?.value ||
      'None'
    )
  },

  getBoard: chapa => {
    const espessura = chapa?.espessura * 10

    const boards = {
      15: 'board15',
      18: 'board18',
      25: 'board25'
    }
    return boards[espessura] || 'b0002'
  },

  reordenarPecasPrimariasSecundarias: projetoAmbiente => {
    return projetoAmbiente.map(ambiente => {
      const projetoPeca = ambiente.projetoPeca.map((peca, index, array) => {
        if (
          peca.projetoPecaServico.length > 0 &&
          peca.projetoPecaServico[0].projetoPecaSecundariaId !== null
        ) {
          const secundaria = array.find(
            p => p.id === peca.projetoPecaServico[0].projetoPecaSecundariaId
          )
          if (secundaria) {
            array.splice(array.indexOf(secundaria), 1)
            array.splice(index + 1, 0, secundaria)
          }
        }
        return peca
      })
      return { ...ambiente, projetoPeca }
    })
  },

  mapNomeServico: value => {
    switch (value) {
      case 1:
        return 'Dobradiça'
      case 2:
        return 'Canal'
      case 3:
        return 'Rebaixo'
      default:
        return '-'
    }
  },

  // retorna descrição de material canaletado em vermelho, usando em ResumoProjeto.js e ResumoDescritivoProjeto.js
  descricaoCanaletado: (material, isEmail = false) => {
    if (material.canaletado > 0) {
      if (!isEmail) {
        return (
          <span className="text-danger">
            {' '}
            - <strong>Canaletado:</strong> Sim,{' '}
            <strong>Distância entre canaletas:</strong> {material.canaletado}{' '}
            polegadas{' '}
          </span>
        )
      } else {
        return `Canaletado: Sim, Distância canaletas: ${material.canaletado} pol.`
      }
    }
  },
  // retorna elementos diferentes entre dois arrays
  arrayDiference: (arrayA, arrayB) => {
    return difference(arrayA, arrayB).concat(difference(arrayB, arrayA))
  },
  getImageUrl: id => {
    if (id?.toString().includes('-')) {
      return `${API_CORE_IMAGES}${id.split('-', 1)}${isSVL ? '.jpg' : ''}`
    }

    return `${API_CORE_IMAGES}${id}${isSVL ? '.jpg' : ''}`
  },
  // retorna elementos iguais entre dois arrays
  arrayInterseccao: (arrayA, arrayB) => {
    return arrayA.filter(x => arrayB.includes(x))
  },
  imgBrokenFita: event => {
    event.target.src = ImgFitaDefault
  },
  hasError: errors => {
    const errorPropeties = Object.keys(errors)
    return errorPropeties.reduce((add, error) => {
      return add + !!errors[error]
    }, false)
  },
  hasWarnings: warnings => {
    const errorPropeties = Object.keys(warnings)
    return errorPropeties.reduce((add, warning) => {
      return add + !!warnings[warning]
    }, false)
  },

  // converte data
  convertDateIsoToDate: (date, format = false) => {
    let newDate = ''
    if (format === false) {
      newDate = new Date(
        date.substring(0, 4),
        date.substring(5, 7) - 1,
        date.substring(8, 10),
        date.substring(11, 13),
        date.substring(14, 16),
        date.substring(17, 19)
      )
    } else {
      newDate = `${date.substring(8, 10)}/${date.substring(
        5,
        7
      )}/${date.substring(0, 4)}`
    }

    return newDate
  },

  validarValorMenor: (val, minVal) => {
    if (parseInt(val) < parseInt(minVal)) return false
    else return true
  },

  validarValor: (val, maxVal) => {
    if (maxVal === null || maxVal === 0 || maxVal === '') {
      maxVal = 999999
    }
    if (val === '' || val === '0' || parseInt(Math.ceil(val)) > maxVal) {
      return false
    }
    return true
  },

  validarTamanhoPeca: (val, minVal, maxVal) => {
    if (!parseInt(val)) {
      return false
    }
    if (parseInt(val) < parseInt(minVal)) {
      return false
    }
    if (maxVal === null || maxVal === 0 || maxVal === '') {
      maxVal = 999999
    }
    if (val === '' || val === '0' || parseInt(Math.ceil(val)) > maxVal) {
      return false
    }
    return true
  },

  validarEspessuraFita: (fitas, chapa) => {
    const validation = fitas
      .map(item => item.altura * 10)
      .map(fita => {
        if (chapa.espessura * 10 <= 20) {
          if (chapa.espessura * 10 === 15) {
            if (fita === 19 || fita === 22) {
              return false
            } else return '19mm'
          } else if (fita === 22) {
            return false
          } else return '22mm'
        } else if (chapa.espessura * 10 > 20) {
          if (fita >= 29) {
            return false
          } else return '29mm ou maior'
        }
        return true
      })

    return validation.filter(item => item)
  },

  retornaLimitMaxAltura: (altura, tipoCorte, descontoAltura) => {
    if (tipoCorte === 3) {
      return altura * 10
    }
    return altura * 10 - descontoAltura
  },

  retornaLimitMaxLargura: (lagura, tipoCorte, descontoLargura) => {
    if (tipoCorte === 2) {
      return lagura * 10
    }
    return lagura * 10 - descontoLargura
  },

  validaMedidaPecaFitagem: (
    comprimento,
    largura,
    minFitagem,
    minFitagemOposta,
    inferiorMaterialId,
    superiorMaterialId,
    esquerdaMaterialId,
    direitaMaterialId
  ) => {
    let tipeError = 'OK'

    if (
      inferiorMaterialId > 0 ||
      superiorMaterialId > 0 ||
      esquerdaMaterialId > 0 ||
      direitaMaterialId > 0
    ) {
      if (
        comprimento < minFitagem &&
        largura < minFitagem &&
        minFitagem === minFitagemOposta
      ) {
        tipeError = `Bordeamento permitido apenas para medidas a partir de ${minFitagem}mm`
      }
      if (
        (comprimento < minFitagem || largura < minFitagem) &&
        minFitagem === minFitagemOposta
      ) {
        tipeError = `Bordeamento permitido apenas para medidas a partir de ${minFitagem}mm`
      }
      if (
        comprimento < minFitagem &&
        largura < minFitagem &&
        minFitagem !== minFitagemOposta
      ) {
        tipeError = `Bordeamento permitido apenas se uma das medidas for igual ou maior que ${minFitagem}mm`
      }

      if (tipeError === 'OK' && minFitagem !== minFitagemOposta) {
        if (comprimento < minFitagemOposta && largura >= minFitagem) {
          tipeError = `Comprimento da peça inferior a ${minFitagemOposta}mm não permite bordeamento na peça.`
        } else if (
          comprimento >= minFitagemOposta &&
          comprimento < minFitagem &&
          largura >= minFitagem &&
          (inferiorMaterialId > 0 || superiorMaterialId > 0)
        ) {
          tipeError = `Comprimento da peça inferior a ${minFitagem}mm não permite bordeamento, desmarque as bordas superior e inferior.`
        }

        if (largura < minFitagemOposta && comprimento >= minFitagem) {
          tipeError = `Largura da peça inferior a ${minFitagemOposta}mm não permite bordeamento na peça.`
        } else if (
          largura >= minFitagemOposta &&
          largura < minFitagem &&
          comprimento >= minFitagem &&
          (esquerdaMaterialId > 0 || direitaMaterialId > 0)
        ) {
          tipeError = `Largura da peça inferior a ${minFitagem}mm não permite bordeamento, desmarque as bordas laterais.`
        }
      }
    }
    return tipeError
  },

  returnTipoProjetDescricao: tipoId => {
    const strTipoId = `${tipoId}`
    let tipoProjString = ''
    switch (strTipoId) {
      case '1':
        tipoProjString = 'Completo'
        break

      case '2':
        tipoProjString = 'Tiras Horizontais'
        break

      case '3':
        tipoProjString = 'Tiras Verticais'
        break

      case '4':
        tipoProjString = 'Chapa Canaletada'
        break

      case '5':
        tipoProjString = 'Corte Transporte'
        break

      default:
        tipoProjString = 'Completo'
        break
    }
    return tipoProjString
  },

  returnStatusOtimizacaoString: statusObj => {
    if (statusObj) {
      if (statusObj === 1) {
        return 'Em processamento'
      } else if (statusObj === 2) {
        return 'Em processamento'
      } else if (statusObj === 3) {
        return 'Completa'
      } else if (statusObj === 4) {
        return 'Falhou'
      } else if (statusObj === 5) {
        return 'Solicitada'
      }
    }
    return 'Não iniciada'
  },

  atribuiCorFitas: listProdutos => {
    let countFitas = 0
    listProdutos.forEach(produto => {
      if (produto.tipoProdutoId === 2) {
        if (countFitas < BoderColors.length) {
          produto.color = BoderColors[countFitas]
        } else {
          produto.color =
            '#' +
            parseInt(Math.random() * 0xffffff)
              .toString(16)
              .padStart(6, '0')
        }
        countFitas += 1
      }
    })
    return listProdutos
  },

  validarEmail: email => {
    const usuario = email.substring(0, email.indexOf('@'))
    const dominio = email.substring(email.indexOf('@') + 1, email.length)
    if (
      usuario.length >= 1 &&
      dominio.length >= 3 &&
      usuario.search('@') === -1 &&
      dominio.search('@') === -1 &&
      usuario.search(' ') === -1 &&
      dominio.search(' ') === -1 &&
      dominio.search('.') !== -1 &&
      dominio.indexOf('.') >= 1 &&
      dominio.lastIndexOf('.') < dominio.length - 1
    ) {
      return true
    } else {
      return false
    }
  },
  addServicoBordeamento: (materiais, fitas, servicos) => {
    const calcSomaBordaservico = fitas => {
      return fitas.reduce((add, fita) => {
        return add + fita.aplicacaoFita
      }, 0)
    }
    if (fitas.length > 0) {
      const codigoBordeamento = servicos.find(
        servico => servico.propriedadeJs.bordeamentoLeoPlan
      )

      const bordeamento = {
        codigo: codigoBordeamento.idErp,
        quantidade: calcSomaBordaservico(fitas)
      }
      return [...materiais, bordeamento]
    } else return materiais
  },
  extrairServico: (materiais, servicosCorte) => {
    const servicos = materiais.filter(item =>
      servicosCorte
        .map(servico => servico.idErp.toString())
        .includes(item.codigo.toString())
    )
    return servicos.map(material => {
      const descicao = servicosCorte.find(
        servico => servico.idErp.toString() === material.codigo.toString()
      )
      return {
        ...descicao,
        quantidadeComAcrecimo: material.quantidade
      }
    })
  },
  extraiChapasEmUso: (otimizacao, materiais) => {
    if (materiais && materiais.length > 0) {
      const materiasUtilizados = materiais
        .filter(isChapa => isChapa.tipoProdutoId === 1)
        .filter(isInUse =>
          otimizacao.find(
            used => used.materialId.toString() === isInUse.id.toString()
          )
        )
        .map(mat => {
          const indexOtimizacao = otimizacao.findIndex(
            x => x.materialId.toString() === mat.id.toString()
          )
          const objMeterial = {
            codigo: mat.codigo,
            descricao: mat.descricao,
            qtdPlacasCortadas:
              otimizacao[indexOtimizacao].chapa.qtdPlacasCortadas,
            canaletado: mat.canaletado
          }

          return objMeterial
        })
      return materiasUtilizados
    } else return []
  },

  extraiFitasEmUso: (otimizacoes, materiais) => {
    const materialFita = materiais?.filter(
      material => material.tipoProdutoId === 2
    )
    const otimizacaoFita = otimizacoes?.flatMap(otimizacao => otimizacao.fitas)

    const fitasUtilizadas = materialFita.map(material => {
      const fitasCorrespondentes = otimizacaoFita.filter(
        fita => fita.codigo.toString() === material.codigo.toString()
      )
      return {
        ...material,
        reutilizacao: fitasCorrespondentes.length,
        aplicacaoFita: fitasCorrespondentes.reduce(
          (total, fita) => total + fita.mtUtilizadosCalculado,
          0
        )
      }
    })

    return fitasUtilizadas
  },

  retornaQuantidadePecas: ambientes => {
    let cont = 0
    if (ambientes && ambientes.length > 0) {
      ambientes.forEach(amb =>
        amb.projetoPeca.forEach(x => (cont += Number(x.quantidade)))
      )
    }
    return cont
  },

  getEstoqueQtd: (estoquesLista, estoqueDesejado, vendedor) => {
    const CSIdERP = appConfig.REACT_APP_CSIdERP
    const CDIdERP = appConfig.REACT_APP_CDIdERP
    const LojasPropriasComZLPL = appConfig.REACT_APP_LojasPropriasComZLPL

    const estoque = {
      Loja: 0,
      CD: 0,
      CentroLoja: 0,
      Centro: 0,
      Avaria: 0,
      CS: 0
    }
    if (
      estoquesLista !== null &&
      estoquesLista !== undefined &&
      estoquesLista.length > 0 &&
      estoqueDesejado &&
      vendedor
    ) {
      let Loja = null
      let Estoque = []
      let EstoqueCS = []

      estoquesLista.forEach(value => {
        // estoque loja
        if (value.CentroId === vendedor.lojaIdErp) {
          // pega o estoque do deposito 1 da loja
          if (value.Deposito.Id === 1) {
            Loja =
              value.EstoqueDisponivel > 0
                ? parseInt(value.EstoqueDisponivel)
                : 0
            // pega o estoque de avaria da loja (verificar regra ja que não é utilizado)
          } else if (value.Deposito.Id === 2) {
            estoque.Avaria =
              value.EstoqueDisponivel > 0
                ? parseInt(value.EstoqueDisponivel)
                : 0
          }
        } else {
          // Deposito Revenda
          // estoque CD
          if (value.Deposito.Id === 1) {
            // define o estoque para lojas 1000, somando todos os CDs no array da variavel de ambiente
            if (
              vendedor.empresaIdErp < 2000 &&
              CDIdERP.includes(value.CentroId)
            ) {
              Estoque = [...Estoque, value]

              // define o estoque para lojas 200, somando todos os CDs enviados pelo back que não cairem na regra da loja
              // necessario por existirem lojas com CDs cadastrados fora a propria loja
            } else if (
              vendedor.empresaIdErp > 2000 &&
              !CSIdERP.includes(value.CentroId)
            ) {
              Estoque = [...Estoque, value]
            }
          }
          // estoque CS
          // funciona apenas para lojas 1000, soma os estoques de todos os CSs no array da variavel de ambiente
          if (
            vendedor.empresaIdErp < 2000 &&
            value.Deposito.Id === 1 &&
            CSIdERP.includes(value.CentroId)
          ) {
            EstoqueCS = [...EstoqueCS, value]
          }
          // Deposito Avaria
          // pega o estoque de avaria (verificar regra ja que não é utilizado e sobrescreve o estoque de avaria da loja)
          if (value.Deposito.Id === 2) {
            estoque.Avaria = value
          }
        }
      })

      const lojaPropriasOutroCD = LojasPropriasComZLPL.find(x =>
        x.lojasId?.includes(vendedor.lojaIdErp)
      )
      if (lojaPropriasOutroCD && Number(vendedor.tipoVendaId) === 70) {
        if (estoquesLista.find(x => x.CentroId === lojaPropriasOutroCD.cd)) {
          Estoque = estoquesLista.filter(
            x => x.CentroId === lojaPropriasOutroCD.cd
          )
        }
      }

      if (Loja !== null) {
        estoque.Loja = Loja
        estoque.CentroLoja = vendedor.lojaIdErp
      } else {
        estoque.CentroLoja = vendedor.lojaIdErp
      }

      if (Estoque.length) {
        estoque.Centro = Estoque.CentroId
        estoque.CD = Estoque.reduce((acc, item) => {
          if (item.EstoqueDisponivel > 0) {
            return acc > item.EstoqueDisponivel ? acc : item.EstoqueDisponivel
          } else return acc
        }, 0)
      }
      if (EstoqueCS.length) {
        estoque.CS = EstoqueCS.reduce((acc, item) => {
          if (item.EstoqueDisponivel > 0) {
            return acc > item.EstoqueDisponivel ? acc : item.EstoqueDisponivel
          } else return acc
        }, 0)
      }

      if (estoqueDesejado === 'L') {
        return estoque.Loja
      } else if (estoqueDesejado === 'CD') {
        return estoque.CD
      } else if (estoqueDesejado === 'CS') {
        return estoque.CS
      }
      return '-'
    } else {
      return '-'
    }
  },

  getTipoVendaId: vendedor => {
    let tipoVenda = ''
    if (vendedor.tipoVendaId) {
      // vendedorSelecionado.tipoVendaId só é informado quando o usuário logado é um vendedor
      tipoVenda = vendedor.tipoVendaId
    } else {
      // quando não possuir tipoVendaId identificar pela empresaId
      // 7 = ZCVS / 70 = ZLPL
      if (vendedor.empresaIdErp < 2000) {
        tipoVenda = 7
      } else {
        tipoVenda = 70
      }
    }
    return tipoVenda
  },

  fitaDesmembrada: (otimizacoes, fitas, materiais, multiploFita) => {
    if (fitas.length) {
      const otmizacaoFita = otimizacoes.reduce((acc, materialOtmz) => {
        const chapa = materiais.find(
          material =>
            material.id.toString() === materialOtmz.materialId.toString()
        )
        const espessuraChapa = chapa.espessura * 10
        const fitas = materialOtmz.fitas.map(fitaOtmz => {
          return { codigo: fitaOtmz.codigo, espessuraChapa }
        })

        return [...acc, ...fitas]
      }, [])

      const fitasUtilizadas = fitas.map(item => {
        const chapas = otmizacaoFita
          .filter(fitaOtmz => {
            return fitaOtmz.codigo === item.codigo
          })
          .map(item => item.espessuraChapa)

        return {
          ...item,
          aplicacaoFita: formulaFita(item, Math.max(...chapas), multiploFita),
          aplicacaoFitaOriginal: item.aplicacaoFita,
          maiorEspessuraChapa: Math.max(...chapas)
        }
      })
      // const fitasMultiplo = fitasMultiplo?
      return fitasUtilizadas
    }

    return []
  },
  desmembramentoFitaUpdate: (otimizacao, projeto, configCorteLoja) => {
    let fitasEmUso = Utils.extraiFitasEmUso(otimizacao, projeto.produtos)
    if (configCorteLoja.desmembramentoFita) {
      fitasEmUso = Utils.fitaDesmembrada(
        otimizacao,
        fitasEmUso,
        projeto.produtos,
        configCorteLoja.multiploFita
      )
    }
    const projetoUpdated = projeto
    let itemFita = []

    fitasEmUso.forEach(fita => {
      itemFita = projetoUpdated.Produtos.map(produto => {
        if (produto.codigo === fita.codigo) return produto
        else return fita
      })
    })

    return itemFita
  },
  formatMaterials: (materials, user, limit) => {
    const addDescricao = materials.map(material => {
      return !material.DescricaoComercial
        ? { ...material, DescricaoComercial: material.Descricao }
        : material
    })

    // extrai estoque loja
    addDescricao.forEach(item => {
      const estoque = item.Estoques.filter(x => {
        if (
          x.CentroId === user.lojaIdErp &&
          (x.Depositoid === '1' || x.Depositoid === '0001')
        ) {
          return x
        } else {
          return null
        }
      })
      if (estoque.length === 1) {
        item.EstoqueLoja = Math.floor(estoque[0].EstoqueDisponivel)
      } else if (estoque.length > 1) {
        item.EstoqueLoja = Math.max.apply(
          Math,
          estoque.map(o => o.EstoqueDisponivel)
        )
      } else {
        item.EstoqueLoja = 0
      }
    })

    const filterFS = addDescricao.map(material => {
      if (
        ['FF', 'FL', 'SF', 'SL'].includes(
          material.SituacaoPorCentro.CodigoSituacao
        )
      ) {
        const estoqueTotal =
          Utils.getEstoqueQtd(material.Estoques, 'CD', user) * 1 +
          Utils.getEstoqueQtd(material.Estoques, 'L', user) * 1 +
          (user.tipoVenda === 'ZCVS' || user.tipoVenda === 'ZMOB'
            ? Utils.getEstoqueQtd(material.Estoques, 'CS', user) * 1
            : 0)
        return { ...material, EstoqueOtmizacao: estoqueTotal }
      } else return material
    })

    const allMaterial = filterFS.filter(material => {
      return material.EstoqueOtmizacao !== undefined
        ? material.EstoqueOtmizacao >= limit
        : true
    })
    const sortResult = [...allMaterial].sort(orderByBuscaMaterial)

    return sortResult
  }
}
const orderByBuscaMaterial = (a, b) => {
  if (a.EstoqueLoja < b.EstoqueLoja) {
    return 1
  }
  if (a.EstoqueLoja > b.EstoqueLoja) {
    return -1
  }
  return 0
}

const formulaFita = (fita, espessuraChapa, multiploFita) => {
  if (fita.aplicacaoFita) {
    const espessuraFita = Math.round(fita.largura)
    const result = Math.round(
      fita.aplicacaoFita / Math.floor(espessuraFita / (espessuraChapa + 4))
    )

    const multiplo = multiploFita % result

    if (multiplo === 0 && multiploFita) return multiploFita
    else return multiplo && multiplo < multiploFita ? result + multiplo : result
  } else return 0
}

export default Utils
