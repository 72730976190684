import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React from 'react'
import { isMobile } from 'react-device-detect'

const DialogEditAmbiente = props => {
  const {
    openModalNomeAmbiente,
    handleCloseModalNomeAmbiente,
    ambienteName,
    editAmbientName,
    alterarNomeAmbiente
  } = props

  return (
    <Dialog
      open={openModalNomeAmbiente}
      onClose={handleCloseModalNomeAmbiente}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Alterar nome Ambiente</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseModalNomeAmbiente}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="text-center" dividers>
        <FormControl variant="filled">
          <TextField
            id="outlined-basic"
            label="Nome do Ambiente"
            variant="filled"
            size="small"
            value={ambienteName}
            onChange={e => editAmbientName(e.target.value)}
          />
        </FormControl>
        <Button
          onClick={alterarNomeAmbiente}
          variant="contained"
          color="secondary"
          className="mb10"
        >
          Alterar Ambiente
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default DialogEditAmbiente
