import { Routes, Route } from 'react-router-dom'

import PrivateRoutes from '@layout/Privateroutes.js'
import Configuracao from '@view/Configuracao/Configuracao.js'
import Inicial from '@view/Inicial/Inicial.js'
import AbaChapasCanaletadas from '@view/LeoPlan/components/ChapasCanaletadas/AbaChapasCanaletadas'
import LeoPlan from '@view/LeoPlan/Leoplan.js'
import LeoPlanLista from '@view/LeoPlan/LeoplanLista.js'
import Login from '@view/Login/Login.js'
import Notfound from '@view/NotFound/NotFound.js'

export default () => {
  return (
    <Routes>
      <Route path="/" exact element={<Inicial />} />
      <Route path="canaletadas" exact element={<AbaChapasCanaletadas />} />
      <Route path="leoplan/:acesso">
        <Route path=":id?" exact element={<Login />} />
        <Route path=":tipo">
          <Route path=":idExterno" exact element={<Inicial />} />
          <Route
            path=":id/:cl/:tipoVenda/:pedidoId"
            exact
            element={<Inicial />}
          />
          <Route
            path=":id/:cl/:tipoVenda/:pedidoId/:projetoId/:depositoId"
            exact
            element={<Inicial />}
          />
        </Route>
      </Route>
      <Route path="/" element={<PrivateRoutes />}>
        <Route path="/configuracao" element={<Configuracao />} />
        <Route
          path="/plano-de-corte/projetos/:tipo"
          element={<LeoPlanLista />}
        />
        <Route path="/plano-de-corte/novo" element={<LeoPlan />} />
        <Route path="/plano-de-corte/novoProjeto/:type" element={<LeoPlan />} />
        <Route
          path="/plano-de-corte/detalhe/:id/:print?"
          element={<LeoPlan />}
        />
      </Route>
      <Route path="*" element={<Notfound />} />
    </Routes>
  )
}
