import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button
} from '@mui/material'
import {
  showMsgE as showMsgEAction,
  showMsgW as showMsgWAction,
  showMsgS as showMsgSAction
} from '@store/actions/snackbarActions.js'
import { useEffect, useState, useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { useDispatch } from 'react-redux'

import Table from '@component/Table/Table'
import OptionMenu from '@view/Configuracao/components/OptionMenu.js'
import DialogSalvarAgrupamento from '@view/Configuracao/dialogs/DialogSalvarAgrupamento.js'
import DialogSalvarParametro from '@view/Configuracao/dialogs/DialogSalvarParametro.js'
import * as helper from '@view/Configuracao/helper/index.js'
import DialogAddChapa from '@view/LeoPlan/components/Dialogs/DialogAddChapa.js'

import api from '@service/api'
import Storage from '@service/storage'

export default function Materiais({ value, index, update }) {
  const dispatch = useDispatch()
  const showMsgE = msg => dispatch(showMsgEAction(msg))
  const showMsgW = msg => dispatch(showMsgWAction(msg))
  const showMsgS = msg => dispatch(showMsgSAction(msg))

  const userLogado = Storage.get('LEOPLAN_USER_INFOS')

  const [agrupamentos, setAgrupamentos] = useState([])
  const [targetAgrupamento, setTargetAgrupamento] = useState(null)
  const [targetAgrupamentoEditar, setTargetAgrupamentoEditar] = useState(null)
  const [targetParametroEditar, setTargetParametroEditar] = useState(null)
  const [listaConfiguracoes, setListaConfiguracoes] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [openDialogAddChapa, setOpenDialogAddChapa] = useState(false)
  const [openDialogSalvarAgrupamento, setOpenDialogSalvarAgrupamento] =
    useState(false)
  const [openDialogSalvarParametro, setOpenDialogSalvarParametro] =
    useState(false)

  const handleChangeAccordions = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    setTargetAgrupamento(null)
    if (isExpanded) {
      handleGetAgrupamento(panel)
    }
  }

  const handleOpenDialogSalvarParametro = (editar, parametro) => {
    if (editar) {
      setTargetParametroEditar(parametro)
    }

    setOpenDialogSalvarParametro(true)
  }

  const handleCloseDialogSalvarParametro = () => {
    setOpenDialogSalvarParametro(false)
    setTargetParametroEditar(null)
  }

  const handleOpenDialogAddChapa = () => {
    setOpenDialogAddChapa(true)
  }

  const handleCloseDialogAddChapa = () => {
    setOpenDialogAddChapa(false)
  }

  const handleOpenDialogSalvarAgrupamento = agrupamento => {
    setTargetAgrupamentoEditar(agrupamento)
    setOpenDialogSalvarAgrupamento(true)
  }

  const handleCloseDialogSalvarAgrupamento = () => {
    setOpenDialogSalvarAgrupamento(false)
    setTargetAgrupamentoEditar(null)
  }

  const handleGetAgrupamento = async agrupamentoId => {
    try {
      const response = await trackPromise(
        api.leoplan.apiGetAgrupamento(agrupamentoId)
      )

      if (response.fail) throw new Error(response.errors)

      setTargetAgrupamento(response.data)
    } catch (error) {
      showMsgE(error)
    }
  }

  const handleSaveAgrupamento = async nomeAgrupamento => {
    if (!nomeAgrupamento) {
      showMsgW('Digite um nome para o grupo de materiais')
      return
    }

    try {
      const data = {
        id: targetAgrupamentoEditar.id,
        nome: nomeAgrupamento,
        lojaIdErp: targetAgrupamentoEditar.lojaIdErp,
        empresaIdErp: targetAgrupamentoEditar.empresaIdErp,
        usuario: userLogado.email
      }

      const response = await trackPromise(
        api.leoplan.apiAtualizarAgrupamento(data)
      )

      if (response.fail) {
        throw new Error(response.errors)
      }

      showMsgS('Grupo de materiais atualizado com sucesso!')
      handleCloseDialogSalvarAgrupamento()
      init()
    } catch (error) {
      showMsgE(error)
    }
  }

  const handleAddMaterial = async material => {
    try {
      const data = {
        materialIdErp: material.IdERP,
        agrupamentoId: targetAgrupamento.id,
        descricao: material.Descricao
      }

      const response = await trackPromise(
        api.leoplan.apiCadastrarMaterialAgrupamento(data)
      )

      if (response.fail) throw new Error(response.errors)

      showMsgS('Material adicionado com sucesso!')
      handleGetAgrupamento(targetAgrupamento.id)
    } catch (error) {
      showMsgE(error)
    }
  }

  const handleDeleteMaterial = async materialId => {
    try {
      const response = await trackPromise(
        api.leoplan.apiDeletarMaterialAgrupamento(
          materialId,
          targetAgrupamento.id
        )
      )

      if (response.fail) throw new Error(response.errors)

      showMsgS(`Material ${materialId} excluído com sucesso`)
      handleGetAgrupamento(targetAgrupamento.id)
    } catch (error) {
      showMsgE(error)
    }
  }

  const handleAddParametro = async (valor, parametro) => {
    try {
      if (!valor) {
        showMsgW('Digite um valor')
        return
      }

      if (valor < 0) {
        showMsgW('O valor não pode ser negativo')
        return
      }

      const data = {
        valor: valor,
        configuracaoIndiceId: parametro,
        agrupamentoId: targetAgrupamento.id,
        usuario: userLogado.email
      }

      const response = await trackPromise(
        api.leoplan.apiCadastrarParametroPorAgrupamento(data)
      )

      if (response.fail) throw new Error(response.errors)

      showMsgS('Parâmetro adicionado com sucesso!')
      handleGetAgrupamento(targetAgrupamento.id)
      handleCloseDialogSalvarParametro()
    } catch (error) {
      showMsgE(error)
    }
  }

  const handleUpdateParametro = async valor => {
    try {
      const data = {
        id: targetParametroEditar.id,
        valor: valor,
        configuracaoIndiceId: targetParametroEditar.idTabela,
        agrupamentoId: targetAgrupamento.id,
        usuario: userLogado.email
      }
      const response = await trackPromise(
        api.leoplan.apiAtualizarParametroPorAgrupamento(data)
      )

      if (response.fail) throw new Error(response.errors)

      showMsgS('Parâmetro atualizado com sucesso!')
      handleGetAgrupamento(targetAgrupamento.id)
      handleCloseDialogSalvarParametro()
    } catch (error) {
      showMsgE(error)
    }
  }

  const handleDeleteParametro = async parametroId => {
    try {
      const response = await trackPromise(
        api.leoplan.apiDeletarParametroPorAgrupamento(
          parametroId,
          targetAgrupamento.id
        )
      )

      if (response.fail) throw new Error(response.errors)

      showMsgS('Parâmetro excluído com sucesso')
      handleGetAgrupamento(targetAgrupamento.id)
    } catch (error) {
      showMsgE(error)
    }
  }

  const init = useCallback(async () => {
    try {
      const responseAgrupamentos = await trackPromise(
        api.leoplan.apiGetAgrupamentosPorLoja(userLogado?.lojaIdErp)
      )

      if (responseAgrupamentos.fail) {
        throw new Error(responseAgrupamentos.errors)
      }

      setAgrupamentos(responseAgrupamentos.data)

      const responseParametros = await trackPromise(
        api.leoplan.apiGetConfiguracoesParametros()
      )

      if (responseParametros.fail) {
        throw new Error(responseParametros.errors)
      }

      setListaConfiguracoes(responseParametros.data)
    } catch (error) {
      showMsgE(error)
    }
  }, [])

  useEffect(() => {
    if (value === index) init()
  }, [value, update, init])

  return (
    <>
      {value === index && (
        <div className="py-4">
          {agrupamentos.map(agrupamento => (
            <Accordion
              expanded={expanded === agrupamento.id}
              onChange={handleChangeAccordions(agrupamento.id)}
              className={
                isMobile ? 'leoExpansionPanelMobile' : 'leoExpansionPanel'
              }
              key={agrupamento.id}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <OptionMenu
                  key={agrupamento.id}
                  actionEditar={() =>
                    handleOpenDialogSalvarAgrupamento(agrupamento)
                  }
                />
                <Typography className="ml20">{agrupamento.nome}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {targetAgrupamento && (
                  <div
                    className={isMobile ? 'flex flex-col' : 'flex w-100 p-4'}
                  >
                    <div className="panel mr-sm-2 flex-auto">
                      <div className="panel-header">Materiais</div>
                      <div className="panel-body">
                        <div className="text-right mb-2">
                          <Button
                            variant="contained"
                            className={isMobile ? 'w-100' : 'm5'}
                            color="secondary"
                            onClick={handleOpenDialogAddChapa}
                          >
                            Adicionar material
                          </Button>
                        </div>

                        <Table
                          title={helper.titleTableMateriais}
                          className="table-configs"
                          data={helper.formatDataTableMateriais(
                            targetAgrupamento.materiaisAgrupamento,
                            handleDeleteMaterial
                          )}
                          search={[0, 1]}
                          pagination
                        />
                      </div>
                    </div>

                    <div className="panel flex-auto">
                      <div className="panel-header">Parâmetros</div>
                      <div className="panel-body">
                        <div className="text-right mb-2">
                          <Button
                            variant="contained"
                            className={isMobile ? 'w-100' : 'm5'}
                            color="secondary"
                            onClick={handleOpenDialogSalvarParametro}
                          >
                            Adicionar parâmetro
                          </Button>
                        </div>

                        <Table
                          title={helper.titleTableParametros}
                          className="table-configs"
                          data={helper.formatDataTableParametros(
                            targetAgrupamento.parametrosAgrupamento,
                            handleOpenDialogSalvarParametro,
                            handleDeleteParametro
                          )}
                          search={[0, 1, 2]}
                          pagination
                        />
                      </div>
                    </div>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
      {openDialogSalvarParametro && (
        <DialogSalvarParametro
          open={openDialogSalvarParametro}
          onClose={handleCloseDialogSalvarParametro}
          categoria={targetAgrupamento?.nome}
          listaConfiguracoes={listaConfiguracoes}
          save={handleAddParametro}
          update={handleUpdateParametro}
          value={targetParametroEditar}
        />
      )}

      {openDialogAddChapa && (
        <DialogAddChapa
          showMsgW={showMsgW}
          openModalAddMaterial={openDialogAddChapa}
          handleCloseModalAddMaterial={handleCloseDialogAddChapa}
          handleIncludeMaterail={handleAddMaterial}
        />
      )}

      {DialogSalvarAgrupamento && targetAgrupamentoEditar && (
        <DialogSalvarAgrupamento
          open={openDialogSalvarAgrupamento}
          onClose={handleCloseDialogSalvarAgrupamento}
          save={handleSaveAgrupamento}
          value={targetAgrupamentoEditar.nome}
        />
      )}
    </>
  )
}
