import {
  InteropContext,
  ServicesProvider
} from '@dotproductdev/parametric-configurator'
import { LinearProgress } from '@mui/material'

import { getMockServices } from './Mocks'
import ruleSet from './rules.json'

const services = getMockServices()
const [, repoService] = services

const ParametricConfiguratorProvider = ({ children }) => {
  return (
    <ServicesProvider services={services}>
      <InteropContext
        interopMainPath="/wasm/main.js"
        fallback={
          <div
            style={{ width: '500px', height: '500px' }}
            className="flex items-center justify-center m-6"
          >
            <LinearProgress />
          </div>
        }
        repository={() => Promise.resolve(repoService.data)}
        rules={() => Promise.resolve(ruleSet)}
      >
        {children}
      </InteropContext>
    </ServicesProvider>
  )
}

export default ParametricConfiguratorProvider
