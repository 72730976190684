import { Close } from '@mui/icons-material'
import {
  Dialog,
  Button,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { useConfiguracao } from '@hook/useConfiguracao'

export default function DialogSalvarParametro({
  open,
  onClose,
  categoria,
  listaConfiguracoes,
  save,
  update,
  value
}) {
  const { configData } = useConfiguracao()

  const comprimentoRefiladoId = 21
  const larguraRefiladoId = 22

  const [targetConfiguracao, setTargetConfiguracao] = useState(null)
  const [targetParametro, setTargetParametro] = useState(null)
  const [targetValor, setTargetValor] = useState('')
  const [typeInputValor, setTypeInputValor] = useState('number')
  const [inputValueError, setInputValueError] = useState(null)

  const handleSelectConfiguracao = value => {
    const findConfiguracao = listaConfiguracoes.find(
      item => item.idTabela === value
    )

    findConfiguracao
      ? setTargetConfiguracao(findConfiguracao)
      : setTargetConfiguracao(null)

    setTargetParametro(null)
    setTargetValor('')
  }

  const handleSelectParametro = value => {
    const findParametro = targetConfiguracao.parametros.find(
      item => item.id === value
    )

    findParametro ? setTargetParametro(findParametro) : setTargetParametro(null)

    setTargetValor('')
  }

  const handleSave = () => {
    value ? update(targetValor) : save(targetValor, targetParametro.id)
  }

  useEffect(() => {
    if (targetParametro && targetValor) {
      if (targetParametro.id === comprimentoRefiladoId) {
        if (Number(targetValor) > configData.descontoMaximoLargura) {
          setInputValueError(
            `O valor excede o desconto máximo do comprimento nas configurações da loja (Desconto no comprimento para corte útil da chapa (mm)). Valor máximo: ${configData.descontoMaximoLargura}`
          )
          return
        }
      }

      if (targetParametro.id === larguraRefiladoId) {
        if (Number(targetValor) > configData.descontoMaximoAltura) {
          setInputValueError(
            `O valor excede o desconto máximo da largura nas configurações da loja (Desconto na largura para corte útil da chapa  (mm)). Valor máximo: ${configData.descontoMaximoAltura}`
          )
          return
        }
      }
    }

    setInputValueError(null)
  }, [targetParametro, targetValor])

  useEffect(() => {
    if (targetParametro?.tipo) {
      switch (targetParametro.tipo) {
        case 1:
          setTypeInputValor('number')
          break

        case 2:
          setTypeInputValor('number')
          break

        default:
          setTypeInputValor('number')
          break
      }
    }
  }, [targetParametro])

  useEffect(() => {
    if (!value) return

    const findConfiguracao = listaConfiguracoes.find(
      item => item.idTabela === value.idTabela
    )

    if (!findConfiguracao) return

    const findParametro = findConfiguracao.parametros.find(
      item => item.caminho === value.caminho
    )

    if (!findParametro) return

    setTargetConfiguracao(findConfiguracao)
    setTargetParametro(findParametro)
    setTargetValor(value.valor)
  }, [value])

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      <DialogTitle>
        <Typography>
          {value ? 'Editar parâmetro' : `Adicionar parâmetro para ${categoria}`}
        </Typography>
        <IconButton aria-label="close" onClick={onClose} size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl variant="filled">
          <InputLabel id="configuracoes_label" htmlFor="filled-fileformat">
            Configuração
          </InputLabel>
          <Select
            labelId="configuracoes_label"
            variant="filled"
            size="small"
            value={targetConfiguracao?.idTabela || ''}
            onChange={e => handleSelectConfiguracao(e.target.value)}
            disabled={value}
          >
            {listaConfiguracoes.map(configuracao => {
              return (
                <MenuItem
                  key={configuracao.idTabela}
                  value={configuracao.idTabela}
                >
                  {configuracao.descricao}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <FormControl variant="filled">
          <InputLabel id="parametros_label" htmlFor="filled-fileformat">
            Parametro
          </InputLabel>
          <Select
            labelId="configuracoes_label"
            variant="filled"
            size="small"
            value={targetParametro?.id || ''}
            onChange={e => handleSelectParametro(e.target.value)}
            disabled={!targetConfiguracao || value}
          >
            {targetConfiguracao?.parametros?.map(parametro => {
              return (
                <MenuItem key={parametro.id} value={parametro.id}>
                  {parametro.descricao}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <FormControl>
          <TextField
            label="Valor"
            variant="filled"
            className="mb10"
            pattern="^\d+(?:\.\d{1.2})?$"
            type={typeInputValor}
            error={targetValor < 0 || inputValueError}
            helperText={inputValueError}
            InputProps={{ inputProps: { min: 0 } }}
            inputProps={{ inputMode: 'numeric' }}
            value={targetValor}
            onChange={e => setTargetValor(e.target.value)}
            disabled={!targetParametro}
          />
        </FormControl>

        <div className={isMobile ? 'w-100' : 'text-center'}>
          <Button
            onClick={handleSave}
            variant="contained"
            color="secondary"
            className={isMobile ? 'w-100 mb-4' : 'mb10 mr10'}
            disabled={inputValueError}
          >
            Salvar
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
            className={`blackButton ${isMobile ? 'w-100' : 'mb10'}`}
          >
            Cancelar
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
