import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

const DialogPrimeirosPassos = ({ open, close, onAssistir }) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth={'sm'}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Primeiros Passos</Typography>
        <IconButton aria-label="close" onClick={close} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography className="mb20">Seja bem vindo ao LeoPlan!</Typography>
        <Typography className="mb20">
          Para ajudar com seu primeiro projeto desenvolvemos um vídeo rápido
          especialmente para ajudar você.
        </Typography>
        <div className="text-center">
          <Button onClick={close} variant="contained" className="mb10 mt10 mr5">
            Pular
          </Button>
          <Button
            onClick={onAssistir}
            variant="contained"
            color="secondary"
            className="mb10 mt10 ml5"
          >
            Assistir vídeo
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default memo(DialogPrimeirosPassos)
