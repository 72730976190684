import { Button } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'

import { useProjeto } from '@hook/useProjeto'

import InfoMsg from '@component/InfoMsg/InfoMsg'

const HeaderMateriais = ({
  handleOpenModalAddFita,
  handleOpenModalAddMaterialGenerico,
  handleOpenModalAddChapa,
  goToImportacao
}) => {
  const { projeto } = useProjeto()
  const isEditable = projeto.origemProjeto !== 3
  return (
    isEditable && (
      <div className={isMobile ? '' : 'pad20 padT10'}>
        <div className="flex flex-col">
          <div className={isMobile ? 'flex-auto' : 'flex justify-between'}>
            <div className="flex">
              {projeto.tipoCorte !== 5 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenModalAddChapa()}
                  className={isMobile ? 'flex-auto mr5 mb10' : 'mr5 mb10'}
                >
                  {' '}
                  INCLUIR CHAPA{' '}
                </Button>
              )}
              {projeto.tipoCorte !== 5 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenModalAddFita()}
                  className={isMobile ? 'flex-auto mb10' : 'mb10'}
                >
                  {' '}
                  INCLUIR FITA{' '}
                </Button>
              )}
              {projeto.tipoCorte === 5 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenModalAddMaterialGenerico()}
                  className={isMobile ? 'flex-auto' : 'ml5 mb10'}
                >
                  {' '}
                  INCLUIR MATERIAL GENÉRICO{' '}
                </Button>
              )}
            </div>
            {projeto.tipoCorte === 1 && !isMobile && (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={goToImportacao}
                  className={isMobile ? 'w-100 mb-3' : 'mr5 mb10'}
                >
                  {' '}
                  Importar projeto{' '}
                </Button>
              </div>
            )}
          </div>
          {!isMobile && (
            <div className="w-100">
              <InfoMsg
                type="info"
                title="Materiais"
                msg="Inclua os materiais que você deseja utilizar neste projeto"
              />
            </div>
          )}
        </div>
      </div>
    )
  )
}

export default HeaderMateriais
