import { Print, HourglassEmpty } from '@mui/icons-material'
import { Button } from '@mui/material'

const ButtonPrint = ({ load, action }) => {
  return (
    <>
      {!load ? (
        <Button
          onClick={action}
          className="noPrint m5"
          variant="contained"
          color="primary"
        >
          <Print className="mr5" /> Imprimir
        </Button>
      ) : (
        <Button
          className="noPrint m5"
          disabled
          variant="contained"
          color="primary"
        >
          <HourglassEmpty className="mr5" /> Carregando
        </Button>
      )}
    </>
  )
}

export default ButtonPrint
