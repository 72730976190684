import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import StarIcon from '@mui/icons-material/Star'
import { TableFooter } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import { withStyles } from 'tss-react/mui'

import Mask from '@service/mask.js'

const StyledTableCell = withStyles(TableCell, () => ({
  head: {
    backgroundColor: '#F5F5F5'
  }
}))

const StyledTableFooter = withStyles(TableFooter, () => ({
  root: {
    backgroundColor: '#F5F5F5'
  }
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FAFAFA'
    },
    border: 'none'
  }
}))

const SortIcon = ({ sort, index }) => {
  return (
    <div className="table_sort">
      <ArrowDropDownIcon
        color={sort[0] === index && sort[1] === true ? 'secondary' : 'action'}
      />
      <ArrowDropUpIcon
        color={sort[0] === index && sort[1] === false ? 'secondary' : 'action'}
      />
    </div>
  )
}
const ratingObjc = (score, key) => {
  let stars = []
  for (let i = 0; i < score; i++) {
    stars = [
      ...stars,
      <StarIcon key={key + i} fontSize="small" color="secondary" />
    ]
  }
  for (let i = score; i < 5; i++) {
    stars = [
      ...stars,
      <StarIcon key={key + i} fontSize="small" color="disabled" />
    ]
  }
  return stars
}

export default function DefaultTable(props) {
  const {
    data,
    headers,
    children,
    sort,
    handleSortChange,
    id,
    onClickLine,
    selectedItems,
    upperHeader
  } = props
  const formatData = (data, key) => {
    if (data.type === 'date') {
      return moment(data.data).format('DD/MM/YYYY')
    } else if (data.type === 'dateHour') {
      return moment(data.data).format('DD/MM/YYYY - HH:mm:ss')
    } else if (data.type === 'hour') {
      return moment(data.data).format('HH:mm')
    } else if (data.type === 'rating') {
      return (
        <div key={key} className="table_ratingData">
          {ratingObjc(data.data, key)}
        </div>
      )
    } else return data.data
  }

  return (
    <TableContainer>
      <Table id={`${id}-table-element`} aria-label="customized table">
        <TableHead>
          {upperHeader && upperHeader}
          {headers && (
            <TableRow>
              {headers.map((header, index) => {
                const idHeader = `${id}-${header}-header-sort`
                const classHeader = sort
                  ? 'table_flexCell clicable'
                  : 'table_flexCell'
                const functionHeader = () => handleSortChange(index)

                return (
                  <StyledTableCell key={header + index}>
                    <div
                      id={idHeader}
                      className={classHeader}
                      onClick={data.length > 0 ? functionHeader : () => true}
                    >
                      {header}
                      {sort && header && <SortIcon sort={sort} index={index} />}
                    </div>
                  </StyledTableCell>
                )
              })}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {data.length ? (
            data.map(line => {
              const objHeaders = Object.keys(line).filter(
                header => header !== '_id' && header !== '_className'
              )
              const keyLine = line._id

              const classLine =
                (onClickLine ? 'clicable' : '') +
                (selectedItems?.includes(line._id) ? ' selected' : '') +
                (line._className ? ` ${line._className.data}` : '')
              const functionLine = onClickLine
                ? () => onClickLine(line)
                : () => false
              const idLine = `${id}-id${line._id}-line`

              return (
                <StyledTableRow
                  key={keyLine}
                  className={classLine}
                  onClick={functionLine}
                  id={idLine}
                  data-testid={idLine}
                >
                  {objHeaders.map((cell, index) => {
                    const key = line._id + cell
                    const data = headers
                      ? headers[index]?.props
                        ? headers[index]?.props.data
                        : headers[index]
                      : ''
                    const colSpan = line[cell]?._colSpan
                    const idString = `${id}-id${line._id}-line-${cell}`
                    const value = formatData(line[cell], line._id + cell)

                    return (
                      <StyledTableCell
                        data={data}
                        id={idString}
                        data-testid={idString}
                        key={key}
                        colSpan={colSpan}
                      >
                        {line[cell].type === 'currency'
                          ? Mask.currency(value)
                          : value}
                      </StyledTableCell>
                    )
                  })}
                </StyledTableRow>
              )
            })
          ) : (
            <tr>
              <td className="noItems">Nenhum dado foi encontrado</td>
            </tr>
          )}
        </TableBody>
        {children && (
          <StyledTableFooter>
            <TableRow className="table_pagination-container">
              {children}
            </TableRow>
          </StyledTableFooter>
        )}
      </Table>
    </TableContainer>
  )
}
