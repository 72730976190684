import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'

import PageTitle from '@component/PageTitle/PageTitle'

import ImportacaoDepara from '../ImportacaoDepara/index.js'
import ImportacaoFile from '../ImportacaoFile/index.js'

const DialogImportacao = ({
  openModalImportacao,
  handleCloseModalImportacao,
  handleOpenModalAddFita,
  handleOpenModalAddChapa,
  materialAutoSelect
}) => {
  const [pecasImportadas, setPecasImportadas] = useState([])

  const [pecasAgrupamentoMat, setPecasAgrupamentoMat] = useState([])
  const [pecasAgrupamentoAmb, setPecasAgrupamentoAmb] = useState([])

  const handleGetImport = (pecas, materiais, ambientes) => {
    setPecasImportadas(pecas)
    setPecasAgrupamentoMat(materiais)
    setPecasAgrupamentoAmb(ambientes)
  }

  const handleClose = () => {
    setPecasImportadas([])
    setPecasAgrupamentoMat([])
    setPecasAgrupamentoAmb([])
    handleCloseModalImportacao()
  }

  return (
    <>
      <Dialog
        open={openModalImportacao}
        aria-labelledby="customized-dialog-title"
        onClose={handleCloseModalImportacao}
        fullScreen={true}
      >
        <DialogContent>
          <div className={isMobile ? '' : 'pad20 padT10'}>
            <PageTitle title="Importação de Peças" />
          </div>
          {!pecasImportadas.length ? (
            <ImportacaoFile
              handleGetImport={handleGetImport}
              pecasImportadas={pecasImportadas}
              handleCloseModalImportacao={handleClose}
            />
          ) : (
            <ImportacaoDepara
              handleCloseModalImportacao={handleClose}
              pecasAgrupamentoMat={pecasAgrupamentoMat}
              setPecasAgrupamentoMat={setPecasAgrupamentoMat}
              pecasAgrupamentoAmb={pecasAgrupamentoAmb}
              setPecasAgrupamentoAmb={setPecasAgrupamentoAmb}
              pecasImportadas={pecasImportadas}
              handleOpenModalAddFita={handleOpenModalAddFita}
              handleOpenModalAddChapa={handleOpenModalAddChapa}
              materialAutoSelect={materialAutoSelect}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DialogImportacao
