const _set = (key, value) => {
  value = value ?? null
  sessionStorage.setItem(key, JSON.stringify(value))
  return _get(key)
}

const _get = key => {
  key = key ?? null
  return JSON.parse(sessionStorage.getItem(key))
}

const _clear = () => {
  sessionStorage.clear()
}

export default {
  set: _set,
  get: _get,
  clear: _clear
}
