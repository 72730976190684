import { Add, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Button, Backdrop, CircularProgress } from '@mui/material'
import React, { useEffect, useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { appConfig } from '@config/env-config.js'

import { useConfiguracao } from '@hook/useConfiguracao'
import { useProjeto } from '@hook/useProjeto'
import { useStepperControl } from '@hook/useStepperControl.js'
import { useUser } from '@hook/useUser.js'

import InfoMsg from '@component/InfoMsg/InfoMsg.js'

import api from '@service/api/index.js'
import Utils from '@service/serviceUtils.js'

import Ambientes from './Ambientes/index.js'
import AbaChapasCanaletadas from './ChapasCanaletadas/AbaChapasCanaletadas.js'
import DialogAddAmbiente from './Dialogs/DialogAddAmbiente.js'
import DialogAlertFita from './Dialogs/DialogAlertFita.js'
import DialogAlertVeio from './Dialogs/DialogAlertVeio.js'
import DialogDownloadFile from './Dialogs/DialogDownloadFile.js'
import DialogEnvioOrcamento from './Dialogs/DialogEnvioOrcamento.js'
import DialogVoltarListagem from './Dialogs/DialogVoltarListagem.js'
import LoadOtimizacao from './Dialogs/LoadOtimizacao.js'
import IniciarProjeto from './IniciarProjeto/index.js'
import Materiais from './Materiais/index.js'
import PlanoCorte from './PlanoCorte/index.js'
import StepperControl from './StepperControl.js'

const TIPO_VENDA_ID = {
  ZMOB: '75'
}

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    marginTop: '90px',
    marginBottom: '200px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  paper: {
    position: 'absolute',
    width: 400,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}))

const LeoPlanContainer = props => {
  const [showGerarPedido, setShowGerarPedido] = React.useState(false)
  const [loadPedido, setLoadPedido] = React.useState(false)
  const [isClientOwner, setIsClientOwner] = React.useState(false)
  const invalidMaterial = false

  const LojasPropriasComZLPL =
    appConfig?.REACT_APP_LojasPropriasComZLPL?.flatMap(
      objeto => objeto.lojasId
    ) ?? []

  const {
    habilitarCanaletado,
    habilitarFuracaoUsinagem,
    configData,
    setConfigData,
    setConfigStatus,
    setUpdateData
  } = useConfiguracao()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { type } = useParams()
  const { ambienteFoiAlterado } = useSelector(state => state.planoCorteState)
  const { lojas, showMsgE, showMsgW, showMsgS } = props
  const {
    setConfigLoja,
    cliente,
    setCliente,
    setProjeto,
    projeto,
    setLoja,
    loja,
    vendedor,
    setVendedor,
    otimizacao,
    setOtimizacao,
    changeProjeto,
    lojaCongifOtmizacao
  } = useProjeto()
  const limiteMinimoAlturaLargura = lojaCongifOtmizacao.minimoChapaCorte
  const limiteMinimoMaquina =
    lojaCongifOtmizacao.configuracoesOtimizacao?.minStripHeight
  const limiteCorteBorda = lojaCongifOtmizacao.minimoChapaPermiteFita
  const limiteCorteBordaOposto =
    lojaCongifOtmizacao.minChapaPermiteFitaLadoOposto <=
    lojaCongifOtmizacao.minimoChapaPermiteFita
      ? lojaCongifOtmizacao.minChapaPermiteFitaLadoOposto
      : lojaCongifOtmizacao.minimoChapaPermiteFita
  const limiteEspessura = lojaCongifOtmizacao.limiteEspessuraBordeamento
  let limiteMaximoAltura = 0
  let limiteMaximoLargura = 0

  const { user } = useUser()

  const [forceValidateAllPecas, setForceValidateAllPecas] = React.useState(0)

  const [criandoProjeto, setCriandoProjeto] = React.useState(true)

  const [openBkdrop, setOpenBkdrop] = React.useState(false)

  const closeBkdrop = useCallback(() => {
    setOpenBkdrop(false)
  }, [])
  const bkdropOpen = useCallback(() => {
    setOpenBkdrop(true)
  }, [])
  // - cria um novo projeto
  const criarProjeto = async () => {
    if (projeto?.nome?.length > 25) {
      showMsgE('O nome excede o limite de 25 caracteres')
      return false
    }

    if (user.tipoUserLeoPlan !== 'cliente' && !cliente.idErp) {
      showMsgW('Informe o cliente')
      return false
    }
    if (user.tipoUserLeoPlan === 'cliente') {
      if (!loja) {
        showMsgW('Informe uma loja')
        return false
      }
      if (!user) {
        showMsgW('Informe um vendedor')
        return false
      }
      if (!projeto.nome) {
        showMsgW('Informe um nome para o projeto')
        return false
      }
    }
    setCriandoProjeto(false)

    let baseProjeto = {}
    baseProjeto = {
      nomeProjeto: projeto.nome,
      nomeVendedor:
        user.tipoUserLeoPlan === 'cliente' ? vendedor.nome : user.nome,
      lojaIdErp: parseInt(loja.idErp),
      email: user.tipoUserLeoPlan === 'cliente' ? vendedor.email : user.email,
      clienteIdErp:
        user.tipoUserLeoPlan === 'cliente' ? user.idErp : cliente.idErp,
      nomeCliente:
        user.tipoUserLeoPlan === 'cliente' ? user.nome : cliente.nome,
      tipoCorte: 0
    }

    switch (type) {
      case 'C':
        baseProjeto.tipoCorte = 1
        break
      case 'H':
        baseProjeto.tipoCorte = 2
        break
      case 'V':
        baseProjeto.tipoCorte = 3
        break
      case 'CC':
        baseProjeto.tipoCorte = 4
        break
      case 'T':
        baseProjeto.tipoCorte = 5
        break
      default:
        baseProjeto.tipoCorte = 1
        break
    }

    if (!projeto.id) {
      const origem = localStorage.getItem('LEOPLAN_PATCH_ORIGEM')

      baseProjeto.origem = origem === 'SVL' ? 1 : origem === 'ECOM' ? 2 : 0
    }
    if (validForm('novoProjeto', baseProjeto)) {
      try {
        const response = await trackPromise(
          api.leoplan.apiCriarProjeto(baseProjeto)
        )

        if (response.fail || !response?.data?.id) {
          throw new Error(response.errors)
        }

        setProjeto(response.data)

        user.tipoUserLeoPlan !== 'cliente' && setVendedor(user)

        showMsgS('Projeto criado com sucesso')
        navigate(`/plano-de-corte/detalhe/${response.data.id}`)
        setActiveStep(1)
      } catch (error) {
        setCriandoProjeto(true)
        showMsgE(error)
      }
    } else {
      setCriandoProjeto(true)
    }
  }

  const editarProjeto = async () => {
    if (projeto?.nome?.length > 25) {
      showMsgE('O nome excede o limite de 25 caracteres')
      return false
    }

    if (user.tipoUserLeoPlan !== 'cliente' && !cliente.idErp) {
      showMsgW('Informe o cliente')
      return false
    }
    if (user.tipoUserLeoPlan === 'cliente') {
      if (!loja) {
        showMsgW('Informe uma loja')
        return false
      }
      if (!user) {
        showMsgW('Informe um vendedor')
        return false
      }
      if (!projeto.nome) {
        showMsgW('Informe um nome para o projeto')
        return false
      }
    }
    const baseProjeto = {
      projetoId: parseInt(projeto.id),
      nomeProjeto: projeto.nome,
      nomeVendedor:
        user.tipoUserLeoPlan === 'cliente' ? vendedor.nome : user.nome,
      lojaIdErp: parseInt(loja.idErp),
      email: user.tipoUserLeoPlan === 'cliente' ? vendedor.email : user.email,
      clienteIdErp:
        user.tipoUserLeoPlan === 'cliente' ? user.idErp : cliente.idErp,
      nomeCliente:
        user.tipoUserLeoPlan === 'cliente' ? user.nome : cliente.nome,
      tipoCorte: projeto.tipoCorte ? projeto.tipoCorte : 1
    }

    if (validForm('editarProjeto', baseProjeto)) {
      try {
        const response = await trackPromise(
          api.leoplan.apiEditarProjeto(baseProjeto)
        )

        if (response.fail || !response?.data?.id) {
          throw new Error(response.errors)
        }

        showMsgS('Projeto alterado com sucesso')
        props.planAmbienteSalvo(true)
      } catch (error) {
        showMsgE(error)
      }
    }
  }
  const [newAmbienteName, setNewAmbienteName] = React.useState('')

  const [expandedAmb, setExpandedAmb] = React.useState([])

  // dispara abertura ou fechamento dos ambientes na tela
  // const clickAmbExpand = (panelIndex) => (event, newExpanded) => {
  const clickAmbExpand = useCallback(panelIndex => {
    // debugger
    const newlista = []
    if (expandedAmb[panelIndex]) {
      // newlista = expandedAmb.map(x => x)
      newlista[panelIndex] = false
      setExpandedAmb(newlista)
    } else {
      // newlista = expandedAmb.map(x => x)
      newlista[panelIndex] = true
      setExpandedAmb(newlista)
    }
  })

  // - monta objeto com todas as peças usado para salvar todas as peças de uma vez
  const formataObjAllPecas = () => {
    function getCanaletado(idMaterial) {
      return projeto.projetoProduto.find(m => m.id === idMaterial)?.canaletado
    }

    function getPeca(pecas) {
      const pecasFormat = []
      pecas.forEach(peca => {
        let veioPeca = false
        if (projeto.tipoCorte === 2 || projeto.tipoCorte === 3) {
          // tiras horizontais
          veioPeca = true
        } else {
          // veioPeca = getVeio(peca.materialId)
          veioPeca = peca.veio
        }
        pecasFormat.push({
          id: peca.id,
          descricao: peca.descricao,
          largura: peca.largura,
          altura: peca.altura,
          quantidade: peca.quantidade,
          veio: veioPeca,
          materialId: peca.materialId,
          pecaProduto: peca.pecaProduto,
          superiorMaterialId: peca.superiorMaterialId,
          inferiorMaterialId: peca.inferiorMaterialId,
          esquerdaMaterialId: peca.esquerdaMaterialId,
          direitaMaterialId: peca.direitaMaterialId,
          ambienteId: peca.ambienteId,
          canaletado: getCanaletado(peca.materialId),
          projetoPecaServico: Utils.updatePayloadConfigurador(peca, projeto)
        })
      })
      return pecasFormat
    }

    const baseProjeto = {
      projetoId: projeto.id
        ? parseInt(projeto.id)
        : projeto.projetoAmbiente[0].projetoId,
      ambientes: projeto.projetoAmbiente.map(a => {
        const ambiente = {
          id: a.id,
          descricao: a.descricao,
          projetoId: a.projetoId,
          pecas: getPeca(a.projetoPeca)
        }
        return ambiente
      })
    }
    return baseProjeto
  }

  const checkHasProdutoNaoUtilizado = () => {
    const MDFsNoProjeto = projeto.projetoProduto.filter(
      x => x.tipoProdutoId === 1
    )

    const MDFsutilizados = projeto?.projetoAmbiente.reduce((arr, ambiente) => {
      ambiente.projetoPeca.forEach(peca => {
        const produtoVinculado = MDFsNoProjeto.find(
          produto => peca.materialId === produto.id
        )
        if (produtoVinculado) arr.push(produtoVinculado.id)
      })

      return arr
    }, [])

    const MDFsNaoUtilizados = MDFsNoProjeto.filter(
      produto => !MDFsutilizados.includes(produto.id)
    ).map(mdf => mdf.codigo)

    if (MDFsNaoUtilizados.length > 0) {
      if (MDFsNaoUtilizados.length === 1) {
        showMsgE(
          `Remova o MDF ${MDFsNaoUtilizados}, ele não esta sendo usado no plano`
        )
      } else {
        showMsgE(
          `Remova os MDFs ${MDFsNaoUtilizados}, eles não estão sendo usados no plano`
        )
      }

      return true
    }

    const FITAsNoProjeto = projeto.projetoProduto.filter(
      x => x.tipoProdutoId === 2
    )
    const listaFitaIds = FITAsNoProjeto.map(fita => fita.id)
    const FITAsutilizadas = projeto?.projetoAmbiente.reduce((arr, ambiente) => {
      ambiente.projetoPeca.forEach(peca => {
        peca.pecaProduto.forEach(peca => {
          if (listaFitaIds.includes(peca.projetoProdutoId))
            arr.push(peca.projetoProdutoId)
        })
      })

      return arr
    }, [])

    const FITAsNaoUtilizadas = FITAsNoProjeto.filter(
      produto => !FITAsutilizadas.includes(produto.id)
    ).map(fita => fita.codigo)

    if (MDFsNaoUtilizados.length > 0) {
      if (MDFsNaoUtilizados.length === 1) {
        showMsgE(
          `Remova o MDF ${MDFsNaoUtilizados}, ele não esta sendo usado no plano`
        )
      } else {
        showMsgE(
          `Remova os MDFs ${MDFsNaoUtilizados}, eles não estão sendo usados no plano`
        )
      }

      return true
    }

    if (FITAsNaoUtilizadas.length > 0) {
      if (FITAsNaoUtilizadas.length === 1) {
        showMsgE(
          `Remova a Fita ${FITAsNaoUtilizadas}, ela não esta sendo usada no plano`
        )
      } else {
        showMsgE(
          `Remova as Fitas ${FITAsNaoUtilizadas}, elas não estão sendo usadas no plano`
        )
      }
      return true
    }
    return false
  }

  // - salva todas as peças de uma vez
  const validarGrupoPecas = () => {
    const hasMaterialWarning = checkMaterialWarning()
    const hasIgnorarVeio = checkIgnorarVeio() && projeto.tipoCorte === 1
    const hasFitaWarning = checkFitaWarning()
    const hasCanaletadoWarning = checkCanaletadoWarning()
    const hasUsinagemWarning = checkUsinagemWarning()
    const hasProdutoNaoUtilizado = checkHasProdutoNaoUtilizado()

    if (hasMaterialWarning) {
      showMsgW('Selecione uma chapa em cada peça para continuar')
    } else if (hasIgnorarVeio) {
      handleOpenModalAlertVeio()
    } else if (hasFitaWarning.some(peca => peca)) {
      handleOpenModalAlertFita()
    } else if (hasCanaletadoWarning) {
      showMsgW('Preencha as informações corretamente antes de continuar')
    } else if (hasUsinagemWarning) {
      showMsgW('Salve a furação ou usinagem para continuar')
    } else if (hasProdutoNaoUtilizado) {
      return
    } else {
      handleDataSubmit()
    }
  }

  const checkMaterialWarning = () => {
    const projetoFormated = formataObjAllPecas()
    const pecasInvalidas = projetoFormated.ambientes
      ?.map(ambiente => {
        return ambiente?.pecas?.find(peca => !peca.materialId)
      })
      .filter(x => x)

    return !!pecasInvalidas?.length
  }

  const checkCanaletadoWarning = () => {
    if (projeto.tipoCorte === 4) {
      const projeto = formataObjAllPecas()
      return !!projeto.ambientes[0].pecas.find(
        peca => peca.canaletado === 0 || peca.quantidade <= 0
      )
    }
  }

  const checkUsinagemWarning = () => {
    const projeto = formataObjAllPecas()
    return !!projeto.ambientes[0].pecas.find(
      peca =>
        peca?.projetoPecaServico?.length &&
        (peca?.projetoPecaServico[0].payloadConfigurador === '' ||
          !peca?.projetoPecaServico[0].payloadConfigurador)
    )
  }

  const checkFitaWarning = () => {
    const projetoFormated = formataObjAllPecas()
    const validDefaultFita = projetoFormated.ambientes?.map(ambiente => {
      return ambiente.pecas.map(peca => {
        const fitas = projeto.projetoProduto.filter(
          fita =>
            fita.id.toString() === peca.direitaMaterialId.toString() ||
            fita.id.toString() === peca.esquerdaMaterialId.toString() ||
            fita.id.toString() === peca.superiorMaterialId.toString() ||
            fita.id.toString() === peca.inferiorMaterialId.toString()
        )
        if (fitas.length) {
          const chapa = projeto.projetoProduto.find(
            chapa => chapa.id.toString() === peca?.materialId?.toString()
          )

          if (!chapa) return peca
          const result = Utils.validarEspessuraFita(fitas, chapa)
          if (result.length) return result
          else return null
        } else return null
      })
    })

    return validDefaultFita.reduce((acc, ambiente) => [...acc, ...ambiente], [])
  }

  const checkIgnorarVeio = () => {
    const projectMaterials = projeto.projetoProduto.map(({ id, veio }) => {
      return { id, veio }
    })
    const hasIgnorarVeio =
      projeto.projetoAmbiente.reduce((acumulador, valorAtual) => {
        // return 0
        const veioIgnorado = valorAtual.projetoPeca.filter(peca => {
          const product = projectMaterials.find(
            material => material.id === peca.materialId
          )
          if (product) {
            // checa se a peça tem veio true com o material false e corrige
            if (product.veio !== peca.veio && peca.veio === true) {
              console.info('Veio Inconsistente', peca)
              peca.veio = false
            }
            return product.veio !== peca.veio
          } else return false
        })
        if (veioIgnorado.length) return acumulador + 1
        else return acumulador
      }, 0) > 0

    return hasIgnorarVeio
  }

  const handleDataSubmit = async () => {
    try {
      if (user?.tipoUserLeoPlan?.toUpperCase() === 'VENDEDOR') {
        if (projeto.lojaIdErp.toString() !== user.loja.idErp.toString()) {
          // chamar api para atualizar loja e vendedor no projeto
          bkdropOpen()
          const response = await trackPromise(
            api.leoplan.apiEditarLojaVendedorProjeto(
              projeto.id,
              user.loja.idErp,
              user.email
            )
          )

          if (response.fail) throw new Error()

          const prodAlterado = projeto
          prodAlterado.lojaIdErp = parseInt(user.loja.idErp)
          setProjeto(prodAlterado)

          setTimeout(function () {
            closeBkdrop()
            salvarGrupoPecasEOtimizar()
          }, 500)
        } else {
          salvarGrupoPecasEOtimizar()
        }
      } else {
        salvarGrupoPecasEOtimizar()
      }
    } catch {
      showMsgE('Algum erro aconteceu ao tentar atualizar o projeto')
      closeBkdrop()
    }
  }

  const salvarGrupoPecasEOtimizar = async () => {
    try {
      const baseProjeto = formataObjAllPecas()

      if (
        validForm('possuiPecas', baseProjeto) &&
        validForm('pecasEmBranco', baseProjeto) &&
        validForm('pecasFormatoValido', projeto.projetoAmbiente) &&
        validForm('fitasInvalidas', projeto.projetoAmbiente) &&
        validForm('fitasNaoAplicadas', projeto.projetoAmbiente)
      ) {
        setLoadOtimization({ load: true, buscarStatus: false })

        const response = await trackPromise(
          api.leoplan.apiFinalizarProjeto(baseProjeto)
        )

        if (response.fail) throw new Error()

        setLoadOtimization({ load: true, buscarStatus: false })

        // Arranjo tecnico temporário para correção de configurações de corte
        // Remover ao ajustar endpoint de otmização e configuração de plano
        if (['1050', '1051', '1049'].includes(projeto.lojaIdErp.toString())) {
          vendedor.empresaIdErp = vendedor.lojaIdErp
        }

        const responseOtimizar = await trackPromise(
          api.leoplan.apiOtimizar(
            baseProjeto.projetoId,
            vendedor.lojaIdErp,
            vendedor.empresaIdErp
          )
        )

        if (responseOtimizar.fail) throw new Error()

        setLoadOtimization({ load: true, buscarStatus: true })
      } else {
        setForceValidateAllPecas(forceValidateAllPecas + 1)
      }
    } catch (error) {
      showMsgE('Algum erro aconteceu ao tentar alterar o projeto')
      setLoadOtimization({ load: false, buscarStatus: false })
    } finally {
      closeBkdrop()
    }
  }

  const checkHasPecas = projeto => {
    return projeto.ambientes.find(ambiente => ambiente.pecas.length)
  }

  const salvarGrupoPecas = useCallback((validation = false) => {
    bkdropOpen()
    const baseProjeto = formataObjAllPecas()

    // confere e ajusta os veios das peças
    if (!checkHasPecas(baseProjeto)) {
      showMsgW('Inclua ao menos uma peça para salvar o projeto')
      closeBkdrop()
      return
    }

    checkIgnorarVeio()

    if (validation) {
      if (
        validForm('possuiPecas', baseProjeto, false) &&
        validForm('pecasEmBranco', baseProjeto, false) &&
        validForm('pecasFormatoValido', projeto.projetoAmbiente, false) &&
        validForm('fitasInvalidas', projeto.projetoAmbiente, false) &&
        validForm('fitasNaoAplicadas', projeto.projetoAmbiente, false)
      ) {
        savePecas()
      } else {
        savePecas()
        setForceValidateAllPecas(forceValidateAllPecas + 1)
      }
    } else {
      savePecas()
    }
    closeBkdrop()

    async function savePecas() {
      try {
        if (validation) bkdropOpen()

        const response = await trackPromise(
          api.leoplan.apiFinalizarProjeto(baseProjeto)
        )

        if (response.fail) throw new Error()

        changeProjeto('projetoAmbiente', response.data.projetoAmbiente)

        showMsgS('Projeto salvo com sucesso!')
      } catch {
        showMsgE('Algum erro aconteceu ao tentar salvar o projeto')
      } finally {
        if (validation) closeBkdrop()
      }
    }
  })

  //  get detalheOtimizacao
  function validarInclusaoPedido() {
    if (projeto.projetoPecaServico.length && !habilitarFuracaoUsinagem) {
      return showMsgE(
        'Os serviços de furação e usinagem não estão habilitados nesta loja'
      )
    }

    if (
      user.tipoVenda === 'ZLPL' &&
      LojasPropriasComZLPL.includes(loja.idErp) &&
      projeto?.projetoPecaServico?.length
    ) {
      return showMsgE(
        'Não é possível incluir serviços de furação e usinagem em ordem de venda ZLPL'
      )
    }

    const materiaisCanaletados = projeto.projetoProduto.filter(
      m => m.canaletado > 0 && m.tipoProdutoId === 1
    )

    if (
      !habilitarCanaletado &&
      materiaisCanaletados.length &&
      !configData?.empresaLojasCanaletado?.includes(projeto.lojaIdErp)
    ) {
      return showMsgE('O serviço de canaletagem não está habilitado nesta loja')
    }

    /// ///////////////////////////////////////////////////////////
    /// // verifica se a loja do projeto é a mesma da loja do svl
    if (user.tipoUserLeoPlan.toUpperCase() === 'VENDEDOR') {
      // console.log('teste 22', projeto, user)
      if (projeto.lojaIdErp.toString() !== user.loja.idErp.toString()) {
        showMsgE(
          'Loja informada no projeto é diferente da loja do vendedor "Re-otimize o projeto"'
        )
        return false
      }
    }

    /// ///////////////////////////////////////////////////////////
    /// / verifica se existe materias MDFs sem ser utilizados vinculados ao projeto
    const MDFsNaoUtilizados = []
    const chapasVinculadas = projeto.projetoProduto.filter(
      x => x.tipoProdutoId === 1
    )
    // let chapasVinculadas = projeto.projetoProduto.filter(x => x.tipoProdutoId === 1)
    // console.log('eeeee', MDFsNaoUtilizados, chapasVinculadas)
    // console.log('plano corte projeto 1', otimizacao)
    // console.log('plano corte projeto 2', projeto.planoCorte, otimizacao)
    chapasVinculadas.forEach(mdfVinculado => {
      let found = false

      projeto.planoCorte.forEach(planocorte => {
        if (mdfVinculado.quantidade > 0) {
          if (planocorte.placaIdERP === mdfVinculado.codigo) {
            found = true
          }
        }
      })

      if (!found) {
        MDFsNaoUtilizados.push(mdfVinculado.codigo)
      }
    })
    if (MDFsNaoUtilizados.length > 0) {
      if (MDFsNaoUtilizados.length === 1) {
        showMsgE(
          `Remova o MDF ${MDFsNaoUtilizados}, ele não esta sendo usado no plano`
        )
      } else {
        showMsgE(
          `Remova os MDFs ${MDFsNaoUtilizados}, eles não estão sendo usados no plano`
        )
      }
      // console.log(MDFsNaoUtilizados)
      return false
    }

    const FitasNaoUtilizadas = []
    const fitasVinculadas = projeto.projetoProduto.filter(
      x => x.tipoProdutoId === 2
    )

    fitasVinculadas.forEach(fitaVinculada => {
      let found = false

      projeto.planoCorte.forEach(planocorte => {
        planocorte.planoCorteFitas.forEach(fita => {
          if (fitaVinculada.quantidade > 0) {
            if (fita.codigo === fitaVinculada.codigo) {
              found = true
            }
          }
        })
      })

      if (!found) {
        FitasNaoUtilizadas.push(fitaVinculada.codigo)
      }
    })
    if (FitasNaoUtilizadas.length > 0) {
      if (FitasNaoUtilizadas.length === 1) {
        showMsgE(
          `Remova a Fita ${FitasNaoUtilizadas}, ela não esta sendo usada no plano`
        )
      } else {
        showMsgE(
          `Remova as Fitas ${FitasNaoUtilizadas}, elas não estão sendo usadas no plano`
        )
      }
      // console.log(FitasNaoUtilizadas)
      return false
    }
    /// ////////////////////////
    // inicia processo de descida para o pedido
    if (
      showGerarPedido &&
      (user.tipoVenda === 'ZCVS' ||
        user.tipoVenda === 'ZLPL' ||
        user.tipoVendaId === TIPO_VENDA_ID.ZMOB)
    ) {
      setLoadPedido(true)
      bkdropOpen()
      incluirProjetoNoPedido()
    }
  }

  // retorna array com indexs dos ambientes usado para controlar abetura ou fechamento dos ambientes na tela
  async function incluirProjetoNoPedido() {
    try {
      const response = await trackPromise(
        api.leoplan.apiVincularPedido(projeto.id, user.pedidoId)
      )

      if (response.fail) throw new Error('Erro ao carregar dados do projeto')

      if (!response.Projeto && !!response.message) {
        throw new Error(response.message)
      }

      const produtosFormatados = response?.data?.Projeto?.produtos.map(
        produto => ({
          ...produto,
          codigo: produto.codigo.split('-', 1)[0],
          planoCorteIds: response?.data?.Projeto?.planoCorte
            .map(planoCorte => {
              if (produto.tipoProdutoId === 1) {
                const find = planoCorte.placas.find(
                  placa => placa.placaIdERP === produto.codigo
                )

                if (find) return planoCorte.id
              }

              if (produto.tipoProdutoId === 2) {
                const find = planoCorte.fitas.find(
                  fita => fita.codigo === produto.codigo
                )

                if (find) return planoCorte.id
              }

              if (produto.tipoProdutoId === 3) {
                if (planoCorte.qtdPlacasCortadas > 0) return planoCorte.id
              }

              if (produto.tipoProdutoId === 4) {
                if (planoCorte.possuiCanaletado === produto.canaletado) {
                  return planoCorte.id
                }
              }
              //(Furação - 5), (Canal - 6), (Rebaixo - 7)
              const servicosFuracaoUsinagem = [5, 6, 7]
              if (
                servicosFuracaoUsinagem.includes(produto.tipoProdutoId) &&
                produto.quantidade > 0
              ) {
                return planoCorte.id
              }
            })
            .filter(x => x)
        })
      )

      const planoCorteFormatado = response?.data?.Projeto?.planoCorte.map(
        planoCorte => ({
          ...planoCorte,
          placaIdERP: planoCorte.placaIdERP.split('-', 1)[0],
          placas: planoCorte.placas.map(placa => ({
            ...placa,
            placaIdERP: placa.placaIdERP.split('-', 1)[0]
          }))
        })
      )

      const adaptRes = {
        Ativo: response.data.Projeto.ativo,
        ClienteFinal: response.data.Projeto.clienteFinal,
        ClienteFinalId: response.data.Projeto.clienteFinalId,
        ClienteLEOId: response.data.Projeto.clienteLEOId,
        DataCriacao: response.data.Projeto.dataCriacao,
        DataInativacao: response.data.Projeto.dataInativacao,
        DataUltimaAtualizacao: response.data.Projeto.dataUltimaAtualizacao,
        Id: response.data.Projeto.id,
        LojaIdErp: response.data.Projeto.lojaIdErp,
        Nome: response.data.Projeto.nome,
        OrcamentoDtCriacao: response.data.Projeto.orcamentoDtCriacao,
        OrcamentoNro: response.data.Projeto.orcamentoNro,
        OrigemProjeto: response.data.Projeto.origemProjeto,
        PedidoId: response.data.Projeto.pedidoId,
        StatusId: response.data.Projeto.statusId,
        UsuarioInativacao: response.data.Projeto.usuarioInativacao,
        VendedorId: response.data.Projeto.vendedorId,
        Ambientes: response.data.Projeto.ambientes,
        ClienteLEO: response.data.Projeto.clienteLEO,
        Historico: response.data.Projeto.historico,
        Produtos: produtosFormatados,
        Status: response.data.Projeto.status,
        Vendedor: response.data.Projeto.vendedor,
        Pecas: response.data.Projeto.pecas,
        PlanoCorte: planoCorteFormatado
      }

      console.log(adaptRes)

      const stringObj = JSON.stringify(adaptRes)
      window.parent.postMessage(stringObj, '*')
    } catch (error) {
      closeBkdrop()
      setLoadPedido(false)
      showMsgE(error.message)
    }
  }

  // - cria um novo ambiente em projetos antigos que ainda não possuem ambientes (usada apenas em projetos que foram criados que não possuem um ambiente)
  const forcarCriacaoAmbiente = async () => {
    try {
      if (projeto.id) {
        const newAmbiente = [
          {
            descricao: 'Ambiente 1',
            projetoId: parseInt(projeto.id),
            pecas: []
          }
        ]

        const response = await trackPromise(
          api.leoplan.apiNovoAmbiente(newAmbiente)
        )

        if (response.fail) throw new Error()

        const novaListaAmbientes = projeto.projetoAmbiente
        novaListaAmbientes.push({
          id: response.data[0].id,
          descricao: response.data[0].descricao,
          projetoId: response.data[0].projetoId,
          projetoPeca: []
        })
        changeProjeto('projetoAmbiente', novaListaAmbientes)
      }
    } catch {
      showMsgE('Não foi possível criar o ambiente')
    }
  }

  // - cria um novo ambiente no projeto
  const criarAmbienteViaApi = async (
    projetoId,
    novoProjeto = false,
    objProjeto = null
  ) => {
    try {
      let newAmbiente = {}

      if (projeto?.tipoCorte === 4) {
        newAmbiente = [
          {
            descricao: 'Chapa Canaletada',
            projetoId: parseInt(projetoId),
            pecas: []
          }
        ]
      } else if (novoProjeto) {
        newAmbiente = [
          {
            descricao: 'Ambiente 1',
            projetoId: parseInt(projetoId),
            pecas: []
          }
        ]
      } else {
        newAmbiente = [
          {
            descricao: newAmbienteName,
            projetoId: parseInt(projetoId),
            pecas: []
          }
        ]
      }

      if (!newAmbiente[0]?.descricao) {
        showMsgW('Informe um nome para o Ambiente')
        return
      }

      const response = await trackPromise(
        api.leoplan.apiNovoAmbiente(newAmbiente)
      )

      if (response.fail) throw new Error()

      if (response?.data[0]?.id) {
        const novaListaAmbientes = projeto.projetoAmbiente
        novaListaAmbientes.push({
          id: response.data[0].id,
          descricao: response.data[0].descricao,
          projetoId: response.data[0].projetoId,
          projetoPeca: []
        })

        if (novoProjeto) {
          objProjeto.projetoAmbiente = response.data[0]

          navigate(`/plano-de-corte/detalhe/${objProjeto.id}`)

          setProjeto(objProjeto)
          handleComplete(0)

          setActiveStep(1)
        } else {
          changeProjeto('projetoAmbiente', novaListaAmbientes)
          clickAmbExpand(novaListaAmbientes.length - 1)
          setNewAmbienteName('')
        }
      }
    } catch {
      if (novoProjeto) {
        showMsgE('Não foi possível criar um ambiente para o projeto')
        navigate('/plano-de-corte/projetos/disponiveis')
      } else {
        showMsgE('Não foi possível criar o ambiente')
      }
    }
  }
  /// ////////////////////////////////////////////////////////
  /// PLANOS DE CORTE
  const [otimizacaoIndexDown, setOtimizacaoIndexDown] = React.useState('')
  const [otimizacaoIdDown, setOtimizacaoIdDown] = React.useState('')
  const [otimizacaoCodMaterial, setOtimizacaoCodMaterial] = React.useState('')

  const [loadOtimization, setLoadOtimization] = React.useState({
    load: false,
    buscarStatus: false
  })

  // - get detalheOtimizacao
  const getDetOtimizacao = async () => {
    try {
      setLoadOtimization({ load: true, buscarStatus: false })

      const responseOtimizacao = await trackPromise(
        api.leoplan.apiGetOtimizacao(projeto.id)
      )

      if (responseOtimizacao.fail || responseOtimizacao?.data?.length <= 0) {
        throw new Error()
      }

      const responseProjeto = await trackPromise(
        api.leoplan.apiGetProjetoDetAsync(projeto.id)
      )

      if (responseProjeto.fail) throw new Error()

      setProjeto(responseProjeto.data.projeto)
      // interrompe bloqueio do load
      setLoadOtimization({ load: false, buscarStatus: false })
      // atualiza materiais na tela
      changeProjeto(
        'projetoProduto',
        responseProjeto.data.projeto.projetoProduto
      )
      changeProjeto(
        'projetoProduto',
        Utils.atribuiCorFitas(responseProjeto.data.projeto.projetoProduto)
      )
      // seta flag de projeto salvo para não bloquear stepper
      props.planAmbienteSalvo(false)
      // repopula demais informações do projeto na tela

      if (projeto.tipoCorte === 4) {
        // marca step de chapas canaletadas como finalizado
        handleComplete(1)
        // direciona para step de otimização
        setActiveStep(2)
      } else {
        // marca step de ambientes como finalizado
        handleComplete(2)
        // direciona para step de otimização
        setActiveStep(3)
      }

      // fecha bloqueio de load padrão
      closeBkdrop()
      // reseta validação das peças
      setForceValidateAllPecas(0)

      // popula otimização
      setOtimizacao(responseOtimizacao.data)
    } catch {
      showMsgE('Não foi possível carregar a otimização')
    }
  }

  const modalDownload = (
    idOtimizacao,
    indexOtimizacao,
    codMaterialOtimizacaoDown
  ) => {
    setOtimizacaoIndexDown(indexOtimizacao)
    setOtimizacaoIdDown(idOtimizacao)
    setOtimizacaoCodMaterial(codMaterialOtimizacaoDown)
    handleOpenModalDownload()
  }

  // - get detalheOtimizacao
  const errorOtimizacao = msgError => {
    setLoadOtimization({ load: false, buscarStatus: false })
    showMsgE(msgError)
  }

  /// ////////////////////////////////////////////////////////
  /// ////////////////////////////////////////////////////////
  /// POPUPS
  const [alertEstoqueModal, setAlertEstoqueModal] = React.useState(false)
  const handleAlertEstoqueOpen = material => {
    setAlertEstoqueModal(material)
  }

  const handleAlertEstoqueClose = () => {
    setAlertEstoqueModal(false)
  }
  const [openModalDownload, setOpenModalDownload] = React.useState(false)
  const handleOpenModalDownload = () => {
    setOpenModalDownload(true)
  }
  const handleCloseModalDownload = () => {
    setOpenModalDownload(false)
  }

  const [openModalAlertVeio, setOpenModalAlertVeio] = React.useState(false)
  const handleOpenModalAlertVeio = () => {
    setOpenModalAlertVeio(true)
  }
  const handleCloseModalAlertVeio = () => {
    setOpenModalAlertVeio(false)
  }

  const [openModalAlertFita, setOpenModalAlertFita] = React.useState(false)
  const handleOpenModalAlertFita = () => {
    setOpenModalAlertFita(true)
  }
  const handleCloseModalAlertFita = () => {
    setOpenModalAlertFita(false)
  }

  const [openModalNewAmbiente, setOpenModalNewAmbiente] = React.useState(false)
  const handleOpenModalNewAmbiente = () => {
    setOpenModalNewAmbiente(true)
  }
  const handleCloseModalNewAmbiente = () => {
    setOpenModalNewAmbiente(false)
  }
  /// ////////////////////////////////////////////////////////
  /// ////////////////////////////////////////////////////////
  // // STEP CONTROLER

  const {
    activeStep,
    setActiveStep,
    completed,
    handleComplete,
    voltarEtapa,
    setIsCanaletadoProject
  } = useStepperControl()

  // navegacao clicando nos steps
  const navegaToStep = step => {
    switch (step) {
      case 0:
        setActiveStep(step)
        break
      case 1:
        if (projeto.id) {
          setActiveStep(step)
        } else {
          showMsgW('Inicie o projeto antes de avançar para esta etapa')
        }
        break
      case 2:
        if (projeto.id) {
          if (projeto.tipoCorte === 4) {
            if (otimizacao.length > 0 && !ambienteFoiAlterado) {
              setActiveStep(step)
            } else {
              // console.log('33',planProjeto.pedidoId)
              if (projeto.pedidoId) {
                setActiveStep(step)
              } else {
                showMsgW('Otimize seu projeto antes de avançar para esta etapa')
              }
            }
          } else {
            const mat = projeto.projetoProduto.filter(
              x => x.tipoProdutoId === 1
            )
            if (mat.length > 0) {
              if (projeto.projetoAmbiente.length === 0) {
                forcarCriacaoAmbiente()
              }
              setActiveStep(step)
              if (projeto.projetoAmbiente.length === 0) {
                clickAmbExpand(0)
              }
            } else {
              showMsgW(
                'Inclua pelo menos uma chapa antes de avançar para esta etapa'
              )
            }
          }
        } else {
          showMsgW('Inicie o projeto antes de avançar para esta etapa')
        }
        break
      case 3:
        if (projeto.id) {
          if (otimizacao.length > 0 && !ambienteFoiAlterado) {
            setActiveStep(step)
          } else {
            if (projeto.pedidoId) {
              setActiveStep(step)
            } else {
              showMsgW('Otimize seu projeto antes de avançar para esta etapa')
            }
          }
        } else {
          showMsgW('Inicie o projeto antes de avançar para esta etapa')
        }
        break
      default:
      /// /console.log('---------')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  // - valida informações obrigatórias
  const validForm = function (id, obj, exibeAlert = true) {
    const errorFields = []
    let msgType = 0
    let ret = true

    if (id === 'novoProjeto') {
      if (obj.lojaIdErp === '' || obj.lojaIdErp === undefined) {
        ret = false
        msgType = 0
        errorFields.push('Loja')
      }
      if (obj.nomeVendedor === '' || obj.nomeVendedor === undefined) {
        ret = false
        msgType = 0
        errorFields.push('Vendedor')
      }
      if (obj.clienteIdErp === '' || obj.clienteIdErp === undefined) {
        ret = false
        msgType = 0
        errorFields.push('Cliente')
      }
      if (obj.nomeProjeto === '' || obj.nomeProjeto === undefined) {
        ret = false
        msgType = 0
        errorFields.push('Nome do Projeto')
      }
    }

    if (id === 'editarProjeto') {
      if (obj.nomeProjeto === '' || obj.nomeProjeto === undefined) {
        ret = false
        msgType = 0
        errorFields.push('Nome do Projeto')
      }
      if (obj.projetoId === '' || obj.projetoId === undefined) {
        ret = false
        msgType = 0
        errorFields.push('Projeto Id não encontrado')
      }
    }

    if (id === 'possuiPecas') {
      // verifica se todos os ambientes possuem ao menos uma peça
      obj.ambientes.forEach(ambiente => {
        if (ambiente.pecas.length === 0) {
          ret = false
          msgType = 1
          errorFields.push(`${ambiente.descricao}`)
        }
      })
    }

    if (id === 'pecasEmBranco') {
      // verifica se os campos obrigatórios da peça foram preenchidos
      obj.ambientes.forEach(ambiente => {
        ambiente.pecas.forEach(peca => {
          /// /|| peca.descricao === ''
          if (
            peca.altura === 0 ||
            peca.largura === 0 ||
            peca.quantidade === 0
          ) {
            ret = false
            msgType = 2
            errorFields.push(`${ambiente.descricao}`)
          }
        })
      })
    }

    if (id === 'fitasNaoAplicadas') {
      // verifica se a fitas selecionadas na peça sem ser utilizadas
      let pecaNaoAplicada = false
      obj.forEach(ambiente => {
        ambiente.projetoPeca.forEach(peca => {
          peca.pecaProduto.forEach(produto => {
            if (
              produto.projetoProdutoId !== peca.direitaMaterialId &&
              produto.projetoProdutoId !== peca.esquerdaMaterialId &&
              produto.projetoProdutoId !== peca.inferiorMaterialId &&
              produto.projetoProdutoId !== peca.superiorMaterialId
            ) {
              const itemProduto = projeto.projetoProduto.filter(
                x => x.id === produto.projetoProdutoId
              )
              if (itemProduto.length > 0) {
                pecaNaoAplicada = true
              }
            }
          })
          if (pecaNaoAplicada) {
            ret = false
            msgType = 6
            errorFields.push(`${ambiente.descricao}`)
          }
        })
      })
    }

    if (id === 'fitasInvalidas') {
      // debugger

      /// verifica se existe alguma fita marcada não incluisa no projeto
      const fitasIncluidasNoProjeto = projeto.projetoProduto.filter(
        x => x.tipoProdutoId === 2
      )

      obj.forEach(ambiente => {
        ambiente.projetoPeca.forEach(peca => {
          if (
            peca.superiorMaterialId ||
            peca.inferiorMaterialId ||
            peca.esquerdaMaterialId ||
            peca.direitaMaterialId
          ) {
            let temInconssistencia = false

            let superiorVinculoValido = false
            let inferiorVinculoValido = false
            let esquerdaVinculoValido = false
            let direitaVinculoValido = false

            fitasIncluidasNoProjeto.forEach((x, index) => {
              if (peca.superiorMaterialId !== 0) {
                if (peca.superiorMaterialId === x.id) {
                  superiorVinculoValido = true
                }
              } else {
                superiorVinculoValido = true
              }

              if (peca.inferiorMaterialId !== 0) {
                if (peca.inferiorMaterialId === x.id) {
                  inferiorVinculoValido = true
                }
              } else {
                inferiorVinculoValido = true
              }

              if (peca.esquerdaMaterialId !== 0) {
                if (peca.esquerdaMaterialId === x.id) {
                  esquerdaVinculoValido = true
                }
              } else {
                esquerdaVinculoValido = true
              }

              if (peca.direitaMaterialId !== 0) {
                if (peca.direitaMaterialId === x.id) {
                  direitaVinculoValido = true
                }
              } else {
                direitaVinculoValido = true
              }

              if (fitasIncluidasNoProjeto.length === index + 1) {
                if (
                  !superiorVinculoValido ||
                  !inferiorVinculoValido ||
                  !esquerdaVinculoValido ||
                  !direitaVinculoValido
                ) {
                  temInconssistencia = true
                }
              }
            })

            if (temInconssistencia) {
              ret = false
              msgType = 4
              errorFields.push(`${ambiente.descricao}`)
            }
          }
        })
      })
    }

    if (id === 'pecasFormatoValido') {
      // debugger
      // console.log('lojaCongifOtmizacao +_+_+_+_+_+_++_+_+_+_+_+_+_+_}}}}}^^^^^^', lojaCongifOtmizacao)
      // console.log('objto validação __+_+__+_+__', obj)
      let erroConfigLoja = false
      if (!lojaCongifOtmizacao.id || lojaCongifOtmizacao.id === 0) {
        ret = false
        msgType = 5
        erroConfigLoja = true
      }
      if (!erroConfigLoja) {
        obj.forEach(ambiente => {
          ambiente.projetoPeca.forEach(peca => {
            const chapaAtivaNaPeca = projeto.projetoProduto.filter(
              x => x.id === peca.materialId
            )

            if (chapaAtivaNaPeca.length > 0) {
              limiteMaximoAltura = Utils.retornaLimitMaxAltura(
                chapaAtivaNaPeca[0].altura,
                projeto.tipoCorte,
                lojaCongifOtmizacao.descontoMaximoAltura
              )
              limiteMaximoLargura = Utils.retornaLimitMaxLargura(
                chapaAtivaNaPeca[0].largura,
                projeto.tipoCorte,
                lojaCongifOtmizacao.descontoMaximoLargura
              )
            }

            // console.log('objto chapa __+_+__+_+__', chapaAtivaNaPeca)
            if (lojaCongifOtmizacao) {
              // verifica borda valida
              // debugger
              if (peca.pecaProduto.length > 0) {
                if (chapaAtivaNaPeca[0].espessura < limiteEspessura / 10) {
                  ret = false
                  msgType = 3
                  errorFields.push(`${ambiente.descricao}`)
                }

                let tipeError = 'OK'

                tipeError = Utils.validaMedidaPecaFitagem(
                  peca.largura,
                  peca.altura,
                  limiteCorteBorda,
                  limiteCorteBordaOposto,
                  peca.inferiorMaterialId,
                  peca.superiorMaterialId,
                  peca.esquerdaMaterialId,
                  peca.direitaMaterialId
                )
                if (tipeError !== 'OK') {
                  ret = false
                  msgType = 3
                  errorFields.push(`${ambiente.descricao}`)
                }
              }
              // verifica altura valida
              if (
                !Utils.validarTamanhoPeca(
                  peca.altura,
                  limiteMinimoAlturaLargura,
                  limiteMaximoAltura
                )
              ) {
                // if (Utils.validarValorMenor(peca.altura, limiteMinimoAlturaLargura) === false || Utils.validarValor(peca.altura, limiteMaximoAltura ) === false) {
                ret = false
                msgType = 3
                errorFields.push(`${ambiente.descricao}`)
              }
              // verifica largura valida
              if (
                !Utils.validarTamanhoPeca(
                  peca.largura,
                  limiteMinimoAlturaLargura,
                  limiteMaximoLargura
                )
              ) {
                // if (Utils.validarValorMenor(peca.largura, limiteMinimoAlturaLargura) === false || Utils.validarValor(peca.largura, limiteMaximoLargura ) === false) {
                ret = false
                msgType = 3
                errorFields.push(`${ambiente.descricao}`)
              }
              // verifica largura e altura minima maquina
              if (
                peca.largura < limiteMinimoMaquina &&
                peca.altura < limiteMinimoMaquina
              ) {
                ret = false
                msgType = 3
                errorFields.push(`${ambiente.descricao}`)
              }
            }
          })
        })
      }
    }

    // fim valida busca
    if (exibeAlert) {
      if (errorFields.length === 1 && msgType === 0) {
        showMsgW('O campo ' + errorFields[0] + ' é obrigatório.')
      }

      if (errorFields.length === 2 && msgType === 0) {
        showMsgW(
          'Os campos ' +
            errorFields[0] +
            ' e ' +
            errorFields[1] +
            ' são obrigatórios.'
        )
      }

      if (errorFields.length > 2 && msgType === 0) {
        let concatString = ''
        for (let n = 0; n < errorFields.length - 1; n++) {
          concatString += errorFields[n] + ', '
        }
        concatString = concatString.slice(0, concatString.lastIndexOf(','))
        concatString += ' e ' + errorFields[errorFields.length - 1]
        showMsgW('Os campos ' + concatString + ' são obrigatórios.')
      }

      if (msgType === 1) {
        showMsgW("O Ambiente '" + errorFields[0] + "' não possui peças")
      }

      if (errorFields.length > 2 && msgType === 1) {
        let concatString = ''
        for (let n = 0; n < errorFields.length - 1; n++) {
          concatString += errorFields[n] + ', '
        }
        concatString = concatString.slice(0, concatString.lastIndexOf(','))
        concatString += ' e ' + errorFields[errorFields.length - 1]
        showMsgW('Os Ambientes ' + concatString + ' não possuem peças.')
      }

      if (msgType === 2) {
        showMsgW(
          "O Ambiente '" +
            errorFields[0] +
            "' possui peças faltando informações."
        )
      }

      if (msgType === 3) {
        showMsgW(
          "O Ambiente '" +
            errorFields[0] +
            "' possui peças com informações inválidas."
        )
      }

      if (msgType === 4) {
        showMsgW(
          "O Ambiente '" +
            errorFields[0] +
            "' possui peças onde Não foi possível identificar a fita de uma das posições. Remarque a fitagem indicada em vermelho para prosseguir."
        )
      }

      if (msgType === 5) {
        showMsgW(
          'Não foi possível identificar as configurações de corte da loja, tente novamente mais tarde.'
        )
      }

      if (msgType === 6) {
        showMsgW(
          "O Ambiente '" +
            errorFields[0] +
            "' possui fita selecionada que não esta sendo usada."
        )
      }

      if (errorFields.length > 2 && msgType === 2) {
        let concatString = ''
        for (let n = 0; n < errorFields.length - 1; n++) {
          concatString += errorFields[n] + ', '
        }
        concatString = concatString.slice(0, concatString.lastIndexOf(','))
        concatString += ' e ' + errorFields[errorFields.length - 1]
        showMsgW(
          'Os Ambientes ' +
            concatString +
            ' possuem peças faltando informações.'
        )
      }
    }

    return ret
  }

  // valida quais steps estão completos no carregamento incial
  useEffect(() => {
    const verificaStepsCompletos = () => {
      projeto.tipoCorte === 4
        ? setIsCanaletadoProject(true)
        : setIsCanaletadoProject(false)

      // console.log('verifica projeto', planProjeto, planAmbientes, projeto.projetoProduto)
      if (projeto.id) {
        // step dados do projeto
        handleComplete(0)

        // step materiais
        const mat = projeto.projetoProduto.filter(x => x.tipoProdutoId === 1)
        if (mat.length > 0) {
          handleComplete(1)
        }

        if (projeto.tipoCorte !== 4) {
          // step ambientes
          if (otimizacao.length > 0) {
            handleComplete(2)
          }
        }
      }
    }

    if (projeto.id) {
      // ajustar
      // setListaAmbientes(planAmbientes)
      verificaStepsCompletos()
    }
  }, [projeto.id, otimizacao, handleComplete, projeto.projetoProduto])

  useEffect(() => {
    if (activeStep === 2) setForceValidateAllPecas(forceValidateAllPecas + 1)
    setupSVLCheckout()
  }, [activeStep])

  const verifyMaterial = async (material, otmizacao) => {
    const materialChapa = Utils.extraiChapasEmUso(otmizacao, material)
    const materialFita = Utils.extraiFitasEmUso(otmizacao, material)

    await materialChapa.forEach(async material => {
      try {
        const resFL = await trackPromise(
          api.core.apiListarProdutos(
            material.codigo,
            user.empresaIdErp,
            user.lojaIdErp,
            'C',
            100,
            user.loja.id,
            user.depositoId,
            true
          )
        )

        if (resFL.length) {
          const estoCD = Utils.getEstoqueQtd(resFL[0].Estoques, 'CD', user)
          const estoL = Utils.getEstoqueQtd(resFL[0].Estoques, 'L', user)
          const estoCS =
            user.tipoVenda === 'ZCVS' || user.tipoVenda === 'ZMOB'
              ? Utils.getEstoqueQtd(resFL[0].Estoques, 'CS', user)
              : 0
          // debugger
          if (estoCD + estoL + estoCS <= material.qtdPlacasCortadas) {
            handleAlertEstoqueOpen(material)
          } else {
            handleAlertEstoqueClose()
          }
        }
      } catch (error) {
        // console.log('error-', error)
      }
    })

    await materialFita.forEach(async material => {
      try {
        const resFL = await trackPromise(
          api.core.apiListarProdutos(
            material.codigo,
            user.empresaIdErp,
            user.lojaIdErp,
            'F',
            100,
            user.loja.id,
            user.depositoId,
            true
          )
        )

        if (resFL.length) {
          const estoCD = Utils.getEstoqueQtd(resFL[0].Estoques, 'CD', user)

          const estoL = Utils.getEstoqueQtd(resFL[0].Estoques, 'L', user)
          const estoCS =
            user.tipoVenda === 'ZCVS' || user.tipoVenda === 'ZMOB'
              ? Utils.getEstoqueQtd(resFL[0].Estoques, 'CS', user)
              : 0

          if (estoCD + estoL + estoCS <= material.qtdPlacasCortadas) {
            handleAlertEstoqueOpen(material)
          } else {
            handleAlertEstoqueClose()
          }
        }
      } catch (error) {
        // console.log('error-', error)
      }
    })
  }

  const getStartInfoExternal = async () => {
    if (!loja?.idErp && projeto.id) {
      const lojaProjeto = lojas.find(
        loja => loja.idErp.toString() === projeto.lojaIdErp.toString()
      )
      setLoja(lojaProjeto)
    }

    if (!vendedor?.id) {
      try {
        const response = await trackPromise(
          api.core.apiGetVendedorInfos(projeto.vendedor.email)
        )

        if (response.fail) throw new Error()

        setVendedor(response.data)

        getConfigLojaData({
          ...response,
          lojaIdErp: response.data.loja.idErp,
          empresaIdErp: response.data.loja.empresa.idErp
        })
      } catch {
        showMsgE('Ocorreu um erro ao consultar o vendedor')
      }
    }
    if (!cliente.nome) setCliente(user)
  }

  const getStartInfoVendor = () => {
    if (!vendedor.id) setVendedor(user)
    if (!loja.idErp) setLoja(user.loja)
    if (!cliente.idErp) {
      setCliente(user.clientePreSelecionado)
    }
    getConfigLojaData(user)
  }
  const setupSVLCheckout = () => {
    if (
      user.pedidoId > 0 &&
      (user.tipoVenda === 'ZCVS' ||
        user.tipoVenda === 'ZLPL' ||
        user.tipoVendaId === TIPO_VENDA_ID.ZMOB) &&
      projeto.ativo
    ) {
      setShowGerarPedido(true)
    }
  }
  const checkProjectOwner = () => {
    if (
      !projeto.id ||
      user.tipoUserLeoPlan !== 'cliente' ||
      (user.tipoUserLeoPlan === 'cliente' &&
        user.idErp.toString() === projeto.clienteLEO.codERP.toString())
    ) {
      setIsClientOwner(true)
      return true
    } else {
      return false
    }
  }
  const getStartNewProjectVendor = () => {
    setCliente(user.clientePreSelecionado)
    setVendedor(user)
    setProjeto({})
    setOtimizacao([])
    setLoja(user.loja)
    getConfigLojaData(user)
  }
  const getStartNewProjectClient = () => {
    setCliente(user)
    setProjeto({})
    setOtimizacao([])
    setVendedor({})
    setLoja({})
  }
  const getConfigLojaData = async vendedor => {
    try {
      const { data } = await api.leoplan.apiGetConfigdata(
        vendedor.lojaIdErp,
        vendedor.empresaIdErp
      )

      if (!configData) {
        setConfigData(data)
        setUpdateData(data)
        setConfigStatus(true)
      }

      setConfigLoja(data)
    } catch {
      showMsgE(
        'Erro ao coletar dados de configuração da loja, entre em contato com o suporte'
      )
    }
  }
  useEffect(() => {
    if (!checkProjectOwner()) return
    if (user.tipoUserLeoPlan === 'cliente' && lojas.length) {
      if (type) getStartNewProjectClient()
      else getStartInfoExternal()
    } else {
      if (type) getStartNewProjectVendor()
      else getStartInfoVendor()
    }
  }, [lojas])

  useEffect(() => {
    if (projeto?.tipoCorte === 4 && props.inicialStep === 3) {
      setActiveStep(2)
      return
    }
    setActiveStep(props.inicialStep)

    if (props.inicialStep === 2 && !projeto?.tipoCorte === 4) {
      clickAmbExpand(0)
    }
  }, [props.inicialStep])

  const isEditable = projeto.origemProjeto !== 3

  return isClientOwner ? (
    <div className={classes.root}>
      <div className="noPrint">
        <StepperControl
          activeStep={activeStep}
          navegaToStep={navegaToStep}
          completed={completed}
        />
      </div>
      <div>
        {/* adicionar a tale de resumo */}
        {projeto.pedidoId && (
          <div className="pad20 padT10 padB0">
            <InfoMsg
              type="warning"
              title="Projeto Finalizado"
              msg={`Este projeto ja foi vinculado ao pedido ${projeto.pedidoId} e não pode ser mais alterado.`}
            />
          </div>
        )}

        {activeStep === 0 && (
          <IniciarProjeto lojas={lojas} showMsgE={showMsgE} />
        )}

        {activeStep === 1 && projeto.tipoCorte === 4 && (
          <AbaChapasCanaletadas />
        )}

        {activeStep === 1 && projeto.tipoCorte !== 4 && <Materiais />}

        {activeStep === 2 && projeto.tipoCorte !== 4 && <Ambientes />}

        {activeStep === 2 && projeto.tipoCorte === 4 && (
          <PlanoCorte
            invalidMaterial={invalidMaterial}
            verifyMaterial={verifyMaterial}
            modalDownload={modalDownload}
            checkIgnorarVeio={checkIgnorarVeio}
            material={alertEstoqueModal}
            clearAlertEstoque={handleAlertEstoqueClose}
          />
        )}

        {activeStep === 3 && projeto.tipoCorte !== 4 && (
          <PlanoCorte
            invalidMaterial={invalidMaterial}
            verifyMaterial={verifyMaterial}
            modalDownload={modalDownload}
            checkIgnorarVeio={checkIgnorarVeio}
            material={alertEstoqueModal}
            clearAlertEstoque={handleAlertEstoqueClose}
          />
        )}

        {/* pop up download plano */}
        <DialogDownloadFile
          openModalDownload={openModalDownload}
          handleCloseModalDownload={handleCloseModalDownload}
          loja={loja}
          showMsgE={showMsgE}
          otimizacaoCodMaterial={otimizacaoCodMaterial}
          otimizacaoIdDown={otimizacaoIdDown}
          otimizacaoIndexDown={otimizacaoIndexDown}
        />
        {/* pop up Ignorar veio */}
        <DialogAlertVeio
          open={openModalAlertVeio}
          close={handleCloseModalAlertVeio}
          action={handleDataSubmit}
        />

        {/* pop up Alert Fita */}
        <DialogAlertFita
          open={openModalAlertFita}
          close={handleCloseModalAlertFita}
          action={handleDataSubmit}
        />
        {/* load block otimização */}
        {projeto.id ? (
          <LoadOtimizacao
            loadOtimization={loadOtimization}
            projetoId={projeto.id}
            errorOtimizacao={errorOtimizacao}
            getDetOtimizacao={getDetOtimizacao}
          />
        ) : (
          <></>
        )}
      </div>

      <Backdrop className={classes.backdrop} open={openBkdrop}>
        {' '}
        <CircularProgress color="inherit" />{' '}
      </Backdrop>

      <div className="groupFixedBtn noPrint">
        <div className="flex justify-between">
          {activeStep === 0 && (
            <>
              <div>
                <DialogVoltarListagem
                  pedidoId={projeto.pedidoId}
                  activeStep={activeStep}
                  txt={
                    !projeto.id
                      ? 'Cancelar'
                      : isMobile
                      ? 'Sair'
                      : 'Sair do Projeto'
                  }
                />
              </div>
              {projeto.id ? (
                <div>
                  {!projeto.pedidoId && isEditable && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="mr5"
                      onClick={editarProjeto}
                    >
                      {' '}
                      Salvar{' '}
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    className="m5"
                    color="secondary"
                    onClick={() => navegaToStep(1)}
                  >
                    {isMobile ? '' : ' Avançar'} <KeyboardArrowRight />
                  </Button>
                </div>
              ) : (
                <div>
                  {criandoProjeto ? (
                    <Button
                      variant="contained"
                      className="m5"
                      color="secondary"
                      onClick={criarProjeto}
                    >
                      {' '}
                      Iniciar Projeto{' '}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="m5"
                      color="secondary"
                    >
                      {' '}
                      Processando...{' '}
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
          {activeStep === 1 &&
            projeto.tipoCorte === 4 &&
            projeto.origemProjeto !== 3 && (
              <div className="flex justify-between w-100">
                <div className={isMobile ? 'flex' : 'col-lg-6 col-sm-4'}>
                  <Button
                    variant="outlined"
                    className="m5 floatLeft"
                    color="primary"
                    onClick={() => voltarEtapa(0)}
                  >
                    {' '}
                    <KeyboardArrowLeft /> {isMobile ? '' : 'Etapa Anterior '}
                  </Button>
                  <DialogVoltarListagem
                    pedidoId={projeto.pedidoId}
                    activeStep={activeStep}
                    txt={
                      !projeto.id
                        ? 'Cancelar'
                        : isMobile
                        ? 'Sair'
                        : 'Sair do Projeto'
                    }
                  />
                </div>

                <div
                  className={isMobile ? 'flex' : 'col-lg-6 col-sm-8 text-right'}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="m5"
                    onClick={() => salvarGrupoPecas(true)}
                  >
                    {' '}
                    Salvar{' '}
                  </Button>
                  {!loadOtimization.load ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      className="m5"
                      onClick={validarGrupoPecas}
                    >
                      {isMobile ? 'Otimizar' : 'Salvar e Otimizar'}
                    </Button>
                  ) : (
                    'Salvando...'
                  )}
                </div>
              </div>
            )}

          {activeStep === 1 && projeto.tipoCorte !== 4 && (
            <div className="flex justify-between w-100">
              <div className="flex">
                <Button
                  variant="outlined"
                  className="m5"
                  color="primary"
                  onClick={() => voltarEtapa(0)}
                >
                  {' '}
                  <KeyboardArrowLeft /> {isMobile ? '' : 'Etapa Anterior '}
                </Button>
                <DialogVoltarListagem
                  pedidoId={projeto.pedidoId}
                  activeStep={activeStep}
                  txt={
                    !projeto.id
                      ? 'Cancelar'
                      : isMobile
                      ? 'Sair'
                      : 'Sair do Projeto'
                  }
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  className="m5"
                  color="secondary"
                  onClick={() => navegaToStep(2)}
                >
                  {isMobile ? '' : ' Avançar'}
                  <KeyboardArrowRight />
                </Button>
              </div>
            </div>
          )}

          {activeStep === 2 && projeto.tipoCorte !== 4 && (
            <>
              <div className={isMobile ? 'flex flex-wrap w-100' : 'w-100'}>
                {isMobile && projeto.origemProjeto !== 3 ? (
                  <div className="w-100 flex-1">
                    {projeto?.tipoCorte !== 5 && (
                      <Button
                        variant="contained"
                        onClick={handleOpenModalNewAmbiente}
                        className="w-100 blackButton"
                      >
                        {' '}
                        <Add /> Ambiente{' '}
                      </Button>
                    )}
                  </div>
                ) : null}
                {!projeto.pedidoId ? (
                  <div
                    className={`flex ${
                      isMobile
                        ? 'justify-between w-100 flex-wrap'
                        : 'justify-end'
                    }`}
                  >
                    <DialogAddAmbiente
                      open={openModalNewAmbiente}
                      close={handleCloseModalNewAmbiente}
                      setNewAmbienteName={setNewAmbienteName}
                      newAmbienteName={newAmbienteName}
                      criarAmbienteViaApi={() =>
                        criarAmbienteViaApi(projeto.id)
                      }
                    />
                    <div className="flex w-100 justify-between">
                      <div className="flex">
                        <Button
                          variant="outlined"
                          className={isMobile ? 'm-1' : 'm5'}
                          color="primary"
                          onClick={() => voltarEtapa(1)}
                        >
                          {' '}
                          <KeyboardArrowLeft />{' '}
                          {isMobile ? '' : 'Etapa Anterior '}
                        </Button>
                        <DialogVoltarListagem
                          pedidoId={projeto.pedidoId}
                          activeStep={activeStep}
                          txt={
                            !projeto.id
                              ? 'Cancelar'
                              : isMobile
                              ? 'Sair'
                              : 'Sair do Projeto'
                          }
                        />
                      </div>

                      <div className="flex">
                        {!isMobile &&
                          projeto?.tipoCorte !== 5 &&
                          projeto.origemProjeto !== 3 && (
                            <Button
                              variant="contained"
                              onClick={handleOpenModalNewAmbiente}
                              className={`blackButton ${
                                isMobile ? 'm-1' : 'm5'
                              }`}
                            >
                              {' '}
                              <Add /> Ambiente{' '}
                            </Button>
                          )}
                        {projeto.origemProjeto !== 3 && (
                          <Button
                            variant="contained"
                            color="primary"
                            className={isMobile ? 'm-1' : 'm5'}
                            onClick={() => salvarGrupoPecas(true)}
                          >
                            {' '}
                            Salvar{' '}
                          </Button>
                        )}

                        <>
                          {otimizacao.length > 0 && (
                            <Button
                              variant="contained"
                              color="secondary"
                              className={isMobile ? 'm-1' : 'm5'}
                              onClick={() => voltarEtapa(3)}
                            >
                              Avançar
                            </Button>
                          )}

                          {!loadOtimization.load ? (
                            <Button
                              variant="contained"
                              color="secondary"
                              className={isMobile ? 'm-1' : 'm5'}
                              onClick={validarGrupoPecas}
                            >
                              {isMobile ? 'Otimizar' : 'Salvar e Otimizar'}
                            </Button>
                          ) : (
                            'Salvando...'
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-6 col-sm-8 text-right">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => voltarEtapa(3)}
                    >
                      {' '}
                      {isMobile ? '' : ' Avançar'} <KeyboardArrowRight />
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}

          {(activeStep === 3 ||
            (activeStep === 2 && projeto.tipoCorte === 4)) && (
            <div className={isMobile ? 'flex flex-wrap w-100' : 'w-100'}>
              <div
                className={isMobile ? 'w-100 flex justify-between' : 'w-100'}
              >
                <Button
                  variant="outlined"
                  className="m5 floatLeft"
                  color="primary"
                  onClick={() => voltarEtapa(1)}
                >
                  {' '}
                  <KeyboardArrowLeft /> {isMobile ? '' : 'Etapa Anterior '}
                </Button>
                <DialogVoltarListagem
                  pedidoId={projeto.pedidoId}
                  activeStep={activeStep}
                  txt={
                    !projeto.id
                      ? 'Cancelar'
                      : isMobile
                      ? 'Sair'
                      : 'Sair do Projeto'
                  }
                />
              </div>
              {!projeto.pedidoId && (
                <div className="w-100 text-right">
                  {showGerarPedido &&
                  !invalidMaterial &&
                  projeto.tipoCorte !== 5 ? (
                    isEditable && (
                      <>
                        {!loadPedido ? (
                          <Button
                            variant="contained"
                            className={isMobile ? 'w-100 mt-2' : 'm5'}
                            color="secondary"
                            type="button"
                            onClick={validarInclusaoPedido}
                          >
                            Incluir no Pedido
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className={isMobile ? 'w-100 mt-2' : 'm5'}
                          >
                            Processando...
                          </Button>
                        )}
                      </>
                    )
                  ) : (
                    <>
                      {user.tipoUserLeoPlan === 'cliente' && projeto?.ativo && (
                        <DialogEnvioOrcamento
                          loja={loja}
                          user={user}
                          vendedor={vendedor}
                          cliente={cliente}
                          projetoId={projeto.id}
                          projetoNome={projeto.nome}
                          showMsgE={showMsgE}
                          showMsgS={showMsgS}
                          materiais={projeto.projetoProduto}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="m20">
      <InfoMsg
        type="warning"
        title="Acesso negado"
        msg="Você não tem permissão para ver esse projeto"
      />
    </div>
  )
}

export default LeoPlanContainer
