import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

const DialogAlertVeio = ({ open, close, action }) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Confirmar Otimização</Typography>
        <IconButton aria-label="close" onClick={close} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="text-center" dividers>
        <div className="unirModal">
          <Typography className="mb20">
            Este projeto tem peças de material com veio marcadas para
            &apos;permitir giro na otimização&apos; isso pode gerar uma
            otimização melhor, mas também pode{' '}
            <strong>
              gerar cortes de peças que não sigam o sentido do veio
            </strong>
            . Tem certeza que deseja otimizar dessa forma?
          </Typography>
          <Button
            onClick={close}
            variant="contained"
            className="mb10  mr5 blackButton"
          >
            Não
          </Button>
          <Button
            onClick={() => {
              action()
              close()
            }}
            variant="contained"
            color="secondary"
            className="mb10"
          >
            Sim
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default memo(DialogAlertVeio)
