import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { makeStyles } from 'tss-react/mui'

import { useProjeto } from '@hook/useProjeto'

import FitasMiniatura from '@component/FitasMiniatura'
import InfoMsg from '@component/InfoMsg/InfoMsg.js'

// - configura algumas classes dinamicamente usadas na interface
const useStyles = makeStyles()(() => ({
  chip: {
    '& span': { border: '#ccc 1px solid' },
    '& span.active': { borderWidth: '5px' }
  }
}))

const initBordasSelect = {
  superiorMaterialId: 0,
  esquerdaMaterialId: 0,
  direitaMaterialId: 0,
  inferiorMaterialId: 0
}

const DialogBordeamentoLote = props => {
  const { classes } = useStyles()
  const {
    open,
    close,
    ambienteName,
    ambienteIndex,
    aplicarFitagemTodoAmbiente,
    tipoCorte
  } = props
  const { projeto } = useProjeto()

  // - verifica se laterais possouim fitas vinculadas para flegar o checkbox na tela
  const hasBorda = (fitaId, allSides = false) => {
    if (!allSides) {
      if (fitaId > 0) {
        return true
      }
    } else {
      if (
        bordaPecaOptions.superiorMaterialId > 0 &&
        bordaPecaOptions.esquerdaMaterialId > 0 &&
        bordaPecaOptions.direitaMaterialId > 0 &&
        bordaPecaOptions.inferiorMaterialId > 0
      ) {
        return true
      }
    }
    return false
  }

  const [bordaPecaOptions, setBordaPecaOptions] =
    React.useState(initBordasSelect)
  const [fitasDoProjeto, setFitasDoProjeto] = React.useState([])
  const [fitaAtiva, setFitaAtiva] = React.useState(0)

  // - aplica cores diferentes para a lista de fitas no drop da peça
  const getcolorCheckBox = idFita => {
    const idFitaIndex = fitasDoProjeto.findIndex(x => x.id === idFita)
    if (idFita > 0) {
      return fitasDoProjeto[idFitaIndex].color
    }
    return ''
  }

  const changeBorda = position => {
    const idFita = fitasDoProjeto[fitaAtiva].id
    switch (position) {
      case 'top':
        if (bordaPecaOptions.superiorMaterialId > 0) {
          setBordaPecaOptions(prevState => ({
            ...prevState,
            superiorMaterialId: 0
          }))
        } else {
          setBordaPecaOptions(prevState => ({
            ...prevState,
            superiorMaterialId: idFita
          }))
        }
        break
      case 'left':
        if (bordaPecaOptions.esquerdaMaterialId > 0) {
          setBordaPecaOptions(prevState => ({
            ...prevState,
            esquerdaMaterialId: 0
          }))
        } else {
          setBordaPecaOptions(prevState => ({
            ...prevState,
            esquerdaMaterialId: idFita
          }))
        }
        break
      case 'right':
        if (bordaPecaOptions.direitaMaterialId > 0) {
          setBordaPecaOptions(prevState => ({
            ...prevState,
            direitaMaterialId: 0
          }))
        } else {
          setBordaPecaOptions(prevState => ({
            ...prevState,
            direitaMaterialId: idFita
          }))
        }
        break
      case 'bottom':
        if (bordaPecaOptions.inferiorMaterialId > 0) {
          setBordaPecaOptions(prevState => ({
            ...prevState,
            inferiorMaterialId: 0
          }))
        } else {
          setBordaPecaOptions(prevState => ({
            ...prevState,
            inferiorMaterialId: idFita
          }))
        }
        break
      case 'all':
        if (
          bordaPecaOptions.superiorMaterialId > 0 &&
          bordaPecaOptions.esquerdaMaterialId > 0 &&
          bordaPecaOptions.direitaMaterialId > 0 &&
          bordaPecaOptions.inferiorMaterialId > 0
        ) {
          setBordaPecaOptions({ ...initBordasSelect })
        } else {
          setBordaPecaOptions({
            superiorMaterialId: idFita,
            esquerdaMaterialId: idFita,
            direitaMaterialId: idFita,
            inferiorMaterialId: idFita
          })
        }
        break
      default:
    }
  }

  const aplicarAltaracaoBordas = () => {
    aplicarFitagemTodoAmbiente(ambienteIndex, bordaPecaOptions)
    setBordaPecaOptions(initBordasSelect)
    setFitaAtiva(0)
  }

  useEffect(() => {
    const fitas = projeto?.projetoProduto?.filter(x => x.tipoProdutoId === 2)
    setFitasDoProjeto(fitas)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projeto?.projetoProduto?.length])

  useEffect(() => {
    setBordaPecaOptions(initBordasSelect)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ambienteIndex])

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Aplicação de bordas</Typography>
        <IconButton aria-label="close" onClick={close} size="large">
          {' '}
          <CloseIcon />{' '}
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div className="row">
          <div className="col-sm-7 fitasTab">
            <div className={classes.chip}>
              {fitasDoProjeto.map((fita, index) => {
                return (
                  <span
                    key={fita.id}
                    className={fitaAtiva === index ? 'active' : ''}
                    onClick={() => setFitaAtiva(index)}
                    style={{ borderColor: fita.color }}
                  >
                    {fita.codigo} - {fita.descricao}
                  </span>
                )
              })}
            </div>
          </div>

          <div className="col-sm-5">
            <div>
              <div className="col-12 text-center">
                <FormControlLabel
                  control={
                    <Checkbox
                      value="bordaA"
                      checked={hasBorda(bordaPecaOptions.superiorMaterialId)}
                      style={{
                        color: getcolorCheckBox(
                          bordaPecaOptions.superiorMaterialId
                        )
                      }}
                      onChange={() => changeBorda('top')}
                      disabled={tipoCorte === 3}
                    />
                  }
                  label="C"
                  labelPlacement="top"
                  className="m-0"
                />
              </div>

              <div className="col-12 flexLeo">
                <FormControlLabel
                  control={
                    <Checkbox
                      value="bordaA"
                      checked={hasBorda(bordaPecaOptions.esquerdaMaterialId)}
                      style={{
                        color: getcolorCheckBox(
                          bordaPecaOptions.esquerdaMaterialId
                        )
                      }}
                      onChange={() => changeBorda('left')}
                      disabled={tipoCorte === 2}
                    />
                  }
                  label="L"
                  labelPlacement="start"
                />
                <FitasMiniatura
                  peca={bordaPecaOptions}
                  materiais={fitasDoProjeto}
                  aplicacao
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="bordaA"
                      checked={hasBorda(bordaPecaOptions.direitaMaterialId)}
                      style={{
                        color: getcolorCheckBox(
                          bordaPecaOptions.direitaMaterialId
                        )
                      }}
                      onChange={() => changeBorda('right')}
                      disabled={tipoCorte === 2}
                    />
                  }
                  label="L"
                  labelPlacement="end"
                />
              </div>

              <div className="col-12 text-center">
                <FormControlLabel
                  control={
                    <Checkbox
                      value="bordaA"
                      checked={hasBorda(bordaPecaOptions.inferiorMaterialId)}
                      style={{
                        color: getcolorCheckBox(
                          bordaPecaOptions.inferiorMaterialId
                        )
                      }}
                      onChange={() => changeBorda('bottom')}
                      disabled={tipoCorte === 3}
                    />
                  }
                  label="C"
                  labelPlacement="bottom"
                  className="m-0"
                />
              </div>

              {tipoCorte === 1 && (
                <div className="col-12 text-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedF"
                        checked={hasBorda(null, true)}
                        className="defaultColor"
                        onChange={() => changeBorda('all')}
                      />
                    }
                    label="Aplicar em todos os lados"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="col-sm-12 text-center mt20">
            <InfoMsg
              type="warning"
              title={`A configuração de borda acima será aplicada em todas as peças do ambiente "${ambienteName}"`}
            />
          </div>
          <div className="col-sm-12 text-center mt20">
            <Button
              variant="contained"
              color="secondary"
              className="mr5 mb10"
              onClick={aplicarAltaracaoBordas}
            >
              Confirmar e Aplicar em todas as peças
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DialogBordeamentoLote
