/* eslint-disable react/jsx-filename-extension */
import {
  type DisplayOptions,
  Materializer
} from '@dotproductdev/parametric-configurator'
import {
  MatNode,
  RotNode,
  type PieceNode
} from '@dotproductdev/parametric-configurator/dist/models/MaterializationModel'
import {
  Environment,
  OrbitControls,
  OrthographicCamera,
  Stage
} from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { useMemo } from 'react'
import { MOUSE } from 'three'

const defaultDisplayOptions: DisplayOptions = {
  // Mostra ou não ferragens (dobradiças, puxadores, cavilhas, minifix etc).
  showHardware: true,
  // Mostra ou não operações de furação e usinagem como geometrias sobre o
  // desenho da peça.
  showOperations: true,
  // - 'detailed' => sugiro deixar nesse padrão se for usar texturas/cores e
  //   mostrar as fitas de bordo.
  // - 'sketch' => desenha linhas apenas, o material é transparente e as fitas
  //   de bordo não aparecem
  renderMode: 'sketch',
  // Ignorar
  showFloorGrid: false,
  // Cor da peça no renderMode === 'sketch'
  sketchPieceColor: '#b9acee',
  // Cor das linhas no renderMode === 'sketch'
  sketchLineColor: '#9c8cda',
  // Cor das operações (no caso de showOperations === true).
  operationsColor: { r: 255, g: 0, b: 0, a: 0.7 },
  showAxesHelper: false,
  // Ignorar
  wallsOpacity: 0
}

export interface LeoVisualizerProps {
  node: PieceNode
  componentType: string
  displayOptions?: DisplayOptions
}

// O padrão do three.js é usar o botão esquerdo para rotacionar e o direito para
// mover. Se a ideia é *desabilitar* a rotação, eu acho mais natural então usar
// o botão esquerdo para mover.
const mouseButtons = {
  LEFT: MOUSE.PAN
}

export const LeoVisualizer = ({
  node,
  displayOptions,
  componentType
}: LeoVisualizerProps) => {
  const factor = 4000000
  const zoom = useMemo(() => {
    const { width, height } = node.work[0]
    return Math.min(factor / width, factor / height)
  }, [node])

  const rotate = (child: MatNode, angle: number): RotNode => ({
    type: 'rot',
    vec: [0, 0, angle],
    child
  })

  const rotatedNode =
    componentType === 'door' ? rotate(node, 0) : rotate(node, -Math.PI / 2)

  // Algumas observações:
  // - displayOptions é usado para ajustar os modos/parâmetros de
  //   renderização.
  // - A posição da "câmera" está focando a parte interna para poder
  //   visualizar as dobradiças da porta no demo, mas para outras peças talvez
  //   seja desejável ver o lado externo, fica a cargo de vocês como fazer
  //   isso, basta trocar o sinal da prop 'position' (eixo Z).
  // - OrbitControls está com rotações desabilitadas, assim temos o efeito de
  //   uma projeção 2D. As opções de zoom e pan (e rotate) podem ser
  //   controladas individualmente.
  // - Environment é usado para definir a iluminação usada pelo render e faz
  //   com que elementos metálicos (ex. dobradiça) sejam renderizados de forma
  //   bastante realística.
  return (
    <div style={{ position: 'relative', width: '500px', height: '500px' }}>
      {/* Comp. esquerda */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translate(-50%, -50%)',
          fontSize: '14px',
          color: '#777',
          zIndex: '999'
        }}
      >
        C 1
        <p>
          {componentType === 'door'
            ? node.work[0].height / 100
            : node.work[0].width / 100}
        </p>
      </div>

      {/* Comp. à direita */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translate(50%, -50%)',
          fontSize: '14px',
          color: '#777',
          zIndex: '999'
        }}
      >
        C 2
        <p>
          {componentType === 'door'
            ? node.work[0].height / 100
            : node.work[0].width / 100}
        </p>
      </div>

      {/* Larg. em cima */}
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '50%',
          transform: 'translateX(-50%)',
          fontSize: '14px',
          color: '#777',
          zIndex: '999'
        }}
      >
        L 1
        <p>
          {componentType === 'door'
            ? node.work[0].width / 100
            : node.work[0].height / 100}
        </p>
      </div>

      {/* Larg. embaixo */}
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          left: '50%',
          transform: 'translateX(-50%)',
          fontSize: '14px',
          color: '#777',
          zIndex: '999'
        }}
      >
        L 2
        <p>
          {componentType === 'door'
            ? node.work[0].width / 100
            : node.work[0].height / 100}
        </p>
      </div>

      <Canvas style={{ width: '100%', height: '100%', position: 'absolute' }}>
        <Stage environment={undefined} intensity={10}>
          <Environment files="/assets/apartment.hdr" />
          <OrthographicCamera makeDefault zoom={zoom} position={[0, 0, 20]} />
          <OrbitControls
            enableRotate={false}
            enablePan={true}
            enableZoom={true}
            enableDamping={false}
            mouseButtons={mouseButtons}
            makeDefault
          />
          <Materializer
            root={rotatedNode}
            displayOptions={displayOptions ?? defaultDisplayOptions}
          />
        </Stage>
      </Canvas>
    </div>
  )
}
