import { useState, useEffect } from 'react'

import DefaultTable from './components/DefaultTable.js'
import GroupTable from './components/GroupTable.js'
import Pagination from './components/Pagination.js'
import SearchTable from './components/SearchTable.js'

import './table.scss'

const sortData = (data, sortData) => {
  if (!data[0].groupName) {
    const headers = Object.keys(data[0]).filter(item => !/_\w+/g.test(item))
    const header = headers[sortData[0]]
    const position = sortData[1]

    if (position) {
      return data.sort(function (a, b) {
        if (
          (a[header].search || a[header].data) >
          (b[header].search || b[header].data)
        ) {
          return 1
        }
        if (
          (a[header].search || a[header].data) <
          (b[header].search || b[header].data)
        ) {
          return -1
        }
        return 0
      })
    } else {
      return data.sort(function (a, b) {
        if (
          (a[header].search || a[header].data) <
          (b[header].search || b[header].data)
        ) {
          return 1
        }
        if (
          (a[header].search || a[header].data) >
          (b[header].search || b[header].data)
        ) {
          return -1
        }
        return 0
      })
    }
  } else {
    const headers = Object.keys(data[0].groupData[0]).filter(
      item => !/_\w+/g.test(item)
    )

    const header = headers[sortData[0]]
    const position = sortData[1]

    data.forEach(group => {
      if (position) {
        return group.groupData.sort(function (a, b) {
          const aData = a[header].search || a[header].data
          const bData = b[header].search || b[header].data
          if (aData > bData) {
            return 1
          }
          if (aData < bData) {
            return -1
          }
          return 0
        })
      } else {
        return group.groupData.sort(function (a, b) {
          const aData = a[header].search ? a[header].search : a[header].data
          const bData = b[header].search ? b[header].search : b[header].data

          if (aData < bData) {
            return 1
          }
          if (aData > bData) {
            return -1
          }
          return 0
        })
      }
    })
    return data
  }
}
const normalyzeData = data => {
  if (!data[0].groupName) {
    const headers = Object.keys(data[0]).filter(item => !/_\w+/g.test(item))

    const nomalyzed = data.map(row => {
      headers.forEach(header => {
        if (!row[header]) return
        if (
          !row[header].data &&
          row[header].data !== 0 &&
          row[header].data !== '0'
        ) {
          return (row[header] = { ...row[header], data: ' ' })
        } else if (
          row[header].type === 'string' ||
          row[header].type === undefined
        ) {
          return (row[header] = {
            ...row[header],
            data: row[header].data.trim()
          })
        }
      })
      return row
    })
    return nomalyzed
    // groupTable
  } else {
    const headers = Object.keys(data[0].groupData[0]).filter(
      item => !/_\w+/g.test(item)
    )

    const nomalyzed = data.map(group => {
      return {
        ...group,

        groupData: group.groupData.map(row => {
          headers.forEach(header => {
            if (
              !row[header].data &&
              row[header].data !== 0 &&
              row[header].data !== '0'
            ) {
              return (row[header] = { ...row[header], data: ' ' })
            } else if (
              row[header].type === 'string' ||
              row[header].type === undefined
            ) {
              return (row[header] = {
                ...row[header],
                data: row[header].data.trim()
              })
            }
          })

          return row
        })
      }
    })
    return nomalyzed
  }
}

export default function Table(props) {
  const { headers, data, type, upperHeader } = props
  const { search, sort, initialSortDesc } = props
  const { className, id } = props
  const { onClickLine, selectedItems } = props
  const { pagination, itensPerPageAtive, itensPerPageList } = props
  const [filteredData, setFilteredData] = useState([])

  // Pagination controlls
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(itensPerPageAtive || 5)
  const [rowsPerPageRange] = useState(itensPerPageList || [5, 10, 25])
  const [totalNumberOfItens, setTotalNumberOfItens] = useState(0)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const paginateData = data => {
    if (type === 'group') {
      const sizes = data.map((group, index) => {
        if (index) {
          return data.reduce((acc, item) => {
            return acc + item.groupData.length
          }, 0)
        } else {
          return group.groupData.length
        }
      })
      const start = sizes.filter(item => item <= page * rowsPerPage).length
      const end =
        sizes.filter(item => item < page * rowsPerPage + rowsPerPage).length + 1

      const dataRange = data.slice(start, end)

      const sliceEnd = sizes[end - 1] - (page * rowsPerPage + rowsPerPage)
      const sliceStart =
        page * rowsPerPage -
        (sizes[start] - dataRange[start ? start - 1 : start]?.groupData.length)

      const slicedData = dataRange.map((group, index) => {
        if (index === 0 && index === dataRange.length - 1) {
          return {
            ...group,
            groupData: group.groupData?.slice(
              sliceStart,
              sliceStart + rowsPerPage
            )
          }
        } else if (index === 0) {
          return { ...group, groupData: group.groupData?.slice(sliceStart) }
        } else if (index === dataRange.length - 1 && sliceEnd) {
          return { ...group, groupData: group.groupData?.slice(0, -sliceEnd) }
        } else return group
      })

      if (rowsPerPage > 0 && pagination) {
        return slicedData
      } else {
        return data
      }
    } else {
      return rowsPerPage > 0 && pagination
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : data
    }
  }
  const RenderPagination = () => {
    let totalItems = filteredData.length
    if (type === 'group') {
      totalItems = filteredData.reduce((acc, group) => {
        return acc + group.groupData.length
      }, 0)
    }
    return (
      <Pagination
        count={totalItems}
        rowsPerPage={rowsPerPage}
        rowsPerPageRange={rowsPerPageRange}
        page={page}
        id={`${id}-search-pagination`}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    )
  }
  // Sort Controlls
  const [sortStatus, setSortStatus] = useState(false)

  const handleSortChange = newIndex => {
    if (sort !== undefined) {
      if (sortStatus[0] === newIndex && sortStatus[1]) {
        setSortStatus([newIndex, false])
        setFilteredData(sortData(filteredData, [newIndex, false]))
      } else {
        setSortStatus([newIndex, true])
        setFilteredData(sortData(filteredData, [newIndex, true]))
      }
      setPage(0)
    }
  }
  useEffect(() => {
    if (data.length) {
      const sortConfg =
        sort !== undefined
          ? initialSortDesc
            ? [sort, false]
            : [sort, true]
          : false

      if (sortConfg && sort >= 0) {
        setFilteredData(sortData(normalyzeData(props.data), sortConfg))
      } else setFilteredData(normalyzeData(props.data))

      setSortStatus(sortConfg)
    } else {
      setFilteredData([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // se for paginado ao recarregar a lista muda para pagina 1
  useEffect(() => {
    if (pagination) {
      if (
        totalNumberOfItens + 1 !== data.length &&
        totalNumberOfItens - 1 !== data.length
      ) {
        setPage(0)
      }
      setTotalNumberOfItens(data.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length, pagination])

  // Search Controlls
  const [searchInput, setSearchInput] = useState('')
  const HandleSearch = inputValue => {
    if (type === 'group') {
      if (inputValue) {
        const headers = Object.keys(data[0].groupData[0]).filter(
          item => !/_\w+/g.test(item)
        )
        // concatenate conditions for each index on search array
        setFilteredData(
          data.map(group => {
            return {
              ...group,
              groupData: group.groupData.filter(item => {
                let conditions = false

                search.forEach(index => {
                  if (index < headers.length) {
                    const normalizeSearch = item[headers[index]]?.search
                      ? item[headers[index]]?.search
                          .toString()
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                      : item[headers[index]]?.data
                          .toString()
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                    conditions =
                      conditions +
                      normalizeSearch.includes(inputValue.toLowerCase())
                  }
                })

                return conditions
              })
            }
          })
        )
        setPage(0)
      } else {
        setFilteredData(data)
      }
    } else {
      if (inputValue) {
        const headers = Object.keys(data[0]).filter(item => !/_\w+/g.test(item))
        // concatenate conditions for each index on search array
        setFilteredData(
          data.filter(item => {
            let conditions = false

            search.forEach(index => {
              if (index < headers.length) {
                const normalizeSearch = item[headers[index]]?.search
                  ? item[headers[index]]?.search
                      .toString()
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                  : item[headers[index]]?.data
                      .toString()
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                conditions =
                  conditions +
                  normalizeSearch.includes(inputValue.toLowerCase())
              }
            })

            return conditions
          })
        )
        setPage(0)
      } else {
        setFilteredData(data)
      }
    }
    setSearchInput(inputValue)
  }

  return data ? (
    <div className={`tableComponents ${className}`}>
      {search && (
        <SearchTable
          id={`${id}-search-input`}
          searchInput={searchInput}
          searchOnChange={HandleSearch}
        />
      )}
      {(type === 'default' || type === undefined) && (
        <DefaultTable
          id={id}
          upperHeader={upperHeader}
          handleSortChange={handleSortChange}
          sort={sortStatus}
          headers={headers}
          data={paginateData(filteredData)}
          onClickLine={onClickLine}
          selectedItems={selectedItems}
        >
          {pagination && RenderPagination()}
        </DefaultTable>
      )}
      {type === 'group' && (
        <GroupTable
          id={id}
          upperHeader={upperHeader}
          handleSortChange={handleSortChange}
          sort={sortStatus}
          headers={headers}
          data={paginateData(filteredData)}
          onClickLine={onClickLine}
          selectedItems={selectedItems}
        >
          {pagination && RenderPagination()}
        </GroupTable>
      )}
    </div>
  ) : (
    <></>
  )
}
