import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVert from '@mui/icons-material/MoreVert'
import AccordionSummary from '@mui/material/AccordionSummary'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'

import { useProjeto } from '@hook/useProjeto'

const HeaderAmbiente = props => {
  const { projeto } = useProjeto()
  const { ambienteIndex, ambienteDescricao, pedidoId, openAmbienteOption } =
    props

  // console.log('h', ambienteIndex, ambienteDescricao, pedidoId, openAmbienteOption)
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`panel${ambienteIndex}a-content`}
      id={`panel${ambienteIndex}a-header`}
    >
      {!pedidoId && (
        <FormControlLabel
          className="btnTituloExpansivePanel"
          aria-label="Acknowledge"
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}
          control={
            <IconButton
              id={`ambienteMenu-${ambienteIndex}`}
              onClick={openAmbienteOption}
              disabled={projeto.origemProjeto === 3}
              size="large"
            >
              <MoreVert />
            </IconButton>
          }
        />
      )}

      <Typography className="ml20">{ambienteDescricao}</Typography>
    </AccordionSummary>
  )
}

function propsAreEqual(prevProps, nextProps) {
  return (
    prevProps.ambienteIndex === nextProps.ambienteIndex &&
    prevProps.ambienteDescricao === nextProps.ambienteDescricao &&
    prevProps.pedidoId === nextProps.pedidoId &&
    prevProps.openAmbienteOption === nextProps.openAmbienteOption
  )
}

export default memo(HeaderAmbiente, propsAreEqual)
