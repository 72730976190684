import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import ReactPlayer from 'react-player'

const DialogPrimeirosPassos = ({ open, close }) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Vídeo Tutorial</Typography>
        <IconButton aria-label="close" onClick={close} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="text-center" dividers>
        <div className="w-100">
          <ReactPlayer
            width="100%"
            controls
            url="https://youtu.be/1cEt3qnUr00"
          />
        </div>
        <Button
          onClick={close}
          variant="contained"
          className="mb10 mt10 blackButton"
        >
          Fechar
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default memo(DialogPrimeirosPassos)
