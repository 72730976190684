import Tooltip from '@mui/material/Tooltip'
import React, { memo, useEffect } from 'react'

const BoderSize = '6px'
const BoderColors = ['#FECD04', '#28a745', '#007bff', '#946bdf']
const BoderOptionsAparence = [
  `${BoderSize} solid ${BoderColors[0]}`,
  `${BoderSize} solid ${BoderColors[1]}`,
  `${BoderSize} solid ${BoderColors[2]}`,
  `${BoderSize} solid ${BoderColors[3]}`
]
const BoderDefault = {
  borderRight: '1px solid #D6D6D6',
  borderLeft: '1px solid #D6D6D6',
  borderTop: '1px solid #D6D6D6',
  borderBottom: '1px solid #D6D6D6'
}

const PecaMiniaturaImport = props => {
  const [bordaCorAplicacao, setBordaCorAplicacao] = React.useState(BoderDefault)
  const [litaDeFitas, setLitaDeFitas] = React.useState([])

  useEffect(() => {
    const fitasValidas = []
    if (props.peca.fitaCima) {
      fitasValidas.push(props.peca.fitaCima)
    }
    if (props.peca.fitaBaixo) {
      if (!fitasValidas.includes(props.peca.fitaBaixo)) {
        fitasValidas.push(props.peca.fitaBaixo)
      }
    }
    if (props.peca.fitaEsquerda) {
      if (!fitasValidas.includes(props.peca.fitaEsquerda)) {
        fitasValidas.push(props.peca.fitaEsquerda)
      }
    }
    if (props.peca.fitaDireita) {
      if (!fitasValidas.includes(props.peca.fitaDireita)) {
        fitasValidas.push(props.peca.fitaDireita)
      }
    }
    setLitaDeFitas(fitasValidas)
  }, [props.peca])

  useEffect(() => {
    if (litaDeFitas.length > 0) {
      litaDeFitas.forEach((x, index) => {
        if (props.peca.fitaCima === x) {
          setBordaCorAplicacao(prevState => ({
            ...prevState,
            borderTop: BoderOptionsAparence[index]
          }))
        }
        if (props.peca.fitaBaixo === x) {
          setBordaCorAplicacao(prevState => ({
            ...prevState,
            borderBottom: BoderOptionsAparence[index]
          }))
        }
        if (props.peca.fitaEsquerda === x) {
          setBordaCorAplicacao(prevState => ({
            ...prevState,
            borderLeft: BoderOptionsAparence[index]
          }))
        }
        if (props.peca.fitaDireita === x) {
          setBordaCorAplicacao(prevState => ({
            ...prevState,
            borderRight: BoderOptionsAparence[index]
          }))
        }
      })
    }
  }, [
    litaDeFitas,
    props.peca.fitaCima,
    props.peca.fitaBaixo,
    props.peca.fitaEsquerda,
    props.peca.fitaDireita
  ])

  return (
    <div className="pecaMiniaturaContainer flexLeo">
      <Tooltip title="Aplicação de fita">
        <span className="pecaMiniatura" style={bordaCorAplicacao}></span>
      </Tooltip>
    </div>
  )
}

export default memo(PecaMiniaturaImport)
