import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { memo } from 'react'
import { isMobile } from 'react-device-detect'

const MenuAmbientesOptions = props => {
  const handleMenuBordeamentoOppening = () => {
    props.openModalConfigBorda(props.indexAmbiente)
  }
  return (
    <Menu
      id="ambiente-menu"
      anchorEl={props.anchorElAmb}
      keepMounted
      open={Boolean(props.anchorElAmb)}
      onClose={props.closeAmbienteOption}
    >
      <MenuItem onClick={props.alterarAmbientePopup}>Alterar nome</MenuItem>
      <MenuItem onClick={() => props.duplicarAmbiente()}>
        Duplicar ambiente
      </MenuItem>
      <MenuItem onClick={props.openModalExcluirAmb}>Excluir ambiente</MenuItem>
      <MenuItem onClick={props.openModalLimparAmb}>Limpar ambiente</MenuItem>
      {props.tipoCorte !== 2 && props.tipoCorte !== 3 && (
        <MenuItem onClick={props.openModalImportPecas}>Importar peças</MenuItem>
      )}
      {props.tipoCorte !== 2 && props.tipoCorte !== 3 && (
        <MenuItem onClick={props.inverterPecasAmbiente}>
          Inverter medidas
        </MenuItem>
      )}
      {props.tipoCorte !== 2 && props.tipoCorte !== 3 && (
        <MenuItem onClick={handleMenuBordeamentoOppening}>
          Bordeamento em lote
        </MenuItem>
      )}
    </Menu>
  )
}

export default memo(MenuAmbientesOptions)
