import ImgMdfDefault from '@asset/img/mdf-defalut.PNG'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { usePromiseTracker } from 'react-promise-tracker'
import { withStyles } from 'tss-react/mui'

import { useUser } from '@hook/useUser'

import Utils from '@service/serviceUtils'

const StyledTableCell = withStyles(TableCell, () => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: '#000',
    padding: '6px 16px'
  },
  body: {
    fontSize: 12,
    borderBottom: '1px solid #F5F5F5',
    padding: '4px 16px'
  }
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FDFDFD'
    }
  }
}))

const TabelaListaMateriaisInclusao = ({ materiais, addMaterial }) => {
  const { promiseInProgress } = usePromiseTracker()

  const [msgPesquisa, setMsgPesquisa] = React.useState('')
  const [qtdColunas, setQtdColunas] = React.useState(2)
  const { user } = useUser()

  const imgBrokenMdf = event => {
    event.target.src = ImgMdfDefault
  }

  useEffect(() => {
    setMsgPesquisa(
      'Nenhum resgistro encontrado. Busque por Material no campo acima'
    )
    if (user?.tipoUserLeoPlan !== 'cliente') {
      if (user?.tipoVenda === 'ZCVS' || user?.tipoVenda === 'ZMOB') {
        setQtdColunas(5)
      } else {
        setQtdColunas(4)
      }
    }
  }, [])

  return (
    <div>
      {isMobile && (
        <div className="w-100">
          <TableRow>
            <StyledTableCell className="w-100">
              Materiais pesquisados
            </StyledTableCell>

            {user?.tipoUserLeoPlan !== 'cliente' && (
              <>
                <StyledTableCell>Loja</StyledTableCell>
                <StyledTableCell>CD</StyledTableCell>
                {(user?.tipoVenda === 'ZCVS' || user?.tipoVenda === 'ZMOB') && (
                  <StyledTableCell>CS</StyledTableCell>
                )}
              </>
            )}
          </TableRow>
        </div>
      )}

      <TableContainer>
        <Table aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Materiais pesquisados</StyledTableCell>

              {user?.tipoUserLeoPlan !== 'cliente' && (
                <>
                  <StyledTableCell>Loja</StyledTableCell>
                  <StyledTableCell>CD</StyledTableCell>
                  {(user?.tipoVenda === 'ZCVS' ||
                    user?.tipoVenda === 'ZMOB') && (
                    <StyledTableCell>CS</StyledTableCell>
                  )}
                </>
              )}
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materiais.length === 0 && (
              <StyledTableRow>
                <StyledTableCell
                  data="Descrição"
                  component="th"
                  scope="row"
                  colSpan={qtdColunas}
                  align="center"
                >
                  {' '}
                  {msgPesquisa}{' '}
                </StyledTableCell>
              </StyledTableRow>
            )}
            {materiais.map(material => (
              <StyledTableRow key={material.Id}>
                <StyledTableCell data="Descrição" component="th" scope="row">
                  <span className="align-left">
                    <img
                      alt="mdf"
                      onError={imgBrokenMdf}
                      className="tableProdThumb"
                      src={Utils.getImageUrl(material.IdERP)}
                    />
                  </span>
                  <span>
                    {material.IdERP} -{' '}
                    {material.Descricao || material.DescricaoComercial}
                    {['FF', 'FL', 'SF', 'SL'].includes(
                      material.SituacaoPorCentro.CodigoSituacao
                    ) ? (
                      <strong> * </strong>
                    ) : (
                      ' '
                    )}
                  </span>
                </StyledTableCell>
                {user?.tipoUserLeoPlan !== 'cliente' && (
                  <>
                    <StyledTableCell data="Loja" component="th" scope="row">
                      <span>
                        {Utils.getEstoqueQtd(material.Estoques, 'L', user)}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell data="CD" component="th" scope="row">
                      <span>
                        {Utils.getEstoqueQtd(material.Estoques, 'CD', user)}
                      </span>
                    </StyledTableCell>
                    {(user?.tipoVenda === 'ZCVS' ||
                      user?.tipoVenda === 'ZMOB') && (
                      <StyledTableCell data="CS" component="th" scope="row">
                        <span>
                          {Utils.getEstoqueQtd(material.Estoques, 'CS', user)}
                        </span>
                      </StyledTableCell>
                    )}
                  </>
                )}
                {isMobile ? (
                  <div className="w-100 p-2">
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      className="w-100"
                      disabled={promiseInProgress}
                      onClick={() =>
                        addMaterial.handleIncludeMaterail(
                          material,
                          addMaterial.type
                        )
                      }
                    >
                      Incluir no projeto
                    </Button>
                  </div>
                ) : (
                  <StyledTableCell>
                    <div className="text-right-md w-100">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        disabled={promiseInProgress}
                        onClick={() =>
                          addMaterial.handleIncludeMaterail(
                            material,
                            addMaterial.type
                          )
                        }
                      >
                        Incluir no projeto
                      </Button>
                    </div>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default TabelaListaMateriaisInclusao
