import { appConfig } from '@config/env-config.js'

import * as apiConfig from '@service/api/apiConfig.js'
import Storage from '@service/storage.js'

const isSVL = localStorage.getItem('LEOPLAN_PATCH_ORIGEM') === 'SVL'

const APICOREOCC = isSVL
  ? appConfig.REACT_APP_APICOREOCC_SVL
  : appConfig.REACT_APP_APICOREOCC_GW

const API_SVL = isSVL
  ? `${appConfig.REACT_APP_API_SVL}configuracao/`
  : appConfig.REACT_APP_API_SVL_GW

const CDIDERP = appConfig.REACT_APP_CDIdERP
const CSIDERP = appConfig.REACT_APP_CSIdERP

export const apiGetClientByDocument = async clientId => {
  return await apiConfig.sendWithAxios(
    `${APICOREOCC}Cliente/v1/Cliente/documento/${clientId}/obter-por-documento`,
    'GET'
  )
}

export const apiGetClienteInfos = async clientId => {
  return await apiConfig.sendWithAxios(
    `${APICOREOCC}cliente/v1/cliente/idExterno/${clientId}/obter-por-id-externo`,
    'GET'
  )
}

export const apiGetVendedorInfos = async email => {
  return await apiConfig.sendWithAxios(
    `${APICOREOCC}geral/v1/Usuario/email/${email}/obter`,
    'GET'
  )
}

export const apiGetListarServicos = async () => {
  return await apiConfig.sendWithAxios(
    `${API_SVL}v1/Configuracao/obter-produto-tipo-servico`,
    'GET'
  )
}

export const apiProdutoById = async prodId => {
  const response = await apiConfig.sendWithAxios(
    `${APICOREOCC}produto/v1/produto/idProdutoErp/${prodId}/obter`,
    'GET'
  )

  const formatData = data => {
    return {
      Altura: data.altura,
      Comprimento: data.comprimento,
      Descricao: data.descricao,
      DescricaoComercial: data.descricaoComercial,
      DescricaoResumida: data.descricaoResumida,
      Id: data.id,
      IdERP: data.idErp,
      Largura: data.largura,
      TipoUnidadeMedida: {
        Descricao: data.tipoUnidadeMedida.descricao,
        Id: data.tipoUnidadeMedida.id,
        IdERP: data.tipoUnidadeMedida.idErp,
        mascaraFormatacao: data.tipoUnidadeMedida.mascaraFormatacao
      },
      TipoUnidadeMedidaId: data.tipoUnidadeMedidaId,
      UrlImagem: data.urlImagem,
      Veio: data.veio,
      permiteSerCanaletado: data.permiteSerCanaletado
    }
  }

  return formatData(response.data)
}

export const apiListarLojas = async () => {
  return await apiConfig.sendWithAxios(
    `${APICOREOCC}loja/v1/Loja/obter-lojas-sem-cd`,
    'GET'
  )
}

export const apiListarLojasPorEmpresa = async empresaIdErp => {
  return await apiConfig.sendWithAxios(
    `${APICOREOCC}loja/v1/Loja/empresaIdErp/${empresaIdErp}/obter-lista`,
    'GET'
  )
}

export const apiGetVendedoresPorLoja = async idErp => {
  return await apiConfig.sendWithAxios(
    `${APICOREOCC}geral/v1/Usuario/lojaIdErp/${idErp}/obter`,
    'GET'
  )
}

export const apiFitasSugeridasIntegracao = async (produtoPrincipal, lojaid) => {
  return await apiConfig.sendWithAxios(
    `${APICOREOCC}produto/v1/Penca/regra/produtoErpId/${produtoPrincipal}/lojaErpId/${lojaid}/pencaCanalId/2/obter-sugestiva-integracao`,
    'GET'
  )
}

export const apiDetalhePrutosPencados = async (
  listaIdsPrudutosPencados,
  lojaId,
  empresaIdCentro
) => {
  const centros =
    CDIDERP.includes(empresaIdCentro) || CSIDERP.includes(empresaIdCentro)
      ? CDIDERP.concat(CSIDERP)
      : [empresaIdCentro]

  const obj = {
    produtosId: listaIdsPrudutosPencados,
    centrosDistribuicao: centros.concat(lojaId)
  }

  const response = await apiConfig.sendWithAxios(
    `${APICOREOCC}produto/v1/produto/obter-produto-estoque`,
    'POST',
    obj
  )

  const formatData = data => {
    if (!data) return []
    return data?.map(item => {
      return {
        Altura: item.altura,
        Comprimento: item.comprimento,
        Descricao: item.descricao,
        DescricaoComercial: item.descricaoComercial,
        DescricaoResumida: item.descricaoResumida,
        Id: item.id,
        IdERP: item.idErp,
        Largura: item.largura,
        Ncm: item.ncm,
        PermiteSerCortado: item.permiteSerCortado,
        UrlImagem: item.urlImagem,
        SituacaoPorCentro: item.situacaoPorCentro
          ? {
              centroId: item.situacaoPorCentro.centroId,
              codigoSituacao: item.situacaoPorCentro.codigoSituacao,
              dataInicioPromocao: item.situacaoPorCentro.dataInicioPromocao,
              dataTerminoPromocao: item.situacaoPorCentro.dataTerminoPromocao,
              descricaoSituacao: item.situacaoPorCentro.descricaoSituacao,
              id: item.situacaoPorCentro.id,
              localizacaoEstoque: item.situacaoPorCentro.localizacaoEstoque,
              produtoId: item.situacaoPorCentro.produtoId,
              tipoPromocao: item.situacaoPorCentro.tipoPromocao,
              tipoPromocaoId: item.situacaoPorCentro.tipoPromocaoId
            }
          : null,
        Estoques: item.estoques.map(posicao => {
          return {
            CentroId: posicao.centroId,
            Deposito: {
              Descricao: posicao.deposito.descricao,
              Id: posicao.deposito.id
            },
            Depositoid: posicao.depositoId,
            DepositoIntermediario: posicao.depositoIntermediario,
            DescricaoDeposito: posicao.descricaoDeposito,
            EstoqueDisponivel: posicao.estoqueDisponivel,
            EstoqueReservado: posicao.estoqueReservado,
            EstoqueTotal: posicao.estoqueTotal
          }
        }),
        TipoUnidadeMedida: {
          Descricao: item.tipoUnidadeMedida.descricao,
          Id: item.tipoUnidadeMedida.id,
          IdERP: item.tipoUnidadeMedida.idErp,
          MascaraFormatacao: item.tipoUnidadeMedida.mascaraFormatacao
        }
      }
    })
  }

  return formatData(response.data)
}

export const apiListarProdutos = async (
  descricao,
  empresaIdERP,
  lojaIdErp,
  tipoProduto,
  totalRegistros,
  lojaIdEmpresa,
  depositoId,
  tipoCorte
) => {
  const userLogado = Storage.get('LEOPLAN_USER_INFOS')

  const objProduto = {
    tiposMaterial: [2, 3, 4, 8],
    situacoes: ['FF', 'FL', 'SF', 'SL', 'EN', 'LN', 'OP'],
    descricaoComercial: '',
    descricao: descricao,
    empresaIdERP: empresaIdERP,
    lojaId: lojaIdErp,
    CategoriaId: tipoProduto === 'F' ? 2 : 1,
    TotalRegistros: totalRegistros,
    IgnorarExcecao: false,
    empresaId: lojaIdEmpresa,
    DepositoId: depositoId,
    lojaExcecaoRestricaoCD: !!(
      userLogado?.tipoVendaId === 70 &&
      appConfig.REACT_APP_LojasPropriasComZLPL.find(x =>
        x.lojasId?.includes(lojaIdErp)
      )
    ),
    TipoOrdem: userLogado?.tipoVenda
  }

  if (tipoCorte === 4) objProduto.permiteSerCanaletado = true
  if (tipoProduto !== 'F') objProduto.permiteSerCortado = true

  const response = await apiConfig.sendWithAxiosCancelPrev(
    `${APICOREOCC}produto/v1/Produto/obter-produto-nome-arvores`,
    'POST',
    objProduto
  )

  const formatData = data => {
    if (!data) return []
    return data?.map(item => {
      return {
        Altura: item.altura,
        Comprimento: item.comprimento,
        Descricao: item.descricao,
        DescricaoComercial: item.descricaoComercial,
        DescricaoResumida: item.descricaoResumida,
        Id: item.id,
        IdERP: item.idErp,
        Largura: item.largura,
        MatchResult: item.matchResult,
        Ncm: item.ncm,
        PermiteSerCortado: item.permiteSerCortado,
        UrlImagem: item.urlImagem,
        SituacaoPorCentro: {
          CentroId: item.situacaoPorCentro.centroId,
          CodigoSituacao: item.situacaoPorCentro.codigoSituacao,
          DataInicioPromocao: item.situacaoPorCentro.dataInicioPromocao,
          DataTerminoPromocao: item.situacaoPorCentro.dataTerminoPromocao,
          DescricaoSituacao: item.situacaoPorCentro.descricaoSituacao,
          Id: item.situacaoPorCentro.id,
          LocalizacaoEstoque: item.situacaoPorCentro.localizacaoEstoque,
          ProdutoId: item.situacaoPorCentro.produtoId,
          TipoPromocao: item.situacaoPorCentro.tipoPromocao,
          TipoPromocaoId: item.situacaoPorCentro.tipoPromocaoId
        },
        Estoques: item.estoques.map(posicao => {
          return {
            CentroId: posicao.centroId,
            Deposito: {
              Descricao: posicao.deposito.descricao,
              Id: posicao.deposito.id
            },
            Depositoid: posicao.depositoId,
            DepositoIntermediario: posicao.depositoIntermediario,
            DescricaoDeposito: posicao.descricaoDeposito,
            EstoqueDisponivel: posicao.estoqueDisponivel,
            EstoqueReservado: posicao.estoqueReservado,
            EstoqueTotal: posicao.estoqueTotal
          }
        }),
        TipoUnidadeMedida: {
          Descricao: item.tipoUnidadeMedida.descricao,
          Id: item.tipoUnidadeMedida.id,
          IdERP: item.tipoUnidadeMedida.idErp,
          MascaraFormatacao: item.tipoUnidadeMedida.mascaraFormatacao
        },
        TipoUnidadeMedidaId: item.tipoUnidadeMedida.id,
        permiteSerCanaletado: item.permiteSerCanaletado
      }
    })
  }

  if (response.hasError) throw new Error('Erro ao buscar dados do vendedor')

  return formatData(response.data)
}

export const apiOrcamento = async (
  nome,
  lojaIdErp,
  projetoId,
  clenteDoc,
  vendedorId,
  produtos
) => {
  const body = {
    Descricao: nome,
    DocumentoCliente: clenteDoc,
    canalId: 6,
    LojaIdERPEnviada: lojaIdErp,
    Itens: produtos,
    IdPlanoDeCorte: [projetoId],
    VendedorIdERPEnviado: vendedorId
  }

  return await apiConfig.sendWithAxios(
    `${appConfig.REACT_APP_APICOREOCC_GW}pedido/v1/Orcamento/cadastrar-ecommerce`,
    'POST',
    body
  )
}
