import { Tabs, Tab } from '@mui/material'
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction
} from '@store/actions/snackbarActions.js'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { useDispatch } from 'react-redux'

import PageTitle from '@component/PageTitle/PageTitle'
import BottomBar from '@view/Configuracao/components/BottomBar.js'
import Materiais from '@view/Configuracao/components/Materiais.js'
import PlanoCorte from '@view/Configuracao/components/PlanoCorte.js'
import DialogSalvarAgrupamento from '@view/Configuracao/dialogs/DialogSalvarAgrupamento.js'

import api from '@service/api'
import Storage from '@service/storage'

export default function Configuracao() {
  const dispatch = useDispatch()
  const showMsgW = msg => dispatch(showMsgWAction(msg))
  const showMsgE = msg => dispatch(showMsgEAction(msg))
  const showMsgS = msg => dispatch(showMsgSAction(msg))

  const userLogado = Storage.get('LEOPLAN_USER_INFOS')

  const indexTabPlanoCorte = 0
  const indexTabMateriais = 1

  const [selectedTab, setSelectedTab] = useState(0)
  const [openDialogSalvarAgrupamento, setOpenDialogSalvarAgrupamento] =
    useState(false)

  const handleChangeTabs = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const handleOpenDialogNovoAgrupamento = () => {
    setOpenDialogSalvarAgrupamento(true)
  }

  const handleCloseDialogNovoAgrupamento = () => {
    setOpenDialogSalvarAgrupamento(false)
  }

  const handleCreateAgrupamento = async nomeNovoAgrupamento => {
    if (!nomeNovoAgrupamento) {
      showMsgW('Digite um nome para o grupo de materiais')
      return
    }

    try {
      const data = {
        nome: nomeNovoAgrupamento,
        lojaIdErp: userLogado.lojaIdErp,
        empresaIdErp: userLogado.empresaIdErp,
        usuario: userLogado.email
      }

      const response = await trackPromise(
        api.leoplan.apiCadastrarAgrupamento(data)
      )

      if (response.fail) throw new Error(response.errors)

      showMsgS('Novo grupo de materiais criado com sucesso!')
      handleCloseDialogNovoAgrupamento()
    } catch (error) {
      showMsgE(error)
    }
  }

  return (
    <div className={isMobile ? '' : 'pad20 padT10'}>
      <PageTitle title="Configuração de parâmetros" />

      <Tabs value={selectedTab} onChange={handleChangeTabs}>
        <Tab label="Planos de Corte" id={indexTabPlanoCorte} />
        <Tab label="Materiais" id={indexTabMateriais} />
      </Tabs>

      <PlanoCorte
        value={selectedTab}
        index={indexTabPlanoCorte}
        userLogado={userLogado}
      />
      <Materiais
        value={selectedTab}
        index={indexTabMateriais}
        update={openDialogSalvarAgrupamento}
      />

      <BottomBar
        setSelectedTab={selectedTab}
        indexTabPlanoCorte={indexTabPlanoCorte}
        actionNovoAgrupamento={handleOpenDialogNovoAgrupamento}
      />

      {openDialogSalvarAgrupamento && (
        <DialogSalvarAgrupamento
          open={openDialogSalvarAgrupamento}
          onClose={handleCloseDialogNovoAgrupamento}
          save={handleCreateAgrupamento}
        />
      )}
    </div>
  )
}
