import ImgFitaDefault from '@asset/img/fita-defalut.PNG'
import ImgMdfDefault from '@asset/img/mdf-defalut.PNG'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import React, { useEffect } from 'react'

import FitasMiniatura from '@component/FitasMiniatura'

import Utils from '@service/serviceUtils.js'

const hasMaterialCanaletado = materiais => {
  return materiais.some(m => m.canaletado > 0)
}

export const headersPlanoCorte = materiais => {
  let headers = [
    'Descrição',
    'Qtd.',
    'Comprimento',
    'Largura',
    'Veio',
    'Ignorar Veio',
    'Fitas de Borda',
    'Miniatura',
    'Serviços'
  ]

  if (hasMaterialCanaletado(materiais)) {
    headers = [
      'Descrição',
      'Qtd.',
      'Comprimento',
      'Largura',
      'Veio',
      'Ignorar Veio',
      'Canaletado (pol.)',
      'Fitas de Borda',
      'Miniatura',
      'Serviços'
    ]
  }

  return headers
}
export const headersChapaCanaletada = [
  'Descrição',
  'Qtd.',
  'Comprimento',
  'Largura',
  'Veio',
  'Canaletado (pol.)'
]

export const formatPlanoCorte = (ambientes, materiais) => {
  if (hasMaterialCanaletado(materiais)) {
    return ambientes.map(ambiente => {
      return {
        _id: ambiente.id,
        _className: { data: 'tableGorupStyle' },
        groupName: ambiente.descricao,
        groupData: ambiente.projetoPeca.map(peca => {
          const material = materiais.find(
            material => material.id === peca.materialId
          )

          const descricaoServico = Utils.mapNomeServico(
            peca?.projetoPecaServico[0]?.tipoServico
          )

          const data = {
            _id: peca.id,
            descricao: {
              data: (
                <>
                  <div className="tableProdThumb">
                    <img
                      src={material ? Utils.getImageUrl(material.codigo) : ''}
                      onError={event => {
                        event.target.src = ImgMdfDefault
                      }}
                      alt=""
                    />
                    {material.canaletado ? (
                      <div className="canaletaThumb" />
                    ) : (
                      ''
                    )}
                  </div>
                  {material.codigo} - {material.descricao}
                </>
              ),
              type: 'object'
            },
            qnt: {
              data: peca.quantidade.toString(),
              type: 'string'
            },
            comprimento: {
              data: peca.largura.toString(),
              type: 'string'
            },
            largura: {
              data: peca.altura.toString(),
              type: 'string'
            },
            veio: {
              data: material.veio ? 'sim' : 'não',
              type: 'string'
            },
            ignorarVeio: {
              data: (
                <div className="text-align-center">
                  {material?.veio && (
                    <CheckCircleIcon
                      className={!peca.veio ? 'activeIcon' : 'fadeIcon'}
                    />
                  )}
                </div>
              ),
              type: 'object'
            },
            canaletado: {
              data: material.canaletado || '',
              type: 'number'
            },
            fitas: {
              data: <FitasLista peca={peca} materiais={materiais} />,
              type: 'object'
            },
            miniatura: {
              data: <FitasMiniatura peca={peca} materiais={materiais} />,
              type: 'object'
            },
            servicos: {
              data: descricaoServico,
              type: 'string'
            }
          }
          return data
        })
      }
    })
  } else {
    return ambientes.map(ambiente => {
      return {
        _id: ambiente.id,
        _className: { data: 'tableGorupStyle' },
        groupName: ambiente.descricao,
        groupData: ambiente.projetoPeca.map(peca => {
          const material = materiais.find(
            material => material.id === peca.materialId
          )

          const descricaoServico = Utils.mapNomeServico(
            peca?.projetoPecaServico[0]?.tipoServico
          )

          const data = {
            _id: peca.id,
            descricao: {
              data: (
                <>
                  <div className="tableProdThumb">
                    <img
                      src={material ? Utils.getImageUrl(material.codigo) : ''}
                      onError={event => {
                        event.target.src = ImgMdfDefault
                      }}
                      alt=""
                    />
                    {material.canaletado ? (
                      <div className="canaletaThumb" />
                    ) : (
                      ''
                    )}
                  </div>
                  {material.codigo} - {material.descricao}
                </>
              ),
              type: 'object'
            },
            qnt: {
              data: peca.quantidade.toString(),
              type: 'string'
            },
            comprimento: {
              data: peca.largura.toString(),
              type: 'string'
            },
            largura: {
              data: peca.altura.toString(),
              type: 'string'
            },
            veio: {
              data: material.veio ? 'sim' : 'não',
              type: 'string'
            },
            ignorarVeio: {
              data: (
                <div className="text-align-center">
                  {material?.veio && (
                    <CheckCircleIcon
                      className={!peca.veio ? 'activeIcon' : 'fadeIcon'}
                    />
                  )}
                </div>
              ),
              type: 'object'
            },
            fitas: {
              data: <FitasLista peca={peca} materiais={materiais} />,
              type: 'object'
            },
            miniatura: {
              data: <FitasMiniatura peca={peca} materiais={materiais} />,
              type: 'object'
            },
            servicos: {
              data: descricaoServico,
              type: 'string'
            }
          }
          return data
        })
      }
    })
  }
}

export const formatChapaCanaletada = (pecas, materiais) => {
  return pecas.map(ambiente => {
    return {
      _className: { data: 'disabledTableHeader' },
      groupName: ambiente.descricao,
      groupData: ambiente.projetoPeca.map(peca => {
        const material = materiais.find(
          material => material.id === peca.materialId
        )
        return {
          _id: peca.id,
          descricao: {
            data: (
              <>
                <div className="tableProdThumb">
                  <img
                    src={material ? Utils.getImageUrl(material.codigo) : ''}
                    onError={event => {
                      event.target.src = ImgMdfDefault
                    }}
                    alt=""
                  />
                  {material.canaletado ? <div className="canaletaThumb" /> : ''}
                </div>
                {material.codigo} - {material.descricao}
              </>
            ),
            type: 'object'
          },
          qnt: {
            data: peca.quantidade.toString(),
            type: 'string'
          },
          comprimento: {
            data: peca.largura.toString(),
            type: 'string'
          },
          largura: {
            data: peca.altura.toString(),
            type: 'string'
          },
          veio: {
            data: peca.veio ? 'sim' : 'não',
            type: 'string'
          },
          Canaleta: {
            data: peca.canaletado?.toString(),
            type: 'string'
          }
        }
      })
    }
  })
}

export const FitasLista = ({ peca, materiais }) => {
  const [fitasSelecionadas, setFitasSelecionadas] = React.useState([])

  useEffect(() => {
    if (peca) {
      const fitas = materiais
        .filter(material => material.tipoProdutoId === 2)
        .filter(material => {
          return (
            peca.direitaMaterialId.toString() === material.id.toString() ||
            peca.esquerdaMaterialId.toString() === material.id.toString() ||
            peca.superiorMaterialId.toString() === material.id.toString() ||
            peca.inferiorMaterialId.toString() === material.id.toString()
          )
        })
      setFitasSelecionadas(fitas)
    }
  }, [peca])

  return (
    <div className={'pageBreak'}>
      {fitasSelecionadas.map((fita, indexFita) => {
        return (
          <div key={indexFita} className="prodThumbContainer">
            <span>
              <img
                src={Utils.getImageUrl(fita.codigo)}
                onError={event => {
                  event.target.src = ImgFitaDefault
                }}
                alt=""
                className="tableProdThumb fita"
                style={{ borderColor: fita.color }}
              />
            </span>
            {fita.codigo} - {fita.descricao}
          </div>
        )
      })}
    </div>
  )
}
