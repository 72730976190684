import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { memo } from 'react'

const MenuCopyPeca = props => {
  return (
    <Menu
      id="peca-menu"
      anchorEl={props.anchorElPeca}
      keepMounted
      open={Boolean(props.anchorElPeca)}
      onClose={props.closePecaOption}
    >
      <MenuItem onClick={props.copyPeca}>Copiar Peça</MenuItem>
      <MenuItem onClick={props.pastePeca}>Colar</MenuItem>
    </Menu>
  )
}

export default memo(MenuCopyPeca)
