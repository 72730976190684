const sistemasFixacao = [
  {
    id: -1,
    nome: 'Nenhum',
    value: 'None',
    conectorPrimario: 'Nenhum',
    conectorSecundario: 'Nenhum',
    permiteCavilhaExtra: false
  },
  {
    id: 1,
    nome: 'Cavilha 8 x 30',
    value: 'Dowels',
    conectorPrimario: 'Base',
    conectorSecundario: 'Topo',
    permiteCavilhaExtra: false
  },
  {
    id: 2,
    nome: 'Minifix',
    value: 'Minifix',
    conectorPrimario: 'Parafuso',
    conectorSecundario: 'Tambor',
    permiteCavilhaExtra: true
  },
  {
    id: 3,
    nome: 'VB 1 Furo',
    value: 'VBOne',
    conectorPrimario: 'Parafuso',
    conectorSecundario: 'Tambor',
    permiteCavilhaExtra: true
  },
  {
    id: 4,
    nome: 'VB 2 Furos',
    value: 'VBTwo',
    conectorPrimario: 'Parafuso',
    conectorSecundario: 'Tambor',
    permiteCavilhaExtra: true
  },
  // {
  //   id: 5,
  //   nome: 'D-Fix',
  //   value: 'DFix',
  //   conectorPrimario: 'Parafuso',
  //   conectorSecundario: 'Tambor',
  //   permiteCavilhaExtra: true
  // },
  {
    id: 6,
    nome: 'Minifix + Cavilha 8 x 30',
    value: 'MinifixAndDowels',
    conectorPrimario: 'Base',
    conectorSecundario: 'Topo',
    permiteCavilhaExtra: false
  }
]

export default sistemasFixacao
