import { SearchOutlined } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'

export default function SeachTable({ searchInput, searchOnChange, id }) {
  return (
    <TextField
      id={id}
      label="Pesquisar"
      size="small"
      variant="filled"
      value={searchInput}
      onChange={e => searchOnChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        )
      }}
    />
  )
}
