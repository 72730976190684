import React, { Fragment, memo } from 'react'

import { useProjeto } from '@hook/useProjeto'

const ClienteInfos = () => {
  const { cliente } = useProjeto()
  return (
    <Fragment>
      {cliente.idErp ? (
        <div className="row">
          <div className="col-lg-6">
            <div className="panel">
              <div className="panel-header text-sm sm:text-base">
                Dados Pessoais
              </div>
              <div className="panel-body divider">
                <div className="row">
                  <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                    {' '}
                    Cpf/Cnpj{' '}
                  </div>
                  <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base">
                    {' '}
                    {cliente.documento}{' '}
                  </div>

                  <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                    {' '}
                    Nome/Razão Social{' '}
                  </div>
                  <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base">
                    {' '}
                    {cliente.nome}{' '}
                  </div>

                  <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                    {' '}
                    Ramo{' '}
                  </div>
                  <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base">
                    {' '}
                    {cliente.ramo.nome}{' '}
                  </div>

                  {cliente.emails.length > 0 ? (
                    <Fragment key={cliente.emails[0].Id}>
                      <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                        {' '}
                        E-mail{' '}
                      </div>
                      <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base wrarpText">
                        {' '}
                        {cliente.emails[0].endereco}{' '}
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                        {' '}
                        E-mail{' '}
                      </div>
                      <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base">
                        {' '}
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="panel-header text-sm sm:text-base">Telefones</div>
              <div className="panel-body">
                {cliente.telefones.map((telefone, index) => (
                  <div className="row" key={index}>
                    <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                      {telefone.tipo.descricao}
                    </div>
                    <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base">
                      {telefone.numero}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="panel">
              <div className="panel-header text-sm sm:text-base">
                Endereço Principal
              </div>
              <div className="panel-body">
                {cliente.enderecos.map(endPadrao => (
                  <div className="row" key={endPadrao.Id}>
                    <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                      {' '}
                      Cep{' '}
                    </div>
                    <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base">
                      {' '}
                      {endPadrao.cep}{' '}
                    </div>

                    <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                      {' '}
                      Logradouro{' '}
                    </div>
                    <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base">
                      {' '}
                      {endPadrao.logradouro}{' '}
                    </div>

                    <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                      {' '}
                      Número{' '}
                    </div>
                    <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base">
                      {' '}
                      {endPadrao.numero}{' '}
                    </div>

                    <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                      {' '}
                      Complemento{' '}
                    </div>
                    <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base">
                      {' '}
                      {endPadrao.complemento}{' '}
                    </div>

                    <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                      {' '}
                      Bairro{' '}
                    </div>
                    <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base">
                      {' '}
                      {endPadrao.bairro}{' '}
                    </div>

                    <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                      {' '}
                      Cidade{' '}
                    </div>
                    <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base">
                      {' '}
                      {endPadrao.cidade}{' '}
                    </div>

                    <div className="col-sm-5 col-md-4 col-lg-5 text-sm sm:text-base">
                      {' '}
                      UF{' '}
                    </div>
                    <div className="col-sm-7 col-md-8 col-lg-7 text-sm sm:text-base">
                      {' '}
                      {endPadrao.estado}{' '}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-sm-12 text-sm sm:text-base">
            nenhum cliente selecionado
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default memo(ClienteInfos)
