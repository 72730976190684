import { Close } from '@mui/icons-material'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  Button,
  IconButton,
  Typography,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'

import { useConfiguracao } from '@hook/useConfiguracao.js'

import InfoMsg from '@component/InfoMsg/InfoMsg'

export default function DialogTamanhoCanaletado({
  open,
  close,
  action,
  canaletado,
  handleOpenModalCanaisPorChapa
}) {
  const { configData } = useConfiguracao()
  const [value, setValue] = useState(canaletado > 0 ? canaletado : '')

  const handleChangeValue = value => {
    setValue(value)
    handleOpenModalCanaisPorChapa(value)
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Canaletar Painel</Typography>
        <IconButton aria-label="close" onClick={close} size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <InfoMsg
            type="info"
            title="Atenção"
            msg="Escolha o tamanho das canaletas para o painel 5057887 - MDF Alabama Dual Touch 2 Faces 18mm Guararapes"
          />

          <FormControl variant="filled">
            <InputLabel id="canaletas_label" htmlFor="filled-fileformat">
              Distância entre canaletas
            </InputLabel>
            <Select
              labelId="canaletas_label"
              variant="filled"
              size="small"
              value={value || ''}
              onChange={e => handleChangeValue(e.target.value)}
            >
              {configData?.configuracoesCanaletagem.map(size => {
                return (
                  <MenuItem key={size.canaletado} value={size.canaletado}>
                    {size.canaletado}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <div className="text-center">
            <Button
              onClick={() => action(value)}
              variant="contained"
              color="secondary"
              className="mb10 mr5"
            >
              Salvar
            </Button>
            <Button
              onClick={close}
              variant="contained"
              className="mb10 blackButton"
            >
              Cancelar
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
