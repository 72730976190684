import CloseIcon from '@mui/icons-material/Close'
import SearchOutlined from '@mui/icons-material/SearchOutlined'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { useSelector } from 'react-redux'

import { useProjeto } from '@hook/useProjeto.js'
import { useUser } from '@hook/useUser.js'

import InfoMsg from '@component/InfoMsg/InfoMsg.js'

import api from '@service/api/index.js'
import Utils from '@service/serviceUtils.js'

import TabelaListaMateriaisInclusao from '../Materiais/ListaMateriaisInclusao.js'

const DialogAddChapa = ({
  openModalAddMaterial,
  handleCloseModalAddMaterial,
  changeMaterialRef,
  materialDepara,
  espessuraChapa,
  handleIncludeMaterail,
  showMsgW
}) => {
  const { configCorteLoja } = useSelector(state => state.planoCorteState)
  const { vendedor, projeto } = useProjeto()
  const { user } = useUser()
  const { minLimitFL } = configCorteLoja

  const [tipoEspessura, setTipoEspessura] = React.useState(espessuraChapa || '')
  const [descricao, setDescricao] = React.useState('')
  const [searchTextTimeout, setSearchTextTimeout] = React.useState(null)
  const [listaBuscaChapa, setListaBuscaChapa] = React.useState([])

  const handleChangeEspessura = value => {
    setTipoEspessura(value)
    handleBuscaChapa(descricao, value)
  }
  const handleChangeDescricao = value => {
    setDescricao(value)
    handleBuscaChapa(value, tipoEspessura)
  }
  const handleBuscaChapa = async (desc, espe) => {
    if (searchTextTimeout) clearTimeout(searchTextTimeout)
    if (descricao.length >= 3 || setTipoEspessura) {
      setSearchTextTimeout(
        setTimeout(async () => {
          const vendedorSearch = vendedor.empresaIdErp ? vendedor : user
          if (vendedorSearch.empresaIdErp) {
            try {
              const res = await trackPromise(
                api.core.apiListarProdutos(
                  desc + ' ' + espe,
                  vendedorSearch.empresaIdErp,
                  vendedorSearch.lojaIdErp,
                  'C',
                  100,
                  vendedorSearch.loja.empresaId,
                  vendedorSearch.depositoId,
                  projeto.tipoCorte
                )
              )
              const allMaterialsFS = Utils.formatMaterials(
                res,
                user,
                minLimitFL
              )
              setListaBuscaChapa(allMaterialsFS)
            } catch (error) {
              // console.log('error-', error)
            }
          } else {
            showMsgW('Informe o vendedor e a loja')
          }
        }, 500)
      )
    } else {
      setListaBuscaChapa([])
    }
  }
  return (
    <Dialog
      open={openModalAddMaterial}
      onClose={handleCloseModalAddMaterial}
      aria-labelledby="customized-dialog-title"
      fullWidth={true}
      maxWidth="md"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        {changeMaterialRef ? (
          <Typography>Trocar Chapa do Projeto </Typography>
        ) : (
          <Typography>Adicionar Chapa ao Projeto </Typography>
        )}
        <IconButton
          aria-label="close"
          onClick={handleCloseModalAddMaterial}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {changeMaterialRef ? (
          <InfoMsg
            type="warning"
            msg={`Pesquise as Chapa para escolher uma substituta para: ${changeMaterialRef.codigo} - ${changeMaterialRef.descricao}.`}
          ></InfoMsg>
        ) : materialDepara ? (
          <InfoMsg
            type="info"
            msg={`Pesquise as Chapa correspondente a ${materialDepara} que deseja incluir no projeto.`}
          ></InfoMsg>
        ) : (
          <InfoMsg
            type="info"
            msg={'Pesquise as Chapa que deseja incluir no projeto.'}
          ></InfoMsg>
        )}
        <div className="row">
          <div className="col-sm-3">
            <FormControl variant="filled" size="small">
              <InputLabel>Espessura</InputLabel>
              <Select
                value={tipoEspessura}
                onChange={e => handleChangeEspessura(e.target.value)}
                label={'Pesquisar Espessura'}
                disabled={!!espessuraChapa}
              >
                <MenuItem value=" ">
                  <em>Todas</em>
                </MenuItem>
                <MenuItem value="2,5mm">2,5mm</MenuItem>
                <MenuItem value="3mm">3mm</MenuItem>
                <MenuItem value="6mm">6mm</MenuItem>
                <MenuItem value="9mm">9mm</MenuItem>
                <MenuItem value="12mm">12mm</MenuItem>
                <MenuItem value="15mm">15mm</MenuItem>
                <MenuItem value="18mm">18mm</MenuItem>
                <MenuItem value="20mm">20mm</MenuItem>
                <MenuItem value="25mm">25mm</MenuItem>
                <MenuItem value="35mm">35mm</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-sm-9">
            <FormControl variant="filled">
              <TextField
                label={'Pesquisar Chapa'}
                size="small"
                variant="filled"
                value={descricao}
                onChange={e => handleChangeDescricao(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <TabelaListaMateriaisInclusao
              materiais={listaBuscaChapa}
              addMaterial={{ handleIncludeMaterail, type: 'C' }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DialogAddChapa
