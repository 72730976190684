import { createContext, useCallback, useContext, useState } from 'react'

const StepperControlContext = createContext()

const StepperControlProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0) // step ativo
  const [completed, setCompleted] = useState(new Set())
  const [isCanaletadoProject, setIsCanaletadoProject] = useState(false)

  const getSteps = useCallback(() => {
    if (isCanaletadoProject) {
      return ['Dados do projeto', 'Chapas Canaletadas', 'Plano de corte']
    }

    return ['Dados do projeto', 'Materiais', 'Ambientes', 'Plano de corte']
  }, [isCanaletadoProject])

  // - marca step como completo
  const handleComplete = useCallback(
    step => {
      const newCompleted = completed
      newCompleted.add(step)
      setCompleted(newCompleted)
    },
    [completed]
  )

  // - desmarca step
  const handleUncomplete = useCallback(
    step => {
      const newCompleted = completed
      if (newCompleted.has(step)) {
        newCompleted.delete(step)
        setCompleted(newCompleted)
      }
    },
    [completed]
  )

  const voltarEtapa = step => {
    setActiveStep(step)
  }

  const handleRestartSteps = useCallback(() => {
    setCompleted(new Set())
    setActiveStep(0)
  }, [])

  return (
    <StepperControlContext.Provider
      value={{
        getSteps,
        activeStep,
        setActiveStep,
        completed,
        setCompleted,
        handleComplete,
        handleUncomplete,
        voltarEtapa,
        isCanaletadoProject,
        setIsCanaletadoProject,
        handleRestartSteps
      }}
    >
      {children}
    </StepperControlContext.Provider>
  )
}

function useStepperControl() {
  const context = useContext(StepperControlContext)

  return context
}

export { StepperControlProvider, useStepperControl }
