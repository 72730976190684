import SaveAlt from '@mui/icons-material/SaveAlt'
import Button from '@mui/material/Button'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

import { useConfiguracao } from '@hook/useConfiguracao'
import { useProjeto } from '@hook/useProjeto'

import InfoMsg from '@component/InfoMsg/InfoMsg'

import './style.scss'

const BoxPlanoCorte = ({ modalDownload }) => {
  const { projeto, otimizacao } = useProjeto()
  const { configData } = useConfiguracao()

  function getChapaName(idChapa) {
    if (idChapa && projeto.projetoProduto) {
      const chapa = projeto.projetoProduto.filter(
        x => x.id.toString() === idChapa
      )
      if (chapa.length > 0) {
        return chapa[0].descricao
      }
    }
    return ''
  }

  function getChapaCodigo(idChapa) {
    if (idChapa && projeto.projetoProduto) {
      const chapa = projeto.projetoProduto.filter(
        x => x.id.toString() === idChapa
      )
      if (chapa.length > 0) {
        return chapa[0].codigo
      }
    }
    return ''
  }

  function getChapaVeio(idChapa) {
    if (idChapa && projeto.projetoProduto) {
      return projeto.projetoProduto.find(
        x => x.id.toString() === idChapa.toString()
      ).veio
    }
    return ''
  }
  function getCanaleta(idChapa) {
    if (idChapa && projeto.projetoProduto) {
      return (
        projeto.projetoProduto.find(x => x.id.toString() === idChapa.toString())
          .canaletado || 0
      )
    }
    return ''
  }

  function getTotalCanais(idChapa) {
    const canaleta = projeto.projetoProduto.find(
      x => x.id.toString() === idChapa.toString()
    ).canaletado

    const canaisPorChapa = configData?.configuracoesCanaletagem?.find(
      item => item.canaletado === canaleta
    )

    return canaisPorChapa?.quantidadeCanaletas
  }

  function gotoDownnload(id, index, materialId) {
    modalDownload(id, index, getChapaCodigo(materialId))
  }
  const formatCanaletado = otimizacao => {
    const chapa = projeto.projetoProduto.find(
      x => x.id.toString() === otimizacao.materialId.toString()
    )
    if (chapa.canaletado) {
      const distanciaPolegadas = chapa.canaletado * 240
      const largura = chapa.largura * 100
      const altura = chapa.altura * 100
      const numeroCortes = Math.ceil(altura / distanciaPolegadas)
      const cuts = Array(numeroCortes).fill('cut')
      const htmlCanaletagem = cuts.reduce((add, _, index) => {
        if (index > 30) return add
        if (index === 0) return ''
        return (
          add +
          `<rect x="0" y="${
            distanciaPolegadas * index
          }" width="${largura}" height="150" class="canaleta"></rect>`
        )
      }, '')
      return otimizacao.html.replaceAll('</svg', htmlCanaletagem + '</svg')
    } else {
      if (isMobile) return otimizacao.html.split('</svg>')[0]
      return otimizacao.html
    }
  }

  return otimizacao?.map((planoOtimizado, index) => {
    return (
      <div className="drawCut" key={index}>
        <div className="panel">
          <div className="panel-header flex-col">
            <div className="panel-header-principal">
              <p>
                Chapa:{' '}
                <span>
                  {getChapaCodigo(planoOtimizado.materialId)} -{' '}
                  {getChapaName(planoOtimizado.materialId)}
                </span>{' '}
              </p>
              <p>
                Qtd de Peças cortadas:{' '}
                <span>{planoOtimizado.qtdPlacasCortadas}</span>{' '}
              </p>
              <p>
                Sobras utilizavéis:{' '}
                <span>{planoOtimizado.sobrantesUtilizaveis}</span>{' '}
              </p>
              <p>
                Possui Veio:{' '}
                <span>
                  {getChapaVeio(planoOtimizado.materialId) ? 'Sim' : 'Não'}
                </span>
              </p>
              {(projeto.tipoCorte === 4 ||
                getCanaleta(planoOtimizado.materialId) > 0) && (
                <p>
                  Canaleta:{' '}
                  <span>
                    {getCanaleta(planoOtimizado.materialId) > 0
                      ? getCanaleta(planoOtimizado.materialId) + ' pol.'
                      : 'Não'}
                  </span>
                </p>
              )}
            </div>
          </div>
          <div className="panel-body divider">
            <div className="row">
              <div className={isMobile ? 'w-100' : 'col-sm-12'}>
                {getCanaleta(planoOtimizado.materialId) ? (
                  <div className="mb10">
                    <InfoMsg
                      type="info"
                      title="Atenção"
                      msg="As linhas vermelhas representam uma previsão da localização das canaletas na chapa"
                    />
                    <div className="flex justify-center">
                      <h3 className="text-center">
                        A chapa terá{' '}
                        <b>
                          {getTotalCanais(planoOtimizado.materialId)} canaletas{' '}
                        </b>
                        com aproximadamente{' '}
                        <b>
                          {getCanaleta(planoOtimizado.materialId) * 2.54}{' '}
                          centímetros{' '}
                        </b>
                        de distância.
                      </h3>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div
                  className="resposta svgMaxSize text-sm md:text-base"
                  dangerouslySetInnerHTML={{
                    __html: formatCanaletado(planoOtimizado)
                  }}
                />
              </div>
              <div
                className={
                  isMobile ? 'w-100 mt-2' : 'col-sm-12 mt10 mb10 noPrint'
                }
              >
                <Button
                  variant="contained"
                  className={`blackButton ${isMobile ? 'w-100 mt-2' : 'm5'}}`}
                  onClick={() =>
                    gotoDownnload(
                      planoOtimizado.id,
                      planoOtimizado.index,
                      planoOtimizado.materialId
                    )
                  }
                >
                  <SaveAlt />
                  Download
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  })
}

export default memo(BoxPlanoCorte)
