import loadImg from '@asset/img/load-100.gif'
import React, { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { useNavigate, useParams } from 'react-router-dom'

import { appConfig } from '@config/env-config.js'

import { useConfiguracao } from '@hook/useConfiguracao.js'
import { useUser } from '@hook/useUser.js'

import api from '@service/api'

const ENVIROMENT = appConfig.REACT_APP_ENVIROMENT
const PARENTURL = appConfig.REACT_APP_PARENTURL
const Inicial = () => {
  const {
    tipo,
    tipoVenda: tipoVendaParam,
    depositoId: depositoIdParam,
    cl: clParam,
    acesso: acessoParam,
    id: idParam,
    idExterno: idExternoParam,
    pedidoId: pedidoIdParam,
    projetoId: projetoIdParam
  } = useParams()
  const navigate = useNavigate()

  const page = tipo?.toLowerCase() || 'historico'
  const tipoVenda = tipoVendaParam?.toString()
  const depositoId = depositoIdParam?.toString()
  const selectedClientId = clParam?.toString()
  const [load, setLoad] = useState('load')
  const { setupVendor, setupClient } = useUser()
  const { setOrigin, setLoja } = useConfiguracao()

  const formatClient = client => {
    const newClient = client
    newClient.tipoUserLeoPlan = 'cliente'
    newClient.enderecos = client.enderecos.filter(
      endereco => endereco.tipoEnderecoId === 1
    )
    if (newClient.enderecos.length >= 1) {
      newClient.enderecos = [newClient.enderecos[0]]
    }
    newClient.idErp = client.idExterno
    newClient.pedidoId = null
    newClient.tipoVenda = 'ZCVS'
    newClient.depositoId = '0001'

    return newClient
  }

  const formatVendor = async vendor => {
    try {
      const newVendor = vendor
      newVendor.tipoUserLeoPlan = 'vendedor'
      newVendor.lojaIdErp = vendor.loja.idErp
      newVendor.empresaIdErp = vendor.loja.empresa.idErp
      newVendor.loja.id = vendor.loja.empresa.id
      newVendor.loja.idErp = vendor.loja.idErp
      newVendor.loja.nomeLoja = vendor.loja.nome
      newVendor.pedidoId = pedidoIdParam
      newVendor.perfilOperacional = vendor.perfilOperacional.map(
        perfil => perfil.nome
      )
      if (tipoVenda) {
        if (tipoVenda === '7') {
          newVendor.tipoVenda = 'ZCVS'
          newVendor.tipoVendaId = 7
        } else if (tipoVendaParam === '70') {
          newVendor.tipoVenda = 'ZLPL'
          newVendor.tipoVendaId = 70
        } else if (tipoVendaParam === '75') {
          newVendor.tipoVenda = 'ZMOB'
          newVendor.tipoVendaId = 75
        } else {
          newVendor.tipoVenda = tipoVendaParam
          newVendor.tipoVendaId = tipoVendaParam || null
        }
        newVendor.depositoId = depositoId
      }
      const response = await api.core.apiGetClienteInfos(selectedClientId)

      if (response.fail) throw new Error(response.errors)

      const selectedClient = response.data

      newVendor.clientePreSelecionado = selectedClientId ? selectedClient : null

      return newVendor
    } catch (error) {
      console.log(error)
    }
  }

  const redirect = () => {
    if (page === 'edit') {
      navigate(`/plano-de-corte/detalhe/${projetoIdParam}`)
    } else navigate(`/plano-de-corte/projetos/${page}`)
  }
  const verifyParent = () => {
    if (window.location.ancestorOrigins) {
      if (window.location.ancestorOrigins.length <= 0) {
        return false
      } else if (!PARENTURL.includes(window.location.ancestorOrigins[0])) {
        return false
      }
      return true
    } else if (document.referrer) {
      if (!PARENTURL.some(url => document.referrer.startsWith(url, 0))) {
        return false
      }
      return true
    } else return false
  }

  const handleSetupClient = async idExterno => {
    try {
      const response = await api.core.apiGetClienteInfos(idExterno)

      if (response.fail) throw new Error()

      if (response?.data?.id) {
        const client = formatClient(response.data)
        setupClient(client)
        setOrigin('ECOM')
        redirect()
      } else {
        setLoad('notfound')
      }
    } catch (err) {
      setLoad('error')
    }
  }

  const handleSetupVendor = async email => {
    try {
      const response = await trackPromise(api.core.apiGetVendedorInfos(email))

      if (response.fail) throw new Error()

      if (response?.data?.id) {
        const vendor = await formatVendor(response.data)
        setupVendor(vendor)
        setOrigin('SVL')
        setLoja(vendor.lojaIdErp)
        redirect()
      } else {
        setLoad('notfound')
      }
    } catch (err) {
      setLoad('error')
    }
  }

  useEffect(() => {
    if (
      (acessoParam && idParam && idExternoParam) ||
      verifyParent() ||
      ENVIROMENT === 'DEV' ||
      ENVIROMENT === 'QA'
    ) {
      if (
        acessoParam?.toLowerCase() === 'viasoft' ||
        acessoParam?.toLowerCase() === 'vendedor'
      ) {
        handleSetupVendor(idParam)
      } else {
        handleSetupClient(idExternoParam)
      }
    } else {
      setLoad('notAllowed')
    }
  }, [])

  return (
    <>
      <div className="flex flex-col text-center h-screen justify-center">
        {load === 'load' ? (
          <h5>Carregando... </h5>
        ) : load === 'error' ? (
          <h5>Problemas ao encontrar usuário, tente novamente mais tarde </h5>
        ) : load === 'notfound' ? (
          <h5>Usuário não localizado</h5>
        ) : load === 'notAllowed' ? (
          <h5>Você não tem permissão para acessar este serviço</h5>
        ) : (
          <h5>Redirecionando... </h5>
        )}

        <div className="m-1 mx-auto">
          {load === 'load' ? <img src={loadImg} alt="load" /> : null}
        </div>
      </div>
    </>
  )
}

export default Inicial
