import { Navigate, Outlet } from 'react-router-dom'

import { useUser } from '@hook/useUser.js'

import Private from './Private.js'

const PrivateRoutes = () => {
  const { hasUser } = useUser()
  return hasUser ? (
    <Private>
      <Outlet />
    </Private>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  )
}

export default PrivateRoutes
