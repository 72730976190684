import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

const DialogDelMaterial = props => {
  const {
    openModalExcluir,
    handleCloseModalExcluir,
    removeMaterial,
    materialId,
    tipo
  } = props

  return (
    <Dialog
      open={openModalExcluir}
      onClose={handleCloseModalExcluir}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Excluir Material</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseModalExcluir}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="text-center" dividers>
        {tipo === 'F' ? (
          <Typography className="mb20">
            Esta fita possui peças vinculadas, essas peças ficarão sem fita.
            Deseja continuar?
          </Typography>
        ) : (
          <Typography className="mb20">
            Ao excluir esta chapa, você também irá excluir as peças vinculadas á
            ela. Deseja continuar?
          </Typography>
        )}
        <Button
          onClick={() => removeMaterial(materialId, tipo)}
          variant="contained"
          color="secondary"
          className="mb10 mr5"
        >
          Confirmar Exclusão
        </Button>
        <Button
          onClick={handleCloseModalExcluir}
          variant="contained"
          className="mb10 blackButton"
        >
          Cancelar
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default memo(DialogDelMaterial)
