import ampliarCanal from '@asset/img/ampliar-canal.png'
import IconFuracaoUsinagem from '@asset/img/icone-furacao-usinagem.svg'
import DeleteForever from '@mui/icons-material/DeleteForever'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import FileCopy from '@mui/icons-material/FileCopy'
import SwapHoriz from '@mui/icons-material/SwapHoriz'
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import {
  showMsgW as showMsgWAction,
  showMsgS as showMsgSAction
} from '@store/actions/snackbarActions.js'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useConfiguracao } from '@hook/useConfiguracao.js'
import { useProjeto } from '@hook/useProjeto.js'

import FitasMiniatura from '@component/FitasMiniatura/index.js'
import SelectUsinagem from '@component/SelectUsinagem/SelectUsinagem.js'

import Mask from '@service/mask.js'
import Utils from '@service/serviceUtils.js'

import InfoTip from '@base/components/InfoTip/index.jsx'

import sistemasFixacaoMenu from '@mock/sistemasFixacao.js'

import DialogBordeamentoPeca from '../Dialogs/DialogBordeamentoPeca.js'
import DialogIntegracaoFurcaoUsinagem from '../Dialogs/DialogIntegracaoFurcaoUsinagem/DialogIntegracaoFurcaoUsinagem.tsx'
import TooltipListaFitas from './TooltipListaFitas.js'

import './PecaItemLine.scss'

const PecaItemLine = ({
  peca,
  planEdtPeca,
  indexAmbiente,
  indexPeca,
  openPecaOption,
  removePeca,
  tabNewPeca,
  salvarGrupoPecas,
  addPecaSecundaria,
  removePecaSecundaria,
  tabIndex,
  tabIndexLineFields,
  setFitaFocus,
  fitaFocus,
  addNewLine
}) => {
  const dispatch = useDispatch()
  const inputMaterial = useRef()
  const inputQuantidade = useRef()
  const showMsgW = msg => dispatch(showMsgWAction(msg))
  const showMsgS = msg => dispatch(showMsgSAction(msg))
  const { lojaCongifOtmizacao, planEdtFitasPeca, projeto } = useProjeto()
  const { habilitarFuracaoUsinagem } = useConfiguracao()
  const config = lojaCongifOtmizacao
  const [listaFitasDisponiveis, setListaFitasDisponiveis] = useState([])
  const [listaChapasDisponiveis, setListaChapasDisponiveis] = useState([])
  const [chapaInput, setChapaInput] = useState('')
  const [chapaSelecionada, setChapaSelecionada] = useState({})
  const [fitasSelecionadas, setFitasSelecionadas] = useState([])
  const [ignorarVeio, setIgnorarVeio] = useState(false)
  const [triggerChange, setTriggerChange] = useState(true)
  const [triggerValidateFita, setTriggerValidateFita] = useState(false)
  const [errors, setErrors] = useState({})
  const [warnings, setWarnings] = useState({})
  const [openModalConfigBorda, setOpenModalConfigBorda] = useState(false)
  const [openFuracaoUsinagemModal, setOpenFuracaoUsinagemModal] =
    useState(false)
  const [hasUsinagem, setHasUsinagem] = useState(
    !!peca?.projetoPecaServico?.length
  )
  const isPrimaria = !!peca?.projetoPecaServico?.[0]?.projetoPecaSecundariaId
  const isSecundaria = !!peca.projetoPecaServico?.[0]?.projetoPecaPrimariaId

  const getSistemaFixacaoNome = sistemaFixacaoId => {
    return (
      sistemasFixacaoMenu.find(item => item.id === sistemaFixacaoId)?.value ||
      'None'
    )
  }

  const sistemaFixacaoNome = getSistemaFixacaoNome(
    peca?.projetoPecaServico[0]?.sistemaFixacaoId
  )
  const [sistemaFixacao, setSistemaFixacao] = useState(sistemaFixacaoNome)
  const fixacaoConectorNome =
    peca?.projetoPecaServico[0]?.fixacaoConector === 1
      ? 'side'
      : peca?.projetoPecaServico[0]?.fixacaoConector === 2
      ? 'base'
      : ''

  const [sistemaFixacaoLado, setSistemaFixacaoLado] =
    useState(fixacaoConectorNome)

  const tabIndexSequence = tabIndex * tabIndexLineFields

  const handleCloseModalConfigBorda = () => {
    setOpenModalConfigBorda(false)
  }

  const handleSaveProjetoServico = event => {
    const value = event.target.value
    if (event.target.name === 'sistemaFixacaoId') {
      const sistemaFixacaoId = sistemasFixacaoMenu.find(
        item => item.value === value
      )?.id

      setSistemaFixacao(value)
      setProjetoPecaServico([
        {
          ...peca?.projetoPecaServico[0],
          sistemaFixacaoId
        }
      ])

      if (isPrimaria) {
        if (sistemaFixacaoId === -1) {
          const { projetoPecaSecundariaId } = peca.projetoPecaServico[0]
          removePecaSecundaria(peca.id, projetoPecaSecundariaId)
        }
      }
    }

    if (event.target.name === 'fixacaoConector') {
      setSistemaFixacaoLado(value)
      setProjetoPecaServico([
        {
          ...peca?.projetoPecaServico[0],
          fixacaoConector: value === 'side' ? 1 : value === 'base' ? 2 : null
        }
      ])

      if (value === 'side') {
        addPecaSecundaria(indexAmbiente, indexPeca, peca)
      }

      if (
        value === 'base' &&
        peca?.projetoPecaServico[0]?.projetoPecaSecundariaId
      ) {
        const { projetoPecaSecundariaId } = peca.projetoPecaServico[0]

        removePecaSecundaria(peca.id, projetoPecaSecundariaId)
      }
    }
  }
  const fillInputs = (chapas, fitas) => {
    if (peca.materialId) {
      const selectedFitas = peca.pecaProduto.map(
        produto => produto.projetoProdutoId
      )
      setChapaSelecionada(
        chapas.find(material => {
          if (material.id.toString() === peca.materialId.toString()) {
            return { ...material, ...peca }
          }
        })
      )
      setFitasSelecionadas(
        fitas.filter(material => {
          return selectedFitas.includes(material.id)
        })
      )
    }
  }
  const handleAutoSizeTiras = chapa => {
    if (projeto?.tipoCorte === 2) {
      handleChangeProperty('largura', chapa.largura * 10)
    } else if (projeto?.tipoCorte === 3) {
      handleChangeProperty('altura', chapa.altura * 10)
    }
  }
  const selectChapa = selected => {
    handleAutoSizeTiras(selected)
    setChapaSelecionada(selected)
    handleChangeProperty('materialId', selected?.id)

    if (isPrimaria) {
      planEdtPeca(selected?.id, indexAmbiente, indexPeca + 1, 'materialId')
    }

    if (selected?.canaletado) {
      selectFitas([])
    }
  }
  const selectFitas = selected => {
    if (selected.length <= 4) {
      setFitasSelecionadas(selected)
      setTriggerChange(!triggerChange)
      setTriggerValidateFita(true)
    } else {
      showMsgW('Limite de fitas atingido.')
    }
  }
  const selectIgnorarVeio = ignorarVeio => {
    setIgnorarVeio(!ignorarVeio)
    planEdtPeca(!ignorarVeio, indexAmbiente, indexPeca, 'veio')
  }

  const validateSize = () => {
    let errorMsg

    errorMsg =
      +peca?.altura < config.minLarguraPecaCanal &&
      !!peca?.projetoPecaServico[0]?.tipoServico &&
      peca?.projetoPecaServico[0]?.tipoServico === 2
        ? `Comprimento da peça inferior a ${config.minLarguraPecaCanal} não permite canal`
        : +peca.altura < config.minLarguraPecaDobradica &&
          !!peca?.projetoPecaServico[0]?.tipoServico &&
          peca?.projetoPecaServico[0]?.tipoServico === 1
        ? `Comprimento da peça inferior a ${config.minLarguraPecaDobradica} não permite dobradiça`
        : +peca.altura < config.minLarguraPecaRebaixo &&
          !!peca?.projetoPecaServico[0]?.tipoServico &&
          peca?.projetoPecaServico[0]?.tipoServico === 3
        ? `Comprimento da peça inferior a ${config.minLarguraPecaRebaixo} não permite rebaixo`
        : +peca?.altura > config.maxLarguraPecaCanal &&
          !!peca?.projetoPecaServico[0]?.tipoServico &&
          peca?.projetoPecaServico[0]?.tipoServico === 2
        ? `Comprimento da peça superior a ${config.maxLarguraPecaCanal} não permite canal`
        : +peca.altura > config.maxLarguraPecaDobradica &&
          !!peca?.projetoPecaServico[0]?.tipoServico &&
          peca?.projetoPecaServico[0]?.tipoServico === 1
        ? `Comprimento da peça superior a ${config.maxLarguraPecaDobradica} não permite dobradiça`
        : +peca.altura > config.maxLarguraPecaRebaixo &&
          !!peca?.projetoPecaServico[0]?.tipoServico &&
          peca?.projetoPecaServico[0]?.tipoServico === 3
        ? `Comprimento da peça superior a ${config.maxLarguraPecaRebaixo} não permite rebaixo`
        : null

    if (!!errorMsg) return errorMsg

    errorMsg =
      +peca?.largura < config.minAlturaPecaCanal &&
      !!peca?.projetoPecaServico[0]?.tipoServico &&
      peca?.projetoPecaServico[0]?.tipoServico === 2
        ? `Largura da peça inferior a ${config.minAlturaPecaCanal} não permite canal`
        : +peca.largura < config.minAlturaPecaDobradica &&
          !!peca?.projetoPecaServico[0]?.tipoServico &&
          peca?.projetoPecaServico[0]?.tipoServico === 1
        ? `Largura da peça inferior a ${config.minAlturaPecaDobradica} não permite dobradiça`
        : +peca.largura < config.minAlturaPecaRebaixo &&
          !!peca?.projetoPecaServico[0]?.tipoServico &&
          peca?.projetoPecaServico[0]?.tipoServico === 3
        ? `Largura da peça inferior a ${config.minAlturaPecaRebaixo} não permite rebaixo`
        : +peca?.largura > config.maxAlturaPecaCanal &&
          !!peca?.projetoPecaServico[0]?.tipoServico &&
          peca?.projetoPecaServico[0]?.tipoServico === 2
        ? `Largura da peça superior a ${config.maxAlturaPecaCanal} não permite canal`
        : +peca.largura > config.maxAlturaPecaDobradica &&
          !!peca?.projetoPecaServico[0]?.tipoServico &&
          peca?.projetoPecaServico[0]?.tipoServico === 1
        ? `Largura da peça superior a ${config.maxAlturaPecaDobradica} não permite dobradiça`
        : +peca.largura > config.maxAlturaPecaRebaixo &&
          !!peca?.projetoPecaServico[0]?.tipoServico &&
          peca?.projetoPecaServico[0]?.tipoServico === 3
        ? `Largura da peça superior a ${config.maxAlturaPecaRebaixo} não permite rebaixo`
        : null

    if (!!errorMsg) return errorMsg
  }

  const inverteMedidasPeca = () => {
    const errorMsg = validateSize()

    if (!!errorMsg) {
      return showMsgW(errorMsg)
    }

    if (isPrimaria) {
      planEdtPeca(peca.largura, indexAmbiente, indexPeca + 1, 'altura')
    }

    planEdtPeca(0, indexAmbiente, indexPeca, 'inverterMedidas')

    showMsgS(
      `Medidas da peça ${peca.descricao || indexPeca + 1} foram invertidas `
    )
  }

  const handleStopBloubbles = (action, e) => {
    e.stopPropagation()
    action(e)
  }

  const enableEditRow = open => {
    if (!open) {
      planEdtPeca(true, indexAmbiente, indexPeca, 'open')
    }
  }

  const setProjetoPecaServico = projetoPecaServico => {
    handleChangeProperty('projetoPecaServico', projetoPecaServico)
    if (projetoPecaServico.length) {
      if (
        projetoPecaServico[0].tipoServico &&
        !projetoPecaServico[0].sistemaFixacaoId &&
        !projetoPecaServico[0].fixacaoConector
      ) {
        handleOpenFuracaoUsinagemModal()
      }
    }
  }

  const handleOpenFuracaoUsinagemModal = () => {
    const errorsUsinagem = validadeInfos()
    if (errorsUsinagem.quantidade) {
      return showMsgW('Informe uma quantidade válida para visualizar a peça')
    }

    if (errorsUsinagem.largura) {
      return showMsgW(
        'Informe uma medida de comprimento válida para visualizar a peça'
      )
    }

    if (errorsUsinagem.altura) {
      return showMsgW(
        'Informe uma medida de largura válida para visualizar a peça'
      )
    }

    setOpenFuracaoUsinagemModal(true)
  }

  const handleChangeProperty = (
    property,
    value,
    number,
    NoSpecialCharacters
  ) => {
    const newValue = number
      ? Mask.number(value)
      : NoSpecialCharacters
      ? Mask.noEspecialCaracter(value)
      : value

    planEdtPeca(newValue, indexAmbiente, indexPeca, property)

    if (isPrimaria) {
      if (property === 'altura') {
        planEdtPeca(newValue, indexAmbiente, indexPeca + 1, property)
      }
    }

    setTriggerChange(!triggerChange)
  }

  const getConfigSizes = (chapa, config) => {
    const maxWidth = chapa?.largura * 10 - config.descontoMaximoLargura
    const maxHeight = chapa?.altura * 10 - config.descontoMaximoAltura
    const fitasNaoUsadas = fitasSelecionadas.reduce((add, fita) => {
      const fitasUsadas = [
        peca.superiorMaterialId,
        peca.inferiorMaterialId,
        peca.direitaMaterialId,
        peca.esquerdaMaterialId
      ]
      if (!fitasUsadas.includes(fita.id)) {
        return add + 1
      }
      return add
    }, 0)
    const medidaMinimaFitagem =
      config.minChapaPermiteFitaLadoOposto <= config.minimoChapaPermiteFita
        ? config.minChapaPermiteFitaLadoOposto
        : config.minimoChapaPermiteFita

    return {
      maxWidth,
      maxHeight,
      fitasNaoUsadas,
      medidaMinimaFitagem
    }
  }

  const validadeInfos = () => {
    const { maxWidth, maxHeight, fitasNaoUsadas, medidaMinimaFitagem } =
      getConfigSizes(chapaSelecionada, config)
    if (chapaSelecionada?.id) {
      const updateErrors = {
        ...errors,
        chapa: !peca.materialId ? 'Informe uma chapa' : null,
        quantidade:
          !peca.quantidade || (peca.quantidade < 1 && peca.materialId)
            ? 'Informe uma quantidade maior que zero'
            : '',
        largura:
          !peca.largura && peca.materialId
            ? `Deve ser no mínimo ${config.minimoChapaCorte}mm e no máximo ${maxWidth}mm`
            : peca.largura < config.minLarguraPecaCanal &&
              peca?.projetoPecaServico[0]?.tipoServico &&
              peca?.projetoPecaServico[0]?.tipoServico === 2
            ? `Comprimento da peça inferior a ${config.minLarguraPecaCanal} não permite canal`
            : peca.largura < config.minLarguraPecaDobradica &&
              peca?.projetoPecaServico[0]?.tipoServico &&
              peca?.projetoPecaServico[0]?.tipoServico === 1
            ? `Comprimento da peça inferior a ${config.minLarguraPecaDobradica} não permite dobradiça`
            : peca.largura < config.minLarguraPecaRebaixo &&
              peca?.projetoPecaServico[0]?.tipoServico &&
              peca?.projetoPecaServico[0]?.tipoServico === 3
            ? `Comprimento da peça inferior a ${config.minLarguraPecaRebaixo} não permite rebaixo`
            : peca.largura > config.maxLarguraPecaCanal &&
              peca?.projetoPecaServico[0]?.tipoServico &&
              peca?.projetoPecaServico[0]?.tipoServico === 2
            ? `Comprimento da peça superior a ${config.maxLarguraPecaCanal} não permite canal`
            : peca.largura > config.maxLarguraPecaDobradica &&
              peca?.projetoPecaServico[0]?.tipoServico &&
              peca?.projetoPecaServico[0]?.tipoServico === 1
            ? `Comprimento da peça superior a ${config.maxLarguraPecaDobradica} não permite dobradiça`
            : peca.largura > config.maxLarguraPecaRebaixo &&
              peca?.projetoPecaServico[0]?.tipoServico &&
              peca?.projetoPecaServico[0]?.tipoServico === 3
            ? `Comprimento da peça superior a ${config.maxLarguraPecaRebaixo} não permite rebaixo`
            : peca.largura < config.minimoChapaCorte && peca.materialId
            ? `Deve ser no mínimo ${config.minimoChapaCorte}mm`
            : peca.largura > maxWidth &&
              peca.materialId &&
              projeto?.tipoCorte !== 2
            ? `Deve ser no máximo ${maxWidth}mm`
            : '',
        altura:
          !peca.altura && peca.materialId
            ? `Deve ser no mínimo ${config.minimoChapaCorte}mm e no máximo ${maxHeight}mm`
            : peca.altura < config.minAlturaPecaCanal &&
              peca?.projetoPecaServico[0]?.tipoServico &&
              peca?.projetoPecaServico[0]?.tipoServico === 2
            ? `Largura da peça inferior a ${config.minAlturaPecaCanal} não permite canal`
            : peca.altura < config.minAlturaPecaDobradica &&
              peca?.projetoPecaServico[0]?.tipoServico &&
              peca?.projetoPecaServico[0]?.tipoServico === 1
            ? `Largura da peça inferior a ${config.minAlturaPecaDobradica} não permite dobradiça`
            : peca.altura < config.minAlturaPecaRebaixo &&
              peca?.projetoPecaServico[0]?.tipoServico &&
              peca?.projetoPecaServico[0]?.tipoServico === 3
            ? `Largura da peça inferior a ${config.minAlturaPecaRebaixo} não permite rebaixo`
            : peca.altura > config.maxAlturaPecaCanal &&
              peca?.projetoPecaServico[0]?.tipoServico &&
              peca?.projetoPecaServico[0]?.tipoServico === 2
            ? `Largura da peça superior a ${config.maxAlturaPecaCanal} não permite canal`
            : peca.altura > config.maxAlturaPecaDobradica &&
              peca?.projetoPecaServico[0]?.tipoServico &&
              peca?.projetoPecaServico[0]?.tipoServico === 1
            ? `Largura da peça superior a ${config.maxAlturaPecaDobradica} não permite dobradiça`
            : peca.altura > config.maxAlturaPecaRebaixo &&
              peca?.projetoPecaServico[0]?.tipoServico &&
              peca?.projetoPecaServico[0]?.tipoServico === 3
            ? `Largura da peça superior a ${config.maxAlturaPecaRebaixo} não permite rebaixo`
            : peca.altura < config.minimoChapaCorte && peca.materialId
            ? `Deve ser no mínimo ${config.minimoChapaCorte}mm`
            : peca.altura > maxHeight &&
              peca.materialId &&
              projeto?.tipoCorte !== 3
            ? `Deve ser no máximo ${maxHeight}mm`
            : '',
        fita:
          peca.materialId &&
          fitasSelecionadas.length &&
          config.limiteEspessuraBordeamento > chapaSelecionada.espessura * 10
            ? `Bordeamento não permitido em chapa com espessura inferior a ${config.limiteEspessuraBordeamento}mm.`
            : (fitasSelecionadas.length &&
                !peca.superiorMaterialId &&
                !peca.inferiorMaterialId &&
                !peca.direitaMaterialId &&
                !peca.esquerdaMaterialId) ||
              fitasNaoUsadas
            ? 'Remova as fitas que não estão sendo usadas'
            : getFitaSideWarning(
                peca,
                config.minimoChapaPermiteFita,
                medidaMinimaFitagem
              ),
        usinagem:
          peca?.projetoPecaServico.length &&
          (peca?.projetoPecaServico[0]?.payloadConfigurador === '' ||
            !peca?.projetoPecaServico[0]?.payloadConfigurador)
      }

      setErrors(updateErrors)

      setWarnings(warning => {
        return {
          ...warning,
          veio: peca.veio !== chapaSelecionada.veio,
          fita: getFitaSugestionWarning()
        }
      })

      return updateErrors
    } else {
      setErrors({ chapa: !peca.materialId ? 'Informe uma chapa' : '' })
    }
  }
  const getFitaSugestionWarning = () => {
    const validacaoFita = Utils.validarEspessuraFita(
      fitasSelecionadas,
      chapaSelecionada
    )
    if (validacaoFita.length) {
      return `A fita selecionada tem tamanho diferente do indicado. A fita indicada para essa espessura de chapa é ${validacaoFita}.`
    }
    return ''
  }
  const getFitaSideWarning = (peca, minFitagem, minFitagemOposta) => {
    if (
      (peca.superiorMaterialId ||
        peca.inferiorMaterialId ||
        peca.direitaMaterialId ||
        peca.esquerdaMaterialId) &&
      fitasSelecionadas.length
    ) {
      if (
        peca.largura < minFitagem &&
        peca.altura < minFitagem &&
        minFitagem === minFitagemOposta
      ) {
        return `Bordeamento permitido apenas para medidas a partir de ${minFitagem}mm`
      }
      if (
        (peca.largura < minFitagem || peca.altura < minFitagem) &&
        minFitagem === minFitagemOposta
      ) {
        return `Bordeamento permitido apenas para medidas a partir de ${minFitagem}mm`
      }

      if (minFitagem !== minFitagemOposta) {
        if (peca.largura < minFitagem && peca.altura < minFitagem) {
          return `Bordeamento permitido apenas se uma das medidas for igual ou maior que ${minFitagem}mm`
        }
        if (peca.largura < minFitagemOposta && peca.altura >= minFitagem) {
          return `Comprimento da peça inferior a ${minFitagemOposta}mm não permite bordeamento na peça.`
        } else if (
          peca.largura >= minFitagemOposta &&
          peca.largura < minFitagem &&
          peca.altura >= minFitagem &&
          (peca.inferiorMaterialId > 0 || peca.superiorMaterialId > 0)
        ) {
          return `Comprimento da peça inferior a ${minFitagem}mm não permite bordeamento, desmarque as bordas superior e inferior.`
        }

        if (peca.altura < minFitagemOposta && peca.largura >= minFitagem) {
          return `Largura da peça inferior a ${minFitagemOposta}mm não permite bordeamento na peça.`
        } else if (
          peca.altura >= minFitagemOposta &&
          peca.altura < minFitagem &&
          peca.largura >= minFitagem &&
          (peca.esquerdaMaterialId > 0 || peca.direitaMaterialId > 0)
        ) {
          return `Largura da peça inferior a ${minFitagem}mm não permite bordeamento, desmarque as bordas laterais.`
        }
      }
      return ''
    } else return ''
  }

  const setFitaSide = (side, fita, selected) => {
    try {
      const codigo = selected ? fitasSelecionadas[fita].id : 0
      switch (side) {
        case 'top':
          handleChangeProperty('superiorMaterialId', codigo)
          break
        case 'left':
          handleChangeProperty('esquerdaMaterialId', codigo)
          break
        case 'right':
          handleChangeProperty('direitaMaterialId', codigo)
          break
        case 'bottom':
          handleChangeProperty('inferiorMaterialId', codigo)
          break

        case 'all':
          handleChangeProperty('superiorMaterialId', codigo)
          handleChangeProperty('esquerdaMaterialId', codigo)
          handleChangeProperty('direitaMaterialId', codigo)
          handleChangeProperty('inferiorMaterialId', codigo)
          break
        default:
          break
      }
    } catch {
      if (!fitasSelecionadas[fita]) showMsgW('Selecione uma fita.')
      else showMsgW('Erro ao adicionar fitagem.')
    }
  }

  const handleKeyDown = event => {
    if (!peca.open) return

    let codigo = 0

    switch (event?.key) {
      case '+':
        addNewLine()
        break
      case 'ArrowUp':
        event.preventDefault()
        if (!fitaFocus) return

        codigo =
          peca.superiorMaterialId > 0
            ? 0
            : fitasSelecionadas[peca.superiorMaterialId]?.id || 0

        handleChangeProperty('superiorMaterialId', codigo)
        break
      case 'ArrowDown':
        if (!fitaFocus) return

        event.preventDefault()
        codigo =
          peca.inferiorMaterialId > 0
            ? 0
            : fitasSelecionadas[peca.inferiorMaterialId]?.id || 0
        handleChangeProperty('inferiorMaterialId', codigo)
        break
      case 'ArrowLeft':
        if (!fitaFocus) return

        event.preventDefault()
        codigo =
          peca.esquerdaMaterialId > 0
            ? 0
            : fitasSelecionadas[peca.esquerdaMaterialId]?.id || 0

        handleChangeProperty('esquerdaMaterialId', codigo)
        break
      case 'ArrowRight':
        if (!fitaFocus) return

        event.preventDefault()
        codigo =
          peca.direitaMaterialId > 0
            ? 0
            : fitasSelecionadas[peca.direitaMaterialId]?.id || 0

        handleChangeProperty('direitaMaterialId', codigo)
        break
      default:
        break
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const getRowStyle = open => {
    const baseStyle = open ? 'peca-selected' : 'peca'
    const isPrimaria = peca?.projetoPecaServico[0]?.projetoPecaSecundariaId
      ? ' primaria'
      : ''
    const isSecundaria = peca.projetoPecaServico[0]?.projetoPecaPrimariaId
      ? ' secundaria'
      : ''

    let style = baseStyle + isPrimaria + isSecundaria

    if (Utils.hasError(errors) > 0) return style + ' invalid'
    if (Utils.hasWarnings(warnings) > 0) return style + ' warning'

    return style
  }

  useEffect(() => {
    setIgnorarVeio(peca.veio)
    if (peca.id && config?.id) validadeInfos()
  }, [peca.id, triggerChange, config, chapaSelecionada])

  useEffect(() => {
    if (projeto?.projetoProduto.length) {
      const materiais = projeto?.projetoProduto.reduce(
        (add, material) => {
          if (material.tipoProdutoId.toString() === '1') {
            return {
              ...add,
              chapas: [...add.chapas, material]
            }
          } else if (material.tipoProdutoId.toString() === '2') {
            return {
              ...add,
              fitas: [...add.fitas, material]
            }
          }
          return add
        },
        { chapas: [], fitas: [] }
      )
      setListaChapasDisponiveis(materiais.chapas)
      setListaFitasDisponiveis(materiais.fitas)
      fillInputs(materiais.chapas, materiais.fitas)
    }
  }, [projeto?.projetoProduto, config, peca, peca?.materialId])

  useEffect(() => {
    if (peca.open && (peca.materialId === 0 || peca.quantidade <= 0)) {
      setTimeout(function () {
        if (peca.materialId === 0) {
          inputMaterial.current?.focus()
        } else if (peca.quantidade <= 0) {
          inputQuantidade.current?.focus()
        }
      }, 600)
    }
  }, [peca.open, peca.materialId])

  useEffect(() => {
    if (triggerValidateFita) {
      planEdtFitasPeca(fitasSelecionadas, indexAmbiente, indexPeca)
    }
  }, [fitasSelecionadas, triggerValidateFita])

  useEffect(() => {
    const { sistemaFixacao: sistemaFixacaoPeca } = peca ?? {}
    if (sistemaFixacaoPeca?.sistemaFixacaoId && sistemaFixacaoPeca?.length) {
      const sistema = sistemasFixacaoMenu.find(
        ({ id }) => id === sistemaFixacaoPeca.sistemaFixacaoId
      )
      setSistemaFixacao(sistema)
    }
  }, [peca])

  const shouldShowInvertPiece =
    (projeto?.tipoCorte === 1 ||
      projeto?.tipoCorte === null ||
      projeto?.tipoCorte === 5) &&
    !projeto.pedidoId &&
    projeto.origemProjeto !== 3

  const espessuraPermiteFuracaoUsinagem =
    chapaSelecionada?.espessura * 10 >=
      config.minEspessuraChapaFuracaoUsinagem &&
    chapaSelecionada?.espessura * 10 <= config.maxEspessuraChapaFuracaoUsinagem

  const verificarEspessuraChapaParaFuracaoUsingem = () => {
    const espessuraChapa = chapaSelecionada?.espessura * 10

    const msg = 'Espessura da chapa incompatível com furação e usinagem.'

    if (!espessuraChapa) {
      return ''
    }

    if (espessuraChapa < config.minEspessuraChapaFuracaoUsinagem) {
      return `${msg} Espessura da chapa inferior a ${config.minEspessuraChapaFuracaoUsinagem}mm`
    } else if (espessuraChapa > config.maxEspessuraChapaFuracaoUsinagem) {
      return `${msg} Espessura da chapa superior a ${config.maxEspessuraChapaFuracaoUsinagem}mm`
    }
  }

  const handleRemoveUsinagem = () => {
    if (isPrimaria) {
      removePecaSecundaria(
        peca.id,
        peca.projetoPecaServico[0].projetoPecaSecundariaId
      )
    }

    setHasUsinagem(false)
    setProjetoPecaServico([])
    setSistemaFixacao('None')
    setSistemaFixacaoLado('side')
  }

  return (
    <>
      {peca.id && (
        <>
          <div
            key={peca.id}
            className={`${getRowStyle(peca.open)} flex gap-10`}
            onClick={() => enableEditRow(peca.open)}
          >
            <div className="flex-1">
              <div
                id="first-line"
                className="flex flex-row items-start justify-start gap-10"
              >
                <div data="Qtd">
                  <div className="label">Quant.</div>
                  {peca.open ? (
                    <Tooltip
                      title={errors.quantidade}
                      arrow
                      className="text-center"
                    >
                      <input
                        id={`tabela-pecas-quantidade-${peca.id}`}
                        ref={inputQuantidade}
                        tabIndex={tabIndexSequence + 1}
                        size="small"
                        className={errors.quantidade ? 'inputError' : ''}
                        value={peca.quantidade}
                        type="number"
                        disabled={
                          !chapaSelecionada || projeto.origemProjeto === 3
                        }
                        onChange={({ target }) => {
                          handleChangeProperty('quantidade', target.value, true)
                        }}
                        onKeyDown={e => {
                          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                            e.preventDefault()
                          }
                        }}
                      />
                    </Tooltip>
                  ) : (
                    Number(peca.quantidade)
                  )}
                </div>

                <div className="flex items-end gap-3">
                  <div data="Comp.">
                    <div className="label">Comp.</div>
                    {peca.open ? (
                      <Tooltip title={errors.largura} arrow>
                        <input
                          id={`tabela-pecas-largura-${peca.id}`}
                          label="Comprimento"
                          tabIndex={tabIndexSequence + 2}
                          size="small"
                          className={errors.largura ? 'inputError' : ''}
                          disabled={
                            !chapaSelecionada ||
                            projeto?.tipoCorte === 2 ||
                            projeto.origemProjeto === 3
                          }
                          value={peca.largura}
                          type="number"
                          onChange={({ target }) =>
                            handleChangeProperty('largura', target.value, true)
                          }
                          onKeyDown={e => {
                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                              e.preventDefault()
                            }
                          }}
                        />
                      </Tooltip>
                    ) : (
                      Number(peca.largura)
                    )}
                  </div>

                  {shouldShowInvertPiece ? (
                    <div data="Inverter medidas">
                      <Tooltip title="Inverter medidas">
                        <IconButton
                          variant="contained"
                          className="rounded-none"
                          color="primary"
                          size="small"
                          onClick={inverteMedidasPeca}
                          disabled={isSecundaria}
                        >
                          <SwapHoriz
                            id={`tabela-pecas-invertside-${peca.id}`}
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ) : null}

                  <div data="Larg.">
                    <div className="label">Larg.</div>
                    {peca.open ? (
                      <Tooltip title={errors.altura} arrow>
                        <input
                          id={`tabela-pecas-altura-${peca.id}`}
                          label="Largura"
                          tabIndex={tabIndexSequence + 3}
                          size="small"
                          className={errors.altura ? 'inputError' : ''}
                          type="number"
                          disabled={
                            isSecundaria ||
                            !chapaSelecionada ||
                            projeto?.tipoCorte === 3 ||
                            projeto.origemProjeto === 3
                          }
                          value={peca.altura}
                          onChange={({ target }) =>
                            handleChangeProperty('altura', target.value, true)
                          }
                          onKeyDown={e => {
                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                              e.preventDefault()
                            }
                          }}
                        />
                      </Tooltip>
                    ) : (
                      Number(peca.altura)
                    )}
                  </div>
                </div>

                <div data="Descrição">
                  <div className="label">Descrição</div>
                  {peca.open ? (
                    <input
                      id={`tabela-pecas-descricao-${peca.id}`}
                      label="Descrição"
                      size="small"
                      tabIndex={tabIndexSequence + 4}
                      className="fontSmall"
                      value={peca.descricao}
                      onChange={({ target }) =>
                        handleChangeProperty(
                          'descricao',
                          target.value.slice(0, 100),
                          false,
                          true
                        )
                      }
                      disabled={
                        !chapaSelecionada || projeto.origemProjeto === 3
                      }
                    />
                  ) : (
                    peca.descricao || '-'
                  )}
                </div>

                <div data="Chapa" className="flex-1">
                  <div className="label">Chapa</div>
                  {peca.open ? (
                    <Autocomplete
                      value={chapaSelecionada || ''}
                      tabIndex={tabIndexSequence + 5}
                      className="noExcludeBtn"
                      size="small"
                      inputValue={chapaInput || ''}
                      onInputChange={(event, newInputValue) =>
                        setChapaInput(newInputValue)
                      }
                      id={`tabela-pecas-chapa-${peca.id}`}
                      options={listaChapasDisponiveis}
                      noOptionsText={'Sem registros'}
                      getOptionLabel={option => {
                        return option.codigo
                          ? `${option.codigo} - ${option.descricao}`
                          : ''
                      }}
                      disabled={projeto.origemProjeto === 3 || isSecundaria}
                      onChange={(_, value) => selectChapa(value)}
                      renderOption={(props, option) => (
                        <div {...props}>
                          <div className="tableProdThumb">
                            <img
                              src={Utils.getImageUrl(option.codigo)}
                              onError={Utils.imgBrokenFita}
                              alt=""
                            />
                            {option.canaletado ? (
                              <div className="canaletaThumb" />
                            ) : (
                              ''
                            )}
                          </div>
                          {option.codigo} - {option.descricao}
                        </div>
                      )}
                      renderInput={params => (
                        <Tooltip
                          title={errors.chapa}
                          open={!!errors.chapa}
                          arrow
                        >
                          <TextField
                            {...params}
                            size="small"
                            inputRef={inputMaterial}
                            error={!!errors.chapa}
                            InputProps={{ ...params.InputProps }}
                          />
                        </Tooltip>
                      )}
                      PaperComponent={({ children }) => (
                        <Paper style={{ minWidth: '50vw' }}>{children}</Paper>
                      )}
                    />
                  ) : (
                    <>
                      {chapaSelecionada?.codigo ? (
                        <>
                          <Tooltip
                            title={`${chapaSelecionada?.codigo} ${chapaSelecionada?.descricaoResumida}`}
                            arrow
                          >
                            <div>
                              <strong>{chapaSelecionada?.codigo}</strong> -{' '}
                              {chapaSelecionada?.descricao?.substring(0, 40) +
                                '...'}
                            </div>
                          </Tooltip>

                          {chapaSelecionada?.canaletado ? (
                            <div className="text-danger">
                              <strong>Canaletado: </strong>
                              {chapaSelecionada?.canaletado} polegadas
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        'Informe uma chapa para esta peça'
                      )}
                    </>
                  )}
                </div>

                {projeto.tipoCorte !== 5 ? (
                  <>
                    <div size="small" data="Fitas">
                      <div className="label">Fitas</div>
                      {fitasSelecionadas.length > 0 ? (
                        <TooltipListaFitas
                          fitasSelecionadas={fitasSelecionadas}
                        >
                          <div onClick={() => setOpenModalConfigBorda(true)}>
                            {fitasSelecionadas.slice(0, 1).map(fita => {
                              return (
                                <div className="ItemFita" key={fita.codigo}>
                                  <span
                                    style={{ backgroundColor: fita.color }}
                                  ></span>
                                  <strong className="text-sm">
                                    {fita.codigo}
                                  </strong>{' '}
                                  - {fita.descricao.substring(0, 15) + '...'}
                                </div>
                              )
                            })}

                            {errors?.fita ? (
                              <Chip
                                label={
                                  errors.fita ===
                                  'Remova as fitas que não estão sendo usadas' ? (
                                    <b>Selecione os lados para fitar</b>
                                  ) : (
                                    <b>Espessura de chapa incompatível</b>
                                  )
                                }
                                icon={<ErrorOutline fontSize="small" />}
                                color="error"
                              />
                            ) : (
                              <>
                                {fitasSelecionadas.length > 1 && (
                                  <Chip
                                    label={`+ ${fitasSelecionadas.length - 1} ${
                                      fitasSelecionadas.length - 1 > 1
                                        ? 'fitas selecionadas'
                                        : 'fita selecionada'
                                    }`}
                                    variant="outlined"
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </TooltipListaFitas>
                      ) : (
                        <div onClick={() => setOpenModalConfigBorda(true)}>
                          Selecionar fitas
                        </div>
                      )}
                    </div>
                    <div size="small" data="Aplicação Fita">
                      <Tooltip title="Aplicação de fita" arrow>
                        <button
                          className="pecaMiniaturaContainer flexLeo"
                          onClick={() => setOpenModalConfigBorda(true)}
                          tabIndex={peca.open ? tabIndexSequence + 6 : -1}
                          onFocus={() => setFitaFocus(true)}
                          onBlur={() => setFitaFocus(false)}
                        >
                          <FitasMiniatura
                            peca={peca}
                            materiais={fitasSelecionadas}
                          />
                        </button>
                      </Tooltip>
                    </div>
                  </>
                ) : null}

                {(projeto?.tipoCorte === 1 || projeto?.tipoCorte === 5) && (
                  <div data="Ignorar Veio">
                    <div className="label">
                      Ignorar veio{' '}
                      <InfoTip info="Esta opção permite ao otimizador girar a peça melhorando o aproveitamento da chapa, mas pode gerar um corte que não siga o veio nessa peça." />
                    </div>
                    <div className="flexLeo">
                      {chapaSelecionada?.veio &&
                      !chapaSelecionada?.canaletado ? (
                        <Switch
                          value={ignorarVeio}
                          className="tabela-pecas-ignorarveio"
                          id={`tabela-pecas-ignorarveio-${peca.id}`}
                          checked={!peca.veio}
                          color="primary"
                          name="veioInput"
                          size="small"
                          onChange={({ target }) =>
                            selectIgnorarVeio(target.checked)
                          }
                          disabled={
                            !chapaSelecionada || projeto.origemProjeto === 3
                          }
                        />
                      ) : (
                        'Sem veio'
                      )}
                    </div>
                  </div>
                )}

                {habilitarFuracaoUsinagem &&
                !hasUsinagem &&
                projeto.tipoCorte !== 5 ? (
                  <div>
                    {espessuraPermiteFuracaoUsinagem ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<img src={IconFuracaoUsinagem} />}
                        onClick={() => setHasUsinagem(true)}
                        disabled={
                          chapaSelecionada?.canaletado ||
                          !espessuraPermiteFuracaoUsinagem
                        }
                      >
                        Incluir Furação e Usinagem
                      </Button>
                    ) : (
                      <div className="text-gray-700 label max-width-thickness-msg">
                        {verificarEspessuraChapaParaFuracaoUsingem()}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>

              {hasUsinagem ? (
                <div
                  id="second-line"
                  className="flex flex-row items-start gap-4 mt-4"
                >
                  <div>
                    <div className="label">Furação e Usinagem</div>

                    <div size="small" data="Usinagem">
                      <div className="flex items-center justify-between">
                        <SelectUsinagem
                          disabled={
                            chapaSelecionada?.canaletado ||
                            !habilitarFuracaoUsinagem ||
                            isSecundaria ||
                            !espessuraPermiteFuracaoUsinagem
                          }
                          projetoPecaServico={peca.projetoPecaServico}
                          setProjetoPecaServico={setProjetoPecaServico}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="label">Sistema de fixação</div>
                    <Select
                      name="sistemaFixacaoId"
                      onChange={handleSaveProjetoServico}
                      value={getSistemaFixacaoNome(
                        peca?.projetoPecaServico[0]?.sistemaFixacaoId
                      )}
                      disabled={
                        isSecundaria ||
                        !peca?.projetoPecaServico[0]?.tipoServico ||
                        peca?.projetoPecaServico[0]?.tipoServico === 1
                      }
                    >
                      {sistemasFixacaoMenu.map(sistema => (
                        <MenuItem key={sistema.id} value={sistema.value}>
                          {sistema.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  {peca?.projetoPecaServico[0]?.sistemaFixacaoId > 0 && (
                    <>
                      <div>
                        <div className="label">Conector</div>
                        <RadioGroup
                          className="MuiRadioGroup noPadding"
                          aria-label="gender"
                          name="fixacaoConector"
                          value={sistemaFixacaoLado}
                          onChange={handleSaveProjetoServico}
                        >
                          <FormControlLabel
                            value="side"
                            control={<Radio />}
                            label={
                              sistemaFixacao?.conectorPrimario || 'Parafuso'
                            }
                            disabled={isSecundaria}
                          />
                          <FormControlLabel
                            value="base"
                            control={<Radio />}
                            label={
                              sistemaFixacao?.conectorSecundario || 'Tambor'
                            }
                            disabled={isSecundaria}
                          />
                        </RadioGroup>
                      </div>

                      {sistemaFixacao?.permiteCavilhaExtra && (
                        <div>
                          <div className="label">Reforço</div>
                          <Checkbox
                            className="defaultColor"
                            checked={peca?.sistemaFixacao?.reforco}
                            onChange={e =>
                              setReinforcementChecked(e.target.checked)
                            }
                            disabled={isSecundaria}
                          />
                          Cavilha Extra
                        </div>
                      )}
                    </>
                  )}

                  {!isSecundaria && (
                    <div className="self-center">
                      <Button
                        variant="contained"
                        className="danger"
                        size="small"
                        startIcon={<DeleteForever />}
                        onClick={handleRemoveUsinagem}
                      >
                        Remover furação e usinagem
                      </Button>
                    </div>
                  )}

                  {!espessuraPermiteFuracaoUsinagem ? (
                    <div className="text-gray-700 label max-width-thickness-msg">
                      {verificarEspessuraChapaParaFuracaoUsingem()}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>

            <div className="flex flex-row items-center gap-10">
              {peca?.projetoPecaServico[0]?.tipoServico ? (
                <div
                  className="flex flex-col items-center"
                  onClick={handleOpenFuracaoUsinagemModal}
                >
                  <Tooltip
                    title="Visualizar peça com furação / usinagem"
                    className="ml-2"
                  >
                    <img
                      src={ampliarCanal}
                      alt="Ampliar Canal"
                      width="80px"
                      className={
                        !peca?.projetoPecaServico[0]?.payloadConfigurador ||
                        peca?.projetoPecaServico[0]?.payloadConfigurador === ''
                          ? 'imagem-borda-vermelha'
                          : ''
                      }
                    />
                  </Tooltip>

                  {!peca?.projetoPecaServico[0]?.payloadConfigurador ? (
                    <span className="text-xs">Clique para salvar usinagem</span>
                  ) : null}
                </div>
              ) : null}
              <div>
                <div className="pecasAction text-right d-none d-sm-block d-md-block d-lg-block d-xl-block ">
                  <Tooltip title="Excluir Peça">
                    <IconButton
                      tabIndex={-1}
                      variant="contained"
                      id={`tabela-pecas-remove-${peca.id}`}
                      color="primary"
                      size="small"
                      className="danger"
                      disabled={projeto.origemProjeto === 3 || isSecundaria}
                      onClick={() =>
                        removePeca(
                          indexAmbiente,
                          peca.id,
                          indexPeca,
                          peca?.projetoPecaServico[0]?.projetoPecaSecundariaId
                        )
                      }
                    >
                      <DeleteForever />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="pecasAction text-right mt4 d-none d-sm-block d-md-block d-lg-block d-xl-block ">
                  <Tooltip title="Copiar/Colar Peça">
                    <IconButton
                      tabIndex={-1}
                      variant="contained"
                      id={`tabelapecasmenu-${indexAmbiente}-${indexPeca}-${peca.id}`}
                      color="primary"
                      size="small"
                      disabled={projeto.origemProjeto === 3 || isSecundaria}
                      onClick={e => handleStopBloubbles(openPecaOption, e)}
                    >
                      <FileCopy />
                    </IconButton>
                  </Tooltip>
                </div>

                <div className="col-12 d-sm-none d-md-none d-lg-none d-xl-none text-center">
                  <Button
                    tabIndex={-1}
                    variant="contained"
                    color="primary"
                    id={`tabela-pecas-removermobile-${peca.id}`}
                    size="small"
                    className="danger"
                    disabled={projeto.origemProjeto === 3 || isSecundaria}
                    onClick={() =>
                      removePeca(
                        indexAmbiente,
                        peca.id,
                        indexPeca,
                        peca?.projetoPecaServico[0]?.projetoPecaSecundariaId
                      )
                    }
                  >
                    Remover
                  </Button>

                  <Button
                    tabIndex={-1}
                    variant="contained"
                    color="primary"
                    size="small"
                    className="ml10"
                    id={`tabela-pecas-colarmobile-${peca.id}`}
                    disabled={projeto.origemProjeto === 3 || isSecundaria}
                    onClick={e => handleStopBloubbles(openPecaOption, e)}
                  >
                    Copiar/Colar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <DialogBordeamentoPeca
        open={openModalConfigBorda}
        close={handleCloseModalConfigBorda}
        peca={peca}
        indexPeca={indexPeca}
        chapaSelecionada={chapaSelecionada}
        fitasSelecionadas={fitasSelecionadas}
        listaFitasDisponiveis={listaFitasDisponiveis}
        selectFitas={selectFitas}
        errors={errors}
        warnings={warnings}
        tabNewPeca={tabNewPeca}
        setFitaSide={setFitaSide}
      />

      {openFuracaoUsinagemModal && (
        <DialogIntegracaoFurcaoUsinagem
          open={openFuracaoUsinagemModal}
          close={() => setOpenFuracaoUsinagemModal(false)}
          peca={peca}
          inverteMedidasPeca={inverteMedidasPeca}
          shouldShowInvertPiece={shouldShowInvertPiece}
          handleChangeProperty={handleChangeProperty}
          salvarGrupoPecas={salvarGrupoPecas}
          sistemaFixacao={getSistemaFixacaoNome(
            peca?.projetoPecaServico[0]?.sistemaFixacaoId
          )}
          sistemaFixacaoLado={sistemaFixacaoLado || 'side'}
          chapaSelecionada={chapaSelecionada}
        />
      )}
    </>
  )
}

export default PecaItemLine
