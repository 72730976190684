import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import { isMobile } from 'react-device-detect'

import InfoMsg from '@component/InfoMsg/InfoMsg.js'

import Table from '../../../../components/Table/Table.js'

export default function DialogResumoCanaletado({
  open,
  close,
  materiaisCanaletados,
  titulo,
  children,
  forceOpen,
  headers,
  configuracoesCanaletagem
}) {
  titulo = (titulo || '').length > 0 ? titulo : 'Atenção'
  forceOpen = true
  headers =
    (headers || []).length > 0
      ? headers
      : [
          'Código',
          'Descrição',
          'Quantidade',
          'Distância entre canaletas',
          'Canaletas por chapa'
        ]
  const dataMateriaisMap = (materiaisCanaletados || []).map(m => {
    return (
      m && {
        _id: m.id,
        codigo: { data: m.codigo },
        descricao: { data: m.descricao },
        quantidade: { data: m.quantidade, type: 'number' },
        canaletado: { data: `${m.canaletado} pol.`, type: 'number' },
        canais: {
          data: `${
            configuracoesCanaletagem?.find(
              item => item.canaletado === m.canaletado
            )?.quantidadeCanaletas || '-'
          }`
        }
      }
    )
  })

  return (
    <Dialog
      open={open}
      aria-labelledby="customized-dialog-title"
      maxWidth="lg"
      fullWidth={true}
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography datatest-id={'titulo_test_id'}>{titulo}</Typography>
        {!forceOpen && (
          <IconButton aria-label="close" onClick={close} size="large">
            <CloseIcon
              datatest-id={'close_modal_test_id'}
              id={'close_modal_btn'}
            />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent datatest-id={'content_test_id'} dividers>
        {children}
        <div>
          {configuracoesCanaletagem?.length > 0 ? (
            <InfoMsg
              type="warning"
              title="Atenção! Confira a quantidade de canais por chapa:"
              msg={
                <p>
                  {configuracoesCanaletagem?.map(x => (
                    <p key={x.canaletado} className="my-1">
                      {x.canaletado} POLEGADAS:{' '}
                      <b>{x?.quantidadeCanaletas} canaletas</b> com
                      aproximadamente <b>{x.canaletado * 2.54} centímetros</b>{' '}
                      de distância
                    </p>
                  ))}
                </p>
              }
            />
          ) : (
            ''
          )}

          <p>Confirme o serviço de canaletagem nos paineis a seguir: </p>
          <Table className="my-5" data={dataMateriaisMap} headers={headers} />
          <div className="text-center mt-4">
            <Button
              onClick={close}
              variant="contained"
              className="mb10"
              color="secondary"
            >
              Confirmar
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
