import {
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction
} from '@store/actions/snackbarActions.js'
import { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import api from '@service/api'
import Storage from '@service/storage.js'

const ConfiguracaoContext = createContext()

const ConfiguracaoProvider = ({ children }) => {
  const dispatch = useDispatch()
  const showMsgE = msg => dispatch(showMsgEAction(msg))
  const showMsgS = msg => dispatch(showMsgSAction(msg))

  const [configStatus, setConfigStatus] = useState(false)
  const [configUpdateData, setConfigUpdateData] = useState(false)
  const [configData, setConfigData] = useState(null)
  const [servicos, setServicos] = useState([])
  const [habilitarCanaletado, setHabilitarCanaletado] = useState(false)
  const [habilitarFuracaoUsinagem, setHabilitarFuracaoUsinagem] =
    useState(false)
  const [origin, setOrigin] = useState()
  const [loja, setLoja] = useState()

  const handleSalveConfig = async () => {
    try {
      const userLogado = Storage.get('LEOPLAN_USER_INFOS')
      await api.leoplan.apiPostConfigdata({
        ...configUpdateData,
        email: userLogado.email
      })
      showMsgS('Configurações alteradas com sucesso')
    } catch {
      showMsgE(
        'Erro ao salvar as configurações, entre em contato com o suporte'
      )
    }
  }

  const handleSaveConfiguracoesPlanoCorte = async () => {
    if (!Object.values(configUpdateData).some(value => Number(value) < 0)) {
      if (configUpdateData.descontoMaximoAltura < 10) {
        showMsgE('Desconto máximo no comprimento não pode ser menor que 10')
        return
      }
      if (configUpdateData.descontoMaximoLargura < 10) {
        showMsgE('Desconto máximo largura não pode ser menor que 10')
        return
      }

      handleSalveConfig(configUpdateData)
    } else showMsgE('Os valores não podem ser negativos')
  }

  const formatUpdateConfigData = data => {
    return {
      id: data.id,
      lojaIdERP: data.lojaIdERP,
      empresaIdERP: data.empresaIdERP,
      configuracoesOtimizacao: {
        bladeThickness: data.configuracoesOtimizacao?.bladeThickness,
        zCutTrimming: data.configuracoesOtimizacao?.zCutTrimming,
        maxStackHeight: data.configuracoesOtimizacao?.maxStackHeight
      },
      multiploFita: data.multiploFita,
      qtdCorteAdicionalRefilo: data.qtdCorteAdicionalRefilo,
      descontoMaximoAltura: data.descontoMaximoAltura,
      descontoMaximoLargura: data.descontoMaximoLargura,
      servicoCanaletagem: data.servicoCanaletagem,
      servicoFuracaoUsinagem: data.servicoFuracaoUsinagem,
      minEspessuraChapaFuracaoUsinagem: data.minEspessuraChapaFuracaoUsinagem,
      maxEspessuraChapaFuracaoUsinagem: data.maxEspessuraChapaFuracaoUsinagem,
      minAlturaPecaDobradica: data.minAlturaPecaDobradica,
      minLarguraPecaDobradica: data.minLarguraPecaDobradica,
      minAlturaPecaCanal: data.minAlturaPecaCanal,
      minLarguraPecaCanal: data.minLarguraPecaCanal,
      minAlturaPecaRebaixo: data.minAlturaPecaRebaixo,
      minLarguraPecaRebaixo: data.minLarguraPecaRebaixo,
      maxAlturaPecaDobradica: data.maxAlturaPecaDobradica,
      maxLarguraPecaDobradica: data.maxLarguraPecaDobradica,
      maxAlturaPecaCanal: data.maxAlturaPecaCanal,
      maxLarguraPecaCanal: data.maxLarguraPecaCanal,
      maxAlturaPecaRebaixo: data.maxAlturaPecaRebaixo,
      maxLarguraPecaRebaixo: data.maxLarguraPecaRebaixo,
      leoMob: data.leoMob || false
    }
  }
  const setUpdateData = data => {
    setConfigUpdateData(formatUpdateConfigData(data))
  }

  useEffect(() => {
    const getServices = async () => {
      try {
        const response = await api.core.apiGetListarServicos()
        if (response.fail) throw new Error(response.errors)
        setServicos(response.data)
      } catch (error) {
        showMsgE(error)
      }
    }

    if (!servicos?.length) getServices()
  }, [servicos])

  useEffect(() => {
    const checkAndSet = (condition, stateSetter) => {
      stateSetter(condition && origin === 'SVL')
    }

    checkAndSet(
      configData?.servicoCanaletagem &&
        configData?.empresaLojasCanaletado?.includes(loja),
      setHabilitarCanaletado
    )

    checkAndSet(
      configData?.servicoFuracaoUsinagem &&
        configData?.empresaLojasFuracaoUsinagem?.includes(loja),
      setHabilitarFuracaoUsinagem
    )
  }, [configData, origin, loja])

  return (
    <ConfiguracaoContext.Provider
      value={{
        configStatus,
        setConfigData,
        configData,
        setConfigStatus,
        handleSaveConfiguracoesPlanoCorte,
        servicos,
        setServicos,
        habilitarCanaletado,
        habilitarFuracaoUsinagem,
        setConfigUpdateData,
        configUpdateData,
        setUpdateData,
        setOrigin,
        setLoja
      }}
    >
      {children}
    </ConfiguracaoContext.Provider>
  )
}

function useConfiguracao() {
  const context = useContext(ConfiguracaoContext)

  return context
}

export { ConfiguracaoProvider, useConfiguracao }
