import FileCsv from '@asset/publicFiles/PLANILHA_LEOPLAN_COM_AMBIENTE.csv'
import { Button } from '@mui/material'
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction
} from '@store/actions/snackbarActions.js'
import { saveAs } from 'file-saver'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { useDispatch } from 'react-redux'

import { useProjeto } from '@hook/useProjeto'

import UploadButton from '@component/Btn/UploadButton'
import InfoMsg from '@component/InfoMsg/InfoMsg'

import api from '@service/api'

const Importacao = ({ handleCloseModalImportacao, handleGetImport }) => {
  const dispatch = useDispatch()
  const showMsgW = msg => dispatch(showMsgWAction(msg))
  const showMsgE = msg => dispatch(showMsgEAction(msg))
  const { projeto, targetAmbienteImport } = useProjeto()
  const [fileDoc, setFileDoc] = useState(null)
  const [fileDocFormat, setFileDocFormat] = useState(null)
  const [processingImport, setProcessingImport] = useState(false)
  const [errosList, setErrosList] = useState([])

  const openFileCSV = () => {
    saveAs(FileCsv, 'modelo.csv')
  }
  const getIdAmbiente = peca => {
    if (peca.nomeAmbiente) {
      let ambienteId = ''
      const ambientes = projeto.projetoAmbiente.length
        ? projeto.projetoAmbiente
        : [projeto.projetoAmbiente]
      ambientes.forEach(ambiente => {
        if (ambiente.descricao === peca.nomeAmbiente) {
          ambienteId = ambiente.id
        }
      })
      return ambienteId
    } else {
      return ''
    }
  }
  const configAgrupamento = list => {
    const agrupamentoMat = []
    const agrupamentoAmb = []
    const rawList = [...list]

    if (list.length > 0) {
      list.forEach(peca => {
        let foundMaterial = false
        agrupamentoMat.forEach(item => {
          if (item.material === peca.material) {
            foundMaterial = true
            if (agrupamentoMat.length > 0) {
              if (!item.ambientesImportados.includes(peca.nomeAmbiente)) {
                item.ambientesImportados.push(peca.nomeAmbiente)
              }
            }
          }
        })
        if (!foundMaterial) {
          agrupamentoMat.push({
            material: peca.material,
            ambientesImportados: [peca.nomeAmbiente],
            chapaEscolhida: '',
            ignorar: false,
            ocultar: false,
            fitas: []
          })
        }
      })

      agrupamentoMat.forEach(group => {
        const fitasValidasInformadas = []

        list.forEach(peca => {
          if (peca.material === group.material) {
            if (
              peca.fitaCima &&
              peca.fitaCima !== 0 &&
              peca.fitaCima !== '0.0'
            ) {
              if (!fitasValidasInformadas.includes(peca.fitaCima)) {
                fitasValidasInformadas.push(peca.fitaCima)
              }
            }
            if (
              peca.fitaBaixo &&
              peca.fitaBaixo !== 0 &&
              peca.fitaBaixo !== '0.0'
            ) {
              if (!fitasValidasInformadas.includes(peca.fitaBaixo)) {
                fitasValidasInformadas.push(peca.fitaBaixo)
              }
            }
            if (
              peca.fitaEsquerda &&
              peca.fitaEsquerda !== 0 &&
              peca.fitaEsquerda !== '0.0'
            ) {
              if (!fitasValidasInformadas.includes(peca.fitaEsquerda)) {
                fitasValidasInformadas.push(peca.fitaEsquerda)
              }
            }
            if (
              peca.fitaDireita &&
              peca.fitaDireita !== 0 &&
              peca.fitaDireita !== '0.0'
            ) {
              if (!fitasValidasInformadas.includes(peca.fitaDireita)) {
                fitasValidasInformadas.push(peca.fitaDireita)
              }
            }
          }
        })

        fitasValidasInformadas.forEach(fita => {
          group.fitas.push({ de: fita, para: '' })
        })
      })

      if (targetAmbienteImport) {
        agrupamentoAmb.push({
          nomeAmbiente: targetAmbienteImport.descricao,
          nomeAmbienteFinal: targetAmbienteImport.descricao,
          idAmbienteFinal: targetAmbienteImport.id,
          ignorar: false,
          pecas: list
        })
      } else {
        list.forEach(peca => {
          let foundAmbiente = false
          let index = ''
          agrupamentoAmb.forEach((item, i) => {
            if (item.nomeAmbiente === peca.nomeAmbiente) {
              foundAmbiente = true
              index = i
            }
          })
          if (!foundAmbiente) {
            agrupamentoAmb.push({
              nomeAmbiente: peca.nomeAmbiente,
              nomeAmbienteFinal: peca.nomeAmbiente
                ? peca.nomeAmbiente
                : 'sem nome',
              idAmbienteFinal: getIdAmbiente(peca),
              ignorar: false,
              pecas: [peca]
            })
          } else {
            agrupamentoAmb[index].pecas.push(peca)
          }
        })
      }

      handleGetImport(rawList, agrupamentoMat, agrupamentoAmb)
    }
  }
  const uploadImport = async file => {
    try {
      const data = new FormData()
      data.append('fileList', file)

      if (!file) {
        showMsgW('Não foi possível carregar o arquivo')
        return
      }

      const response = await trackPromise(api.leoplan.apiUploadFile(data))

      if (response.fail) throw new Error()

      if (response.data.completeArquivosUpload[0].erros.length > 0) {
        setErrosList(response.data.completeArquivosUpload[0].erros)
      } else {
        if (
          retornaQtdAmbientesImportados(
            response.data.completeArquivosUpload[0].pecasUpload
          ) <= 15
        ) {
          setErrosList([])
          const pecasFormatadas = removeCaracteresEspeciais(
            response.data.completeArquivosUpload[0].pecasUpload
          )
          configAgrupamento(pecasFormatadas)
        } else {
          setErrosList(['Só é possível importar até 15 ambientes por vez'])
        }
      }
      setProcessingImport(false)
    } catch {
      setProcessingImport(false)
      showMsgE('Erro ao enviar arquivo')
    }

    function removeCaracteresEspeciais(pecas) {
      return pecas.map(peca => ({
        ...peca,
        descricao: peca?.descricao?.replace(/[^a-zA-Z0-9 ]/g, '')
      }))
    }

    function retornaQtdAmbientesImportados(pecasImport) {
      const listAmbientesImportados = []

      pecasImport.forEach(peca => {
        if (!listAmbientesImportados.includes(peca.nomeAmbiente)) {
          listAmbientesImportados.push(peca.nomeAmbiente)
        }
      })

      return listAmbientesImportados.length
    }
  }

  const importPlano = () => {
    if (fileDoc) {
      if (
        fileDocFormat.toUpperCase() === '.CSV' ||
        fileDocFormat.toUpperCase() === '.TXT' ||
        fileDocFormat.toUpperCase() === 'XLSX'
      ) {
        setProcessingImport(true)
        uploadImport(fileDoc)
      } else {
        showMsgW('Apenas os formatos CSV, TXT e XLSX são aceitos')
      }
    } else {
      showMsgW('Primeiro carregue um arquivo')
    }
  }
  return (
    <div className={isMobile ? '' : 'pad40'}>
      <div className="box">
        <div>
          <span className="titulo">Projeto: </span>
          {projeto.id} - {projeto.nome}
        </div>
      </div>
      <InfoMsg
        type="info"
        title="Importação"
        msg="São aceitos formatos 'Cut Planning' e .CSV (para formato .CSV siga nosso arquivo modelo)"
      ></InfoMsg>
      <div className="mt20">
        <ul>
          {errosList.map((error, index) => {
            return <li key={index}>{error}</li>
          })}
        </ul>
      </div>
      <div className="row">
        <div
          className={
            isMobile
              ? 'flex flex-col gap-2 w-100'
              : 'col-sm-12 text-center flexLeo f-start'
          }
        >
          <Button
            variant="outlined"
            color="primary"
            className="mr5 mb5"
            onClick={openFileCSV}
          >
            Baixar modelo .csv
          </Button>
          <UploadButton action={setFileDoc} setFormat={setFileDocFormat}>
            Carregar Arquivo
          </UploadButton>
          {!processingImport ? (
            <Button
              variant="contained"
              color="secondary"
              className="mr5 mb5"
              onClick={importPlano}
            >
              Importar
            </Button>
          ) : (
            <> Processando... </>
          )}
        </div>
        <div className={isMobile ? 'w-100 mt-8' : 'col-sm-12 text-center mt10'}>
          <Button
            variant="contained"
            className={`blackButton ${isMobile ? 'w-100' : 'mr5'}`}
            onClick={handleCloseModalImportacao}
          >
            Voltar para o projeto
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Importacao
