import CloudDownload from '@mui/icons-material/CloudDownload'
import EditIcon from '@mui/icons-material/Edit'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import MoreVert from '@mui/icons-material/MoreVert'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import SearchOutlined from '@mui/icons-material/SearchOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Backdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import { saveAs } from 'file-saver'
import * as moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'
import { useNavigate } from 'react-router-dom'
import { withStyles, makeStyles } from 'tss-react/mui'

import { appConfig } from '@config/env-config.js'

import { useUser } from '@hook/useUser.js'

import EditableCell from '@component/EditableCell/index.jsx'

import api from '@service/api/index.js'
import Utils from '@service/serviceUtils.js'

import DialogAtivarInativarPlano from './Dialogs/DialogAtivarInativarPlano.js'
import DialogBemVindo from './Dialogs/DialogBemVindo.js'
import DialogDuplicaPlano from './Dialogs/DialogDuplicaPlano.js'
import DialogEscolhaProjType from './Dialogs/DialogEscolhaProjType.js'
import DialogPrimeirosPassos from './Dialogs/DialogPrimeirosPassos.js'

const baseUrl = appConfig.REACT_APP_BASEURL
const StyledTableCell = withStyles(TableCell, () => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: '#000',
    padding: '6px 16px'
  },
  body: {
    fontSize: 12,
    borderBottom: '1px solid #F5F5F5',
    padding: '2px 16px'
  }
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    '&:nth-of-type(odd)': { backgroundColor: '#FDFDFD' }
  }
}))

const useStyles = makeStyles()(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

function TablePaginationActions(props) {
  const { classes } = useStyles()
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0)
  }
  const handleBackButtonClick = event => {
    onPageChange(event, page - 1)
  }
  const handleNextButtonClick = event => {
    onPageChange(event, page + 1)
  }
  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="large"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

const MenuMoreOptions = props => {
  const {
    openModalDuplicar,
    openModalExcluir,
    projeto,
    open,
    close,
    tipoLista
  } = props

  return (
    <Menu
      id="ambiente-menu"
      anchorEl={open}
      keepMounted
      open={Boolean(open)}
      onClose={close}
    >
      {tipoLista !== 2 && projeto?.origemProjeto !== 'LEOMOB' && (
        <MenuItem onClick={openModalDuplicar}>Duplicar Projeto</MenuItem>
      )}
      {tipoLista === 0 && (
        <MenuItem onClick={() => openModalExcluir(projeto)}>
          Inativar Projeto
        </MenuItem>
      )}

      {tipoLista === 2 && (
        <MenuItem onClick={() => openModalExcluir(projeto)}>
          Ativar Projeto
        </MenuItem>
      )}
    </Menu>
  )
}

const ListaProjetos = props => {
  const { user } = useUser()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const {
    showMsgS,
    showMsgW,
    showMsgE,
    planosCorte,
    handleSaveName,
    tipoLista,
    getProjetos
  } = props
  const [dateDe, setDateDe] = React.useState('')
  const [dateAte, setDateAte] = React.useState('')
  const [filterText, setFilterText] = React.useState('')
  const [openBkdrop, setOpenBkdrop] = React.useState(false)
  const closeBkdrop = () => {
    setOpenBkdrop(false)
  }
  const toggleBkdrop = () => {
    setOpenBkdrop(!openBkdrop)
  }

  const [planosFiltrados, setPlanosFiltrados] = React.useState([])
  const [loadAction, setLoadAction] = React.useState(false)

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, planosFiltrados.length - page * rowsPerPage)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const [baseProject, setBaseProject] = React.useState(null)

  const [openMenuProjeto, setOpenMenuProjeto] = React.useState(null)
  const closeMenuProjeto = () => {
    setOpenMenuProjeto(null)
  }
  const projetoMoreOptions = (event, proj) => {
    setBaseProject(proj)
    setOpenMenuProjeto(event.target)
  }
  const [openModalDuplicar, setOpenModalDuplicar] = React.useState(false)
  const handleOpenModalDuplicar = () => {
    setOpenModalDuplicar(true)
  }
  const handleCloseModalDuplicar = () => {
    setOpenModalDuplicar(false)
  }

  const [openPrimeirosPassos, setOpenPrimeirosPassos] = React.useState(false)
  const handleOpenPrimeirosPassos = () => {
    setOpenPrimeirosPassos(true)
  }
  const handleClosePrimeirosPassos = () => {
    setOpenPrimeirosPassos(false)
  }

  const [openBemVindo, setOpenBemVindo] = React.useState(false)
  const handleCloseBemVindo = () => {
    setOpenBemVindo(false)
  }
  const onAssistir = () => {
    setOpenBemVindo(false)
    handleOpenPrimeirosPassos()
  }
  const [openModalTypeProj, setOpenModalTypeProj] = React.useState(isMobile)
  const closeModalTypeProj = () => {
    setOpenModalTypeProj(false)
  }
  const initProjByType = type => {
    goToNewProjectType(type)
  }

  const [openModalExcluir, setOpenModalExcluir] = React.useState(false)
  const handleOpenModalExcluir = project => {
    if (project) {
      if (
        project.status === 1 ||
        project.status === 2 ||
        project.status === 5
      ) {
        showMsgW('Aguarde terminar a otimização antes de excluir o projeto')
        return false
      }
    }
    if (project.pedidoId) {
      showMsgW(
        'Este projeto está vinculado a um pedido no SVL e não pode ser excluído'
      )
      return false
    }

    closeMenuProjeto()
    setOpenModalExcluir(true)
  }
  const closeModalExcluir = () => {
    setOpenModalExcluir(false)
  }

  const goToDetalhe = project => {
    toggleBkdrop()
    if (project) {
      if (project.status === 2) {
        showMsgW(
          'Aguarde mais um momento enquanto processamos a melhor otimização'
        )
        closeBkdrop()
      } else if (project.status === 1 || project.status === 5) {
        showMsgW('Aguarde a otimização ser processada para acessar seu projeto')
        closeBkdrop()
      } else {
        navigate(`/plano-de-corte/detalhe/${project.projetoId}`)
      }
    } else {
      navigate(`/plano-de-corte/detalhe/${project.projetoId}`)
    }
  }

  const projetoAtivarDesativar = async (projetoId, ativo) => {
    setLoadAction(true)
    try {
      await trackPromise(
        api.leoplan.apiProjetoAtivarDesativar(projetoId, ativo)
      )
      if (ativo) {
        showMsgS('Projeto Ativado com sucesso')
      } else {
        showMsgS('Projeto Inativado com sucesso')
      }
      getProjetos(props.tipoLista === 2 ? 'desativados' : 'disponiveis')
    } catch {
      if (ativo) {
        showMsgE('Não foi possível ativar o projeto')
      } else {
        showMsgE('Não foi possível inativar o projeto')
      }
    } finally {
      closeModalExcluir()
      setLoadAction(false)
    }
  }

  const goToNewProjectType = type => {
    navigate(`/plano-de-corte/novoProjeto/${type}`)
  }

  const consultarProjeto = async projetoId => {
    try {
      const response = await trackPromise(
        api.leoplan.apiGetProjetoDetAsync(projetoId)
      )

      if (response.fail) throw new Error()

      return response.data.projeto
    } catch {
      throw new Error('Ocorreu um erro ao consultar o projeto')
    }
  }

  const downloadPDF = async (otimizacao, project) => {
    try {
      const projetoApi = await consultarProjeto(project.projetoId)
      const materiais = projetoApi.projetoProduto
      const materialCodeList = materiais.filter(
        x => x.id.toString() === otimizacao.materialId.toString()
      )

      const materialCode =
        materialCodeList.length > 0
          ? materialCodeList[0].codigo
          : otimizacao.materialId
      const materialDescricao =
        materialCodeList.length > 0
          ? materialCodeList[0].descricao
              .substring(0, 20)
              .normalize('NFD')
              .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s._])/g, '')
              .replace(/([^\w]+|\s+)/g, '_')
          : ''

      const response = await trackPromise(
        Promise.all([
          api.leoplan.apiOtimizarGetFiles(
            'Sumario',
            otimizacao.id,
            otimizacao.index,
            'pdf',
            projetoApi.id,
            `${projetoApi.id}-${projetoApi.nome}-${projetoApi.lojaIdErp}-${materialCode}`
          ),
          api.leoplan.apiOtimizarGetFiles(
            'Detalhado',
            otimizacao.id,
            otimizacao.index,
            'pdf',
            projetoApi.id,
            `${projetoApi.id}-${projetoApi.nome}-${projetoApi.lojaIdErp}-${materialCode}`
          )
        ])
      )

      if (response[0].fail || response[1].fail) throw new Error()

      const sumario = new Blob([response[0].data], { type: 'file/pdf' })
      const detalhado = new Blob([response[1].data], { type: 'file/pdf' })

      saveAs(
        sumario,
        `${projetoApi.id}-${projetoApi.nome}-${projetoApi.lojaIdErp}-${materialCode}-${materialDescricao}_Sumario.pdf`
      )

      saveAs(
        detalhado,
        `${projetoApi.id}-${projetoApi.nome}-${projetoApi.lojaIdErp}-${materialCode}-${materialDescricao}_Detalhado.pdf`
      )
    } catch {
      showMsgE('Não foi possível carregar o arquivo')
    }
  }

  const getPlanoPdf = async project => {
    try {
      const response = await trackPromise(
        api.leoplan.apiGetOtimizacao(project.projetoId)
      )

      if (!response.data.length) throw new Error()

      response.data.forEach(otimizacao => {
        downloadPDF(otimizacao, project)
      })
    } catch {
      showMsgE('Não foi possível carregar os arquivos')
    }
  }

  const duplicarPlano = async (projectId, nome) => {
    try {
      setLoadAction(true)
      const response = await trackPromise(
        api.leoplan.apiClonarPlano(projectId, nome)
      )

      if (response.fail || !response.data.id) throw new Error(response.errors)
      showMsgS('Plano duplicado com sucesso, redirecionando...')
      setTimeout(function () {
        navigate(`/plano-de-corte/detalhe/${response.data.id}`)
      }, 1000)
    } catch (error) {
      showMsgE(error)
    } finally {
      setLoadAction(false)
    }
  }

  const handleFormChange =
    (propName = '') =>
    event => {
      if (propName === 'dateDe') {
        setDateDe(event.target.value)
        fitrarPlano(filterText, event.target.value, dateAte, planosCorte)
      } else if (propName === 'dateAte') {
        setDateAte(event.target.value)
        fitrarPlano(filterText, dateDe, event.target.value, planosCorte)
      }
    }

  const handleFilterChange = () => event => {
    setFilterText(event.target.value)
    const txt = event.target.value || ''

    fitrarPlano(txt, dateDe, dateAte, planosCorte)
  }

  const fitrarPlano = (text, dateInicio, dateFim, listaPlanos) => {
    const textFilter = text.toUpperCase()
    let filter = []

    if (text && text.length > 0) {
      filter = listaPlanos.filter(x => {
        if (x?.projetoId?.toString()?.includes(textFilter)) {
          return x
        } else if (x?.projetoNome?.toUpperCase()?.includes(textFilter)) {
          return x
        } else if (x?.vendedor?.toUpperCase()?.includes(textFilter)) {
          return x
        } else if (x?.clienteLEO?.toUpperCase()?.includes(textFilter)) {
          return x
        } else if (x?.origemProjeto?.toUpperCase()?.includes(textFilter)) {
          return x
        } else if (
          moment(x?.dataUltimaAtualizacao)
            .format('DD/MM/YYYY')
            .includes(textFilter)
        ) {
          return x
        } else if (
          moment(x?.dataCriacao).format('DD/MM/YYYY').includes(textFilter)
        ) {
          return x
        } else if (x.pedidoSap) {
          if (x?.pedidoSap?.toUpperCase()?.includes(textFilter)) {
            return x
          }
        }
        return null
      })
    } else {
      filter = listaPlanos.filter(x => x)
    }

    if (dateInicio) {
      const dateI = dateInicio.substring(0, 10).replace(/[^\d]/g, '')
      filter = filter.filter(x => {
        if (x.dataCriacao.substring(0, 10).replace(/[^\d]/g, '') >= dateI) {
          return x
        }
        return null
      })
    }
    if (dateFim) {
      const dateF = dateFim.substring(0, 10).replace(/[^\d]/g, '')
      filter = filter.filter(x => {
        if (x.dataCriacao.substring(0, 10).replace(/[^\d]/g, '') <= dateF) {
          return x
        }
        return null
      })
    }

    setPlanosFiltrados(filter)
  }
  const onDownloadPdf = () => {
    saveAs(baseUrl + 'ManualLeoPlan.pdf', 'ManualLeoPlan.pdf')
  }

  useEffect(() => {
    fitrarPlano(filterText, dateDe, dateAte, planosCorte)
  }, [planosCorte, filterText, dateDe, dateAte])

  useEffect(() => {
    if (
      props.loaded &&
      planosCorte.length === 0 &&
      user.tipoUserLeoPlan !== 'vendedor' &&
      props.tipoLista === 0
    ) {
      setOpenBemVindo(true)
    }
  }, [planosFiltrados])

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12 mb10">
          <TextField
            label="Pesquisar"
            size="small"
            variant="filled"
            value={filterText}
            onChange={handleFilterChange()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 mb10">
          <TextField
            id="dateDe"
            label="Date de"
            type="date"
            size="small"
            variant="filled"
            value={dateDe}
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: '1900-01-24', max: dateAte }}
            onChange={handleFormChange('dateDe')}
          />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 mb10">
          <TextField
            id="dateAte"
            label="Date até"
            type="date"
            size="small"
            variant="filled"
            value={dateAte}
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: dateDe, max: '9999-05-31' }}
            onChange={handleFormChange('dateAte')}
          />
        </div>
      </div>
      <div className="row flex">
        <div className="col w-100 flex flex-col sm:flex-row sm:justify-end">
          <div className="pb-1 md:px-1">
            <Button
              variant="contained"
              color="primary"
              className="w-100"
              onClick={() => setOpenModalTypeProj(true)}
            >
              Novo Projeto
            </Button>
          </div>
          <div className="pb-1 md:px-1">
            <Button
              variant="contained"
              color="secondary"
              className="w-100"
              onClick={handleOpenPrimeirosPassos}
            >
              Vídeo Tutorial
            </Button>
          </div>
          <div className="pb-1 md:px-1">
            <Button
              variant="contained"
              color="secondary"
              className="w-100"
              onClick={onDownloadPdf}
              endIcon={<PictureAsPdfIcon />}
            >
              Manual LeoPlan
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TableContainer>
            <Table aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Projeto</StyledTableCell>
                  <StyledTableCell>Tipo</StyledTableCell>
                  <StyledTableCell>Criação</StyledTableCell>
                  <StyledTableCell>Atualização</StyledTableCell>
                  <StyledTableCell>Vendedor</StyledTableCell>
                  {user.tipoUserLeoPlan === 'vendedor' ? (
                    <>
                      <StyledTableCell>Canal</StyledTableCell>
                      <StyledTableCell>Cliente Leo</StyledTableCell>
                    </>
                  ) : null}
                  {tipoLista === 0 && (
                    <StyledTableCell>Otimização Status</StyledTableCell>
                  )}
                  {tipoLista === 1 && (
                    <StyledTableCell>Pedido SAP</StyledTableCell>
                  )}
                  <StyledTableCell align="right">Opções</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? planosFiltrados.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : planosFiltrados
                ).map(row => (
                  <StyledTableRow key={row.projetoId}>
                    <StyledTableCell data="Projeto">
                      <span className="ProjectNameCell_ProjectList">
                        {row.projetoId} -
                        {tipoLista === 0 && row.origemProjeto !== 'LEOMOB' ? (
                          <EditableCell
                            handleSave={value =>
                              handleSaveName(row.projetoId, value)
                            }
                            cellContent={row.projetoNome}
                          />
                        ) : (
                          ` ${row?.projetoNome}`
                        )}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell data="Tipo">
                      {Utils.returnTipoProjetDescricao(row.tipoCorte)}
                    </StyledTableCell>
                    <StyledTableCell data="Criação">
                      {moment(row.dataCriacao).format('DD/MM/YYYY')}
                    </StyledTableCell>
                    <StyledTableCell data="Atualização">
                      {moment(row.dataUltimaAtualizacao).format('DD/MM/YYYY')}
                    </StyledTableCell>
                    <StyledTableCell data="Vendedor">
                      {row.vendedor}
                    </StyledTableCell>
                    {user.tipoUserLeoPlan === 'vendedor' && (
                      <>
                        <StyledTableCell data="Canal">
                          {row.origemProjeto}
                        </StyledTableCell>
                        <StyledTableCell data="Cliente">
                          {row.clienteLEO}
                        </StyledTableCell>
                      </>
                    )}
                    {tipoLista === 0 && (
                      <StyledTableCell data="Otimização Status">
                        {Utils.returnStatusOtimizacaoString(row.status)}
                      </StyledTableCell>
                    )}
                    {tipoLista === 1 && (
                      <StyledTableCell data="Pedido">
                        {row.pedidoSap ? row.pedidoSap : '-'}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="right" component="th" scope="row">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="mr5 d-lg-none d-xl-none"
                        size="small"
                        onClick={() => {
                          goToDetalhe(row)
                        }}
                      >
                        Editar
                      </Button>
                      {row.status === 3 && (
                        <Button
                          variant="outlined"
                          color="primary"
                          className="mr5 d-lg-none d-xl-none"
                          size="small"
                          onClick={() => {
                            getPlanoPdf(row)
                          }}
                        >
                          Download
                        </Button>
                      )}

                      <Tooltip title="Mais Opções">
                        <IconButton
                          size="small"
                          className="m5 float-right"
                          onClick={event => projetoMoreOptions(event, row)}
                        >
                          {' '}
                          <MoreVert />{' '}
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        title={
                          row.origemProjeto === 'LEOMOB'
                            ? 'Visualizar Projeto'
                            : 'Editar Projeto'
                        }
                      >
                        <IconButton
                          aria-label="edit"
                          variant="outlined"
                          color="primary"
                          className="m5 float-right d-none d-lg-block d-xl-block"
                          size="small"
                          onClick={() => {
                            goToDetalhe(row)
                          }}
                        >
                          {' '}
                          {row.origemProjeto === 'LEOMOB' ? (
                            <VisibilityIcon />
                          ) : (
                            <EditIcon />
                          )}{' '}
                        </IconButton>
                      </Tooltip>
                      {row.status === 3 && (
                        <Tooltip title="Download Plano">
                          <IconButton
                            aria-label="edit"
                            variant="outlined"
                            color="primary"
                            className="m5 float-right d-none d-lg-block d-xl-block"
                            size="small"
                            onClick={() => {
                              getPlanoPdf(row)
                            }}
                          >
                            {' '}
                            <CloudDownload />{' '}
                          </IconButton>
                        </Tooltip>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                {emptyRows > 0 && (
                  <StyledTableRow style={{ height: 53 * emptyRows }}>
                    {user.tipoUserLeoPlan === 'vendedor' ? (
                      <StyledTableCell colSpan={9}></StyledTableCell>
                    ) : (
                      <StyledTableCell colSpan={8}></StyledTableCell>
                    )}
                  </StyledTableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'Todos', value: -1 }
                    ]}
                    labelRowsPerPage="Itens por página"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} de ${count}`
                    }
                    count={planosFiltrados.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Linhas por página' },
                      native: true
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>

      <DialogDuplicaPlano
        projetoNome={baseProject?.projetoNome}
        projetoId={baseProject?.projetoId}
        open={openModalDuplicar}
        close={handleCloseModalDuplicar}
        action={duplicarPlano}
        showMsgW={showMsgW}
        loadDuplicate={loadAction}
      />
      <DialogPrimeirosPassos
        open={openPrimeirosPassos}
        close={handleClosePrimeirosPassos}
      />
      <DialogBemVindo
        open={openBemVindo}
        close={handleCloseBemVindo}
        onAssistir={onAssistir}
      />
      <DialogEscolhaProjType
        open={openModalTypeProj}
        close={closeModalTypeProj}
        action={initProjByType}
      />
      <DialogAtivarInativarPlano
        projetoNome={baseProject?.projetoNome}
        projetoId={baseProject?.projetoId}
        ativo={baseProject?.ativo}
        loadAction={loadAction}
        open={openModalExcluir}
        close={closeModalExcluir}
        action={projetoAtivarDesativar}
        showMsgW={showMsgW}
      />

      <MenuMoreOptions
        id="optionsProj"
        open={openMenuProjeto}
        close={closeMenuProjeto}
        projeto={baseProject}
        tipoLista={tipoLista}
        openModalDuplicar={handleOpenModalDuplicar}
        openModalExcluir={handleOpenModalExcluir}
      />

      <Backdrop className={classes.backdrop} open={openBkdrop}>
        {' '}
        <CircularProgress color="inherit" />{' '}
      </Backdrop>
    </>
  )
}

export default ListaProjetos
