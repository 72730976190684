import CloseIcon from '@mui/icons-material/Close'
import SaveAlt from '@mui/icons-material/SaveAlt'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { saveAs } from 'file-saver'
import React, { memo, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { trackPromise } from 'react-promise-tracker'

import { useProjeto } from '@hook/useProjeto'
import { useUser } from '@hook/useUser'

import api from '@service/api'

const DialogDownloadFile = props => {
  const {
    openModalDownload,
    handleCloseModalDownload,
    otimizacaoIdDown,
    loja,
    otimizacaoCodMaterial,
    otimizacaoIndexDown,
    showMsgE
  } = props
  const { user } = useUser()
  const { projeto } = useProjeto()

  const [fileTypes, setFileTypes] = useState([])
  const [fileTypeDown, setFileTypeDown] = React.useState('')

  const changeFileFormat = event => {
    setFileTypeDown(event.target.value)
  }
  useEffect(() => {
    if (projeto?.projetoProduto?.length > 0 && otimizacaoCodMaterial) {
      if (user.tipoUserLeoPlan === 'vendedor' && projeto?.tipoCorte !== 5) {
        setFileTypes([
          'xml',
          'pdf',
          'saw',
          'acad',
          'fdt',
          'cutplanning',
          'prog',
          'wincut'
        ])
      } else setFileTypes(['pdf'])
    }
  }, [user, otimizacaoCodMaterial])

  const executarDownload = async () => {
    try {
      const response = await trackPromise(
        api.leoplan.apiOtimizarGetFiles(
          'Sumario',
          otimizacaoIdDown,
          otimizacaoIndexDown,
          fileTypeDown,
          projeto?.id,
          `${projeto?.id}-${projeto?.nome}-${loja.idErp}-${otimizacaoCodMaterial}`
        )
      )

      if (response.fail) throw new Error('Não foi possível carregar o arquivo')

      let formatfile = fileTypeDown
      if (
        fileTypeDown === 'acad' ||
        fileTypeDown === 'prog' ||
        fileTypeDown === 'wincut'
      ) {
        formatfile = 'zip'
      }
      const data = new Blob([response.data], { type: `file/${formatfile}` })
      if (formatfile !== 'pdf') {
        saveAs(
          data,
          `${projeto?.id}-${projeto?.nome}-${loja.idErp}-${otimizacaoCodMaterial}_Sumario.${formatfile}`
        )
      } else {
        const produtoDaOtimizacao = projeto?.projetoProduto.filter(
          x => x.codigo.toString() === otimizacaoCodMaterial.toString()
        )[0]
        const produtoDescricao = produtoDaOtimizacao.descricao
          .substring(0, 20)
          .normalize('NFD')
          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s._])/g, '')
          .replace(/([^\w]+|\s+)/g, '_')
        saveAs(
          data,
          `${projeto?.id}-${projeto?.nome}-${loja.idErp}-${otimizacaoCodMaterial}-${produtoDescricao}_Sumario.${formatfile}`
        )
      }

      if (fileTypeDown === 'pdf') {
        const responseDetalhado = await trackPromise(
          api.leoplan.apiOtimizarGetFiles(
            'Detalhado',
            otimizacaoIdDown,
            otimizacaoIndexDown,
            fileTypeDown,
            projeto?.id,
            `${projeto?.id}-${projeto?.nome}-${loja.idErp}-${otimizacaoCodMaterial}`
          )
        )

        if (responseDetalhado.fail) {
          throw new Error('Não foi possível carregar o arquivo Detalhado')
        }

        let formatfile = fileTypeDown

        if (fileTypeDown === 'acad') {
          formatfile = 'zip'
        }

        const dataDetalhado = new Blob([responseDetalhado.data], {
          type: `file/${formatfile}`
        })

        const produtoDaOtimizacao = projeto?.projetoProduto.filter(
          x => x.codigo.toString() === otimizacaoCodMaterial.toString()
        )[0]
        const produtoDescricao = produtoDaOtimizacao.descricao
          .substring(0, 20)
          .normalize('NFD')
          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s._])/g, '')
          .replace(/([^\w]+|\s+)/g, '_')
        saveAs(
          dataDetalhado,
          `${projeto?.id}-${projeto?.nome}-${loja.idErp}-${otimizacaoCodMaterial}-${produtoDescricao}_Detalhado.${formatfile}`
        )
      }
    } catch (error) {
      showMsgE(error)
    }
  }

  return (
    <Dialog
      open={openModalDownload}
      onClose={handleCloseModalDownload}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Download arquivos</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseModalDownload}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <p>Escolha um dos formatos abaixo</p>
        <FormControl variant="filled">
          <InputLabel htmlFor="filled-fileformat">Formatos</InputLabel>
          <Select value={fileTypeDown} onChange={changeFileFormat}>
            {fileTypes.map(fileformat => {
              return (
                <MenuItem value={fileformat} key={fileformat}>
                  {fileformat}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          className={isMobile ? 'w-100 mt-2 mb-4' : 'mb-4'}
          onClick={executarDownload}
          disabled={!fileTypeDown}
        >
          <SaveAlt />
          Baixar arquivo
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default memo(DialogDownloadFile)
