// snackbar
export const SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS'
export const SNACKBAR_WARNING = 'SNACKBAR_WARNING'
export const SNACKBAR_ERROR = 'SNACKBAR_ERROR'
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR'

// plano de corte
export const PLAN_NEWPROJETO = 'PLAN_NEWPROJETO'
export const PLAN_STATUSPROJETO = 'PLAN_STATUSPROJETO'
export const PLAN_UPTAMBIENTE = 'PLAN_UPTAMBIENTE'
export const PLAN_LOADAMBIENTE = 'PLAN_LOADAMBIENTE'
export const PLAN_UPTMATERIAIS = 'PLAN_UPTMATERIAIS'
export const PLAN_LOADMATERIAIS = 'PLAN_LOADMATERIAIS'
export const PLAN_EDTPECA = 'PLAN_EDTPECA'
export const PLAN_EDTPECABORDATOTAL = 'PLAN_EDTPECABORDATOTAL'
export const PLAN_EXCLUDEFITA = 'PLAN_EXCLUDEFITA'
export const PLAN_UPTBUSCAFITAS = 'PLAN_UPTBUSCAFITAS'
export const PLAN_UPTFITAS_RECOMENDADAS = 'PLAN_UPTFITAS_RECOMENDADAS'
export const PLAN_ADDFITA_RECOMENDADA = 'PLAN_ADDFITA_RECOMENDADA'
export const PLAN_AMBIENTE_SALVO = 'PLAN_AMBIENTE_SALVO'

export const PLAN_RESET_CONFIGLOJA = 'PLAN_RESET_CONFIGLOJA'
export const PLAN_SET_CONFIGLOJA = 'PLAN_SET_CONFIGLOJA'
