import Alert from '@mui/material/Alert'
import React, { memo } from 'react'

const InfoMsg = props => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <Alert severity={props.type || 'info'}>
          <strong>{props.title}</strong> {props.msg}
        </Alert>
      </div>
    </div>
  )
}

export default memo(InfoMsg)
