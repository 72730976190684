import MailIcon from '@mui/icons-material/Mail'
import { Button } from '@mui/material'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import { useConfiguracao } from '@hook/useConfiguracao.js'
import { useProjeto } from '@hook/useProjeto'

import PageTitle from '@component/PageTitle/PageTitle.js'

import DialogResumoCanaletado from '../Dialogs/DialogResumoCanaletado.js'
import BoxPlanoCorte from './components/BoxPlanoCorte.js'
import ButtonPrint from './components/ButtonPrint.js'
import DialogAlertEstoque from './components/DialogAlertEstoque.js'
import DialogEnviarEmail from './components/DialogEnviarEmail.js'
import ListagemPecas from './components/ListagemPecas.js'
import ResumoAlertInfo from './components/ResumoAlertInfo.js'
import ResumoDescritivoProjeto from './components/ResumoDescritivoProjeto.js'
const PlanoCorte = ({
  invalidMaterial,
  verifyMaterial,
  modalDownload,
  checkIgnorarVeio,
  material,
  clearAlertEstoque
}) => {
  const { print } = useParams()
  const componentRef = useRef()

  const { projeto, cliente, otimizacao, lojaCongifOtmizacao } = useProjeto()
  const { configData } = useConfiguracao()

  const [loadPrint, setLoadPrint] = useState(false)
  const [alertEstoqueModal, setAlertEstoqueModal] = useState(false)
  const [resumoCanaletadoModal, setResumoCanaletadoModal] = useState(false)
  const [materiaisCanaletados, setMateriaisCanaletados] = useState(false)
  const [modalEmail, setModalEmail] = useState(false)

  const handlePrintBtn = () => {
    window.open(window.location.href + '/print')
  }
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => loadPrintChange(true),
    onBeforePrint: () => loadPrintChange(false),
    onAfterPrint: () => window.close(),
    documentTitle: 'Plano LeoPlan',
    suppressErrors: true,
    content: () => componentRef.current
  })

  const loadPrintChange = status => {
    // eslint-disable-next-line no-new
    new Promise(resolve => {
      resolve(setLoadPrint(status))
    })
  }

  const hasIgnorarVeio = checkIgnorarVeio()

  const handleCloseAlertEstoqueModal = () => {
    setAlertEstoqueModal(false)
    clearAlertEstoque()
  }

  useEffect(() => {
    if (!print && lojaCongifOtmizacao?.minLimitFL) {
      verifyMaterial(
        projeto.projetoProduto,
        otimizacao,
        lojaCongifOtmizacao.minLimitFL
      )
    }
  }, [lojaCongifOtmizacao?.minLimitFL])

  useLayoutEffect(() => {
    if (print) {
      handlePrint()
    }
  }, [verifyMaterial])

  useEffect(() => {
    if (material && projeto.tipoCorte !== 5) setAlertEstoqueModal(true)
  }, [material])

  useEffect(() => {
    const materiaisCanaletadosFiltered =
      (projeto.projetoProduto || []).filter(
        m => m.canaletado > 0 && m.tipoProdutoId === 1
      ) || []

    if (materiaisCanaletadosFiltered.length > 0) {
      setResumoCanaletadoModal(true)
      setMateriaisCanaletados(materiaisCanaletadosFiltered)
    }
  }, [projeto.projetoProduto])

  return (
    <>
      <div ref={componentRef}>
        <div className={isMobile ? '' : 'pad20 padT10 padB10'}>
          <ResumoAlertInfo
            invalidMaterial={invalidMaterial}
            hasIgnorarVeio={hasIgnorarVeio}
          />

          <PageTitle
            title="Resumo do projeto"
            action={
              <>
                {!isMobile && (
                  <ButtonPrint action={handlePrintBtn} load={loadPrint} />
                )}
                {projeto.origemProjeto !== 3 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalEmail(true)}
                    className={isMobile ? 'w-100' : 'noPrint m5'}
                  >
                    <MailIcon className="mr5" /> Enviar e-mail
                  </Button>
                )}
              </>
            }
          />

          <ResumoDescritivoProjeto />
        </div>

        {!isMobile && <ListagemPecas />}

        <div
          className={
            isMobile ? 'w-100' : 'pad20 block mb2 pageBreakBefore blockVisible'
          }
        >
          <PageTitle title="Plano de Corte" />
          <BoxPlanoCorte modalDownload={modalDownload} />
        </div>
      </div>
      <DialogEnviarEmail
        open={modalEmail}
        close={() => setModalEmail(false)}
        cliente={cliente}
      />

      {alertEstoqueModal && (
        <DialogAlertEstoque
          open={alertEstoqueModal}
          close={handleCloseAlertEstoqueModal}
          material={material}
        />
      )}

      {resumoCanaletadoModal && (
        <DialogResumoCanaletado
          open={resumoCanaletadoModal}
          close={() => setResumoCanaletadoModal(false)}
          materiaisCanaletados={materiaisCanaletados}
          configuracoesCanaletagem={configData?.configuracoesCanaletagem}
        />
      )}
    </>
  )
}

export default PlanoCorte
