import CloseIcon from '@mui/icons-material/Close'
import { Button } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React from 'react'
import { isMobile } from 'react-device-detect'

import { useProjeto } from '@hook/useProjeto.js'

import Utils from '@service/serviceUtils.js'

import ConfigFita from '../Ambientes/ConfigFita.js'

const DialogBordeamentoPeca = ({
  open,
  close,
  peca,
  indexPeca,
  chapaSelecionada,
  fitasSelecionadas,
  listaFitasDisponiveis,
  selectFitas,
  errors,
  warnings,
  tabNewPeca,
  setFitaSide
}) => {
  const { projeto } = useProjeto()

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Aplicação de bordas</Typography>
        <IconButton aria-label="close" onClick={close} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <div>
            <Autocomplete
              multiple
              limitTags={1}
              value={fitasSelecionadas}
              inputValue={fitasSelecionadas.descricao}
              isOptionEqualToValue={(option, value) =>
                `${option.codigo} - ${option.descricao}` ===
                `${value.codigo} - ${value.descricao}`
              }
              id={`tabela-pecas-fitas-${peca.id}`}
              options={listaFitasDisponiveis}
              noOptionsText={'Nenhuma fita cadastrada no projeto'}
              onChange={(_, value) => selectFitas(value)}
              getOptionLabel={option =>
                `${option.codigo} - ${option.descricao}`
              }
              renderOption={(props, option) => (
                <div {...props}>
                  <img
                    src={Utils.getImageUrl(option.codigo)}
                    onError={Utils.imgBrokenFita}
                    alt=""
                  />
                  {option.codigo} - {option.descricao}
                </div>
              )}
              disabled={
                !chapaSelecionada ||
                !!chapaSelecionada?.canaletado ||
                projeto.origemProjeto === 3
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label="Fitas"
                  variant="filled"
                  size="small"
                  error={!!errors.fita}
                  helperText={errors.fita || warnings.fita}
                  disabled={projeto.tipoCorte === 5}
                />
              )}
            />
          </div>

          {fitasSelecionadas.length ? (
            <div className="mt-5">
              <ConfigFita
                tabNewPeca={tabNewPeca}
                fitasSelecionadas={fitasSelecionadas}
                planEdtPecaBordaTotal={peca.planEdtPecaBordaTotal}
                peca={peca}
                id={`tabela-pecas-menu-${peca.id}`}
                setFitaSide={setFitaSide}
                indexPeca={indexPeca}
              />
            </div>
          ) : (
            'Selecione uma fita'
          )}

          <div className="col-sm-12 text-center mt20">
            <Button
              variant="contained"
              className="mr5 mb10 blackButton"
              onClick={close}
            >
              Fechar
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DialogBordeamentoPeca
