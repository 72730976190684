import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

const DialogDelAmbiente = props => {
  const { openModalExcluirAmb, handleCloseModalExcluirAmb, removeAmbiente } =
    props

  return (
    <Dialog
      open={openModalExcluirAmb}
      onClose={handleCloseModalExcluirAmb}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Excluir Ambiente</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseModalExcluirAmb}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="text-center" dividers>
        <Typography className="mb20">
          Tem certeza que deseja excluir este ambiente? todas as peças deste
          ambiente serão deletadas também
        </Typography>
        <Button
          onClick={removeAmbiente}
          variant="contained"
          color="secondary"
          className="mb10 mr5"
        >
          Excluir Ambiente
        </Button>
        <Button
          onClick={handleCloseModalExcluirAmb}
          variant="contained"
          className="mb10 blackButton"
        >
          Cancelar
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default memo(DialogDelAmbiente)
