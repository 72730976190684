import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

const DialogAlertUsinagem = ({ open, action }) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        <Typography>Atenção</Typography>
      </DialogTitle>
      <DialogContent className="text-center" dividers>
        <div className="unirModal">
          <Typography className="mb20">
            Ao substituir as chapas, os serviços de furação e usinagem serão
            removidos automaticamente nas peças relacionadas. Confirme para
            prosseguir.
          </Typography>
          <Button
            onClick={action}
            variant="contained"
            className="mb10  mr5 blackButton"
          >
            Estou ciente
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default memo(DialogAlertUsinagem)
