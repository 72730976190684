import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

export default function InfoTip({ info }) {
  const useStylesBootstrap = makeStyles()(theme => ({
    arrow: {
      color: theme.palette.common.black
    },
    tooltip: {
      backgroundColor: theme.palette.common.black
    }
  }))

  function BootstrapTooltip(props) {
    const { classes } = useStylesBootstrap()

    return <Tooltip arrow classes={classes} {...props} />
  }

  return (
    <BootstrapTooltip title={info}>
      <InfoIcon fontSize="small" color="disabled" />
    </BootstrapTooltip>
  )
}
