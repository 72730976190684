import LinearProgress from '@mui/material/LinearProgress'
import { usePromiseTracker } from 'react-promise-tracker'

const LoadingBar = () => {
  const { promiseInProgress } = usePromiseTracker()

  return (
    promiseInProgress && (
      <div className="loadPage">
        <LinearProgress />
      </div>
    )
  )
}

export default LoadingBar
