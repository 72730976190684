import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useConfiguracao } from '@hook/useConfiguracao'

export default function BottomBar({
  setSelectedTab,
  indexTabPlanoCorte,
  actionNovoAgrupamento
}) {
  const navigate = useNavigate()
  const { handleSaveConfiguracoesPlanoCorte } = useConfiguracao()

  const handleVoltar = () => {
    navigate(-1)
  }

  return (
    <div className="groupFixedBtn">
      <div className="d-flex justify-content-between">
        <Button
          variant="contained"
          className="m5 blackButton"
          onClick={handleVoltar}
        >
          Voltar
        </Button>
        {setSelectedTab === indexTabPlanoCorte ? (
          <Button
            variant="contained"
            className="m5"
            color="secondary"
            onClick={handleSaveConfiguracoesPlanoCorte}
          >
            Salvar
          </Button>
        ) : (
          <Button
            variant="contained"
            className="m5"
            color="secondary"
            onClick={actionNovoAgrupamento}
          >
            Adicionar configuração
          </Button>
        )}
      </div>
    </div>
  )
}
