import imgCanal from '@asset/img/canal.png'
import imgDobradica from '@asset/img/dobradica.png'
import imgRebaixo from '@asset/img/rebaixo.png'
import { ListSubheader, MenuItem, Select, Checkbox } from '@mui/material'
import { useState } from 'react'

import Utils from '@base/services/serviceUtils'

const SelectUsinagem = ({
  tabIndex,
  disabled,
  projetoPecaServico,
  setProjetoPecaServico
}) => {
  const [open, setOpen] = useState(false)

  const handleChange = event => {
    const value = event.target.value

    const selected = value
      .filter(
        (item, _index, array) =>
          array.filter(i => i.tipoServico === item.tipoServico).length === 1
      )
      .slice(-1)
    setProjetoPecaServico([{ ...projetoPecaServico[0], ...selected[0] }])

    setOpen(false)
  }

  const isChecked = (projetoPecaServico, tipoServico) => {
    return (
      !!projetoPecaServico?.length &&
      projetoPecaServico?.some(item => item.tipoServico === tipoServico)
    )
  }

  return (
    <Select
      multiple
      displayEmpty
      size="small"
      className="flex-1"
      tabIndex={tabIndex}
      value={projetoPecaServico || []}
      onChange={handleChange}
      disabled={disabled}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderValue={projetoPecaServico => (
        <>
          {projetoPecaServico?.length === 0
            ? 'Furação e Usinagem'
            : Utils.mapNomeServico(projetoPecaServico[0]?.tipoServico)}
        </>
      )}
    >
      <ListSubheader>Furação</ListSubheader>
      <MenuItem key={1} value={{ tipoServico: 1 }}>
        <img src={imgDobradica} className="mr-3 w-16" />
        <Checkbox
          className="defaultColor"
          checked={isChecked(projetoPecaServico, 1)}
        />
        Dobradiça
      </MenuItem>

      <ListSubheader>Usinagem</ListSubheader>
      <MenuItem key={2} value={{ tipoServico: 2 }}>
        <img src={imgCanal} className="mr-3 w-16" />
        <Checkbox
          className="defaultColor"
          checked={isChecked(projetoPecaServico, 2)}
        />
        Canal
      </MenuItem>
      <MenuItem key={3} value={{ tipoServico: 3 }}>
        <img src={imgRebaixo} className="mr-3 w-16" />
        <Checkbox
          className="defaultColor"
          checked={isChecked(projetoPecaServico, 3)}
        />
        Rebaixo
      </MenuItem>
    </Select>
  )
}
export default SelectUsinagem
